// @ts-nocheck

import {isAnonymousFlow} from 'config';
import {Modal, ScreenNames} from 'modules';
import React from 'react';
import {BackHandler, StyleSheet, View} from 'react-native';
import 'react-native-gesture-handler';
import Animated, {
  EasingNode,
  interpolateNode,
  timing,
} from 'react-native-reanimated';
import SafeAreaView from 'react-native-safe-area-view';
import {connect} from 'react-redux';
import {
  addFamilyHistory,
  addMinInfoSetSymtomsToQueue,
  ASSESSMENT_QUESTION_ANSWER_STAGE,
  Colors,
  createPatientProfile,
  debouncer,
  ENTER_BIRTHDATE,
  ENTER_BIRTH_MONTH,
  ENTER_COUNTRY,
  ENTER_GENDER,
  EventType,
  getAllSubBodyPartsFromQr,
  getAssessmentToken,
  getMainFma,
  HUMAN_ANATOMY,
  INITIAL_STAGE,
  isEntireSelection,
  MARKPRO_MEDIUM,
  MONTH_PICKER_SIMPLIFIED,
  openSession as startSession,
  reopenSession,
  SECOND_PASS,
  SELECT_SYMPTOM,
  SELECT_SYMPTOM_NO,
  SELECT_SYMPTOM_SUGGESTION,
  SELECT_SYMPTOM_YES,
  SUBMIT_BIRTH_DATE_AND_TIME,
  SUBMIT_BIRTH_MONTH,
  SUBMIT_COUNTRY,
  updatePatientProfile,
  useAnalytics,
  YEAR_PICKER,
} from 'utils';
import uuid from 'uuid';
import {
  AssessmentCloseModal,
  AssessmentMessage,
  Chat,
  ExtractHeightWrapper,
  GiftedChat,
  HumanAnatomyModal,
  SearchBar,
  Socket,
  SymptomListModal,
} from '../../../components';
import {IsUserStartingAnAssessment} from '../../../redux/actions/Application';
import {ChatHeaderAssessment} from './ChatHeaderAssessment';

const id = uuid.v4();

const anonymousUser = {
  firstname: 'firstName',
  nickname: null,
  lastname: 'lastName',
  username: 'username',
  date_of_birth: '2021',
};
class AssessmentDashboardScreen extends React.Component {
  // This is counting all the messages currently in the chat.
  // This includes questions and answers given by the user.
  // There is currently not a better way to decide when to show the back button
  // So we are going to hide it until the number of messages is less than 13
  private renderBackButtonMessagesThresholdCount = 13;

  socketIo = new Socket();
  chatRef = React.createRef();
  sessionId = undefined;
  translateY = new Animated.Value(-50);
  animatedOpacity = new Animated.Value(1);
  chatHeaderHeight = 1;
  isGoingDown = undefined;
  initialStart = null;
  animationStart = false;
  hideBackButton = false;
  state = {
    equalSizeChips: true,
    messages: [],
    isTyping: true,
    quickReplies: [],
    showChatInputHeaderView: false,
    showSymptomListModal: false,
    symptomListStage: SELECT_SYMPTOM,
    symptomListTag: SELECT_SYMPTOM,
    selectedProfileId: null,
    sessionId: null,
    selectedSymptom: null,
    showDateTimeModal: false,
    showSymptomSuggestions: false,
    customerProfileListCount: 0,
    symptomListForQueue: [],
    showCloseWarning: false,
    showBackButtonIndex: 0,
    unmountedMessages: [],
    showCountyPicker: false,
    countryModalFilterBy: 'country',
    selectedCountry: '',
    showRestartModal: false,
    backButtonDisabled: true,
    refreshClosure: null,
    showBodySelectionModal: false,
    humanAnatomyDataObject: null,
    pickerFormat: YEAR_PICKER,
    shouldRenderAllMonths: false,
    date_of_birth: null,
    country: null,
    sex: null,
    hasQuestionAnswerStageStarted: false,
    showCheckboxPicker: false,
    checkboxData: null,
    riskFactors: [],
  };

  interpolatedTranslateY = interpolateNode(this.animatedOpacity, {
    inputRange: [0, 1],
    outputRange: [1, -50],
  });

  sessionOpened = false;
  currentQuestionId = null;

  toggleIsTyping = () => {
    this.setState((prevState) => {
      return {
        isTyping: !prevState.isTyping,
      };
    });
  };

  updateBlock = (
    res,
    bindMessages = false,
    isTyping = false,
    showChatInputHeaderView = false,
  ) => {
    if (res.submitRiskFactors) {
      this.submitRiskFactors();
      return;
    }
    if (!res.messages) {
      this.props.navigation.replace('AssessmentResultLoading', {
        session_id: this.state.sessionId,
        selected_patient_id: this.state.selectedProfileId,
        main_symptom: this.state.selectedSymptom,
      });
      return;
    }

    this.setState(
      {
        messages: bindMessages ? this.bindMessages(res.messages) : res.messages,
      },
      () => {
        this.setState({
          quickReplies: res.quickReplies ? res.quickReplies : res.actions,
          showChatInputHeaderView: showChatInputHeaderView,
          showCheckboxPicker: res.messages[0]?.step === SECOND_PASS,
          equalSizeChips: res.messages[0]?.step === INITIAL_STAGE,
          checkboxData: res.checkboxData || [],
        });
      },
    );
  };

  bindMessages = (messages) => GiftedChat.append(this.state.messages, messages);

  bindQuickReplies = (messages, qc) => GiftedChat.append(messages, qc);

  getNextMessage = (message, {sessionId}) => {
    this.socketIo.emit(
      this.updateBlock,
      0,
      message,
      {},
      {sessionId},
      this.toggleIsTyping,
    );
  };

  fetchInitialMessages = () => {
    this.setState({
      isTyping: true,
    });
    setTimeout(
      () =>
        this.socketIo.on().then((res) => {
          this.updateBlock(res);
        }),
      1000,
    );
  };

  componentDidMount() {
    BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackButtonClick,
    );
    this.props.dispatch(IsUserStartingAnAssessment(false));
    this.socketIo.init(new AssessmentMessage());
    this.socketIo.initAnimationTyping(this.toggleIsTyping);
    if (isAnonymousFlow) {
      this.onPressChatAction({
        value: {action: ENTER_BIRTHDATE, option: {data: new Date()}},
      });
    } else {
      this.fetchInitialMessages();
    }
    const {logEvent} = useAnalytics();
    logEvent(EventType.ASSESSMENT_START, {timestamp: Date.now()});
  }

  handleBackButtonClick() {
    return true;
  }

  componentWillUnmount() {
    BackHandler.removeEventListener(
      'hardwareBackPress',
      this.handleBackButtonClick,
    );
  }

  updateChatThread = (action) => {
    const {tag, coordinates = null} = action;
    this.chatRef.current?._insertInToAnsweredQuestionsArray(tag);
    this.getNextMessage(
      {
        ...action,
        coordinates,
      },
      {sessionId: this.state.sessionId},
    );
  };

  submitRiskFactors = () => {
    addFamilyHistory(
      {patient_id: this.state.selectedProfileId},
      this.state.riskFactors,
      this.props.platformUrl,
      this.socketIo.getAssessmentToken(),
    ).then(() => {
      reopenSession(
        this.sessionId,
        this.props.platformUrl,
        this.socketIo.getAssessmentToken(),
      )
        .then(() =>
          addMinInfoSetSymtomsToQueue(
            this.props.platformUrl,
            this.socketIo.getAssessmentToken(),
            this.sessionId,
          ),
        )
        .then(() => this.updateChatThread({value: {action: SECOND_PASS}}));
    });
  };

  addToSymptomQueue = (item) => {
    this.setState({
      symptomListForQueue: [...this.state.symptomListForQueue, item],
    });
  };

  onPressChatSymptomChatModal = (action) => {
    if (action?.value?.action === SELECT_SYMPTOM) {
      if (!this.sessionOpened) {
        this.openSession(
          action?.data?.item,
          this.state.selectedProfileId,

          () => {
            this.sessionOpened = true;
            this.addToSymptomQueue(action?.data?.item);
            this.updateChatThread(action);
            this.setState({
              showBackButtonIndex: this.state.messages?.length + 2,
              showSymptomSuggestions: false,
            });
          },
        );
        return 3;
      }

      this.updateChatThread(action);
      return 3;
    } else if (action?.value?.action === SELECT_SYMPTOM_SUGGESTION) {
      this.selectedSymptomsListForQueue = [
        ...this.selectedSymptomsListForQueue,
        action?.data,
      ];
      this.openSession(action?.data, this.state.selectedProfileId);
      this.updateChatThread(action);
      return 1;
    } else if (action?.value?.action === SELECT_SYMPTOM_YES) {
      this.updateChatThread(action);
      this.addToSymptomQueue(action?.data?.item);
      return 2;
    }
    return 0;
  };
  onPressChatAction = (action) => {
    const {tag} = action;

    if (tag === this.currentQuestionId && tag && this.currentQuestionId) {
      return;
    }
    if (action?.value?.action === SECOND_PASS) {
      const riskFactors = action?.value?.option;

      this.setState(
        {riskFactors: this.state.riskFactors.concat(riskFactors)},
        () => this.updateChatThread(action),
      );

      return;
    }
    if (action?.value?.action === ENTER_BIRTHDATE) {
      getAssessmentToken(id, id).then((resToken) => {
        createPatientProfile(
          anonymousUser,
          this.props.platformUrl,
          resToken.data?.token,
        ).then((res) => {
          this.setState({
            selectedProfileId: res.data.patient_id,
          });
        });
        this.socketIo.setAssessmentToken(resToken.data?.token);
      });
      this.chatRef?.current?._insertInToAnsweredQuestionsArray(ENTER_BIRTHDATE);

      this.setState({
        showDateTimeModal: true,
        pickerFormat: YEAR_PICKER,
      });
    }

    if (action?.value?.action === SUBMIT_BIRTH_MONTH) {
      // The date that we should send to the API needs to be defaulted to the month of July and the 15th day
      // The client does not want to collect the full date of birth, because it is indetifiable information
      // The reasoning behind the month being July and the day being 15th is an approximation for medical assesment purposes
      this.setState({
        showDateTimeModal: false,
        date_of_birth:
          this.state.date_of_birth +
          '-' +
          action?.value?.option?.monthNumber +
          '-' +
          '15',
      });
    }
    if (action?.value?.action === SUBMIT_BIRTH_DATE_AND_TIME) {
      this.chatRef?.current?._insertInToAnsweredQuestionsArray(
        ENTER_BIRTH_MONTH,
      );
      this.setState(
        {
          showDateTimeModal: false,
        },
        () => {
          const birthYear = +action?.value?.option?.data;
          if (new Date().getFullYear() - birthYear <= 2) {
            this.setState({
              date_of_birth: '' + birthYear,
              showDateTimeModal: true,
              pickerFormat: MONTH_PICKER_SIMPLIFIED,
              shouldRenderAllMonths: new Date().getFullYear() !== birthYear,
            });
          } else {
            // Hardcoding the month to July and the date to 15th as per the explanation above.
            this.setState({date_of_birth: birthYear + '-7-15'});
          }
        },
      );
    }

    // Sets the user's gender to the state
    if (action?.value?.action === ENTER_GENDER) {
      this.chatRef?.current?._insertInToAnsweredQuestionsArray(ENTER_GENDER);
      this.setState({showCountyPicker: true, sex: action?.title});
    }

    this.currentQuestionId = tag;

    if (action?.value?.action === SELECT_SYMPTOM_YES) {
      this.setState({
        showSymptomListModal: true,
        symptomListTag: tag,
        symptomListStage: SELECT_SYMPTOM_YES,
      });
      return;
    }

    if (action?.value.action === SELECT_SYMPTOM_NO) {
      this.socketIo.setSymptomQueue(this.state.symptomListForQueue);
      this.setState({
        showSymptomSuggestions: false,
        showBackButtonIndex: this.state.messages?.length + 2,
      });
    }

    if (this.onPressChatSymptomChatModal(action)) {
      return;
    }

    if (action?.value.action === ASSESSMENT_QUESTION_ANSWER_STAGE) {
      this.setState({hasQuestionAnswerStageStarted: true});
      const answerIndex = action?.data?.answer_index;
      const itemId = action?.data?.item_id;
      const isEntireBody = isEntireSelection(itemId);
      if (isEntireBody) {
        let allBodyParts = getAllSubBodyPartsFromQr(action?.qr);

        let mainPart = getMainFma(action?.qr);
        if (allBodyParts?.length > 0) {
          const imageObject = {
            mainFmaId: mainPart,
            selectedBodyPart: allBodyParts,
          };

          const answerMap = {
            answer_index: action?.data?.answer_index,
            display_name: action?.data?.display_name,
            question_id: action?.data?.question_id,
          };

          action = {
            coordinates: null,
            data: answerMap,
            msgText: action?.data?.display_name,
            step: HUMAN_ANATOMY,
            svg: imageObject,
            tag: action?.tag,
            value: {action: ASSESSMENT_QUESTION_ANSWER_STAGE},
          };
        }
      }

      if (answerIndex === '-2') {
        this.setState({
          showBodySelectionModal: true,
          humanAnatomyDataObject: action,
        });
        return;
      }
    }

    if (action?.value.action === SUBMIT_COUNTRY) {
      this.chatRef?.current?._insertInToAnsweredQuestionsArray(ENTER_COUNTRY);
      if (isAnonymousFlow) {
        this.setState({showCountyPicker: false});
        updatePatientProfile(
          {
            patient_id: this.state.selectedProfileId,
            country: action?.value?.option?.fullCountryInGeoListFormat,
            date_of_birth: this.state.date_of_birth,
            sex_at_birth: this.state.sex,
          },
          this.props.platformUrl,
          this.socketIo.getAssessmentToken(),
        );
      } else {
      }

      if (action?.data?.patient_id ? true : false) {
        if (action?.title === '+ Add profile') {
          this.props.navigation.navigate('AddProfile', {isFromChat: true});
          return;
        } else {
          this.setState({
            showSymptomSuggestions: true,
          });
        }

        this.setState({
          selectedProfileId: action.data.patient_id,
        });
      }
    }

    const {backButtonDisabled} = this.state;

    if (backButtonDisabled) {
      this.setState({backButtonDisabled: false});
    }

    this.updateChatThread(action);
  };

  renderChatInputHeaderView = (props) => {
    return (
      <SearchBar
        onClick={() => {
          this.setState({
            showSymptomListModal: true,
          });
        }}
        description="Search for other symptoms"
      />
    );
  };
  animate = (opacity) => {
    if (this.animationStart) {
      return;
    }
    timing(this.animatedOpacity, {
      duration: 1000,
      toValue: opacity,
      easing: EasingNode.bezier(0.5, 0.01, 0, 1),
    }).start(() => {
      this.animationStart = false;
    });
  };

  onContentSizeChanged = (isScrollVisible) => {
    if (isScrollVisible) {
      if (this.animatedOpacity !== 1) {
        this.animate(0);
      }
    } else {
      if (this.animatedOpacity !== 0) {
        this.animate(1);
      }
    }
  };

  handleOnScroll = (event) => {
    const {
      nativeEvent: {
        contentOffset: {y: contentOffsetY},
        // eslint-disable-next-line no-unused-vars
        contentSize: {height: contentSizeHeight},
        // eslint-disable-next-line no-unused-vars
        layoutMeasurement: {height: layoutMeasurementHeight},
      },
    } = event;
    if (this.initialStart == null) {
      this.initialStart = contentOffsetY;
    }
    if (this.initialStart > contentOffsetY) {
      this.animate(1);
      this.animationStart = true;
      this.isGoingDown = false;
    } else if (this.initialStart < contentOffsetY) {
      this.animate(0);
      this.isGoingDown = true;
      this.animationStart = true;
    }
    this.initialStart = contentOffsetY;
  };

  openSession = (item, selectedProfileId, callback = () => {}) => {
    this.setState({
      selectedSymptom: item,
    });

    const assessmentToken = this.socketIo.getAssessmentToken();

    if (assessmentToken == null || assessmentToken?.length === 0) {
      return;
    }

    startSession(
      item,
      selectedProfileId,
      this.props.platformUrl,
      assessmentToken,
    )
      .then((res) => {
        this.sessionId = res.data.session_id;

        this.setState({
          sessionId: res.data.session_id,
        });

        callback();
      })
      .catch((err) => {
        // eslint-disable-next-line no-alert
      });
  };

  setChatHeaderHeight = (height) => (this.chatHeaderHeight = height);

  refreshClosure = (quickReplies, messages) => () => {
    this.setState({
      quickReplies: quickReplies,
      messages: messages,
    });

    this.toggleIsTyping();
    this.assessmentGoBack();
  };

  nullafyCurrentQID = () => {
    this.currentQuestionId = null;
  };

  onPressHumanAnatomy = (data) => {
    this.nullafyCurrentQID();
    this.onPressChatAction(data);
  };

  handleBack = debouncer(() => {
    const quickReplies = [];
    const messages = [...this.state.messages];

    // TODO why splicing exactly 3 messages?
    const messagesToDelete = messages.splice(0, 3);

    this.setState({
      unmountedMessages: [...this.state.unmountedMessages, ...messagesToDelete],
      refreshClosure: this.refreshClosure(quickReplies, messages),
      backButtonDisabled: true,
    });
  });

  assessmentGoBack = () => {
    this.socketIo.assessmentBackStep(this.updateBlock, this.sessionId);
  };

  assessmentSubmitBodyPart = (qr) => {
    this.socketIo.assessmentSubmitBodyPart(
      this.updateBlock,
      this.sessionId,
      qr,
    );
  };

  closeHumanAnatomyModal = () => {
    this.nullafyCurrentQID();
    this.setState({
      showBodySelectionModal: false,
    });
  };

  private shouldRenderBackButton = () => {
    if (
      this.state.backButtonDisabled ||
      this.state.messages.length <= this.renderBackButtonMessagesThresholdCount
    ) {
      return false;
    }
    // For now we are going to render the back button only when we pass the first 3 steps of collecting
    // patient personal data and the actual symptom stage has started.
    return this.state.hasQuestionAnswerStageStarted;
    // return (
    //   this.state.messages?.length > this.renderBackButtonMessagesThresholdCount
    // );
  };

  restartAssessmentHandler = () => {
    this.setState(
      {
        customerProfileListCount: 0,
        messages: [],
        showRestartModal: false,
        pickerFormat: YEAR_PICKER,
        showDateTimeModal: false,
        showCountyPicker: false,
        shouldRenderAllMonths: false,
        date_of_birth: null,
        country: null,
        sex: null,
        hasQuestionAnswerStageStarted: false,
        selectedCountry: '',
        selectedProfileId: null,
        symptomListForQueue: [],
      },
      () => {
        this.sessionOpened = false;
        this.currentQuestionId = null;
        this.socketIo.init(new AssessmentMessage());
        this.socketIo.initAnimationTyping(this.toggleIsTyping);
        this.onPressChatAction({
          value: {action: ENTER_BIRTHDATE, option: {data: new Date()}},
        });
      },
    );
  };

  private closeSymptomModalHandler = () => {
    this.currentQuestionId = null;
    this.setState({
      showSymptomListModal: false,
    });
  };

  private setSessionIdHandler = (_, item) => {
    this.setState({
      selectedSymptom: item,
      showSymptomSuggestions: false,
    });
  };

  private closeRestartModalHandler = () => {
    this.setState({
      showRestartModal: false,
    });
  };

  render() {
    const {
      showSymptomListModal,
      selectedProfileId,
      symptomListStage,
      symptomListTag,
      symptomListForQueue,
      sex,
    } = this.state;

    return (
      <View style={[styles.assessmentDashboardScreenContainer]}>
        <SafeAreaView
          forceInset={{bottom: 'never'}}
          style={[styles.safeAreaView]}>
          <View style={[styles.extractHeightWrapperView]}>
            <ExtractHeightWrapper setHeight={this.setChatHeaderHeight}>
              <ChatHeaderAssessment
                translateY={this.interpolatedTranslateY}
                animatedOpacity={this.animatedOpacity}
                goBack={this.props}
                sessionId={this.state.sessionId}
                platformUrl={this.props.platformUrl}
                platformToken={this.props.assessmentToken}
                user={{}}
                showBackButton={this.shouldRenderBackButton()}
                onBackButton={this.handleBack}
                navigateToSettings={() =>
                  this.props.navigation.navigate(ScreenNames.Settings)
                }
                setShowCloseWarning={() => {
                  this.setState({
                    showCloseWarning: true,
                  });
                }}
                windowWidth={this.props.windowWidth}
                showRestartButton={this.state.messages?.length > 2}
                showRestartModal={() => this.setState({showRestartModal: true})}
              />
            </ExtractHeightWrapper>
            <View style={[styles.chatView]}>
              <Chat
                equalSizeChips={this.state.equalSizeChips}
                messages={this.state.messages}
                renderInputAndQuickReplies={this.state.showSymptomSuggestions}
                quickReplies={this.state.quickReplies}
                ref={this.chatRef}
                onSend={() => {}}
                onChatContentSizeChanged={this.onContentSizeChanged}
                chatInputHeaderView={
                  this.state.showChatInputHeaderView
                    ? this.renderChatInputHeaderView
                    : null
                }
                showDateTimeModal={this.state.showDateTimeModal}
                shouldRenderAllMonths={this.state.shouldRenderAllMonths}
                pickerFormat={this.state.pickerFormat}
                showUserLocationModal={false}
                showCountyPicker={this.state.showCountyPicker}
                showCheckboxPicker={this.state.showCheckboxPicker}
                checkboxData={this.state.checkboxData}
                stackAsChips={!this.state.showSymptomSuggestions}
                isTyping={this.state.isTyping}
                placeholder={''}
                onScroll={this.handleOnScroll}
                disabled={false}
                onQuickReply={this.onPressChatAction}
                validate={false}
                field={''}
                countryModalFilterBy={this.state.countryModalFilterBy}
                selectedValueCountry={this.state.selectedCountry}
                selectedValueProvince={''}
                unMountedMsgs={this.state.unmountedMessages}
                refreshList={this.state.refreshClosure}
              />
            </View>

            <SymptomListModal
              type="assessment"
              closeModal={this.closeSymptomModalHandler}
              visible={showSymptomListModal}
              selectedProfileId={selectedProfileId}
              setSessionId={this.setSessionIdHandler}
              chatInputAction={this.onPressChatSymptomChatModal}
              symptomListStage={symptomListStage}
              symptomListTag={symptomListTag}
              symptomQueueToExclude={symptomListForQueue}
              profileSexAtBirth={sex}
            />

            <AssessmentCloseModal
              visible={this.state.showCloseWarning}
              onNo={() => {
                this.setState({
                  showCloseWarning: false,
                });
              }}
              onYes={() => {
                this.setState({
                  showCloseWarning: false,
                });
                this.props.navigation.navigate('DashBoard');
              }}
            />
            {this.state.showBodySelectionModal && (
              <HumanAnatomyModal
                onPressHumanAnatomy={this.onPressHumanAnatomy}
                showBodySelectionModal={this.state.showBodySelectionModal}
                humanAnatomyDataObject={this.state.humanAnatomyDataObject}
                close={this.closeHumanAnatomyModal}
              />
            )}

            <Modal.Restart
              visible={this.state.showRestartModal}
              closePressHandler={this.closeRestartModalHandler}
              confirmPressHandler={this.restartAssessmentHandler}
            />
          </View>
        </SafeAreaView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  chatView: {flex: 1, zIndex: -1},
  extractHeightWrapperView: {flex: 1, backgroundColor: Colors.white},
  safeAreaView: {flex: 1},
  assessmentDashboardScreenContainer: {
    flex: 1,
    backgroundColor: Colors.primary,
  },
  dateTimeShadowContainer: {
    height: 30,
    shadowColor: Colors.black,
    alignItems: 'center',
    padding: 5,
    shadowOffset: {
      height: 3,
      width: 0,
    },
    shadowOpacity: 0.2,
    backgroundColor: Colors.white,
  },
  titleBar: {
    flexDirection: 'row',
    width: '100%',
    position: 'absolute',
    justifyContent: 'center',
    height: 65,
    alignItems: 'center',
  },
  titleBarText: {
    color: Colors.white,
    fontSize: 18,
    fontFamily: MARKPRO_MEDIUM,
    lineHeight: 19,
    paddingRight: 5,
  },
});

const mapStateToProps = (state) => {
  return {
    platformToken: state.application.platformToken,
    platformUrl: state.application.platformUrl,
    assessmentToken: state.application.assessmentToken,
    windowWidth: state.appConfig.windowWidth,
  };
};

export default connect(mapStateToProps)(AssessmentDashboardScreen);
