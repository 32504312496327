import React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';
import {fma14602, fma20378, fma20379} from 'utils';
import {getColorFma} from 'utils';

export const Abdomen_fma20377 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1242 2208">
      <Path
        id="Path_5"
        dataName="Path 5"
        d="M1082.6,1519.7c-6.3-52.1-18-128.7-30.4-207.8c-7.2-46.1-19.7-91.3-37.4-134.5c-7.5-18.3-9.2-36.5-7.9-52.7  c3.1-40,3.5-80.2-0.3-120.1c-0.7-7.3-1.1-13.1-1.1-17c0-45.2,38.7-194,49.4-241.7c2.3-10.1,3.3-20.3,3.3-30.6  c0-5.9-0.1-15.8-0.3-28.7c-0.2-19.8-16.4-35.8-36.2-35.8l-800,0c-20,0-36.3,16.3-36.2,36.4c0,14.8,0.1,26.8,0.1,34.7  c0,19.2,2.2,38.2,6.5,56.9c12.3,52.8,44,167.7,44,208.8c0,4-0.4,9.9-1.1,17.4c-3.8,38.8-4.9,77.9-1,116.7  c1.6,16.4,0.4,34.9-6.9,53.7c-16.9,43.7-30.3,88.6-37.5,134.8c-12.5,79.7-24.3,157-30.6,209.5c-2.4,19.9,13.1,37.3,33.1,37.3h857.5  C1069.5,1557.1,1085,1539.6,1082.6,1519.7z"
        fill="#ead9e7"
      />
      <Path
        id={fma14602}
        dataName="Path 18"
        d="M657.9,1322.3c-8.9-0.5-57.3-1.2-61.9-1c-58,2.2-101.9,21.1-137.2,39.4c-4.1,2.1-16.2,8.5-16.9,11.3  c-1.4,5.9-0.2,12.3,3.5,17.5l71.5,102c9.9,14.6,26.3,23.4,44,23.4h123.7c17.6,0,34-8.7,43.9-23.2l67.8-99.8  c3.3-4.9,4.3-10.7,2.8-16.4c-1.4-5.4-23-14.9-29.1-17.7C729.7,1339,691.7,1324.3,657.9,1322.3z"
        fill={getColorFma(fma14602, selectedBodyPart)}
        onPressIn={() => onPressClick(fma14602)}
        onClick={() => onPressClick(fma14602)}
      />
      <Path
        id={fma20378}
        dataName="Path 19"
        d="M622.2,1077.4c0-5.5-0.9-38.3-24.7-38.3h-0.1l-293.8-4.7c-16.5,0-30.8,9.8-40.2,27.5  c-10.9,20.6-12.6,46.4-6.8,55l162.8,228.4c5.2,7.4,13.7,11.8,22.8,11.8c0.1,0,0.1,0,0.2,0c2.5,0,4.9-0.4,7.3-1  c1.9-0.5,3.8-1.2,5.6-2.1c9.3-4.8,19.2-9.7,29.8-14.3c30.4-13.2,66.5-24.3,110.6-25.9c4.1-0.2,8-1.2,11.4-2.9  c9.3-4.5,15.6-13.9,15.6-24.8l0-2.1c-0.3-3.5-0.3-6.8-0.3-9.4L622.2,1077.4z"
        fill={getColorFma(fma20378, selectedBodyPart)}
        onPressIn={() => onPressClick(fma20378)}
        onClick={() => onPressClick(fma20378)}
      />
      <Path
        id={fma20379}
        dataName="Path 20"
        d="M975.8,1067.1c-6.9-14.4-19.9-31.3-33.6-31.3l-269.5-1.3c-18.4,0-34.1,11.6-40.2,27.9c-1.8,4.7-2.8,9.7-2.8,15  l0.4,197.2c0,2.7,0,5.9,0.3,9.3c0.8,10.1,4.1,21.9,17.1,28.5c5,2.5,11.5,4.3,19.9,4.8c29.8,1.8,55.7,11.3,87.9,25.7  c5.7,2.6,11.6,5.2,17.8,8.1c1.9,0.9,3.9,1.6,5.9,2.2c4,1.2,8.2,1.9,12.4,1.9c0,0,0,0,0,0c14.2,0,27.4-7,35.3-18.7l150.1-209.1  C989.6,1111,984.7,1085.5,975.8,1067.1z"
        fill={getColorFma(fma20379, selectedBodyPart)}
        onPressIn={() => onPressClick(fma20379)}
        onClick={() => onPressClick(fma20379)}
      />
      <Circle
        id="Ellipse_10"
        dataName="Ellipse 10"
        cx="627.1"
        cy="1033"
        r="18"
        fill="#241f21"
      />
    </Svg>
  );
};
