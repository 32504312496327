import React from 'react';
import {Row, Column} from 'modules';
import {Text} from 'libs';
import {Spacing, ExclamationPointSvg, Colors} from 'utils';

export function TreatmentExclamation() {
  return (
    <Row
      alignItems="center"
      paddingVertical={Spacing.small25}
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor={Colors.borderPink}
      marginVertical={Spacing.medium}>
      <Column
        backgroundColor={Colors.worstPossible}
        justifyContent="center"
        alignItems="center"
        borderRadius={Spacing.small}
        marginRight={Spacing.base}
        width={Spacing.medium}
        height={Spacing.medium}>
        <ExclamationPointSvg />
      </Column>

      <Text.Body variant="normal" size="body2" style={{flex: 1}}>
        Check with your health care provider before giving your child any
        medicines, including non-prescription medicines.
      </Text.Body>
    </Row>
  );
}
