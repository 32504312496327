import React, {PropsWithChildren} from 'react';
import {View, StyleSheet, GestureResponderEvent} from 'react-native';
import {Button} from 'libs';
import {Row} from 'modules';

interface IItemBaseProps {
  onPress: (event: GestureResponderEvent) => void;
}

export function ItemBase({
  children,
  onPress,
}: PropsWithChildren<IItemBaseProps>) {
  return (
    <Row justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <View style={styles.textContainerStyle}>{children}</View>

      <Button.Icon onPress={onPress} />
    </Row>
  );
}

const styles = StyleSheet.create({
  textContainerStyle: {
    flex: 1,
  },
});
