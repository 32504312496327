import {Button, Checkbox} from 'libs';
import {Flex} from 'modules';
import React, {useState} from 'react';
import {FlatList, View} from 'react-native';
import {Spacing, useTheme} from 'utils';

interface ICheckboxPickerProps {
  data: any[];
  onDone: (pickedItems: any[]) => void;
  extractTitle: (item: any) => string;
}
export function CheckboxPicker({
  data,
  onDone,
  extractTitle,
}: ICheckboxPickerProps) {
  const initial: any[] = [];
  const [pickedItems, setPickedItems] = useState(initial);
  const theme = useTheme();

  return (
    <Flex style={{marginBottom: Spacing.base18}}>
      <FlatList
        keyExtractor={(item) => item.id}
        data={data}
        renderItem={({item}) => (
          <Checkbox.Primary
            title={extractTitle(item)}
            isChecked={pickedItems.filter((t) => t.id == item.id).length > 0}
            onPressed={(checked) => {
              if (checked) {
                setPickedItems((old) => [...old, item]);
              } else {
                setPickedItems((old) => old.filter((t) => t.id !== item.id));
              }
            }}
          />
        )}
      />

      <Button.Secondary
        title={'Continue'}
        onPress={() => onDone(pickedItems)}
        buttonStyle={{borderColor: theme.colors?.primary, borderWidth: 2}}
        type="outline"
      />
    </Flex>
  );
}
