import React, {useState} from 'react';
import {View, FlatList, Text, StyleSheet, Pressable} from 'react-native';
import {Colors} from 'utils';
import {MARKPRO_BOLD, MARKPRO_BOOK, MARKPRO_MEDIUM} from 'utils';
import IconAntDesign from 'react-native-vector-icons/AntDesign';
import {getNameNickname} from 'utils';
import {heightToDp} from 'utils';
import {
  Arc,
  TopBarOutWard,
  BackIcon,
  IOSStatusBar,
  NameWithDateContainer,
  SearchBar,
} from '../../../../components';
import {
  arcViewHeight56,
  arcViewWidth375,
  viewHeight293,
  viewWidth1200,
} from 'utils';
import {connect} from 'react-redux';
import {viewStyles} from 'utils';

const CauseItem = ({
  item,
  navigation,
  selectedCustomerProfile,
  clinicalPresentations,
}) => {
  const displayName = item.item.diagnosis?.display_name;

  const probability = item.item.probability * 100;
  let prob = probability.toFixed(2);
  if (prob === 0) {
    prob = probability.toFixed(4);
  }

  return (
    <Pressable
      onPress={() => {
        navigation.navigate('AssessmentResultItem', {
          differential_diagnosis: item.item,
          selected_customer_profile: selectedCustomerProfile,
          clinical_presentations: clinicalPresentations,
        });
      }}
      style={[styles.causeItemPressable]}>
      <View style={[styles.displayNameView]}>
        <Text style={[styles.displayNameText]}>{displayName}</Text>
        <View style={[styles.probView]}>
          <Text style={[styles.probText]}>{prob}%</Text>
          <IconAntDesign name="arrowright" size={20} color={Colors.primary} />
        </View>
      </View>
      <View style={styles.divider} />
    </Pressable>
  );
};

const BackView = ({navigation}) => (
  <View style={styles.bacViewContainer}>
    <BackIcon color={Colors.white} goBack={() => navigation.goBack()} />
  </View>
);

const renderItem = (
  item,
  navigation,
  selectedCustomerProfile,
  clinicalPresentations,
) => {
  return (
    <CauseItem
      item={item}
      navigation={navigation}
      selectedCustomerProfile={selectedCustomerProfile}
      clinicalPresentations={clinicalPresentations}
    />
  );
};

const ViewWithArc = ({height, width}) => {
  return (
    <View style={[styles.viewWithArc]}>
      <View style={[styles.headingTextContainer, {marginBottom: height + 40}]}>
        <Text style={styles.lifestyleText}>Possible causes</Text>
      </View>
      <View style={[styles.arcView]}>
        <Arc calculatedHeight={height} calculatedWidth={width} />
      </View>
    </View>
  );
};

const _searchMatchingDDO = (text, ddoList, setFilteredList) => {
  const filtered = ddoList.filter((ddo) =>
    ddo.diagnosis.display_name.toLowerCase().includes(text.toLowerCase()),
  );
  setFilteredList(filtered);
};

const renderHeaderComponent = (
  arcCalculatedHeight,
  arcWidth,
  mainSymptom,
  enteredText,
  setEnteredText,
  setFilteredList,
  ddoList,
) => {
  const mainSymptomDisplayName = mainSymptom?.display_name;

  return (
    <View style={[styles.renderHeaderComponentContainer]}>
      <ViewWithArc height={arcCalculatedHeight} width={arcWidth} />
      <View style={[styles.renderHeaderComponentView]}>
        <View
          style={[
            styles.renderHeaderComponentView2,
            {
              transform: [{translateY: -(arcCalculatedHeight / 2)}],
            },
          ]}
        />
      </View>
      <Text style={[styles.renderHeaderComponentText1]}>
        These are all the possible causes for
      </Text>
      <Text style={[styles.renderHeaderComponentText2]}>
        <Text style={[styles.renderHeaderComponentText3]}>
          {mainSymptomDisplayName}
        </Text>
        <Text style={[styles.renderHeaderComponentText4]}>
          {' based on your assessment and health profile:'}
        </Text>
      </Text>

      <SearchBar
        text={enteredText}
        onTextEdit={(text) => {
          setEnteredText(text);
          if (text.length === 0) {
            setFilteredList(ddoList);
            return;
          }
          _searchMatchingDDO(text, ddoList, setFilteredList);
        }}
        clear={() => {
          setEnteredText('');
          setFilteredList(ddoList);
        }}
        description={'Search here '}
        showClose
        editable
      />
      <View style={[styles.titleTextView]}>
        <Text style={[styles.titleText, viewStyles.flex1]}>
          {'Possible Cause'}
        </Text>
        <Text style={styles.titleText}>{'% Chance'}</Text>
      </View>
    </View>
  );
};

function AssessmentResultPossibleCauses(props) {
  const [filteredList, setFilteredList] = useState(
    props.route.params.differential_diagnosis,
  );
  const [enteredText, setEnteredText] = useState('');
  const calculatedHeight = (props.windowWidth * viewHeight293) / viewWidth1200;
  const arcWidth = props.windowWidth + 15;
  const arcCalculatedHeight = arcWidth * (arcViewHeight56 / arcViewWidth375);

  const name = getNameNickname(props.route.params.selected_customer_profile);

  return (
    <View style={[styles.assessmentResultPossibleCausesContainer]}>
      <View style={[viewStyles.positionAbsolute, styles.zIndex]}>
        <IOSStatusBar color={Colors.primary} />
        <TopBarOutWard
          color={Colors.primary}
          calculateHeight={calculatedHeight}
          windowWidth={props.windowWidth}
        />
      </View>
      <View style={styles.userNameContainer}>
        <NameWithDateContainer
          name={name}
          patientPicture={
            props.route.params.selected_customer_profile.patient_picture
          }
          showDate
          customerProfile={props.route.params.selected_customer_profile}
        />
      </View>
      <BackView navigation={props.navigation} />
      <FlatList
        bounces={false}
        style={[styles.flatListStyle]}
        contentContainerStyle={[styles.contentContainerStyle]}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.diagnosis.ddo_id}
        data={filteredList}
        renderItem={(item) =>
          renderItem(
            item,
            props.navigation,
            props.route.params.selected_customer_profile,
            props.route.params.clinical_presentations,
          )
        }
        ListHeaderComponent={renderHeaderComponent(
          arcCalculatedHeight,
          arcWidth,
          props.route.params.main_symptom,
          enteredText,
          setEnteredText,
          setFilteredList,
          props.route.params.differential_diagnosis,
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainerStyle: {minHeight: heightToDp(80)},
  flatListStyle: {backgroundColor: Colors.white},
  assessmentResultPossibleCausesContainer: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  titleTextView: {flexDirection: 'row', marginHorizontal: 30, paddingTop: 20},
  renderHeaderComponentText4: {
    lineHeight: 20,
    fontFamily: MARKPRO_BOOK,
    fontSize: 16,
    marginHorizontal: 30,
  },
  renderHeaderComponentText3: {
    lineHeight: 20,
    fontFamily: MARKPRO_BOLD,
    fontSize: 16,
    marginHorizontal: 30,
  },
  renderHeaderComponentText2: {marginHorizontal: 30, paddingBottom: 20},
  renderHeaderComponentText1: {
    lineHeight: 20,
    fontFamily: MARKPRO_BOOK,
    fontSize: 16,
    marginHorizontal: 30,
  },
  renderHeaderComponentView2: {alignItems: 'center'},
  renderHeaderComponentView: {backgroundColor: Colors.white},
  renderHeaderComponentContainer: {backgroundColor: Colors.white},
  arcView: {position: 'absolute', bottom: 0},
  probText: {
    fontFamily: MARKPRO_BOLD,
    fontSize: 15,
    color: Colors.textBlackColor,
    paddingHorizontal: 20,
  },
  probView: {
    width: 115,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  displayNameText: {
    fontFamily: MARKPRO_MEDIUM,
    fontSize: 18,
    lineHeight: 19,
    color: Colors.primary,
    flex: 1,
  },
  displayNameView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 20,
  },
  causeItemPressable: {paddingHorizontal: 30, backgroundColor: Colors.white},
  header: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  userNameContainer: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: heightToDp(4),
    zIndex: 10,
  },
  zIndex: {
    zIndex: 10,
  },
  title: {
    fontSize: 24,
    color: Colors.black,
    textAlign: 'center',
    marginBottom: 10,
  },
  headingTextContainer: {
    zIndex: 10,
    transform: [
      {
        translateY: -20,
      },
    ],
  },
  viewWithArc: {
    height: heightToDp(35),
    justifyContent: 'flex-end',
    backgroundColor: Colors.primary,
  },
  backView: {
    width: 60,
    height: 60,
    position: 'absolute',
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
  },
  lifestyleText: {
    fontSize: 28,
    fontFamily: MARKPRO_BOLD,
    color: Colors.white,
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    top: -5,
  },
  bacViewContainer: {
    position: 'absolute',
    zIndex: 10,
    top: heightToDp(6),
    left: 10,
  },
  descText: {
    fontSize: 16,
    color: Colors.textBlackColor,
    fontFamily: MARKPRO_BOOK,
    textAlign: 'justify',
  },
  divider: {height: 1, backgroundColor: Colors.borderPink},
  titleText: {
    fontSize: 15,
    color: Colors.searchIconColor,
    fontFamily: MARKPRO_BOLD,
  },
});

const mapStateToProps = (state) => ({
  windowWidth: state.appConfig.windowWidth,
});

export default connect(mapStateToProps)(AssessmentResultPossibleCauses);
