import axios from 'axios';
import {db} from 'config';
import {doc, getDoc} from 'firebase/firestore';
import {createWorklet} from 'react-native-reanimated';
import {
  ADD_MIN_INFO_SET_SYMPTOPMS_TO_QUEUE,
  ADD_SYMPTOMS_TO_QUEUE,
  APPLICATION_JSON,
  coll_lbe,
  DELETE_DX_SESSION_RECORD,
  GET_CLINICAL_PRESENTATIONS,
  GET_DDO_CONDITION_LIBRARY,
  GET_DIAGNOSTIC_QUESTION,
  GET_DIFFERENTIAL_DIAGNOSIS,
  GET_DX_SESSION_RECORD,
  GET_DX_SESSION_RECORD_HEADERS,
  GET_MIN_SET_OF_RISK_FACTORS,
  HEALTH_CHECK,
  SESSION_CLOSE,
  SESSION_OPEN,
  SESSION_REOPEN,
  SESSION_STEP_BACK,
  SUBMIT_DIAGNOSTIC_ANSWER,
} from '../constants';
import {getPlatformId, setPlatformId} from './AsyncStorageService';

// =====================================================
// Open Diagnose Session
// =====================================================
export const openSession = createWorklet(
  (symptomListItem, patientId, uri, token) =>
    new Promise(async (resolve, reject) => {
      const url = uri + SESSION_OPEN;

      const config = {
        headers: {
          'Content-Type': APPLICATION_JSON,
          Authorization: `Bearer ${token}`,
        },
      };

      let body = {
        carebot_session_id: null,
        carebot_session_record_document_type_version: null,
        username: 'service-account-2018-10-04@treatment.com',
        patient_id: patientId,
        reason_for_encounter: symptomListItem,
      };

      try {
        const resOpenSession = await axios.put(url, body, config);

        if (!resOpenSession) {
          reject(resOpenSession);
        } else {
          resolve(resOpenSession);
        }
      } catch (e) {
        reject(e);
      }
    }),
);

// =====================================================
// Close Session
// =====================================================
export const closeSession = (sessionId, uri, token) => {
  const url = uri + SESSION_CLOSE;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    session_id: sessionId,
  };

  return axios.put(url, body, config);
};

export const reopenSession = (sessionId, uri, token) => {
  const url = uri + SESSION_REOPEN;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    session_id: sessionId,
  };

  return axios.put(url, body, config);
};

// =====================================================
// Submit Answer for Diagnostic Question
// =====================================================
export const submitAnswer = (answerMap, uri, token) => {
  const url = uri + SUBMIT_DIAGNOSTIC_ANSWER;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.put(url, answerMap, config);
};
// I have no idea what this does and why it should be called but if we don't call it it does not work
export const addMinInfoSetSymtomsToQueue = (uri, token, sessionId) => {
  const url = uri + ADD_MIN_INFO_SET_SYMPTOPMS_TO_QUEUE;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    session_id: sessionId,
  };

  return axios.put(url, body, config);
};

// ===========================================================
// Handle Diagnose Question/Answer
// ===========================================================
export const handleDiagnosticQuestion = (
  sessionId,
  uri,
  token,
  symptomListQueue = [],
) =>
  new Promise(async (resolve, reject) => {
    try {
      const resultDiagnosticQuestion = await getDiagnosticQuestion(
        sessionId,
        uri,
        token,
      );

      if (!resultDiagnosticQuestion) {
        reject(resultDiagnosticQuestion);
      }

      // Check for LabTest
      const diagnosticQuestion = resultDiagnosticQuestion;

      if (
        diagnosticQuestion.question_type !== 'none' &&
        diagnosticQuestion.categories_general[0].includes('LabTest')
      ) {
        const map = {
          answer_index: -1,
          display_name: 'do not know',
          question_id: diagnosticQuestion.question_id,
        };

        // Submit LabTest Answer
        const resultSubmitLabTest = await submitAnswer(map, uri, token);
        if (!resultSubmitLabTest.data) {
          reject(resultSubmitLabTest);
        }

        // Get next question
        const resultHandleDiagnosticQuestion = await handleDiagnosticQuestion(
          sessionId,
          uri,
          token,
          symptomListQueue,
        );
        if (!resultHandleDiagnosticQuestion.data) {
          reject(resultHandleDiagnosticQuestion);
        } else {
          resolve(resultHandleDiagnosticQuestion);
        }
      } else {
        resolve(diagnosticQuestion);
      }
    } catch (e) {
      reject(e);
    }
  });

// =====================================================
// Handle Diagnose Question/Answer
// =====================================================
export const sessionStepBack = (sessionId, uri, token) => {
  const url = uri + SESSION_STEP_BACK;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    session_id: sessionId,
  };

  return axios.put(url, body, config);
};

// ========================================================
// Diagnose Questions/Answer
// ========================================================
export const getDiagnosticQuestion = createWorklet((sessionId, uri, token) => {
  const url = uri + GET_DIAGNOSTIC_QUESTION;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    session_id: sessionId,
    general_category_values: ['/Symptom'],
  };

  return new Promise(async (resolve, reject) => {
    try {
      const resultGetDiagnosticQuestion = await axios.put(url, body, config);
      if (!resultGetDiagnosticQuestion) {
        reject(resultGetDiagnosticQuestion);
      }

      let diagnosticQuestion = resultGetDiagnosticQuestion.data;

      const questionType = diagnosticQuestion.question_type;

      if (questionType === 'modifier') {
        diagnosticQuestion = await fetchModifierLayman(diagnosticQuestion);
      } else if (questionType === 'modifier-branch') {
        diagnosticQuestion = await fetchModifierBranchLayman(
          diagnosticQuestion,
        );
      } else if (questionType === 'symptom') {
        diagnosticQuestion = await fetchQuestionSymptomLayman(
          diagnosticQuestion,
        );
      }

      resolve(diagnosticQuestion);
    } catch (e) {
      reject(e);
    }
  });
});

// ==========================================================
// Replace display name layman from LBE - if exist
// ==========================================================
export const fetchModifierLayman = async (diagnosticQuestion) =>
  new Promise(async (resolve, reject) => {
    try {
      // Fetch lbe symptom document
      const docRef = doc(db, coll_lbe, diagnosticQuestion.symptom_id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const docMap = docSnapshot.data();
        const {modifiers} = docMap;
        const questionModifier = modifiers.filter(
          (f) => f.item_id === diagnosticQuestion.modifier_item_id,
        );

        if (questionModifier.length === 1) {
          diagnosticQuestion.modifier_display_name_layman =
            questionModifier[0]?.display_name_layman;

          const answerModifiers = questionModifier[0].branches;

          const type = questionModifier[0]?.type;
          if (type) {
            diagnosticQuestion.type = type;
          }
          // Temp solution for Abdominal Pain < 3 days, > 3 days
          let isAbdominalPain = false;
          const dn = diagnosticQuestion.symptom_id;
          if (dn === 'abdominal_pain@C0000737') {
            isAbdominalPain = true;
          }
          answerModifiers.forEach((am) => {
            diagnosticQuestion.answer_list.forEach((da) => {
              if (isAbdominalPain) {
                if (da.display_name === am.display_name) {
                  da.display_name_layman = am?.display_name_layman;
                }
              } else {
                if (da.item_id === am.item_id) {
                  da.display_name_layman = am?.display_name_layman;
                }
              }
            });
          });
        }
      }

      resolve(diagnosticQuestion);
    } catch (e) {
      reject(e);
    }
  });

// ==========================================================
// Replace display name layman from LBE - if exist
// ==========================================================
export const fetchModifierBranchLayman = async (diagnosticQuestion) =>
  new Promise(async (resolve, reject) => {
    try {
      // Fetch lbe symptom document
      const docRef = doc(db, coll_lbe, diagnosticQuestion.symptom_id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const docMap = docSnapshot.data();

        const {modifiers} = docMap;
        const questionModifier = modifiers.filter(
          (f) => f.item_id === diagnosticQuestion.modifier_item_id,
        );

        if (questionModifier.length === 1) {
          diagnosticQuestion.modifier_display_name_layman =
            questionModifier[0]?.display_name_layman;

          const answerModifiers = questionModifier[0].branches.filter(
            (qm) =>
              qm?.item_id ===
              diagnosticQuestion?.modifier_branch_item_id_list[0],
          );

          const type = questionModifier[0]?.type;
          if (type) {
            diagnosticQuestion.type = type;
          }

          const modifier_branch_item_id_list_last =
            diagnosticQuestion.modifier_branch_item_id_list[
              diagnosticQuestion.modifier_branch_item_id_list?.length - 1
            ];

          // Temp
          let isAbdominalPain = false;
          const dn = diagnosticQuestion.symptom_id;
          if (dn === 'abdominal_pain@C0000737') {
            isAbdominalPain = true;
          }

          const modifier_branch_display_name_list_last =
            diagnosticQuestion.modifier_branch_display_name_list[
              diagnosticQuestion.modifier_branch_display_name_list?.length - 1
            ];

          answerModifiers.forEach((am) => {
            // Temp
            if (isAbdominalPain) {
              if (modifier_branch_display_name_list_last === am?.display_name) {
                diagnosticQuestion.modifier_display_name_layman =
                  am?.sub_first_question ??
                  diagnosticQuestion.modifier_display_name_layman;
              }
            } else {
              if (modifier_branch_item_id_list_last === am?.item_id) {
                diagnosticQuestion.modifier_display_name_layman =
                  am?.sub_first_question ??
                  diagnosticQuestion.modifier_display_name_layman;
              }
            }

            am?.subBranches.forEach((sb) => {
              if (modifier_branch_item_id_list_last === sb?.item_id) {
                diagnosticQuestion.modifier_display_name_layman =
                  sb?.sub_first_question ??
                  diagnosticQuestion.modifier_display_name_layman;
              }
              diagnosticQuestion.answer_list.forEach((da) => {
                if (da.item_id === sb.item_id) {
                  da.display_name_layman = sb?.display_name_layman;
                }
              });
            });
          });
        }
      }

      resolve(diagnosticQuestion);
    } catch (e) {
      reject(e);
    }
  });

// ================================================================
// Replace display name Symptom layman from LBE - if exist
// ================================================================
export const fetchQuestionSymptomLayman = async (diagnosticQuestion) =>
  new Promise(async (resolve, reject) => {
    try {
      // Fetch lbe symptom document
      const docRef = doc(db, coll_lbe, diagnosticQuestion.symptom_id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const docMap = docSnapshot.data();
        const {branches} = docMap;
        diagnosticQuestion.symptom_display_name_layman =
          docMap.editedDisplayName2;

        if (branches.length > 0) {
          branches.forEach((b) => {
            diagnosticQuestion.answer_list.forEach((da) => {
              if (da.item_id === b.item_id) {
                da.display_name_layman = b?.display_name_layman;
              }
            });
          });
        }
      }

      resolve(diagnosticQuestion);
    } catch (e) {
      reject(e);
    }
  });

// ==================================================================================
// Get "name" from LBE for DDOs
// ==================================================================================
export const fetchNameForDDO = async (ddo) =>
  new Promise(async (resolve, reject) => {
    try {
      // Fetch lbe symptom document
      const docRef = doc(db, coll_lbe, ddo.diagnosis.ddo_id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const docMap = docSnapshot.data();
        const name = docMap?.name;
        if (name.length > 0) {
          ddo.diagnosis.display_name_layman = name;
        }
      }

      resolve(ddo);
    } catch (e) {
      reject(e);
    }
  });

// ==================================================================================
// Replace display name Symptom layman from LBE - if exist, for Session Record Header
// ==================================================================================
export const fetchQuestionSymptomLaymanSessionHeader = async (sessionHeader) =>
  new Promise(async (resolve, reject) => {
    try {
      // Fetch lbe symptom document
      const docRef = doc(
        db,
        coll_lbe,
        sessionHeader.data.reason_for_encounter.sdco_id,
      );
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const docMap = docSnapshot.data();
        if (sessionHeader.data.reason_for_encounter?.display_name_layman) {
          sessionHeader.data.reason_for_encounter.display_name_layman =
            docMap.editedDisplayName1;
        } else {
          sessionHeader = {
            ...sessionHeader,
            data: {
              ...sessionHeader.data,
              reason_for_encounter: {
                ...sessionHeader.data.reason_for_encounter,
                display_name_layman: docMap.editedDisplayName1,
              },
            },
          };
        }
      }

      resolve(sessionHeader);
    } catch (e) {
      reject(e);
    }
  });

// ==================================================================
// Get Differential Diagnosis Result
// ==================================================================
export const getDifferentialDiagnosis = async (sessionId, uri, token) => {
  const url = uri + GET_DIFFERENTIAL_DIAGNOSIS;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    session_id: sessionId,
  };

  return await axios.put(url, body, config);
};

// ==================================================================
// Get Clinical Presentation Result
// ==================================================================
export const getClinicalPresentations = async (sessionId, uri, token) => {
  const url = uri + GET_CLINICAL_PRESENTATIONS;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    session_id: sessionId,
  };

  return await axios.put(url, body, config);
};

// =====================================================
// Send Dx Health Check
// =====================================================
export const sendDxHealthCheck = async (uri, token) =>
  new Promise(async (reject, resolve) => {
    try {
      const url = uri + HEALTH_CHECK;

      const config = {
        headers: {
          'Content-Type': APPLICATION_JSON,
          Authorization: `Bearer ${token}`,
        },
      };

      const body = {};

      const resultHealthCheck = await axios.put(url, body, config);
      if (!resultHealthCheck.data) {
        reject(resultHealthCheck);
      }

      const platform_id =
        resultHealthCheck.data.platform_configuration_event_summary
          .platform_configuration_id;
      await setPlatformId(platform_id);
      resolve(platform_id);
    } catch (e) {
      reject(e);
    }
  });

// ==================================================================
// Diagnostic Session Record Header List
// ==================================================================
export const getDiagnosticSessionHeaderList = (patientId, uri, token) => {
  return new Promise(async (resolve, reject) => {
    const url = uri + GET_DX_SESSION_RECORD_HEADERS;
    const platform_id = await getPlatformId();

    const config = {
      headers: {
        'Content-Type': APPLICATION_JSON,
        Authorization: `Bearer ${token}`,
      },
    };

    const body = {
      collection_id: null,
      patient_id: patientId,
      platform_configuration_id: platform_id,
    };

    try {
      const resultSessionHeaderList = await axios.put(url, body, config);
      if (!resultSessionHeaderList.data) {
        reject(resultSessionHeaderList);
      }

      resolve(resultSessionHeaderList.data.documents);
    } catch (e) {
      reject(e);
    }
  });
};

// ========================================================================
// Get Dx-Session Record
// ========================================================================
export const getDxSessionRecord = async (patientId, documentId, uri, token) => {
  const url = uri + GET_DX_SESSION_RECORD;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    collection_id: null,
    patient_id: patientId,
    document_header: {
      description: '',
      display_name: '',
      document_id: documentId,
      document_type: 'http://treatment.com/diagnostic-session-record',
      document_type_name: 'diagnostic-session-record',
      document_type_version: '',
      size: 0,
      version: '0.0.0',
    },
    document_type_version: null,
  };

  return await axios.put(url, body, config);
};

// =====================================================
// Delete Dx-Session Record
// =====================================================
export const deleteDxSessionRecord = async (
  diagnosticSessionRecord,
  uri,
  token,
) => {
  const url = uri + DELETE_DX_SESSION_RECORD;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    collection_id: null,
    patient_id: diagnosticSessionRecord.patient_id,
    document_type_version: null,
    document_reference: {
      description: '',
      display_name: '',
      document_id: diagnosticSessionRecord.document_id,
      document_type: diagnosticSessionRecord.document_type,
      document_type_name: diagnosticSessionRecord.document_type_name,
      document_type_version: diagnosticSessionRecord.document_type_version,
      size: diagnosticSessionRecord.size,
      version: '0.0.0',
    },
  };

  return axios.put(url, body, config);
};

// =========================================================
// Get DDO details from Condition Library
// =========================================================
export const getDDOFromConditionLibrary = (ddoId, uri, token) => {
  const url = uri + GET_DDO_CONDITION_LIBRARY;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    ddo_id: ddoId,
  };

  return axios.put(url, body, config);
};

// ======================================================================
// Add Symptoms To Queue
// ======================================================================
export const addSymptomsToQueue = async (sessionId, symptoms, uri, token) => {
  const url = uri + ADD_SYMPTOMS_TO_QUEUE;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    session_id: sessionId,
    symptoms: symptoms,
  };

  return axios.put(url, body, config);
};

export const getMinSetOfRiskFactors = async (uri, token, sessionId) => {
  const url = uri + GET_MIN_SET_OF_RISK_FACTORS;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };
  const body = {
    session_id: sessionId,
  };
  return axios.put(url, body, config);
};
