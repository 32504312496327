// @ts-nocheck
import {useEffect} from 'react';
import {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
  runOnJS,
} from 'react-native-reanimated';

export default function usePickerAnimation() {
  const translateY = useSharedValue(300);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{translateY: translateY.value}],
    };
  });

  const animate = (translateYAmount, callBack, duration = 800) => {
    translateY.value = withTiming(
      translateYAmount,
      {
        duration: duration,
      },
      () => {
        runOnJS(callBack)();
      },
    );
  };

  useEffect(() => {
    animate(0, () => {});
  }, []);

  return [animatedStyle, animate];
}
