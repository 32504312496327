import React, {useEffect} from 'react';
import {View, StyleSheet, Text} from 'react-native';
import Animated, {
  useAnimatedProps,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import Svg, {Circle, G} from 'react-native-svg';
import {Colors} from 'utils';
import {MARKPRO_BOLD, MARKPRO_BOOK} from 'utils';
import {textStyles, viewStyles} from 'utils';

export function AnimatedDoughNut({
  radius = 60,
  percentage = 67,
  showPercentage,
  strokeWidth = 30,
  lineCap,
  widthToInset = 0,
  index = 1,
}) {
  const max = 106;
  const drawPercentage = (percentage / max) * 100;
  const halfCircle = radius + strokeWidth;
  const circumference = 2 * Math.PI * radius;

  const AnimatedCircle = Animated.createAnimatedComponent(Circle);

  const animatedPercentage = useSharedValue(circumference);
  const strokeDashOffset =
    circumference - (circumference * drawPercentage) / 100;
  const animatedProps = useAnimatedProps(() => {
    return {
      strokeDashoffset: animatedPercentage.value,
    };
  });

  const getStrokeColor = () => {
    if (percentage >= 75) {
      return Colors.percentagePurple1;
    } else if (percentage >= 50) {
      return Colors.progressBarPurple;
    } else if (percentage >= 25) {
      return Colors.percentagePurple2;
    } else {
      return Colors.percentagePurple3;
    }
  };

  useEffect(() => {
    animatedPercentage.value = withTiming(strokeDashOffset, {
      duration: 1500,
    });
  }, []);

  let countString;
  switch (index) {
    case 0:
      countString = 'st';
      break;
    case 1:
      countString = 'nd';
      break;
    case 2:
      countString = 'rd';
      break;
    case 3:
      countString = 'th';
      break;
  }

  const fontSize = {
    fontSize: radius === 90 ? 15 : 0,
  };

  return (
    <View>
      <Svg
        height={radius * 2}
        width={radius * 2}
        viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}>
        <G rotation={-90} origin={`${halfCircle} , ${halfCircle}`}>
          <Circle
            cx={'50%'}
            cy={'50%'}
            r={radius}
            opacity={0.4}
            stroke={Colors.circleStrokeColor}
            strokeWidth={strokeWidth + widthToInset}
          />
          <AnimatedCircle
            cx={'50%'}
            cy={'50%'}
            animatedProps={animatedProps}
            r={radius}
            stroke={getStrokeColor()}
            strokeDasharray={circumference}
            strokeLinecap={lineCap}
            fill={'transparent'}
            strokeWidth={strokeWidth}
          />
        </G>
      </Svg>
      {showPercentage && (
        <View
          style={[styles.showPercentageView, StyleSheet.absoluteFillObject]}>
          <View style={[styles.radiusTextView, StyleSheet.absoluteFillObject]}>
            <Text
              style={
                (styles.radiusText, {fontSize: radius === 90 ? 40 : 16})
              }>{`${index + 1}`}</Text>
            {radius === 90 && (
              <Text
                style={
                  (styles.countStringText,
                  {
                    lineHeight: radius === 90 ? 17 : 12,
                    fontSize: radius === 90 ? 20 : 12,
                  })
                }>{`${countString}`}</Text>
            )}
          </View>
          {radius === 90 && (
            <Text
              style={[
                viewStyles.positionAbsolute,
                viewStyles.bottom51,
                textStyles.fontFamilyMARKPRO_BOOK,
                fontSize,
              ]}>
              match
            </Text>
          )}
        </View>
      )}
    </View>
  );
}

const MemorizedDoughNut = React.memo(AnimatedDoughNut);
export default MemorizedDoughNut;
const styles = StyleSheet.create({
  matchText: {
    position: 'absolute',
    bottom: 51,
    fontFamily: MARKPRO_BOOK,
  },
  countStringText: {
    fontFamily: MARKPRO_BOLD,
  },
  radiusText: {
    fontFamily: MARKPRO_BOLD,
  },
  radiusTextView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  showPercentageView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 1,
  },
});
