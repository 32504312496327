import {Pressable, StyleSheet, Text} from 'react-native';
import {Colors} from 'utils';
import {MARKPRO_BOLD} from 'utils';
import React from 'react';
import {ListRowDeleteSvg} from 'components/reusables/components/SVGS';

export const ListRowDelete = ({onPress}) => (
  <Pressable onPress={onPress} style={[styles.listRowDeletePressable]}>
    <ListRowDeleteSvg />
    <Text style={[styles.listRowDeleteText]}>Delete</Text>
  </Pressable>
);
const styles = StyleSheet.create({
  listRowDeleteText: {
    color: Colors.white,
    fontSize: 14,
    fontFamily: MARKPRO_BOLD,
    top: 2,
  },
  listRowDeletePressable: {
    width: 75,
    height: 75,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 9,
    backgroundColor: '#F84C45',
    shadowColor: '#F84C45',
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.8,
    shadowRadius: 2,
  },
});
