import React from 'react';
import {Pressable, StyleSheet} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';

export const BackIcon = ({goBack, color, messageStore}: any) => (
  <Pressable
    onPress={() => {
      if (messageStore) {
        messageStore.getInstance().clearStore();
      }
      goBack();
    }}
    style={[styles.backIconPressable]}>
    <Icon name="arrowleft" color={color} size={30} />
  </Pressable>
);
const styles = StyleSheet.create({
  backIconPressable: {
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
