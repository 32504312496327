// @ts-nocheck
import {Picker} from '@react-native-picker/picker';
import {useDisabledState} from 'modules';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Pressable, StyleSheet, Text} from 'react-native';
//import MonthPicker, {ACTION_DATE_SET} from 'react-native-month-year-picker';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import {
  Colors,
  MARKPRO_MEDIUM,
  MONTH_PICKER,
  MONTH_PICKER_SIMPLIFIED,
  SUBMIT_BIRTH_DATE_AND_TIME,
  SUBMIT_BIRTH_MONTH,
  YEAR_PICKER,
} from 'utils';
import DateTimePicker from './DateTimePicker';

const DatePickerIOS = ({
  pickerFormat = YEAR_PICKER,
  isVisible,
  onSendData,
  dateTimeModalAction = SUBMIT_BIRTH_DATE_AND_TIME,
  startDate,
  shouldRenderAllMonths = false,
}) => {
  const [selectedYear, setYear] = useState('' + new Date().getFullYear());
  const [selectedMonth, setMonth] = useState('January');
  const [monthNumber, setMonthNumber] = useState('01');
  const submit = (date) => {
    let data = null;
    let action = SUBMIT_BIRTH_DATE_AND_TIME;
    switch (pickerFormat) {
      case MONTH_PICKER: {
        data = date;
        break;
      }
      case MONTH_PICKER_SIMPLIFIED: {
        data = selectedMonth;
        action = SUBMIT_BIRTH_MONTH;
        break;
      }
      case YEAR_PICKER: {
        data = selectedYear;
        break;
      }
      default: {
        moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
      }
    }

    onSendData({
      value: {
        action: action,
        option: {
          data,
          monthNumber,
          jsDate: date,
        },
      },
    });
  };

  const translateY = useSharedValue(300);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{translateY: translateY.value}],
    };
  });

  const renderMonthPickerSimplified = () => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const monthElements = [];
    const numberOfMonthsToRender = shouldRenderAllMonths
      ? months.length - 1
      : new Date().getMonth();
    for (let i = 0; i <= numberOfMonthsToRender; i++) {
      monthElements.push(
        <Picker.Item label={months[i]} value={months[i]} key={i} />,
      );
    }
    return (
      <Picker
        selectedValue={selectedMonth}
        onValueChange={(itemValue, itemIndex) => {
          setMonth(itemValue);
          let monthNumber = itemIndex + 1;
          if (monthNumber.toString().length === 1) {
            setMonthNumber('0' + monthNumber);
          } else {
            setMonthNumber(monthNumber);
          }
        }}>
        {monthElements}
      </Picker>
    );
  };

  const renderMonthPicker = () => {
    let minimumDate = new Date(1900, 1, 1);
    if (typeof startDate === 'string') {
      minimumDate = moment(startDate, 'MMMM YYYY').toDate();
    }

    return (
      // <MonthPicker
      //   autoTheme={false}
      //   onChange={(event, selectedDate) => {
      //     setDate(selectedDate);
      //     switch (event) {
      //       case ACTION_DATE_SET:
      //         submit(moment(selectedDate).format('MMMM YYYY'));
      //         break;
      //     }
      //   }}
      //   value={date}
      //   minimumDate={minimumDate}
      //   maximumDate={new Date()}
      //   locale="us"
      //   cancelButton={''}
      // />
      <></>
    );
  };

  const animate = (translateYAmount, callBack, duration = 800) => {
    translateY.value = withTiming(
      translateYAmount,
      {
        duration: duration,
      },
      () => {
        runOnJS(callBack)();
      },
    );
  };

  const renderDateTimePicker = () => {
    return (
      <DateTimePicker
        title="Date*"
        placeholder="Today"
        type="spinner"
        maxDate={new Date()}
        onChangeText={(_event, selectedDate) => {
          setDate(selectedDate);
        }}
        value={date}
        mode="date"
        format="DD-MM-YYYY"
      />
    );
  };

  renderYearPicker = () => {
    const years = [];
    for (let year = 1900; year <= new Date().getFullYear(); year++) {
      years.push(
        <Picker.Item label={year + ''} value={year + ''} key={year} />,
      );
    }
    return (
      <Picker
        selectedValue={selectedYear}
        onValueChange={(itemValue, itemIndex) => {
          setYear(itemValue);
        }}>
        {years}
      </Picker>
    );
  };

  useEffect(() => {
    animate(0, () => {});
  }, []);

  const [date, setDate] = useState(new Date());
  const [disabled, donePressHandler] = useDisabledState();

  const renderPicker = (_) => {
    switch (pickerFormat) {
      case MONTH_PICKER:
        return renderMonthPicker();
      case YEAR_PICKER:
        return renderYearPicker();
      case MONTH_PICKER_SIMPLIFIED:
        return renderMonthPickerSimplified();
      default:
        return renderDateTimePicker();
    }
  };

  return (
    <Animated.View style={animatedStyle}>
      <Pressable
        disabled={disabled}
        style={styles.toolbar}
        onPress={() => donePressHandler(animate(300, () => submit(date), 200))}>
        <Text style={styles.doneButton}>Done</Text>
      </Pressable>
      {renderPicker()}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  doneButton: {
    color: Colors.doneButtonTextColor,
    fontSize: 16,
    fontFamily: MARKPRO_MEDIUM,
    paddingHorizontal: 5,
    paddingVertical: 5,
  },
  toolbar: {
    paddingHorizontal: 5,
    backgroundColor: '#F2F2F2',
    width: '100%',
    flexDirection: 'row-reverse',
    height: 40,
    alignItems: 'center',
  },
});

export default DatePickerIOS;
