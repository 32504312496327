import {useFocusEffect} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {
  FlatList,
  InteractionManager,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {connect} from 'react-redux';
import {
  arcViewHeight56,
  arcViewWidth375,
  changeAndroidStatusBarColor,
  Colors,
  getDDOFromConditionLibrary,
  MARKPRO_BOLD,
  viewHeight293,
  viewStyles,
  viewWidth1200,
} from 'utils';
import {
  Arc,
  BackIcon,
  IOSStatusBar,
  Spacer,
  TopBarOutWard,
} from '../../../../components';
import AnimatedDoughNut from '../../../../components/reusables/animated-reusables/AnimatedDoghNut';
import {Description, Prevention, Risks, Symptoms, Treatment} from '../../.././';

export const Header = ({
  height,
  color = Colors.topPurple,
  shareAssessment,
  windowWidth,
}) => {
  return (
    <View style={[styles.zIndex]}>
      <TopBarOutWard
        color={color}
        calculateHeight={height}
        windowWidth={windowWidth}
      />
    </View>
  );
};

const BackView = ({navigation}) => (
  <View style={styles.bacViewContainer}>
    <BackIcon
      color={Colors.textBlackColor}
      goBack={() => navigation.goBack()}
    />
  </View>
);

export const ViewWithArc = ({
  height,
  width,
  displayName,
  color = Colors.topPurple,
  customStyles = {},
  textColor = Colors.black,
  pronounciation = '',
}) => {
  const colorBg = {backgroundColor: color};
  const colorTxt = {color: textColor};
  const maxWidth = {maxWidth: '90%'};
  return (
    <View
      style={[
        styles.viewWithArc,
        viewStyles.alignItemsCenter,
        colorBg,
        {...customStyles},
      ]}>
      <View>
        <Text style={[styles.title, colorTxt, maxWidth]}>{displayName}</Text>
        {pronounciation?.length > 0 && (
          <Text style={[styles.viewWithArcText]}>{`(${pronounciation})`}</Text>
        )}
      </View>

      <View style={[styles.arcView, {backgroundColor: color}]}>
        <Arc calculatedHeight={height} calculatedWidth={width} />
      </View>
    </View>
  );
};

const ActionViewItem = ({index, onPress, selectedItemIndex, item}) => (
  <Pressable
    onPress={() => onPress(index)}
    style={[
      styles.actionViewItem,
      {
        backgroundColor:
          index === selectedItemIndex ? Colors.buttonOnHighlight : Colors.white,
      },
    ]}>
    <Text style={[styles.itemText]}>{item}</Text>
  </Pressable>
);
const ActionViewItems = ({items, selectedItemIndex, onPress}) => (
  <View style={[styles.actionViewItemView]}>
    {items.map((el, index) => (
      <ActionViewItem
        key={index}
        item={el}
        selectedItemIndex={selectedItemIndex}
        index={index}
        onPress={onPress}
      />
    ))}
  </View>
);

function AssessmentResultItem(props) {
  const calculatedHeight = (props.windowWidth * viewHeight293) / viewWidth1200;
  const arcWidth = props.windowWidth + 15;
  const arcCalculatedHeight = arcWidth * (arcViewHeight56 / arcViewWidth375);

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [showDeleteAssessmentModal, setShowDeleteAssessmentModal] =
    useState(false);
  const [ddoConditionLibrary, setDdoConditionLibrary] = useState(null);

  // Temp
  const [isLoading, setIsLoading] = useState(true);

  useFocusEffect(
    React.useCallback(() => {
      changeAndroidStatusBarColor(Colors.topPurple);
    }, []),
  );

  const runAfterInteractions = (callBack) => {
    InteractionManager.runAfterInteractions(() => {
      callBack();
    });
  };

  useEffect(() => {
    getDDOFromConditionLibrary(
      props.route.params.differential_diagnosis.diagnosis.ddo_id,
      props.platformUrl,
      props.platformToken,
    )
      .then((res) => {
        runAfterInteractions(() => {
          setDdoConditionLibrary(res.data.data[0]);
          setIsLoading(false);
        });
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const ITEMS = ['Description', 'Symptoms', 'Risks', 'Treatment', 'Prevention'];

  const onPress = (index) => setSelectedItemIndex(index);

  const getView = () => {
    if (isLoading) {
      return null;
    }

    switch (selectedItemIndex) {
      case 0:
        return <Description key={0} ddo={ddoConditionLibrary} />;
      case 1:
        return (
          <Symptoms
            key={1}
            ddoConditionLibrary={ddoConditionLibrary}
            ddo={props.route.params.differential_diagnosis}
            clinicalPresentations={props.route.params.clinical_presentations}
          />
        );
      case 2:
        return <Risks key={2} ddoConditionLibrary={ddoConditionLibrary} />;
      case 3:
        return <Treatment key={4} ddoConditionLibrary={ddoConditionLibrary} />;
      case 4:
        return <Prevention key={3} ddoConditionLibrary={ddoConditionLibrary} />;
    }
  };

  const renderHeaderComponent = () => {
    const probability =
      props.route.params.differential_diagnosis.probability * 100;
    let prob = probability.toFixed(2);
    if (prob === 0) {
      prob = probability.toFixed(4);
    }

    const differentialDiagnosis = props.route.params.differential_diagnosis;

    // Display Name
    let displayName;
    let pronunciation = null;

    if (!ddoConditionLibrary) {
      displayName = differentialDiagnosis.diagnosis?.display_name;
    } else {
      displayName = differentialDiagnosis.diagnosis?.display_name_layman;
      pronunciation = ddoConditionLibrary.pronunciation;
    }

    return (
      <>
        <Spacer height={60} color={Colors.topPurple} />
        <ViewWithArc
          height={arcCalculatedHeight}
          width={arcWidth}
          displayName={displayName}
          pronounciation={pronunciation}
        />
        <View style={[styles.animatedDoughNutMainView]}>
          <View
            style={[
              styles.animatedDoughNutView,
              {
                transform: [{translateY: -(arcCalculatedHeight / 2)}],
              },
            ]}>
            <AnimatedDoughNut
              widthToInset={20}
              lineCap={'round'}
              showPercentage={true}
              percentage={prob}
              index={props.route.params.index}
              radius={90}
              strokeWidth={20}
            />
          </View>
          <View style={[styles.actionViewItemsView]}>
            <ActionViewItems
              items={ITEMS}
              selectedItemIndex={selectedItemIndex}
              onPress={onPress}
            />
          </View>
        </View>
      </>
    );
  };

  const renderFooterComponent = () => {
    return (
      <View style={[styles.renderFooterComponentContainer]}>
        <View style={[styles.renderFooterComponentArcView]} />
        <Arc
          calculatedHeight={arcCalculatedHeight}
          color={Colors.systemChatBubblesColor}
          calculatedWidth={arcWidth}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={[viewStyles.positionAbsolute, styles.zIndex]}>
        <IOSStatusBar color={Colors.topPurple} />
      </View>
      <BackView navigation={props.navigation} />
      <FlatList
        bounces={false}
        contentContainerStyle={viewStyles.minHeight0}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.toString()}
        data={[0]}
        renderItem={() => getView()}
        ListFooterComponent={renderFooterComponent()}
        ListHeaderComponent={renderHeaderComponent()}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  renderFooterComponentArcView: {height: 20},
  renderFooterComponentContainer: {backgroundColor: Colors.white},
  actionViewItemsView: {paddingHorizontal: 20},
  animatedDoughNutView: {alignItems: 'center'},
  animatedDoughNutMainView: {backgroundColor: Colors.white, zIndex: 10},
  actionViewItemView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  itemText: {fontFamily: MARKPRO_BOLD, color: Colors.primary, fontSize: 16},
  arcView: {alignItems: 'center'},
  viewWithArcText: {fontSize: 18, textAlign: 'center'},
  container: {
    flex: 1,
    backgroundColor: Colors.topPurple,
  },
  title: {
    color: Colors.textBlackColor,
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 27,
    fontFamily: MARKPRO_BOLD,
  },
  viewWithArc: {
    justifyContent: 'flex-end',
    zIndex: 10,
    backgroundColor: Colors.topPurple,
  },
  bacViewContainer: {
    position: 'absolute',
    zIndex: 10,
    top: 20,
    left: 10,
  },
  headingTextContainer: {
    zIndex: 10,
  },
  zIndex: {
    zIndex: 10,
  },
  actionViewItem: {
    padding: 11,
    margin: 5,
    borderRadius: 20,
    borderWidth: 0.8,
    borderColor: Colors.buttonOnHighlight,
  },
});

const mapStateToProps = (state) => {
  return {
    platformToken: state.application.platformToken,
    platformUrl: state.application.platformUrl,
    windowWidth: state.appConfig.windowWidth,
  };
};

export default connect(mapStateToProps)(AssessmentResultItem);
