// @ts-nocheck

import {AssessmentResult} from 'modules';
import React, {useEffect} from 'react';
import {
  EventType,
  firstElementStartWithHashtag,
  getParsedHashtagText,
  useAnalytics,
} from 'utils';
import {ITabResultsProps} from '../TabResults';

interface IRisksProps extends ITabResultsProps {
  riskFactorsList?: Array<string>;
}

export function Risks({title, riskFactorsList}: IRisksProps) {
  const {logEvent} = useAnalytics();
  useEffect(() => {
    logEvent(EventType.RESULT_RISKS_VIEW, {timestamp: Date.now()});
  }, []);

  return (
    <>
      <AssessmentResult.Tab.TextTitle title={title} />

      {firstElementStartWithHashtag(riskFactorsList) && (
        <AssessmentResult.Tab.TextTitleList
          title={getParsedHashtagText(riskFactorsList?.[0])}
        />
      )}

      <AssessmentResult.Tab.RenderList items={riskFactorsList} />
    </>
  );
}
