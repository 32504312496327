import React from 'react';
import {useTheme} from 'utils';
import {ButtonBase} from './ButtonBase';
import {IButtonPropsBase} from './IButtonPropsBase';

interface IButtonPrimaryProps extends IButtonPropsBase {}

export function ButtonPrimary({
  title,
  onPress,
  iconRight,
  icon,
  disabled,
}: IButtonPrimaryProps) {
  const theme = useTheme();

  return (
    <ButtonBase
      title={title}
      onPress={onPress}
      containerStyle={{
        backgroundColor: theme.colors?.primary,
      }}
      disabledStyle={{backgroundColor: theme.colors?.disabled}}
      disabledTitleStyle={{color: theme.colors?.white}}
      iconRight={iconRight}
      icon={icon}
      disabled={disabled}
    />
  );
}
