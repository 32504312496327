import {Picker} from '@react-native-picker/picker';
import React, {useEffect, useState} from 'react';
import {Pressable, StyleSheet, Text} from 'react-native';
import {Transition, Transitioning} from 'react-native-reanimated';
import {connect} from 'react-redux';
import {
  Colors,
  countriesList,
  MARKPRO_MEDIUM,
  SUBMIT_CITY,
  SUBMIT_COUNTRY,
  SUBMIT_PROVINCE,
} from 'utils';

function UserLocationPickerIOS(props) {
  const _setCountries = () => {
    const mappedObject = countriesList.map((el) => {
      return {
        key: el.iso3,
        name: el.name,
        states: el.states,
      };
    });
    setCountryList(mappedObject);
    setFilteredList(mappedObject);
    setSelectedCountry(mappedObject[0].name);
  };

  let ref = React.useRef();

  useEffect(() => {
    _setCountries();
    ref.current?.animateNextTransition();
  }, []);

  useEffect(() => {
    if (props.countryModalFilterBy === 'province') {
      const filteredCountry = countriesList.filter(
        (el) => el.name === props.selectedValueCountry,
      );
      const states =
        filteredCountry.length > 0
          ? Object.keys(filteredCountry[0].states).map((el) => {
              return {
                key: el,
                name: el,
                cities: filteredCountry[0].states[el],
              };
            })
          : [];
      setCountryList(states);
      setFilteredList(states);
      setSelectedCountry(states[0].name);
      return;
    }
    if (props.countryModalFilterBy === 'city') {
      const filteredCountry = countriesList.filter(
        (el) => el.name === props.selectedValueCountry,
      );
      const states =
        filteredCountry.length > 0
          ? Object.keys(filteredCountry[0].states).map((el) => {
              return {
                key: el,
                name: el,
                cities: filteredCountry[0].states[el],
              };
            })
          : [];

      const state = states.filter(
        (el) => el.name === props.selectedValueProvince,
      );
      const cities = state[0].cities.map((el) => {
        return {
          key: el,
          name: el,
        };
      });
      setCountryList(cities);
      setFilteredList(cities);
      setSelectedCountry(cities[0].name);
      return;
    }

    _setCountries();
  }, [props.countryModalFilterBy]);

  const [countryList, setCountryList] = useState([]);
  const [filteredList, setFilteredList] = useState(countryList);
  const [selectedCountry, setSelectedCountry] = useState(filteredList[0]?.name);

  const sendData = (country) => {
    let ACTION = SUBMIT_COUNTRY;
    if (props.countryModalFilterBy === 'province') {
      ACTION = SUBMIT_PROVINCE;
    }
    if (props.countryModalFilterBy === 'city') {
      ACTION = SUBMIT_CITY;
    }

    if (!selectedCountry) {
      return;
    }

    props.animateDownArrow(0);
    props.onSendData({
      value: {
        action: ACTION,
        option: {
          data: country,
        },
      },
    });
  };

  const transition = (
    <Transition.Together>
      <Transition.In type={'slide-bottom'} durationMs={300} />
      <Transition.In type={'fade'} durationMs={300} />
    </Transition.Together>
  );

  return (
    <Transitioning.View
      ref={ref}
      transition={transition}
      style={[styles.container]}>
      <Pressable
        style={styles.toolbar}
        onPress={() => sendData(selectedCountry)}>
        <Text style={styles.doneButton}>Done</Text>
      </Pressable>
      {filteredList.length > 0 && (
        <Picker
          selectedValue={
            selectedCountry ? selectedCountry : filteredList[0].name
          }
          onValueChange={(itemValue, itemIndex) =>
            setSelectedCountry(itemValue)
          }>
          {filteredList.length > 0 &&
            filteredList.map((el) => (
              <Picker.Item key={el.name} label={el.name} value={el.name} />
            ))}
        </Picker>
      )}
    </Transitioning.View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
  },
  textInput: {
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#fff',
  },
  listContainer: {
    paddingTop: 30,
    flexDirection: 'column',
  },
  listItem: {
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomColor: 'lightgrey',
    borderBottomWidth: 1,
  },
  toolbar: {
    backgroundColor: '#F2F2F2',
    width: '100%',
    flexDirection: 'row-reverse',
    height: 44,
    alignItems: 'center',
  },
  doneButton: {
    color: Colors.doneButtonTextColor,
    fontSize: 16,
    fontFamily: MARKPRO_MEDIUM,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
});

const mapStateToProps = (state) => {
  return {
    platformToken: state.application.platformToken,
    platformUrl: state.application.platformUrl,
  };
};

export default connect(mapStateToProps)(UserLocationPickerIOS);
