export default class Socket {
  messageStore;
  init = (store) => (this.messageStore = store);
  on = (dependency = {}) => this.messageStore.getinitialMessages(dependency);
  emit = (
    callBack,
    limit,
    data,
    dependency,
    additionalData,
    toggleTyping = () => {},
  ) =>
    this.messageStore.mergeValueWithArray(
      callBack,
      limit,
      data,
      dependency,
      additionalData,
      toggleTyping,
    );
  callActionMapper = (data) => this.messageStore.actionMapper(data);
  writeToStore = (data) => {
    let object = data.reduce((acc, currentObj) => {
      return {...acc, [currentObj.key]: currentObj.value};
    }, {});

    this.messageStore._snapShotForStoring = {
      ...this.messageStore._snapShotForStoring,
      ...object,
    };
  };
  getStore = () => this.messageStore._snapShotForStoring;
  setMessageIndex = (index) => {
    this.messageStore.setInititalMessageIndex(index);
  };
  clearStore = () => this.messageStore.clearStore();
  setStepsToSkip = (steps) => this.messageStore._setStepsToSkip(steps);
  setAuthStep = (step) => this.messageStore._setAuthMethod(step);
  getAuthStep = (_) => this.messageStore.authMethod;
  initAnimationTyping = (toggleTyping) =>
    this.messageStore.initAnimationTyping(toggleTyping);
  addToMessageIndex = (value) =>
    this.messageStore.addToInitialMessageIndex(value);
  getNextMessageIndex = () => this.messageStore._getNextMesssageIndex();
  swapSnap = (_) => this.messageStore.swapTemporarySnap();
  setSymptomQueue = (symptomList) =>
    this.messageStore.setSymptomQueue(symptomList);
  assessmentBackStep = (callBack, sessionId) =>
    this.messageStore.assessmentBackStep(callBack, sessionId);
  assessmentSubmitBodyPart = (callBack, sessionId, quickReply) =>
    this.messageStore.assessmentSubmitBodyPart(callBack, sessionId, quickReply);
  setAssessmentToken = (token) => this.messageStore.setAssessmentToken(token);
  getAssessmentToken = () => this.messageStore.assessmentToken;
  getKeyValue = (key) => this.messageStore.getValueFortheKey(key);
}
