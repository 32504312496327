import {useNativeDriver} from 'config';
import React from 'react';
import {Animated} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import {viewStyles} from 'utils';
import CaraThinkingBubble from '../../reusables/components/CaraThinkingBubble';
import ChatInputContainer from '../../reusables/components/ChatInputContainer';
import {GiftedChat} from '../lib';
import BubbleSelector from './BubbleSelector';
import CustomSendButton from './CustomSendButtons';
import TextMessage from './TextMessage';

export default class Chat extends React.PureComponent {
  validInputRef = React.createRef();
  state = {
    inputToolBarHeight: 44,
    text: '',
    answeredQuestions: [],
    isInputContainerTextValid: true,
    setTextSecure: true,
    isValidText: true,
    enable: true,
    isValid: true,
    failDescription: '',
    mountChatChatActionContainer: false,
  };

  animatedX = new Animated.Value(300);
  updatePasswordState = (isValid, isValidText, enable, failDescription) => {
    this.setState({
      isValidText: isValidText,
      enable: enable,
      isValid: isValid,
      failDescription: failDescription,
    });
  };
  componentDidMount() {
    Animated.timing(this.animatedX, {
      toValue: 0,
      duration: 800,
      useNativeDriver,
    }).start();
  }

  setMountChatActionContainer = (val) => {
    this.setState({
      mountChatChatActionContainer: val,
    });
  };

  _insertInToAnsweredQuestionsArray = (identifier) => {
    this.setState({
      answeredQuestions: [...this.state.answeredQuestions, identifier],
    });
  };

  _deleteLastStep = () => {
    const insertAnsweredQs = [...this.state.answeredQuestions];
    insertAnsweredQs.splice(insertAnsweredQs.length - 1, 1);

    this.setState({
      answeredQuestions: insertAnsweredQs,
    });
  };

  _setInputToolBarHeight = (height) => {
    this.setState({
      inputToolBarHeight: height,
    });
  };
  _clearChatInputState = () => {
    this.validInputRef?.current?.clearAll();
  };

  _cleanState = () => {
    this.setState({
      isValidText: true,
      enable: true,
      isValid: true,
      failDescription: '',
    });
  };

  render() {
    const opacity = this.animatedX.interpolate({
      inputRange: [0, 100, 200],
      outputRange: [1, 0, 0],
    });

    const bottomOffset = DeviceInfo.hasNotch() ? 34 : 0;

    const {
      equalSizeChips,
      quickReplies,
      style = {},
      messages,
      placeholder = 'Enter Text..',
      onSend,
      stackAsChips = false,
      onQuickReply,
      validate = false,
      field = '',
      showDateTimeModal,
      selectedValueCountry,
      selectedValueProvince,
      countryModalFilterBy,
      showCountyPicker,
      showCheckboxPicker,
      checkboxData,
      showUserLocationModal,
      chatInputHeaderView,
      dateTimeModalAction,
      renderInputAndQuickReplies = false,
      pickerFormat,
      onChatContentSizeChanged = () => {},
      unMountedMsgs = [],
      refreshList,
      startDate,
      shouldRenderAllMonths,
    } = this.props;

    return (
      <Animated.View
        style={[
          viewStyles.flexGrow1,
          {
            opacity: opacity,
            transform: [
              {
                translateY: this.animatedX,
              },
            ],
          },
        ]}>
        <GiftedChat
          isValid={this.state.isValid}
          unMountedMsgs={unMountedMsgs}
          refreshList={refreshList}
          isTyping={false}
          renderFooter={(props) => {
            return <CaraThinkingBubble isTyping={this.props.isTyping} />;
          }}
          showUserAvatar={false}
          renderAvatar={() => null}
          renderDay={() => null}
          bottomOffset={bottomOffset}
          onChatContentSizeChanged={onChatContentSizeChanged}
          minComposerHeight={this.state.inputToolBarHeight}
          onScroll={this.props.onScroll}
          minInputToolbarHeight={this.state.inputToolBarHeight}
          renderComposer={(props) => (
            <ChatInputContainer
              equalSizeChips={equalSizeChips}
              isValidText={this.state.isValidText}
              mountUnmountChatActionContainer={this.setMountChatActionContainer}
              mounted={this.state.mountChatChatActionContainer}
              enable={this.state.enable}
              failDescription={this.state.failDescription}
              isValid={this.state.isValid}
              updatePasswordState={this.updatePasswordState}
              renderInputAndQuickReplies={renderInputAndQuickReplies}
              setTextSecure={(flag) => this.setState({setTextSecure: flag})}
              dateTimeModalAction={dateTimeModalAction}
              pickerFormat={pickerFormat}
              shouldRenderAllMonths={shouldRenderAllMonths}
              selectedValueProvince={selectedValueProvince}
              selectedValueCountry={selectedValueCountry}
              renderHeaderView={chatInputHeaderView}
              countryModalFilterBy={countryModalFilterBy}
              onPressQuickReply={onQuickReply}
              showDateTimeModal={showDateTimeModal}
              showCountyPicker={showCountyPicker}
              showCheckboxPicker={showCheckboxPicker}
              showUserLocationModal={showUserLocationModal}
              forwardRef={this.validInputRef}
              stackAsChips={stackAsChips}
              insertAnsweredQuestion={this._insertInToAnsweredQuestionsArray}
              quickReplies={quickReplies}
              checkboxData={checkboxData}
              setHeight={this._setInputToolBarHeight}
              validate={validate}
              field={field}
              startDate={startDate}
              {...props}
            />
          )}
          renderSend={(props) => <CustomSendButton {...props} />}
          textSecure={this.state.setTextSecure}
          messagesContainerStyle={style}
          containerStyle={[viewStyles.borderTopWidth0]}
          textInputProps={{autoFocus: true, autoCorrect: false}}
          placeholder={placeholder}
          renderTime={() => null}
          messages={messages}
          onSend={(e) => onSend(e)}
          renderAvatarOnTop
          renderMessageText={(props) =>
            !props.currentMessage.image ? (
              <TextMessage _onQuickReply={onQuickReply} {...props} />
            ) : null
          }
          showAvatarForEveryMessage={false}
          renderQuickReplies={(props) => null}
          renderBubble={(props) => (
            <BubbleSelector
              mountUnmountChatActionContainer={this.setMountChatActionContainer}
              answeredQuestions={this.state.answeredQuestions}
              textSecure={this.state.setTextSecure}
              inputText={this.state.text}
              _onQuickReply={onQuickReply}
              {...props}
            />
          )}
          onQuickReply={(val) => {
            if (val[0].value.action) {
              onQuickReply(val[0]);
            }
          }}
          user={{
            _id: '1',
          }}
        />
      </Animated.View>
    );
  }
}
