import {compose, createStore} from 'redux';
import rootReducer from '../reducers/index';


let composeEnhancers = compose;
if (__DEV__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const store = createStore(
  rootReducer,
  composeEnhancers(),
);

export {store};
