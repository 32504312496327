import React from 'react';
import {View, StyleSheet, GestureResponderEvent} from 'react-native';
import {ModalBase, ModalPropsBase} from './ModalBase';
import {Button} from 'libs';

interface IModalReminderProps extends ModalPropsBase {
  continuePressHandler: (event: GestureResponderEvent) => void;
}

export function ModalReminder({
  visible,
  closePressHandler,
  continuePressHandler,
}: IModalReminderProps) {
  return (
    <ModalBase
      visible={visible}
      title="Reminder"
      description="This symptom assessment is for informational purposes only, and does not replace a medical professional's advice or diagnosis. This assessment does not include all conditions, and your actual condition may not be included. Please consult a medical professional for any medical concern. If you are experiencing a medical emergency, call emergency services or go to the nearest hospital immediately."
      closePressHandler={closePressHandler}>
      <View style={styles.buttonContainer}>
        <Button.Primary title="Continue" onPress={continuePressHandler} />
      </View>
    </ModalBase>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 35,
  },
});
