import AntDesign from '../../app/assets/fonts/AntDesign.ttf';
import Feather from '../../app/assets/fonts/Feather.ttf';
import Ionicons from '../../app/assets/fonts/Ionicons.ttf';
import MarkProBold from '../../app/assets/fonts/MarkPro-Bold.otf';
import MarkProBook from '../../app/assets/fonts/MarkPro-Book.otf';
import MarkProMedium from '../../app/assets/fonts/MarkPro-Medium.otf';
import MaterialCommunityIcons from '../../app/assets/fonts/MaterialCommunityIcons.ttf';
import MaterialIcons from '../../app/assets/fonts/MaterialIcons.ttf';
import Octicons from '../../app/assets/fonts/Octicons.ttf';

const iconFonts = [
  {url: AntDesign, name: 'AntDesign'},
  {url: Feather, name: 'Feather'},
  {url: Ionicons, name: 'Ionicons'},
  {url: MaterialCommunityIcons, name: 'MaterialCommunityIcons'},
  {url: MaterialIcons, name: 'MaterialIcons'},
  {url: Octicons, name: 'Octicons'},
];

const openTypeFonts = [
  {url: MarkProBold, name: 'MarkPro-Bold'},
  {url: MarkProBook, name: 'MarkPro-Book'},
  {url: MarkProMedium, name: 'MarkPro-Medium'},
];

export function appendFonts(document: Document): void {
  const style = document.createElement('style');
  const fragment = document.createDocumentFragment();
  style.setAttribute('type', 'text/css');

  iconFonts.forEach((font) => {
    fragment.appendChild(
      document.createTextNode(`@font-face {
      src: url(${font.url});
      font-family: ${font.name};
    }`),
    );
  });

  openTypeFonts.forEach((font) => {
    fragment.appendChild(
      document.createTextNode(`@font-face {
      src: url(${font.url}) format('opentype');
      font-family: ${font.name};
    }`),
    );
  });

  style.appendChild(fragment);

  document.head.appendChild(style);
}
