// @ts-nocheck

import {AssessmentResult} from 'modules';
import React, {useEffect} from 'react';
import {EventType, useAnalytics} from 'utils';
import {ITabResultsProps} from '../TabResults';
interface IPreventionProps extends ITabResultsProps {
  preventionList?: Array<string>;
}

export function Prevention({title, preventionList}: IPreventionProps) {
  const {logEvent} = useAnalytics();
  useEffect(() => {
    logEvent(EventType.RESULT_PREVENTION_VIEW, {timestamp: Date.now()});
  }, []);

  return (
    <>
      <AssessmentResult.Tab.TextTitle title={title} />

      <AssessmentResult.Tab.TextTitleList title="You can:" />

      <AssessmentResult.Tab.RenderList
        items={preventionList}
        checkForFirstItem={false}
      />
    </>
  );
}
