export const familyHistory = {
  catalog_id: 'family-history',
  version: '1.0.0',
  display_name: 'Family History Catalog',
};

export const currentMedicalCondition = {
  catalog_id: 'history-of-disease-or-procedure',
  version: '1.0.0',
  display_name: 'History of Disease or Procedure Catalog',
};
