interface ITypography {
  h1: number;
  h2: number;
  h3: number;
  h4: number;
  h5: number;
  subtitle1: number;
  subtitle2: number;
  subtitle3: number;
  subtitle4: number;
  body1: number;
  body2: number;
  body3: number;
  body4: number;
  button: number;
  caption1: number;
  caption2: number;
  regular: string;
  bold: string;
  medium: string;
}

export const typography: ITypography = {
  h1: 64,
  h2: 48,
  h3: 36,
  h4: 26,
  h5: 24,
  subtitle1: 22,
  subtitle2: 20,
  subtitle3: 18,
  subtitle4: 17,
  body1: 16,
  body2: 15,
  body3: 14,
  body4: 14,
  button: 14,
  caption1: 13,
  caption2: 10,
  regular: '',
  bold: '',
  medium: '',
};
