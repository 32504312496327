import {StyleProp, ViewStyle, GestureResponderEvent} from 'react-native';

export interface IconPropsBase {
  name?: string;
  size?: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
  onPress?: (event: GestureResponderEvent) => void;
}

export enum IconSize {
  Small = 17,
  Medium = 21,
  Large = 25,
}
