import React, {useEffect} from 'react';
import {View, Text, StyleSheet, Pressable} from 'react-native';
import Animated, {
  useSharedValue,
  withTiming,
  useAnimatedStyle,
  withSequence,
  withSpring,
  withDelay,
  runOnJS,
} from 'react-native-reanimated';

export default function AnimatedToast({
  visible,
  leftItems,
  description,
  descriptionStyles = {marginLeft: 10},
  rightItems,
  onLeftItemClick = () => {},
  onTimeOut = () => {},
  onRightItemClick = () => {},
  setVisible,
  customBgStyles,
  cancelAnimationUpdate,
  withDelayTime = 1000,
}) {
  const translateY = useSharedValue(-100);
  useEffect(() => {
    if (!visible) {
      return;
    }
    translateY.value = withSequence(
      withSpring(50),
      withDelay(
        withDelayTime,
        withTiming(
          -100,
          {
            duration: 1000,
          },
          (isFinished) => {
            if (!isFinished) {
              return;
            }
            runOnJS(setVisible)(false);
            runOnJS(onTimeOut)();
          },
        ),
      ),
    );
  }, [visible]);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{translateY: translateY.value}],
    };
  });

  const onRightItemClickMethod = () => {
    onRightItemClick();

    translateY.value = withSpring(-100);
    setVisible(false);
  };

  return (
    <Animated.View
      style={[
        styles.container,
        StyleSheet.absoluteFill,
        animatedStyles,
        customBgStyles,
      ]}>
      <View style={styles.leftItem}>
        <Pressable style={styles.rightItem}>{leftItems()}</Pressable>
        <Text style={descriptionStyles}>{description}</Text>
      </View>
      <Pressable onPress={onRightItemClickMethod}>{rightItems}</Pressable>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 70,
    borderRadius: 20,
    padding: 10,
    zIndex: 600,
    justifyContent: 'space-between',
    marginHorizontal: 20,
    elevation: 20,
    shadowColor: '#151515',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.4,
    shadowRadius: 4.65,
  },
  rightItem: {flexDirection: 'row', alignItems: 'center', paddingLeft: 10},
  leftItem: {flexDirection: 'row', alignItems: 'center'},
});
