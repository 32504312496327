import React from 'react';
import Svg, {Line, Path} from 'react-native-svg';
import {fma19_1_5, fma222982, fma317911, fma38288, fma40771} from 'utils';
import {getColorFma} from 'utils';

export const Hand_Left_Back_fma9714 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.828 199.51">
      <Path
        id="Path_136"
        dataName="Path 136"
        d="M17.514,199.228l45.754.282a6.518,6.518,0,0,0,6.556-6.3c.308-8.94,1.7-24.735,7.616-29.875,8.414-7.316,19.387-26.337,25.6-30.727s8.414-23.045,15.731-29.629c1.828-1.647,3.977-3.43,6.1-5.276,6.361-5.538,12.466-11.642,8.9-16.306-4.755-6.219-26.7-4.39-42.8,27.434l-2.927-16.1s5.281-46.861,6.95-72.061c.34-5.13-2.986-9.389-7.193-9.877a8.451,8.451,0,0,0-9.147,6.95c-4.3,24.918-7.436,57.064-7.436,57.064H66.462l-3.2-64.7a12.944,12.944,0,0,0-1.774-6.1A7.485,7.485,0,0,0,54.582.011c-7.864.366-8.047,10.242-8.047,10.242l1.647,63.1-5.488.732-6.95-55.967s-1.1-8.687-7.681-8.413c-8.78.366-6.584,15.729-6.584,15.729l2.925,54.5-5.121,1.1L12.15,38.053s-.731-6.4-6.95-6.036S.079,40.248.079,40.248l7.133,93.826,6.22,28.166-2.373,29.954a6.519,6.519,0,0,0,5.985,7.014C17.2,199.22,17.357,199.227,17.514,199.228Z"
        transform="translate(-0.004 -0.006)"
        fill="#ead9e7"
      />

      <Path
        id={fma222982}
        dataName="Path 137"
        d="M90.949,108.693l-2.9-15.955s.216-2.23.728-7.064a1.667,1.667,0,0,0-2.111-1.8,19.569,19.569,0,0,1-7.214.568c-4.447-.338-7.413-4.7-9.064-8.61a1.646,1.646,0,0,0-1.479-.99h0a1.645,1.645,0,0,0-1.567,1c-.9,2.112-3.629,5.85-11.239,5.19a11.042,11.042,0,0,1-8.625-6.248,1.685,1.685,0,0,0-1.733-1.036l-.555.064a1.654,1.654,0,0,0-1.448,1.354C43.1,79,41.049,84.7,34.032,85.792a10.28,10.28,0,0,1-10.265-4.5,1.681,1.681,0,0,0-1.744-.806l-.525.1a1.685,1.685,0,0,0-1.306,1.159C17.44,91.245,7.931,90.684,4.011,90.5l3.013,39.491,79.607.422A1.079,1.079,0,0,0,87.7,129.5Z"
        transform="translate(-0.004 0.371)"
        fill={getColorFma(fma222982, selectedBodyPart)}
        onPressIn={() => onPressClick(fma222982)}
        onClick={() => onPressClick(fma222982)}
      />

      <Path
        id={fma40771}
        dataName="Path 138"
        d="M91.406,134.287l-3.623-1.314a.99.99,0,0,0-1.108.3c-1.877,2.308-9.185,11.313-11.735,14.7-3.627,4.813-7.227,12.325-8.464,15.009a.949.949,0,0,0,.467,1.258.913.913,0,0,0,.132.049l7.846,2.335c.11-.2.221-.4.335-.588a11.482,11.482,0,0,1,2.188-2.7c7.029-6.112,14.94-19.487,21.277-26.581Z"
        transform="translate(-0.004 -0.006)"
        fill={getColorFma(fma40771, selectedBodyPart)}
        onPressIn={() => onPressClick(fma40771)}
        onClick={() => onPressClick(fma40771)}
      />

      <Path
        id={fma38288}
        dataName="Path 139"
        d="M64.45,165.465h-.021c-.536-.062-1.075-.1-1.614-.137l-49.389-3.087-1.288,16.25c21.732-2.43,46,1.228,58.553,3.634.725-5.678,1.354-10.289,3.578-14.275L67.9,166.117A23.186,23.186,0,0,0,64.45,165.465Z"
        transform="translate(-0.004 -0.006)"
        fill={getColorFma(fma38288, selectedBodyPart)}
        onPressIn={() => onPressClick(fma38288)}
        onClick={() => onPressClick(fma38288)}
      />

      <Path
        id={fma317911}
        dataName="Path 140"
        d="M65.247,161.874c4.39-10.974,14.787-22.881,20.118-29.081a.63.63,0,0,0-.48-1.042L6.994,131.6l.119,2.519,5.594,25.337.337,1.527s42.245,2.513,49.619,2.853c0,0,1.738.274,2.059-.6C65.124,162.151,65.247,161.874,65.247,161.874Z"
        transform="translate(-0.004 -0.006)"
        fill={getColorFma(fma317911, selectedBodyPart)}
        onPressIn={() => onPressClick(fma317911)}
        onClick={() => onPressClick(fma317911)}
      />

      <Path
        id="Path_142"
        dataName="Path 142"
        d="M94.022,17.263s-1.829,8.047-8.657,7.315c-6.323-.677-5-8.9-5-8.9"
        transform="translate(-0.004 -0.006)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id="Path_143"
        dataName="Path 143"
        d="M127.555,94.567l-2.806-2.316c-2.437-2.317,4.634-8.536,4.634-8.536"
        transform="translate(-0.004 -0.006)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id="Path_144"
        dataName="Path 144"
        d="M61.467,4.582s-1.22,9.388-6.585,9.388-6.705-8.779-6.705-8.779"
        transform="translate(-0.004 -0.006)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id="Path_145"
        dataName="Path 145"
        d="M33.422,12.873s1.951,8.535-4.633,8.535-5.486-7.56-5.486-7.56"
        transform="translate(-0.004 -0.006)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id="Path_146"
        dataName="Path 146"
        d="M9.524,34.577s.367,8.169-3.414,8.657-3.658-8.779-3.658-8.779"
        transform="translate(-0.004 -0.006)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Line
        id="Line_19"
        dataName="Line 19"
        x2="4.877"
        y2="14.998"
        transform="translate(26.834 102.974)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Line
        id="Line_20"
        dataName="Line 20"
        y1="20.241"
        x2="0.488"
        transform="translate(47.075 96.512)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Line
        id="Line_21"
        dataName="Line 21"
        y1="16.583"
        x2="5.121"
        transform="translate(61.951 100.902)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id={fma19_1_5}
        dataName="Path 147"
        d="M3.8,89.217c14.217,1.052,15.481-8.184,15.481-8.184L13.649,47.112a2.313,2.313,0,0,0-2.641-1.907L2.687,46.511a2.314,2.314,0,0,0-1.95,2.46Z"
        transform="translate(-0.004 0.371)"
        fill={getColorFma(fma19_1_5, selectedBodyPart)}
        onPressIn={() => onPressClick(fma19_1_5)}
        onClick={() => onPressClick(fma19_1_5)}
      />

      <Path
        id={fma19_1_5}
        dataName="Path 148"
        d="M24.4,79.935a8.812,8.812,0,0,0,9.389,4.512c7.562-1.339,8.9-10.365,8.9-10.365L36.714,25.973a2.314,2.314,0,0,0-2.562-2.013L23.633,25.181A2.313,2.313,0,0,0,21.59,27.6Z"
        transform="translate(-0.004 0.371)"
        fill={getColorFma(fma19_1_5, selectedBodyPart)}
        onPressIn={() => onPressClick(fma19_1_5)}
        onClick={() => onPressClick(fma19_1_5)}
      />

      <Path
        id={fma19_1_5}
        dataName="Path 149"
        d="M48.177,73.351a9.565,9.565,0,0,0,8.047,6.341c8.814.81,10.242-4.877,10.242-4.877L63.73,19.488a2.312,2.312,0,0,0-2.212-2.2l-12.346-.522a2.312,2.312,0,0,0-2.408,2.212c0,.053,0,.107,0,.16Z"
        transform="translate(-0.004 0.371)"
        fill={getColorFma(fma19_1_5, selectedBodyPart)}
        onPressIn={() => onPressClick(fma19_1_5)}
        onClick={() => onPressClick(fma19_1_5)}
      />

      <Path
        id={fma19_1_5}
        dataName="Path 150"
        d="M92.169,28.639,79.4,27.165a2.312,2.312,0,0,0-2.555,1.952c-3.335,22.332-5.619,45.7-5.619,45.7s2.3,7.385,8.414,8.29a13.954,13.954,0,0,0,9.644-1.6c1.364-12.643,3.547-33.6,4.93-50.382A2.308,2.308,0,0,0,92.169,28.639Z"
        transform="translate(-0.004 0.371)"
        fill={getColorFma(fma19_1_5, selectedBodyPart)}
        onPressIn={() => onPressClick(fma19_1_5)}
        onClick={() => onPressClick(fma19_1_5)}
      />

      <Path
        id={fma19_1_5}
        dataName="Path 151"
        d="M113.532,93.958c-8.68,12.118-21.625,31.671-24.815,36.507a1.064,1.064,0,0,0,.515,1.577l3.211,1.209L99.7,135.76a21.578,21.578,0,0,1,3.343-3.15c6.22-4.39,8.414-23.045,15.73-29.629,2-1.8,4.391-3.772,6.7-5.807v-.008a43.77,43.77,0,0,1-8.048-4.653,2.294,2.294,0,0,0-3.178.461Q113.895,93.452,113.532,93.958Z"
        transform="translate(-0.004 0.371)"
        fill={getColorFma(fma19_1_5, selectedBodyPart)}
        onPressIn={() => onPressClick(fma19_1_5)}
        onClick={() => onPressClick(fma19_1_5)}
      />
    </Svg>
  );
};
