import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {fma22552, fma258847, fma35503, fma73092} from 'utils';
import {getColorFma} from 'utils';

export const Foot_Left_Outside_fma274 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163.453 84.049">
      <Path
        id="Path_389"
        dataName="Path 389"
        d="M149.863.1H109a5.853,5.853,0,0,0-5.842,5.527l-.1,1.826c-.412,7.422-6.216,16.162-12.9,19.422L35.684,53.457A18.127,18.127,0,0,1,29.011,55H27.936a12.116,12.116,0,0,0-5.884,1.525l-8.694,4.83a12.435,12.435,0,0,1-4.964,1.318l-7.037.082s-2.672,6.688-.532,10.8a11.5,11.5,0,0,0,10.188,6.1c9.773,0,27.829,4.4,33.057,4.4H55.351q2.193,0,4.385-.166c9.7-.741,49.93-3.7,74.26-3.7,8.806,0,25.19-2.139,28.517-11.407s-3.22-19.249-4.289-27.625c-.372-2.92-1.869-22.575-2.519-35.5A5.846,5.846,0,0,0,149.863.1Z"
        transform="translate(0.005)"
        fill="#ead9e7"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id="Path_390"
        dataName="Path 390"
        d="M11.2,62.9a2.518,2.518,0,0,1-.862,3.267c-1.553,1.052-6.936.683-9.387.505"
        transform="translate(0.005)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id={fma258847}
        dataName="Path 391"
        d="M145.528,2.2l-.715,27.884a2.149,2.149,0,0,0,.1.712l9.8,30.246a2.136,2.136,0,0,0,2.191,1.472l6.485-.484c.179-5.529-4.447-15.022-5.159-20.872C157.523,35.334,155.415,0,155.415,0l-7.784.117A2.138,2.138,0,0,0,145.528,2.2Z"
        transform="translate(0.005)"
        fill={getColorFma(fma258847, selectedBodyPart)}
        onPressIn={() => onPressClick(fma258847)}
        onClick={() => onPressClick(fma258847)}
      />
      <Path
        id={fma35503}
        dataName="Path 392"
        d="M143.763,3.112l-.527,26.874a2.878,2.878,0,0,0,.134.93l9.371,29.665a2.9,2.9,0,0,1-1.817,3.611l-25.093,8.67a2.9,2.9,0,0,1-3.609-1.6L111.986,47.378a2.9,2.9,0,0,1-.233-1.087l-.805-43.233A2.9,2.9,0,0,1,113.8.107h.051L140.87.156a2.9,2.9,0,0,1,2.893,2.907Z"
        transform="translate(0.005)"
        fill={getColorFma(fma35503, selectedBodyPart)}
        onPressIn={() => onPressClick(fma35503)}
        onClick={() => onPressClick(fma35503)}
      />
      <Path
        id={fma73092}
        dataName="Path 393"
        d="M108.947.1h0c-2.732.143-5.635,2.422-5.674,5.158h0A24.974,24.974,0,0,1,90.166,26.878h0L46.7,48.035a3.624,3.624,0,0,0-.8,5.984L60.336,66.684a3.625,3.625,0,0,0,3.925.559l43.784-20.154a3.623,3.623,0,0,0,2.09-3.314l-.367-42.9a.775.775,0,0,0-.78-.772Z"
        transform="translate(0.005)"
        fill={getColorFma(fma73092, selectedBodyPart)}
        onPressIn={() => onPressClick(fma73092)}
        onClick={() => onPressClick(fma73092)}
      />
      <Path
        id={fma22552}
        dataName="Path 394"
        d="M57.347,74.317l.006,2.35A2.9,2.9,0,0,0,60.237,79.3H99.088a2.9,2.9,0,0,0,.656-.075l18.356-4.7a2.9,2.9,0,0,0,2.164-3.484,2.976,2.976,0,0,0-.158-.481l-8.243-20.42a2.9,2.9,0,0,0-3.81-1.518l-.06.027L59.021,71.416A2.9,2.9,0,0,0,57.347,74.317Z"
        transform="translate(0.005)"
        fill={getColorFma(fma22552, selectedBodyPart)}
        onPressIn={() => onPressClick(fma22552)}
        onClick={() => onPressClick(fma22552)}
      />
    </Svg>
  );
};
