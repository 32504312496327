import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {fma258847, fma35452, fma51084} from 'utils';
import {getColorFma} from 'utils';

export const Foot_Left_Inside_fma273 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164.509 80.955">
      <Path
        id="Path_770"
        dataName="Path 770"
        d="M14.467,0H57.8a4.331,4.331,0,0,1,4.324,4.089h0a29.906,29.906,0,0,0,16.747,25.22l50.436,24.607a15.386,15.386,0,0,0,6.743,1.557h0A12.241,12.241,0,0,1,142,57.014l7.487,4.16a12.26,12.26,0,0,0,5.8,1.54l7.11.083s3.948,7.745,1.051,11.422c-3.215,4.081-6.686,5.448-10.3,5.448-9.876,0-11.375.691-16.9-2.611-3.324-1.987-3.032,2.671-10,2.431s-12.728.961-21.853-1.681S91.184,74.444,85.9,74.444c-3.763,0-19.224.488-27.808.768a33.217,33.217,0,0,0-10.347,2c-4.712,1.725-12.17,4.022-17.8,3.718C21.06,80.45,4.491,78.769,1.129,69.4S2.81,53.314,3.77,49.712c.762-2.858,3.791-32.468,5.011-44.585A5.7,5.7,0,0,1,14.467,0Z"
        transform="translate(0.012 0)"
        fill="#ead9e7"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id="Path_771"
        dataName="Path 771"
        d="M153.391,63.878c1.8,2.041,6,2.762,8.765,2.521"
        transform="translate(0.012 0)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id={fma35452}
        dataName="Path 772"
        d="M20.367,1.783l.18,27.545a1.619,1.619,0,0,1-.069.479l-10,33.013a1.612,1.612,0,0,0,1.071,2.014l.05.014a78.608,78.608,0,0,0,18.24,2.506c8.064,0,24.878-.821,30.388-1.1a1.615,1.615,0,0,0,1.528-1.7,1.654,1.654,0,0,0-.018-.171l-9.961-62.9a1.618,1.618,0,0,0-1.6-1.364L21.981.159a1.617,1.617,0,0,0-1.614,1.62Z"
        transform="translate(0.012 0)"
        fill={getColorFma(fma35452, selectedBodyPart)}
        onPressIn={() => onPressClick(fma35452)}
        onClick={() => onPressClick(fma35452)}
      />
      <Path
        id={fma258847}
        dataName="Path 773"
        d="M18.551,1.646v25.27a16.976,16.976,0,0,1-.72,4.9L8.6,62.556a1.645,1.645,0,0,1-2.048,1.1,1.505,1.505,0,0,1-.159-.057L1.267,61.475a1.638,1.638,0,0,1-.991-1.808,34.755,34.755,0,0,1,2.471-7.418c1.794-4.12,4.1-28.052,4.1-28.052L8.787,5.215a1.345,1.345,0,0,1,.028-.178C8.968,4.347,10.1,0,14.47,0h2.438A1.645,1.645,0,0,1,18.551,1.646Z"
        transform="translate(0.012 0)"
        fill={getColorFma(fma258847, selectedBodyPart)}
        onPressIn={() => onPressClick(fma258847)}
        onClick={() => onPressClick(fma258847)}
      />
      <Path
        id={fma51084}
        dataName="Path 774"
        d="M58.012,30.458l5.627,34.069a1.612,1.612,0,0,0,1.588,1.351C78.3,65.95,115.185,73.032,121,73.814a28.782,28.782,0,0,0,11.372-1.255,1.606,1.606,0,0,0,1.14-1.678c-.222-2.6-.552-5.851-.723-7.5a1.6,1.6,0,0,0-1.126-1.374C124.648,59.849,82.489,46.469,60.6,28.925a1.612,1.612,0,0,0-2.591,1.533Z"
        transform="translate(0.012 0)"
        fill={getColorFma(fma51084, selectedBodyPart)}
        onPressIn={() => onPressClick(fma51084)}
        onClick={() => onPressClick(fma51084)}
      />
    </Svg>
  );
};
