import {isWeb} from 'config';
import {Icon, Text} from 'libs';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Colors, TopBarSvg, TopIcon, viewStyles} from 'utils';

function DiseaseCitationsHeader(props: any) {
  return (
    <>
      <View style={styles.headerContainer}>
        <View style={styles.headerTitleContainer}>
          <Text.Subtitle variant="medium" size="subtitle3">
            Medical Conditions Citations
          </Text.Subtitle>
        </View>
        <TopIcon style={{...viewStyles.zIndex100}} />
        <View style={styles.iconCloseContainer}>
          <Icon.Close
            onPress={props.navigation.goBack}
            size={Icon.Size.Medium}
          />
        </View>
      </View>
      {!isWeb ? <TopBarSvg fillColor={Colors.topPurple} /> : null}
    </>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    height: isWeb ? 100 : 70,
    backgroundColor: Colors.topPurple,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitleContainer: {
    position: 'absolute',
    top: 45,
  },
  iconCloseContainer: {
    position: 'absolute',
    right: 5,
    bottom: 18,
  },
});

export default DiseaseCitationsHeader;
