// @ts-nocheck
import {isWeb} from 'config';
import {CheckboxPicker} from 'libs';
import React, {memo} from 'react';
import {FlatList, Keyboard, StyleSheet, Text, View} from 'react-native';
import {isIOS} from 'react-native-elements/dist/helpers';
import Animated, {
  EasingNode,
  interpolateNode,
  timing,
} from 'react-native-reanimated';
import Icon from 'react-native-vector-icons/AntDesign';
import {connect} from 'react-redux';
import {
  Colors,
  heightToDp,
  MARKPRO_MEDIUM,
  SECOND_PASS,
  Spacing,
  viewStyles,
  widthToDp,
} from 'utils';
import CountryPicker from '../../../../modules/user/CountryPicker';
import {CurvedWh, SideBarIndicatorPrimary} from '../../../assets/images';
import {
  AnimatedSideBar,
  ChatInput,
  DefaultButton,
  QuickRepliesSelector,
  Spacer,
  UserLocationPickerIOS,
} from '../../index';
import DatePickerIOS from './DatePickerIOS';
import SeverityTextTitle from './SeverityTextTitle';

const WarningMessage = ({failDescription}) => (
  <View style={styles.warningMsgContainer}>
    <View style={styles.warningIconContainer}>
      <Icon name="exclamation" size={15} color="#CC3030" />
    </View>
    <Text style={styles.failText}>{failDescription}</Text>
  </View>
);

class ChatInputContainer extends React.Component {
  title1 = 'Password does not meet the criteria';
  height = 0;

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>) {
    if (
      prevProps?.quickReplies[0]?.inputQuickReplies?.field !==
      this.props.quickReplies[0]?.inputQuickReplies?.field
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        secureTextEntry:
          this.props.quickReplies[0]?.inputQuickReplies?.field === 'password',
      });
    }
    if (this.props.mounted !== prevProps?.mounted) {
      let translateY = 0;
      if (this.props.mounted) {
        translateY = 0;
      } else {
        translateY = 600;
      }
      timing(this.translateY, {
        toValue: translateY,
        duration: 1000,
        easing: EasingNode.bezier(0.5, 0.01, 0, 1),
      }).start();
    }
  }

  spinValue = new Animated.Value(0);
  translateY = new Animated.Value(600);

  spin = interpolateNode(this.spinValue, {
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  });

  state = {
    isValid: true,
    failDescription: '',
    enterText: '',
    enable: '',
    secureTextEntry:
      this.props.quickReplies[0]?.inputQuickReplies?.field === 'password',
    sliderRange: 0,
    selectedYear: '',
  };

  clearAll = () => {
    this.setState({
      enterText: '',
    });
  };

  toggleSecureTextEntry = () => {
    this.setState((prevState) => {
      return {
        secureTextEntry: !prevState.secureTextEntry,
      };
    }, this.props.setTextSecure(!this.state.secureTextEntry));
  };
  _animateDownArraow = (toValue) => {
    timing(this.spinValue, {
      toValue: toValue,
      duration: 200,
      easing: EasingNode.bezier(0.5, 0.01, 0, 1),
    }).start();
  };
  _onChangeText = (text) => {
    this.setState({
      enterText: text,
    });
    this.validate(text);
  };
  onViewLayout = (e) => {
    const {layout} = e.nativeEvent;
    this.height = layout.height;
    this.props.setHeight(layout.height);
    this.props.onInputSizeChanged({height: layout.height});
  };
  cleatText = () => {
    this.setState(
      {
        enterText: null,
        enable: false,
      },
      () => {
        Keyboard.dismiss();
      },
    );
  };
  validate = (text) => {
    if (this.props.quickReplies[0]?.inputQuickReplies?.validate) {
      if (this.props.quickReplies[0]?.inputQuickReplies?.field === 'password') {
        if (text === '') {
          this.props.updatePasswordState(true, false, false, '');
          return false;
        }
        let mediumRegex = new RegExp(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
        );
        if (!mediumRegex.test(text)) {
          this.props.updatePasswordState(false, false, false, this.title1);
          return false;
        }
        this.props.updatePasswordState(true, true, true, '');
        return true;
      }
    } else if (text.length >= 3) {
      this.props.updatePasswordState(true, true, true, '');
    } else {
      this.props.updatePasswordState(true, true, true, '');
    }
  };

  animateContainerPosition = (toValue) => {
    timing(this.translateY, {
      duration: 1000,
      toValue: toValue,
      easing: EasingNode.bezier(0.5, 0.01, 0, 1),
    }).start();
  };

  renderInputView = () => {
    const tag = this.props.quickReplies[0]?.tag;
    const props = {
      clearText: this.cleatText,
      key: 2,
      tag: tag,
      isValidText: this.props.isValid,
      toggleSecureText: this.toggleSecureTextEntry,
      secureTextEntry: this.state.secureTextEntry,
      editable: this.props.quickReplies[0]?.inputQuickReplies?.editable,
      insertAnsweredQuestion: this.props.insertAnsweredQuestion,
      inputActions: this.props.quickReplies[0].inputQuickReplies,
      validate: this.validate,
      enable: this.props.enable,
      isPswField:
        this.props.quickReplies[0]?.inputQuickReplies?.field === 'password',
      enterText: this.state.enterText,
      placeHolder: this.props.quickReplies[0]?.inputQuickReplies?.placeHolder,
      onChangeText: this._onChangeText,
      animate: this._animateDownArraow,
      spin: this.spin,
    };

    return <ChatInput {...this.props} {...props} />;
  };
  renderQuickReplies = () => {
    const quickReplies = {
      quickReplies: this.props.quickReplies[0]?.quickReplies,
    };
    return (
      <QuickRepliesSelector
        key={1}
        unmountChatContainer={this.props.mountUnmountChatActionContainer}
        stackAsChips={this.props.stackAsChips}
        onQuickReply={this.props.onQuickReply}
        currentMessage={quickReplies}
        equalSizeChips={this.props.equalSizeChips}
      />
    );
  };
  renderHeaderView = () => {
    if (this.props.renderHeaderView) {
      return this.props.renderHeaderView(this.props);
    }
    return null;
  };

  renderSeverityText = (severity) => {
    return <SeverityTextTitle severity={severity} />;
  };

  onSliderContinue = () => {
    let selectedReply =
      this.props?.quickReplies[0]?.quickReplies.values[
        this.state.sliderRange - 1
      ];
    const x = this.state.sliderRange;
    switch (this.state.sliderRange) {
      case x > 0 && x < 4:
        selectedReply = this.props?.quickReplies[0]?.quickReplies.values[0];
        break;
      case x > 0 && x < 7:
        selectedReply = this.props?.quickReplies[0]?.quickReplies.values[1];
        break;
      case x > 0 && x < 11:
        selectedReply = this.props?.quickReplies[0]?.quickReplies.values[2];
        break;
    }
    this.props.onPressQuickReply(selectedReply);
  };

  renderQuickRepliesOrChatInput = () => {
    if (this.props?.quickReplies[0]?.answer_type === 'ct-slider') {
      return (
        <View style={styles.renderQuickRepliesOrChatInputContainer}>
          <Spacer color={Colors.systemChatBubblesColor} height={10} />
          <AnimatedSideBar
            style={styles.animatedSideBarView}
            renderSeverityText={this.renderSeverityText}
            onDragEnd={(item) => {
              this.setState({
                sliderRange: item.severity,
              });
            }}
            symptomRange={{
              tracks: [
                {severity: this.state.sliderRange, symptom_track_item: {}},
              ],
            }}
            rulerColor={Colors.systemChatBubblesColor}
            beizereCurveImage={CurvedWh}
            circleImage={SideBarIndicatorPrimary}
            WIDTH={this.props.windowWidth}
          />
          <DefaultButton
            name={'Continue'}
            style={styles.defaultButtonStyle}
            disabled={this.state.sliderRange === 0}
            click={this.onSliderContinue}
          />
        </View>
      );
    }

    let view = this.renderQuickReplies();
    let view2;
    if (this.props?.quickReplies[0]?.inputQuickReplies?.values?.length > 0) {
      view2 = this.renderInputView();
      if (
        this.props.renderInputAndQuickReplies ||
        this.props?.quickReplies[0]?.renderInputAndQuickReplies
      ) {
        return [view2, view];
      }

      return [view2];
    }
    return [view];
  };

  renderCheckboxPicker = () => {
    const data = [];
    this.props.checkboxData.forEach((element, index) => {
      data.push({
        title: element.split('/')[element.split('/').length - 1],
        id: index,
      });
    });
    return (
      <CheckboxPicker
        data={data}
        extractTitle={(item) => item.title}
        onDone={(items) => {
          const pickedItems = [];
          let title = '';
          items.forEach((item) => {
            title += `${data[item.id].title}\n`;
            pickedItems.push({
              risk_factor: this.props.checkboxData[item.id],
              present: true,
            });
          });
          this.props.onPressQuickReply({
            title,
            value: {
              action: SECOND_PASS,
              option: pickedItems,
            },
          });
        }}
      />
    );
  };

  renderCountryPicker = () => {
    return (
      <View style={[styles.renderDateTimeModalContainer]}>
        <View style={[styles.sliderView, {transform: [{translateY: 0}]}]}>
          <CountryPicker
            onSendData={this.props.onPressQuickReply}
            onLayout={this.onViewLayout}
          />
        </View>
      </View>
    );
  };

  renderDatTimeModal = () => {
    return (
      <View style={[styles.renderDateTimeModalContainer]}>
        <View style={[styles.sliderView, {transform: [{translateY: 0}]}]}>
          <DatePickerIOS
            pickerFormat={this.props.pickerFormat}
            isVisible={this.props.showDateTimeModal}
            dateTimeModalAction={this.props.dateTimeModalAction}
            onSendData={this.props.onPressQuickReply}
            onLayout={this.onViewLayout}
            startDate={this.props.startDate}
            shouldRenderAllMonths={this.props.shouldRenderAllMonths}
          />
        </View>
      </View>
    );
  };
  renderUserLocationModal = () => {
    return (
      <View style={viewStyles.height260}>
        <View style={[styles.sliderView, {transform: [{translateY: 0}]}]}>
          <UserLocationPickerIOS
            animateDownArrow={this._animateDownArraow}
            countryModalFilterBy={this.props.countryModalFilterBy}
            selectedValueCountry={this.props.selectedValueCountry}
            selectedValueProvince={this.props.selectedValueProvince}
            onSendData={this.props.onPressQuickReply}
          />
        </View>
      </View>
    );
  };
  render() {
    return (
      <Animated.View
        style={[
          styles.container,
          {transform: [{translateY: isWeb ? 0 : this.translateY}]},
        ]}
        onLayout={this.onViewLayout}>
        {this.props.mounted ? (
          <FlatList
            data={[0]}
            bounces={false}
            keyExtractor={(item, index) => index.toString()}
            renderItem={() => {
              return (
                <>
                  {!this.props.equalSizeChips && this.renderHeaderView()}
                  {this.props.quickReplies[0]?.quickReplies &&
                    this.renderQuickRepliesOrChatInput()}
                  {this.props.equalSizeChips && this.renderHeaderView()}
                  {!this.props.isValid && (
                    <WarningMessage
                      failDescription={this.props.failDescription}
                    />
                  )}
                  {this.props.showDateTimeModal && this.renderDatTimeModal()}
                  {this.props.showCountyPicker && this.renderCountryPicker()}
                  {this.props.showCheckboxPicker && this.renderCheckboxPicker()}
                </>
              );
            }}
          />
        ) : null}
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  renderQuickRepliesOrChatInputContainer: {
    backgroundColor: Colors.white,
    height: 230,
  },
  renderDateTimeModalContainer: {
    height: isIOS ? 255 : isWeb ? 60 : 100,
  },
  animatedSideBarView: {position: 'absolute', top: 10},
  defaultButtonStyle: {position: 'absolute', top: 160, left: 20, right: 20},
  failText: {
    padding: 5,
    paddingHorizontal: 10,
    color: Colors.deleteDarkColor,
    fontFamily: MARKPRO_MEDIUM,
  },
  warningIconContainer: {
    backgroundColor: Colors.worstPossible,
    borderRadius: 50,
    height: widthToDp(7),
    width: widthToDp(7),
    justifyContent: 'center',
    alignItems: 'center',
  },
  sliderView: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.white,
  },
  container: {
    width: '100%',
    maxHeight: isWeb ? 'auto' : heightToDp(50),
    backgroundColor: Colors.white,
    elevation: 20,
    shadowColor: '#151515',
    shadowOffset: {
      width: 5,
      height: -10,
    },
    shadowOpacity: 0.06,
    shadowRadius: 4.65,
    borderTopColor: Colors.white,
    marginBottom: isWeb ? Spacing.small25 : 0,
  },
  warningMsgContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: widthToDp(2),
  },
});

const mapStateToProps = (state) => ({
  windowWidth: state.appConfig.windowWidth,
});

export default connect(mapStateToProps)(memo(ChatInputContainer));
