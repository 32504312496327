import React from 'react';
import Svg, {Path, G} from 'react-native-svg';
import {fma23_2_4, fma24995, fma37850, fma51131} from 'utils';
import {getColorFma} from 'utils';

export const Foot_Right_Bottom_fma2320 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1242 2308">
      <Path
        id="Path_596"
        dataName="Path 596"
        d="M372.9,909.6l99.4,700.5c6.1,42.8,26.8,82.8,60.2,110.3c23.7,19.6,55.9,35.3,98.4,35.3  c113.2,0,157.1-116.5,157.1-184.1v-213.7c0-97,12.6-193.6,38.4-287c19-68.8,37.6-147.8,35.9-188.3c-3.4-81.1,0.1-233.1,0.1-315.8  c0-52.6-23.1-110.2-77.2-110.2c-64.2,0-74.4,56.7-74,101.3c0.4,45.6,0.2,133.9,0.2,133.9l-12.9,0.4c0,0-3.9-105.8-4.4-141.2  c-0.3-19.3-13.7-49.8-47.6-48.2c-38.2,1.7-40.8,39.4-38.8,62c1.8,19.8,13.5,138.1,13.5,138.1l-11.4,3l-11.3-122.9  c0,0-3.3-40.1-40.7-35.9c-38.8,4.4-32.1,52.8-32.1,52.8l15.2,128.8l-11.4,3.4c0,0-6.4-60.4-8.8-79.3c-1.6-12.7-11.1-34.3-41.3-30  c-30.8,4.4-25.9,45.3-25.9,45.3l12.6,95.3l-9.3,5.9c0,0-6.1-41.4-9.1-62.1c-1.7-12.9-10-31.6-35.3-30c-27,1.7-31.6,18-31.2,53.4  c0.4,35.5,5.9,77.1,0,94C375.4,841.2,369.1,884.5,372.9,909.6z"
        fill="#ead9e7"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id={fma23_2_4}
        dataName="Path 597"
        d="M853.1,511.5c-85.5-89.2-446.2,181.4-469.7,248.3c-0.2,0.5-2.1,3.9-2.9,8.8c-6.3,40.2-8.4,73.6-8.9,98  c-0.2,11.7,4.6,19.5,9.6,19.3c3.2-0.1,5.8-4.5,6.4-5.5c8.7-14.4,105.3-104.9,221.9-134.7c59.9-15.3,111.3-11,147.2-8  c42.7,3.6,78.2,11.7,104.1,19.2c0-44.9-0.1-89.8-0.1-134.7C878.9,554.9,866.7,525.8,853.1,511.5z"
        fill={getColorFma(fma23_2_4, selectedBodyPart)}
        onPressIn={() => onPressClick(fma23_2_4)}
        onClick={() => onPressClick(fma23_2_4)}
      />
      <G>
        <Path
          id={fma37850}
          dataName="Path 598"
          d="M375.4,930.3l48.3,348.9c2,14.3,14.2,24.9,28.6,24.9c62.7,0,227.2,0,309.7,0c15.1,0,27.8-11.6,28.8-26.7   c8.7-131,68.8-311.7,69.4-391.1c0.2-32.9-0.8-52.2-0.8-84.1c0-13.4-9.1-25-22.1-28.1c-49.9-12.2-129.8-25.9-210.2-13.2   C531,776.1,419.5,872.7,384.4,905C377.4,911.4,374.1,920.9,375.4,930.3z"
          fill={getColorFma(fma37850, selectedBodyPart)}
          onPressIn={() => onPressClick(fma37850)}
          onClick={() => onPressClick(fma37850)}
        />
        <Path
          id={fma51131}
          dataName="Path 599"
          d="M432.4,1329.2l22.8,158.6c1.6,10.8,14.9,14.9,22.2,6.8c23.5-26,71.5-64,151.3-64c67.1,0,111.9,35.5,136.3,62.6   c7.9,8.8,22.4,3.2,22.4-8.6l0.1-157.3c0-7.1-5.7-12.8-12.8-12.8H445.1C437.3,1314.5,431.3,1321.4,432.4,1329.2z"
          fill={getColorFma(fma51131, selectedBodyPart)}
          onPressIn={() => onPressClick(fma51131)}
          onClick={() => onPressClick(fma51131)}
        />
        <Path
          id={fma24995}
          dataName="Path 600"
          d="M627.8,1441.7c-87,1.7-162.5,74.2-161.6,106.3c4.6,133.8,66.6,207.8,164.7,207.8   c120.4,0,158.5-120.8,156.9-195.9C785.2,1520.5,713.9,1440,627.8,1441.7z"
          fill={getColorFma(fma24995, selectedBodyPart)}
          onPressIn={() => onPressClick(fma24995)}
          onClick={() => onPressClick(fma24995)}
        />
      </G>
      <Path
        id="Path_648"
        dataName="Path 648"
        d="M622.3,831.5c0,0-34.6,167.6,103.8,186.2"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
