import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {IButtonPropsBase} from './IButtonPropsBase';
import {useTheme, colors, Spacing} from 'utils';
import {Text} from 'libs';

interface IButtonActionProps extends IButtonPropsBase {
  selectedTitle?: string;
  highlighted?: boolean;
}

export function ButtonAction({
  title,
  onPress,
  highlighted,
}: IButtonActionProps) {
  const theme = useTheme();

  return (
    <TouchableOpacity
      style={[styles.buttonStyle, highlighted && styles.highlighted]}
      onPress={onPress}>
      <Text.Body variant="bold" size="body1" color={colors.primary}>
        {title}
      </Text.Body>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  buttonStyle: {
    borderRadius: Spacing.small,
    borderWidth: Spacing.tiny1,
    borderColor: colors.veryLightOrange,
    paddingHorizontal: Spacing.base,
    paddingVertical: Spacing.tiny,
    marginRight: Spacing.base,
    marginTop: Spacing.base,
  },
  highlighted: {
    backgroundColor: colors.veryLightOrange,
  },
});
