import React from 'react';
import Svg, {Path} from 'react-native-svg';

export function HospitalSvg(props: React.ComponentProps<Svg>) {
  return (
    <Svg width={16} height={16} fill="none" {...props}>
      <Path
        d="M8 0a7.98 7.98 0 00-8 8c0 4.433 3.567 8 8 8s8-3.567 8-8-3.567-8-8-8zm5 8.7c0 .4-.333.7-.7.7H9.433v2.867c0 .4-.333.7-.7.7H7.3c-.4 0-.7-.334-.7-.7V9.4H3.733c-.4 0-.7-.333-.7-.7V7.267c0-.4.334-.7.7-.7H6.6V3.7c0-.4.333-.7.7-.7h1.433c.4 0 .7.333.7.7v2.867H12.3c.4 0 .7.333.7.7V8.7z"
        fill="#D9ABD1"
      />
    </Svg>
  );
}
