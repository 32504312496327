import React from 'react';
import Svg, {Line, Path} from 'react-native-svg';
import {
  fma15_2_6,
  fma38284,
  fma38290,
  fma38293,
  fma42352,
  fma61070,
} from 'utils';
import {getColorFma} from 'utils';

export const Hand_Right_Front_fma321784 = ({
  selectedBodyPart,
  onPressClick,
}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.828 200.301">
      <Path
        id="Path_218"
        dataName="Path 218"
        d="M17.514,200.016l45.754.282a6.517,6.517,0,0,0,6.556-6.3c.309-8.939,1.1-26.441,7.012-31.582,8.414-7.316,19.991-24.63,26.21-29.019s8.414-23.046,15.728-29.63c1.83-1.646,3.979-3.429,6.1-5.275,6.361-5.539,12.466-11.643,8.9-16.307-4.754-6.218-26.7-4.389-42.8,27.435l-2.926-16.1S92.055,46.6,93.724,21.4c.339-5.129-2.989-9.388-7.195-9.876a8.449,8.449,0,0,0-9.144,6.95c-4.3,24.917-7.865,56.881-7.865,56.881l-4.573-.549-1.679-63.9a12.954,12.954,0,0,0-1.785-6.115c-1.28-2.1-3.85-4.948-7.327-4.787C46.292.372,46.53,11.041,46.53,11.041l-.238,63.222-3.658.183-6.9-55.54s-.975-9.3-7.553-9.023c-8.781.366-6.712,16.339-6.712,16.339l2.962,53.162-5.212,1.829-7.082-42.37s-.731-6.4-6.951-6.036S.07,41.037.07,41.037L7.2,134.864l6.219,28.166L11.05,192.983A6.521,6.521,0,0,0,17.039,200C17.2,200.008,17.355,200.015,17.514,200.016Z"
        transform="translate(0.005 0.001)"
        fill="#ead9e7"
        style="mix-blend-mode: multiply;isolation: isolate"
      />

      <Path
        id={fma15_2_6}
        dataName="Path 219"
        d="M90.975,109.624c7.914-15.648,17.24-24.035,25.244-27.787a1.91,1.91,0,0,1,2.425.716L123.333,90a1.9,1.9,0,0,1-.383,2.471c-7.665,6.372-17.1,16.549-25.834,31.937a1.9,1.9,0,0,1-2.416.816,21.989,21.989,0,0,1-11.279-11.091C80.816,108.216,90.975,109.624,90.975,109.624Z"
        transform="translate(0.005 0.001)"
        fill={getColorFma(fma15_2_6, selectedBodyPart)}
        onPressIn={() => onPressClick(fma15_2_6)}
        onClick={() => onPressClick(fma15_2_6)}
      />

      <Path
        id={fma15_2_6}
        dataName="Path 220"
        d="M89.5,76.225a2.442,2.442,0,0,1-2.635,2.231c-.078-.007-.156-.017-.233-.031l-14.852-2.66a2.439,2.439,0,0,1-2-2.68c.691-5.964,2.694-22.765,5.232-39.753a1.081,1.081,0,0,1,1.126-.92l15.646.824a1.075,1.075,0,0,1,1.024,1.123l0,.027C91.784,48.192,90.481,64.3,89.5,76.225Z"
        transform="translate(0.005 0.001)"
        fill={getColorFma(fma15_2_6, selectedBodyPart)}
        onPressIn={() => onPressClick(fma15_2_6)}
        onClick={() => onPressClick(fma15_2_6)}
      />

      <Path
        id={fma15_2_6}
        dataName="Path 221"
        d="M64.887,72.464a2.222,2.222,0,0,1-2.163,2.279c-.042,0-.083,0-.125,0l-14.142-.416A2.221,2.221,0,0,1,46.3,72.1l.188-50.288a1.082,1.082,0,0,1,1.086-1.076h.014l14.913.293a1.081,1.081,0,0,1,1.058,1.051Z"
        transform="translate(0.005 0.001)"
        fill={getColorFma(fma15_2_6, selectedBodyPart)}
        onPressIn={() => onPressClick(fma15_2_6)}
        onClick={() => onPressClick(fma15_2_6)}
      />

      <Path
        id={fma15_2_6}
        dataName="Path 222"
        d="M42.377,72.373A2.416,2.416,0,0,1,40.618,75l-13.4,3.664a2.412,2.412,0,0,1-2.964-1.689,2.453,2.453,0,0,1-.082-.511L21.825,32.768a1.082,1.082,0,0,1,.876-1.119l13.152-2.505A1.08,1.08,0,0,1,37.115,30c0,.022.008.045.011.067Z"
        transform="translate(0.005 0.001)"
        fill={getColorFma(fma15_2_6, selectedBodyPart)}
        onPressIn={() => onPressClick(fma15_2_6)}
        onClick={() => onPressClick(fma15_2_6)}
      />

      <Path
        id={fma15_2_6}
        dataName="Path 223"
        d="M18.865,79.336a2.183,2.183,0,0,1-1.4,2.4A89.337,89.337,0,0,0,6.612,86.654a2.18,2.18,0,0,1-2.949-.894,2.206,2.206,0,0,1-.251-.862L.739,49.745a1.081,1.081,0,0,1,.826-1.132l10.617-2.527a1.078,1.078,0,0,1,1.3.8c.006.024.011.048.015.073Z"
        transform="translate(0.005 0.001)"
        fill={getColorFma(fma15_2_6, selectedBodyPart)}
        onPressIn={() => onPressClick(fma15_2_6)}
        onClick={() => onPressClick(fma15_2_6)}
      />

      <Path
        id={fma61070}
        dataName="Path 231"
        d="M87.108,107.7l3.506-.03L88.049,93.529s.043-3.827.766-12.559c-7.37-1.846-36.488-9.6-63.922,0,0,0-15.792,6.073-20.85,9.053l2.938,41.853,26.144-.731,26.153,1.1L88.714,134.7a2.2,2.2,0,0,0,2.011-.971l3.584-5.373a1.18,1.18,0,0,0-.357-1.63l-.044-.027a32.351,32.351,0,0,1-11.462-10.12C79.241,111.727,82.463,107.552,87.108,107.7Z"
        transform="translate(0.005 0.001)"
        fill={getColorFma(fma61070, selectedBodyPart)}
        onPressIn={() => onPressClick(fma61070)}
        onClick={() => onPressClick(fma61070)}
      />

      <Path
        id={fma38293}
        dataName="Path 232"
        d="M62.675,133.754l23.677,1.882a1.8,1.8,0,0,1,1.278,2.845l-18.7,26.611a1.727,1.727,0,0,1-1.6.732l-8.3-.918a1.786,1.786,0,0,1-1.545-2v-.01l3.326-27.574A1.757,1.757,0,0,1,62.675,133.754Z"
        transform="translate(0.005 0.001)"
        fill={getColorFma(fma38293, selectedBodyPart)}
        onPressIn={() => onPressClick(fma38293)}
        onClick={() => onPressClick(fma38293)}
      />

      <Path
        id={fma42352}
        dataName="Path 233"
        d="M32.951,160.51l1.532-26.259a1.766,1.766,0,0,1,1.814-1.687l20.9.884a1.78,1.78,0,0,1,1.672,1.882c0,.046-.008.091-.014.136L55.564,162.5a1.759,1.759,0,0,1-1.876,1.567l-19.146-1.657A1.78,1.78,0,0,1,32.951,160.51Z"
        transform="translate(0.005 0.001)"
        fill={getColorFma(fma42352, selectedBodyPart)}
        onPressIn={() => onPressClick(fma42352)}
        onClick={() => onPressClick(fma42352)}
      />

      <Path
        id={fma38290}
        dataName="Path 234"
        d="M32.819,134.457l-1.6,26.036a1.769,1.769,0,0,1-1.732,1.683l-14.705.078a1.758,1.758,0,0,1-1.712-1.409l-5.46-25.478a1.786,1.786,0,0,1,1.34-2.14,1.837,1.837,0,0,1,.314-.043l21.769-.636a1.771,1.771,0,0,1,1.79,1.753C32.825,134.353,32.823,134.4,32.819,134.457Z"
        transform="translate(0.005 0.001)"
        fill={getColorFma(fma38290, selectedBodyPart)}
        onPressIn={() => onPressClick(fma38290)}
        onClick={() => onPressClick(fma38290)}
      />

      <Path
        id={fma38284}
        dataName="Path 235"
        d="M55.087,165.8l18.335,2.469a63.966,63.966,0,0,0-3.076,16.1L12,181.034l1.347-17,17.581-.42Z"
        transform="translate(0.005 0.001)"
        fill={getColorFma(fma38284, selectedBodyPart)}
        onPressIn={() => onPressClick(fma38284)}
        onClick={() => onPressClick(fma38284)}
      />

      <Line
        id="Line_40"
        dataName="Line 40"
        x1="8.413"
        y1="0.732"
        transform="translate(72.695 52.682)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Line
        id="Line_41"
        dataName="Line 41"
        x1="10.608"
        y2="0.366"
        transform="translate(50.016 44.635)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Line
        id="Line_42"
        dataName="Line 42"
        x1="5.853"
        y2="1.463"
        transform="translate(8.681 66.948)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_237"
        dataName="Path 237"
        d="M60.984,91.456s-17.923,17.558-40.6,14.266"
        transform="translate(0.005 0.001)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_238"
        dataName="Path 238"
        d="M68.895,101.985c-9.416,2.924-23.339,8.983-33.516,20.929"
        transform="translate(0.005 0.001)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
