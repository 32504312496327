import React, {useEffect} from 'react';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  runOnJS,
  withTiming,
  withDelay,
} from 'react-native-reanimated';
import {connect} from 'react-redux';

const AnimatableBubble = (props) => {
  const duration = props.currentMessage?.duration
    ? props.currentMessage.duration
    : 400;
  const waitTime = props.currentMessage?.shouldWaitTime
    ? props.currentMessage.shouldWaitTime
    : 200;
  const unMountedMsgs = props.unmountedMsgs;
  const refreshList = props.refreshList;

  const animatedX = useSharedValue(
    props.position === 'left' ? -props.windowWidth : props.windowWidth,
  );
  const scaleX = useSharedValue(0.5);

  const animatedStyleLeftMessage = useAnimatedStyle(() => {
    return {
      transform: [{scaleX: scaleX.value}],
    };
  });

  const animatedStyleRightMessage = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: animatedX.value,
        },
      ],
    };
  });

  const findDeletion = () => {
    const filterList = unMountedMsgs.filter(
      (el) => el._id === props.currentMessage._id,
    );
    if (filterList.length > 0) {
      if (props.position === 'left') {
        animatedX.value = withTiming(-props.windowWidth, {
          duration: duration,
        });
      } else {
        animatedX.value = withDelay(
          waitTime,
          withTiming(
            props.windowWidth,
            {
              duration: duration,
            },
            () => {
              runOnJS(refreshList)();
            },
          ),
        );
      }
    }
  };

  useEffect(() => {
    findDeletion();
  }, [props.unmountedMsgs]);

  useEffect(() => {
    if (props.position === 'right') {
      animatedX.value = withTiming(0, {
        duration: duration,
      });
    } else {
      animatedX.value = withDelay(
        waitTime,
        withTiming(
          0,
          {
            duration: duration,
          },
          () => {
            runOnJS(props.mountUnmountChatActionContainer)(true);
          },
        ),
      );
    }
    return () => {};
  }, []);

  return (
    <Animated.View
      style={true ? animatedStyleRightMessage : [animatedStyleLeftMessage]}>
      {props.children}
    </Animated.View>
  );
};

const mapStateToProps = (state) => ({
  windowWidth: state.appConfig.windowWidth,
});

export default connect(mapStateToProps)(AnimatableBubble);
