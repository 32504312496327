export const Colors = {
  primary: '#E9662F',
  mangoWhite: '#e1e1e1',
  primaryLight: '#FEE3E9',
  secondary: '#71D0F2',
  secondaryLight: '#B2E8FC',
  textPrimary: '#323233',
  textSecondary: '#888888',
  textGreyColor: '#a49999',
  borders: '#E1E4E5',
  black: '#000',
  white: '#FFFFFF',
  success: '#00CD85',
  danger: '#FF4444',
  info: '#33B5E5',
  warning: '#FFBB33',
  greenAlt: '#00CD85',
  Yellow: '#FFBB33',
  Green: '#00CD85',
  Red: '#FF4444',
  Amber: '#FFBB33',
  userChatBubblesColor: '#FFE8DE',
  circleStrokeColor: '#F0DEED',
  systemChatBubblesColor: '#F9F6F4',
  chatMessageContainerColor: '#E5E5E5',
  chatMessageContainerOrangeColor: '#FFE8DE',
  textBlackColor: '#231F20',
  textDarkGreyColor: '#101010',
  topPurple: '#C882BB',
  percentagePurple1: '#C964B6',
  percentagePurple2: '#F3A3E5',
  percentagePurple3: '#FFBDF3',
  textPurple: '#763069',
  bubbleTextColor: '#231F20',
  keyboardToolbarBackground: '#F0F1F3',
  doneButtonTextColor: '#007AFF',
  progressRed: '#FB5851',
  progressGreen: '#5DE0A1',
  progressYellow: '#FFDD2B',
  borderPink: '#EAD9E7',
  deleteTextColor: '#F84C45',
  emptyDeleteButton: '#FB857F',
  searchIconColor: '#736969',
  checkIconPurple: '#873E79',
  closeIconbg: '#EEEEEE',
  transparent: 'transparent',
  deleteDarkColor: '#CC3030',
  displayDateColor: '#aba6a3',
  mild: '#3C976B',
  moderate: '#FFECBB',
  worstPossible: '#FFEAE9',
  mildBackground: '#DFF9EC',
  moderateBackGround: '#FFECBB',
  buttonOnHighlight: '#FFEEE8',
  progressBarPurple: '#DF91D1',
  appleColor: '#FF1B12',
  fitBitColor: '#12B0B9',
  editSuccessToastBgColor: '#F1FFF8',
  editSuccessTextColor: '#3C976B',
  editSuccessTickBgColor: '#DFF9EC',
  deleteToastBgColor: '#FFF8F8',
  tickColor: '#FFFCF9',
  bodyPink: '#d9abd1',
  bodyPurple: '#873f7a',
  shimmerBackGroundColor: '#ebebeb',
  updatePasswordButton: '#FF8C64',
  lightGreen: '#003B49',
  lightGreyColor: '#949494',
  darkLightColor: '#00000080',
  secondLightGreyColor: '#ABA6A3',
  toolbarColor: '#F2F2F2',
  lightWhite: 'rgba(255, 255, 255, 0.3)',
  lightPink: '#FFEBD8',
};
