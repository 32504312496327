import React from 'react';
import {Image, ImageStyle, StyleProp, ImageSourcePropType} from 'react-native';

export interface ImageBaseProps {
  source: ImageSourcePropType;
  style?: StyleProp<ImageStyle>;
}

export function ImageBase({source, style}: ImageBaseProps) {
  return <Image source={source} style={style} />;
}
