import React, {PropsWithChildren} from 'react';
import {ErrorBoundary as ErrorBoundaryReact} from 'react-error-boundary';
import {StyleSheet, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Text} from '../../components';

interface IErrorBoundaryProps {
  message?: string;
}

export function ErrorBoundary({
  message = 'Something went wrong.',
  children,
}: PropsWithChildren<IErrorBoundaryProps>) {
  function errorHandler(error: Error, info: {componentStack: string}) {
    console.error(`error: ${error}`);
    console.error(`componentStack: ${info.componentStack}`);
  }

  return (
    <ErrorBoundaryReact
      FallbackComponent={() => (
        <SafeAreaView>
          <View style={styles.error}>
            <Text.Body>{message}</Text.Body>
          </View>
        </SafeAreaView>
      )}
      onError={errorHandler}>
      {children}
    </ErrorBoundaryReact>
  );
}

const styles = StyleSheet.create({
  error: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
