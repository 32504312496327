import {ErrorMessage} from '@hookform/error-message';
import React, {PropsWithChildren} from 'react';
import {useFormContext} from 'react-hook-form';
import {View} from 'react-native';

interface IFormFieldProps {
  name: string;
}

export function FormField({
  name,
  children,
}: PropsWithChildren<IFormFieldProps>) {
  const {
    formState: {errors},
  } = useFormContext();

  return (
    <View>
      {children}
      <ErrorMessage errors={errors} name={name} />
    </View>
  );
}
