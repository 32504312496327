import {Colors} from 'utils';
import {Text, StyleSheet, View} from 'react-native';
import {MARKPRO_BOLD, MARKPRO_BOOK} from 'utils';
import React, {useEffect, useState} from 'react';
import {
  Line,
  renderAssessmentSummaryItem,
} from 'containers/dashBoard/assessment/assesmentResults/AssessmentResults';
import {Pressable} from 'react-native';

const LineView = ({index}) => <Line color={Colors.borderPink} key={index} />;

export default function AssessmentSummary(props) {
  const [isViewAll, setIsViewAll] = useState(false);
  const [cpListToShow, setCpListToShow] = useState([]);
  const [viewList, setViewList] = useState([]);

  useEffect(() => {
    _buildViewList();
  }, []);

  const _calculateList = () => {
    const newValue = !isViewAll;

    if (newValue) {
      setCpListToShow([...viewList]);
    } else {
      if (viewList.length > 3) {
        let list = [...viewList];
        list = list.slice(0, 3);
        setCpListToShow(list);
      } else {
        setCpListToShow([...viewList]);
      }
    }

    setIsViewAll(newValue);
  };

  const _buildViewList = () => {
    let list = [];

    props.clinicalPresentations.forEach((cp, index) => {
      if (cp.manifestation_item_id_list[0] === 'present') {
        const symptomDisplayName = cp.sdco_reference?.display_name;
        const row = renderAssessmentSummaryItem(
          index === 0 ? symptomDisplayName : 'Present',
          index === 0 ? 'Main symptom' : symptomDisplayName,
          false,
        );

        const view = (
          <View>
            {index !== 0 && <LineView index={index} />}
            {row}
          </View>
        );

        list = [...list, view];
      } else {
        const symptomDisplayName = cp.sdco_reference?.display_name;
        const row = renderAssessmentSummaryItem(
          'Absent',
          symptomDisplayName,
          false,
        );

        const view = (
          <View>
            {index !== 0 && <LineView index={index} />}
            {row}
          </View>
        );

        list = [...list, view];
      }
      // eslint-disable-next-line no-shadow
      cp.modification_indices.forEach((mi, index) => {
        if (mi !== -1) {
          const modifierDisplayNameLayman = cp.modifier_display_names[index];
          const modificationDisplayNameListsLayman =
            cp.modification_display_name_lists_layman[index][0];
          const row = renderAssessmentSummaryItem(
            modificationDisplayNameListsLayman,
            modifierDisplayNameLayman,
            false,
          );

          list = [...list, row];
        }
      });
    });

    setViewList(list);
    if (list.length > 3) {
      let listTemp = [...list];
      listTemp = listTemp.slice(0, 3);
      setCpListToShow(listTemp);
    } else {
      setCpListToShow([...list]);
    }
  };

  return (
    <View style={[styles.assessmentSummaryContainer]}>
      <Text style={[styles.titleText]}>{`${props.title}`}</Text>
      {viewList.length > 3 && (
        <Pressable onPress={_calculateList} style={[styles.pressableStyle]}>
          <Text style={[styles.viewAllText]}>
            {cpListToShow.length > 3 ? 'View less' : 'View all'}
          </Text>
        </Pressable>
      )}
      {cpListToShow}
    </View>
  );
}
const styles = StyleSheet.create({
  viewAllText: {fontFamily: MARKPRO_BOOK},
  pressableStyle: {justifyContent: 'center', alignItems: 'flex-end'},
  titleText: {
    fontFamily: MARKPRO_BOLD,
    fontSize: 22,
    paddingBottom: 20,
  },
  assessmentSummaryContainer: {marginHorizontal: 28, paddingVertical: 20},
});
