import React from 'react';
import {Text} from 'libs';
import {ItemBase} from './ItemBase';

interface ISymptomItemProps {
  itemTitle?: string;
  enteredText?: string;
  item: object;
  openSessionAssessmentHandler: (item: object) => void;
}

export function SymptomItem({
  itemTitle,
  enteredText,
  item,
  openSessionAssessmentHandler,
}: ISymptomItemProps) {
  return (
    <ItemBase onPress={() => openSessionAssessmentHandler(item)}>
      <Text.Highlight
        highlightText={[`${enteredText}`]}
        text={itemTitle}
        textStyle="textStyleOne"
        highlightStyle="highlightStyleOne"
      />
    </ItemBase>
  );
}
