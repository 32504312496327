// @ts-nocheck
import React, {useState} from 'react';
import {Picker} from '@react-native-picker/picker';
import {SUBMIT_COUNTRY, countriesList} from 'utils';

import Animated from 'react-native-reanimated';
import usePickerAnimation from './hooks/usePickerAnimation';
import useDisabledState from './hooks/useDisabledState';
import {Button} from 'libs';

const CountryPicker = ({onSendData}) => {
  const [selectedCountry, setCountry] = useState('United States of America');
  const [animatedStyle, animate] = usePickerAnimation();
  const [disabled, donePressHandler] = useDisabledState();

  const submit = () => {
    onSendData({
      value: {
        action: SUBMIT_COUNTRY,
        option: {
          selectedCountry,
          fullCountryInGeoListFormat: countriesList.items.find((t) =>
            t.includes(selectedCountry),
          ),
        },
      },
    });
  };

  const getPickerItem = (value) => {
    return <Picker.Item label={value} value={value} key={value} />;
  };

  const parseCountries = () => {
    const countryNames = [];
    let usa = null;
    let canada = null;
    let germany = null;
    countriesList.items.sort((a, b) => {
      let partsA = a.split('/');
      let countryNameA = partsA[partsA.length - 1].split(':')[0];
      let partsB = b.split('/');
      let countryNameB = partsB[partsB.length - 1].split(':')[0];
      return countryNameA.localeCompare(countryNameB);
    });
    countriesList.items.forEach((item) => {
      // Countries are in the followin format because potato
      // "/Africa:002/Eastern Africa:014/Burundi:BDI"
      let parts = item.split('/');
      let lastPart = parts[parts.length - 1];
      let countryName = lastPart.split(':')[0];
      let countryCode = lastPart.split(':')[1];
      if (countryCode === 'USA') {
        usa = countryName;
      } else if (countryCode === 'DEU') {
        germany = countryName;
      } else if (countryCode === 'CAN') {
        canada = countryName;
      } else {
        countryNames.push(getPickerItem(countryName));
      }
    });
    countryNames.unshift(getPickerItem(germany));
    countryNames.unshift(getPickerItem(canada));
    countryNames.unshift(getPickerItem(usa));
    return countryNames;
  };

  const renderCountryPicker = () => {
    const countries = parseCountries();

    return (
      <Picker
        selectedValue={selectedCountry}
        onValueChange={(itemValue) => {
          setCountry(itemValue);
        }}>
        {countries}
      </Picker>
    );
  };

  return (
    <Animated.View style={animatedStyle}>
      <Button.ChatConfirmAction
        disabled={disabled}
        title={'Done'}
        onPress={() => donePressHandler(animate(300, () => submit(), 200))}
      />
      {renderCountryPicker()}
    </Animated.View>
  );
};

export default CountryPicker;
