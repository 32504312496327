// @ts-nocheck

import {Text} from 'libs';
import {
  AssessmentResult,
  Container,
  ResultDetailsDescription,
  ResultDetailsItem,
} from 'modules';
import React, {useEffect, useState} from 'react';
import {
  calculateDiagnosePercentage,
  colors,
  EventType,
  Spacing,
  useAnalytics,
} from 'utils';

interface IResultDetailsProps {
  title?: string;
  index?: number;
  differentialDiagnosisItems?: Array<{
    diagnosis: {ddo_id: string};
    probability_multipliers_info: Array<{
      multiplier: number;
      description: string;
    }>;
  }>;
  differentialDiagnosis?: {
    diagnosis: {ddo_id: string};
    probability: number;
  };
}

export function ResultDetails({
  title,
  differentialDiagnosisItems,
  index,
  differentialDiagnosis,
}: IResultDetailsProps) {
  const [
    diagnoseProbabilityMultiplierItems,
    setDiagnoseProbabilityMultiplierItems,
  ] = useState(null);
  const {logEvent} = useAnalytics();
  useEffect(() => {
    logEvent(EventType.RESULT_DETAILS_VIEW, {timestamp: Date.now()});
  }, []);

  useEffect(() => {
    const diagnose = differentialDiagnosisItems?.find(
      (item: any) =>
        item?.diagnosis?.ddo_id === differentialDiagnosis?.diagnosis.ddo_id,
    );

    setDiagnoseProbabilityMultiplierItems(
      diagnose.probability_multipliers_info,
    );
  }, []);

  return (
    <>
      <AssessmentResult.Tab.TextTitle title={title} />

      <Container marginTop={Spacing.tiny} marginBottom={Spacing.base13}>
        <Text.Subtitle
          variant="bold"
          size="subtitle3"
          color={index === 0 ? colors.green : colors.black}>
          {calculateDiagnosePercentage(differentialDiagnosis.probability)} Score
        </Text.Subtitle>
      </Container>

      <ResultDetailsDescription />

      <ResultDetailsItem
        diagnoseProbabilityMultiplierItems={diagnoseProbabilityMultiplierItems}
      />
    </>
  );
}
