import {
  APPLE_AUTH,
  customer,
  DOWN_ARROW,
  EMAIL_AUTH,
  ENTER_EMAIl,
  ENTER_FIRST_NAME,
  ENTER_GENDER,
  ENTER_LAST_NAME,
  ENTER_NAME,
  ENTER_NICKNAME,
  ENTER_PASSOWRD,
  ENTER_PRONOUN,
  ENTER_PROVINCE,
  GREETINGS_MESSAGE,
  REGISTER_COMPLETE,
  SKIP,
  SUBMIT_BIRTH_DATE_AND_TIME,
  SUBMIT_CITY,
  SUBMIT_COUNTRY,
  SUBMIT_DATA,
  SUBMIT_PROVINCE,
  systemUser,
} from 'utils';
import {store} from '../../../../redux/stores';
import {_NewMessages} from '../message-arrays/AuthMessageArray';

let Pointer;
let temporarySnap;

export default class MessageStore {
  today;
  nextMessageIndex;
  stepToSkip;
  authMethod;
  store;
  _NewMessages;
  _snapShotForStoring;

  constructor() {
    this.today = new Date();
    this.nextMessageIndex = 0;
    this.stepToSkip = [];
    this.authMethod = EMAIL_AUTH;
    this.store = store.getState();
    this.systemUser = systemUser;
    this.customer = customer;
    this._snapShotForStoring = {};
    this._NewMessages = [..._NewMessages];
  }

  _setAuthMethod = (method) => {
    this.authMethod = method;
  };

  _setStepsToSkip = (steps) => {
    this.stepToSkip.concat(steps);
  };

  getinitialMessages = (_) => {
    return new Promise((resolve, reject) => {
      resolve({
        messages: this._NewMessages[this.nextMessageIndex].messages,
        actions: this._NewMessages[this.nextMessageIndex].actions,
      });
      this.nextMessageIndex += 1;
      Pointer = this.nextMessageIndex;
    });
  };

  setInititalMessageIndex = (index) => {
    this.nextMessageIndex = index;
  };

  addToInitialMessageIndex = (value) => {
    this.nextMessageIndex = Pointer - 1;
    Pointer = this.nextMessageIndex;
  };

  clearStore = () => {
    this.nextMessageIndex = 0;
    this._snapShotForStoring = {};
    this._NewMessages = [..._NewMessages];
  };

  createUserMessage = (data, dependency) => {
    let ob = {};
    if (dependency?.user === 'system') {
      const {key, value} = dependency;
      ob = {
        [key]: value,
      };
    }
    const obj = {
      _id: Math.random(),
      createdAt: this.today,
      user: {
        _id: '1',
        name: '',
        avatar: '',
        type: 'Customer',
      },
      messageType: 'Automated',
      type: data.type,
      data: data.data,
      last_message: data.last_message,
      step: null,
      sub_step: null,
      text: data.text,
      ...ob,
    };
    return obj;
  };

  _createAndStoreData = (key, data) => {
    let objectToBeMergerged = {
      [key]: data,
    };
    this._snapShotForStoring = {
      ...this._snapShotForStoring,
      ...objectToBeMergerged,
    };
    temporarySnap = this._snapShotForStoring;
  };

  _getNextMesssageIndex = () => {
    return Pointer;
  };

  swapTemporarySnap = () => {
    this._snapShotForStoring = temporarySnap;
  };

  getValueFortheKey = (key) => this._snapShotForStoring[key];

  actionMapper = (
    messageObj,
    {userId = null, token = null, platformUrl = null, username = null},
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {action, data} = messageObj;
        switch (action) {
          case ENTER_FIRST_NAME:
            this._createAndStoreData('first_name', data);
            break;
          case ENTER_LAST_NAME:
            this._createAndStoreData('last_name', data);
            break;
          case ENTER_EMAIl:
            this._createAndStoreData('email', data);
            break;
          case ENTER_PASSOWRD:
            this._createAndStoreData('password', data);
            this._createAndStoreData('username', data);
            break;
          case ENTER_NAME:
            this._createAndStoreData('displayName', data);
            break;
          case ENTER_GENDER:
            this._createAndStoreData('sex_at_birth', data);
            break;
          case ENTER_PRONOUN:
            this._createAndStoreData('pronouns', data);
            break;
          case SUBMIT_COUNTRY:
            this._createAndStoreData('country', data);
            break;
          case SUBMIT_PROVINCE:
            this._createAndStoreData('state', data);
            break;
          case SUBMIT_BIRTH_DATE_AND_TIME:
            this._createAndStoreData('date_of_birth', data);
            break;
          case ENTER_NICKNAME:
            this._createAndStoreData('nickname', data);
            break;
          case SKIP:
            this._createAndStoreData('nickname', '');
            break;
          case SUBMIT_DATA:
            return;
          default:
        }

        resolve('resolved');
      } catch (e) {
        reject(e);
      }
    });
  };

  setMessageIndex = (val) => {
    this.nextMessageIndex += val;
    Pointer = this.nextMessageIndex;
  };

  _sendMessages = (msgs) => {};

  _getMessagesForTheAction(next, message, action, dependency) {
    let messages = [];
    let createdMessage;
    let actions = [];

    if (action === SUBMIT_CITY) {
      createdMessage = this.createUserMessage(message, dependency);
      messages.push(createdMessage);
    } else if (action === ENTER_PASSOWRD) {
    } else if (action === REGISTER_COMPLETE) {
      messages = [...this._NewMessages[this.nextMessageIndex].messages];
      actions = this._NewMessages[this.nextMessageIndex].actions
        ? this._NewMessages[this.nextMessageIndex].actions
        : [];
      this.setMessageIndex(1);
    } else if (action === GREETINGS_MESSAGE) {
      messages = [...this._NewMessages[this.nextMessageIndex].messages];
      if (
        dependency?.hasDependency &&
        (!dependency?.user || dependency?.user === 'user')
      ) {
        let {messageIndex, key, value} = dependency;
        messages[messageIndex][key] = value;
      }
      actions = this._NewMessages[this.nextMessageIndex].actions
        ? this._NewMessages[this.nextMessageIndex].actions
        : [];
    } else if (action === ENTER_LAST_NAME && this.authMethod === APPLE_AUTH) {
      createdMessage = this.createUserMessage(message, dependency);
      messages.push(createdMessage);
    } else if (action === SUBMIT_COUNTRY) {
      createdMessage = this.createUserMessage(message, dependency);
      messages = [...this._NewMessages[this.nextMessageIndex].messages];
      messages.push(createdMessage);
      if (
        dependency?.hasDependency &&
        (!dependency?.user || dependency?.user === 'user')
      ) {
        let {messageIndex, key, value} = dependency;
        messages[messageIndex][key] = value;
      }

      const stateProvince =
        message.data === 'United States' ? 'Select state' : 'Select province';

      actions = [
        {
          createdAt: this.today,
          tag: ENTER_PROVINCE,
          inputQuickReplies: {
            placeHolder: stateProvince,
            editable: false,
            type: 'radio',
            values: [
              {
                _id: 1,
                action: ENTER_PROVINCE,
                iconName: DOWN_ARROW,
                hasReactToEnable: false,
              },
            ],
          },
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'Province',
                value: {
                  action: ENTER_PROVINCE,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...customer},
          _id: '98f530bd-cae9-4581-b163-ec00a257ac071province',
        },
      ];

      this.setMessageIndex(1);
    } else {
      createdMessage = this.createUserMessage(message, dependency);
      messages = [...this._NewMessages[this.nextMessageIndex].messages];
      messages.push(createdMessage);
      if (
        dependency?.hasDependency &&
        (!dependency?.user || dependency?.user === 'user')
      ) {
        let {messageIndex, key, value} = dependency;
        messages[messageIndex][key] = value;
      }
      actions = this._NewMessages[this.nextMessageIndex].actions
        ? this._NewMessages[this.nextMessageIndex].actions
        : [];

      this.setMessageIndex(1);
    }

    const mergeMessages = [...messages];
    const objTobePassed = {
      messages: mergeMessages,
      actions: actions,
      completed: false,
    };
    return objTobePassed;
  }

  mergeValueWithArray = (
    callBack,
    limit,
    data,
    dependency,
    {userId = null, token = null, platformUrl = null, username = null},
  ) => {
    let objTobePassed = this._getMessagesForTheAction(
      limit,
      data,
      data.action,
      dependency,
    );

    if (data.action === SUBMIT_CITY) {
      callBack({...objTobePassed, clearQR: true});
      objTobePassed = {};
    }

    this.actionMapper(data, {userId, token, platformUrl, username}).then(
      (res) => {
        callBack({...objTobePassed, ...res});
      },
    );
  };
}
