// @ts-nocheck

import moment from 'moment';
import React from 'react';
import {
  Dimensions,
  Image,
  Platform,
  Pressable,
  StatusBar,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import {
  Colors,
  heightToDp,
  MARKPRO_BOLD,
  MARKPRO_BOOK,
  MARKPRO_MEDIUM,
  viewStyles,
  widthToDp,
} from 'utils';
import {CheckedBoxGrey, CheckedBoxOrange} from '../../../assets/images';
import RadioButton from './RadioButton';
import {DropDown} from './SVGS';

const WIDTH = Dimensions.get('screen').width;

export const RadioWithDescription = (props) => (
  <View style={[styles.radioWithDescriptionContainer]}>
    <RadioButton select={props.onSelect} selected={props.selected} />
    <Text
      style={[
        styles.radioWithDescriptionText,
        {
          color: props.selected
            ? Colors.textBlackColor
            : Colors.searchIconColor,
        },
      ]}>
      {props.title}
    </Text>
  </View>
);
export const AndroidStatusBar = ({bgColor, barStyle}) => {
  return Platform.OS === 'android' ? (
    <StatusBar backgroundColor={bgColor} barStyle={barStyle} />
  ) : null;
};

export const ListItemInput = ({
  title,
  onChangeText,
  value,
  customStyles,
  enable = true,
}) => (
  <View style={[styles.listItemInput, {...customStyles}]}>
    <Text style={styles.listItemViewTitle}>{title}</Text>
    <TextInput
      editable={enable}
      value={value}
      style={[styles.listItemTextInput]}
      onChangeText={(text) => onChangeText(text)}
      autoCorrect={false}
      importantForAutofill="no"
      spellCheck={false}
    />
  </View>
);

export const ListItemRadioContainer = ({
  topSelected,
  bottomSelected,
  title1,
  title2,
  onSelectTop,
  onSelectBottom,
}) => (
  <View>
    <RadioWithDescription
      selected={topSelected}
      title={title1}
      onSelect={onSelectTop}
    />
    <RadioWithDescription
      selected={bottomSelected}
      title={title2}
      onSelect={onSelectBottom}
    />
  </View>
);

export const SectionTitleText = ({text}) => (
  <View style={[styles.sectionViewTitle, viewStyles.marginBottom5]}>
    <Text style={styles.sectionViewTitleText}>{text}</Text>
  </View>
);

export const DropdownButton = () => (
  <View style={styles.dropDownButton}>
    <DropDown />
  </View>
);

export const ListItemWithDropDown = ({title, value, onPress}) => (
  <TouchableOpacity
    style={styles.listItemWithDropDown}
    onPress={() => {
      onPress(true);
    }}>
    <View style={viewStyles.flex1}>
      <Text style={styles.listItemViewTitle}>{title}</Text>
      <Text style={[styles.listItemWithDropDownText]}>{value}</Text>
    </View>
    <DropdownButton />
  </TouchableOpacity>
);

export const Dots = ({count, color, opacityDegrader, size}) => {
  return (
    <View style={[styles.dotsView]}>
      {[...Array(count).keys()].map((el) => {
        const style = {
          height: size,
          width: size,
          opacity: 1 / (el + 1),
          marginHorizontal: 1,
          borderRadius: size / 2,
          backgroundColor: color,
        };
        return <View style={style} />;
      })}
    </View>
  );
};

export const Spacer = ({height = 20, color = Colors.primary}) => (
  <View style={{height: height, backgroundColor: color}} />
);

export const CustomCheckBox = ({selected, onPress}) => {
  return (
    <Pressable onPress={onPress} style={[styles.customCheckBoxPressable]}>
      <Image
        source={selected ? CheckedBoxOrange : CheckedBoxGrey}
        style={[styles.customCheckBoxImage]}
      />
    </Pressable>
  );
};

export const MarginSpacer = ({
  marginTop = 0,
  marginBottom = 0,
  marginRight = 0,
  marginLeft = 0,
}) => (
  <View
    style={{
      marginTop: marginTop,
      marginBottom: marginBottom,
      marginRight: marginRight,
      marginLeft: marginLeft,
    }}
  />
);

export const SlidingView = React.memo(
  ({
    width,
    onLeftPress,
    onRightPress,
    leftTitle,
    rightTitle,
    selectedIndex,
    onValue,
    hour,
  }) => {
    const translateX = useSharedValue(selectedIndex === 0 ? 0 : width / 2);
    const translateXStyles = useAnimatedStyle(() => {
      return {
        transform: [{translateX: translateX.value}],
      };
    });

    const updateAmPm = () => {
      if (translateX.value === 0) {
        onValue(0, hour);
      } else {
        onValue(1, hour);
      }
    };

    const onPressSlider = () => {
      const toValue = translateX.value === 0 ? width / 2 : 0;
      translateX.value = withSpring(toValue, null, () => runOnJS(updateAmPm)());
    };

    const fontStyle = {
      color: selectedIndex === 1 ? Colors.white : Colors.searchIconColor,
      fontFamily: selectedIndex === 1 ? MARKPRO_BOLD : MARKPRO_BOOK,
      fontWeight: selectedIndex === 1 ? 'bold' : 'normal',
    };

    return (
      <View style={[styles.slidingContainer]}>
        <View style={[styles.slidingAnimatedMainView, {width: width}]}>
          <Animated.View
            style={[
              styles.slidingViewSlider,
              translateXStyles,
              {width: width / 2},
            ]}
          />
          <Pressable
            onPress={() => {
              onPressSlider();
            }}
            style={[styles.leftTitlePressable, {width: width / 2}]}>
            <Text style={[styles.leftTitlePressableText, fontStyle]}>
              {leftTitle}
            </Text>
          </Pressable>
          <Pressable
            onPress={() => {
              onPressSlider();
            }}
            style={[styles.rightTitlePressable, {width: width / 2}]}>
            <Text style={[styles.rightTitlePressableText, fontStyle]}>
              {rightTitle}
            </Text>
          </Pressable>
        </View>
      </View>
    );
  },
);

export const BackDrop = ({backDropStyles, offSet, onPress}) => (
  <Animated.View
    onTouchStart={onPress}
    style={[styles.backDropStyles, {top: offSet}, backDropStyles]}
  />
);

export const ExtractHeightWrapper = ({setHeight, style, children}) => (
  <View
    style={[style]}
    onLayout={(e) => {
      const {layout} = e.nativeEvent;
      const {height} = layout;
      setHeight(height);
    }}>
    {children}
  </View>
);

export function DateShadowContainer({customStyles}: any) {
  const d = new Date();
  return (
    <View style={[styles.dateTimeShadowContainer, customStyles]}>
      <Text style={[styles.dateShadowContainerText]}>
        {moment(d).format('MMMM D, YYYY')}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  dateShadowContainerText: {
    color: Colors.black,
    fontFamily: MARKPRO_BOLD,
  },
  rightTitlePressableText: {
    textAlign: 'center',
    fontSize: 16,
  },
  rightTitlePressable: {
    alignContent: 'center',
    justifyContent: 'center',
    height: 40,
  },
  leftTitlePressableText: {
    textAlign: 'center',
    fontSize: 16,
  },
  leftTitlePressable: {
    alignContent: 'center',
    justifyContent: 'center',
    height: 40,
  },
  slidingAnimatedMainView: {
    flexDirection: 'row',
    borderRadius: 5,
    paddingVertical: 5,
    height: 40,
    alignItems: 'center',
    paddingHorizontal: 0,
  },
  slidingContainer: {
    backgroundColor: Colors.systemChatBubblesColor,
    padding: 6,
    borderRadius: 12,
  },
  animatedTextTitle: {
    fontFamily: MARKPRO_BOOK,
    color: Colors.white,
    fontSize: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  onPressSliderStyle: {
    height: 35,
    backgroundColor: Colors.transparent,
    alignItems: 'center',
    justifyContent: 'center',
  },
  customCheckBoxImage: {width: 32, height: 32},
  customCheckBoxPressable: {width: 32, height: 32},
  dotsView: {flexDirection: 'row', marginHorizontal: 10},
  listItemWithDropDownText: {
    fontSize: 17,
    fontFamily: MARKPRO_BOOK,
    paddingTop: 3,
  },
  listItemTextInput: {
    fontSize: 17,
    color: Colors.bubbleTextColor,
    fontFamily: MARKPRO_BOOK,
    paddingTop: 3,
  },
  radioWithDescriptionText: {
    fontSize: 16,
    marginLeft: 8,
  },
  radioWithDescriptionContainer: {
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'center',
  },
  dateTimeShadowContainer: {
    height: 30,
    shadowColor: Colors.black,
    alignItems: 'center',
    padding: 5,
    shadowOffset: {
      height: 3,
      width: 0,
    },
    shadowOpacity: 0.2,
    backgroundColor: Colors.white,
    elevation: 10,
  },
  slidingViewSlider: {
    position: 'absolute',
    backgroundColor: Colors.primary,
    borderRadius: 10,
    top: 0,
    bottom: 0,
  },
  backDropStyles: {
    width: WIDTH,
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'rgba(0 , 0 , 0 , 0.3)',
  },
  listItemInput: {
    backgroundColor: Colors.systemChatBubblesColor,
    justifyContent: 'center',
    borderRadius: 18,
    paddingLeft: 15,
    paddingVertical: 13,
    marginTop: 20,
  },
  listItemWithDropDown: {
    backgroundColor: Colors.systemChatBubblesColor,
    justifyContent: 'center',
    borderRadius: 18,
    paddingLeft: 15,
    paddingRight: 10,
    paddingVertical: 13,
    marginTop: 20,
    flexDirection: 'row',
    zIndex: 100,
  },
  dropDownButton: {
    width: 40,
    height: 40,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: Colors.primary,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItemViewTitle: {
    fontSize: 15,
    color: Colors.searchIconColor,
    fontFamily: MARKPRO_MEDIUM,
  },
  container: {
    marginTop: heightToDp(12),
    flex: 1,
  },
  deleteButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 18,
    padding: widthToDp(4),
    borderColor: Colors.primary,
    borderWidth: 1,
    marginVertical: heightToDp(6),
  },
  header: {
    position: 'absolute',
    justifyContent: 'flex-end',
    height: heightToDp(12),
    top: 0,
    right: 0,
    left: 0,
    backgroundColor: Colors.topPurple,
    zIndex: 10,
  },
  titleBar: {
    flexDirection: 'row',
    width: '100%',
    position: 'absolute',
    justifyContent: 'center',
    height: 65,
    alignItems: 'center',
  },
  titleBarText: {
    color: Colors.black,
    fontSize: 18,
    fontFamily: MARKPRO_MEDIUM,
    lineHeight: 19,
    paddingRight: 5,
  },
  backView: {
    width: 60,
    height: 60,
    position: 'absolute',
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  infoIcon: {
    position: 'absolute',
    justifyContent: 'center',
    height: 60,
    right: 15,
    alignItems: 'center',
    zIndex: 10,
  },
  generalInfoText: {
    fontSize: 28,
    fontFamily: MARKPRO_BOLD,
    color: Colors.black,
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    top: 15,
  },
  sectionViewTitle: {
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  sectionViewTitleText: {
    fontFamily: MARKPRO_MEDIUM,
    fontSize: 18,
    color: Colors.bubbleTextColor,
  },
  borderView: {
    backgroundColor: Colors.white,
    height: 50,
    justifyContent: 'center',
  },
  borderViewSub: {height: 1, backgroundColor: Colors.borderPink},
  checkBox: {
    height: 20,
    width: 20,
    backgroundColor: Colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
