import Svg, {Circle} from 'react-native-svg';
import React from 'react';

export const RowMoreOptionIcon = () => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <Circle cx="2" cy="14" r="2" fill="#736969" />
    <Circle cx="8" cy="14" r="2" fill="#736969" />
    <Circle cx="14" cy="14" r="2" fill="#736969" />
  </Svg>
);
