import Svg, {Line, Path} from 'react-native-svg';
import React from 'react';

export default function DeleteIcon() {
  return (
    <Svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M3 7.5L3.95564 12.8516C4.12597 13.8054 4.95555 14.5 5.92449 14.5H10.0755C11.0445 14.5 11.874 13.8054 12.0444 12.8516L13 7.5"
        stroke="#F84C45"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.36277 3.4117C6.54425 2.86724 7.05377 2.5 7.62768 2.5H8.37232C8.94623 2.5 9.45575 2.86724 9.63723 3.4117L10 4.5H6L6.36277 3.4117Z"
        stroke="#F84C45"
        strokeWidth="2"
      />
      <Line
        x1="2"
        y1="4.5"
        x2="14"
        y2="4.5"
        stroke="#F84C45"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
