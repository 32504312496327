import React from 'react';
import {Text} from 'libs';
import {colors} from 'utils';

export function ResultDetailsDescription() {
  return (
    <Text.Body variant="normal" size="body1" color={colors.black}>
      Based on the information you provided, the following details{' '}
      <Text.Body variant="normal" size="body1" color={colors.green}>
        increased
      </Text.Body>{' '}
      or{' '}
      <Text.Body variant="normal" size="body1" color={colors.errorDark}>
        decreased{' '}
      </Text.Body>
      the likelihood of this result.
    </Text.Body>
  );
}
