// @ts-nocheck
import {useNativeDriver} from 'config';
import React from 'react';
import {StyleSheet} from 'react-native';
import {
  ADD_PROFILE_SEX,
  ASSESSMENT_QUESTION_ANSWER_STAGE,
  Colors,
  DRINKING,
  ENTER_BIRTHDATE,
  ENTER_BIRTH_MONTH,
  ENTER_CITY,
  ENTER_COUNTRY,
  ENTER_EMAIl,
  ENTER_FIRST_NAME,
  ENTER_GENDER,
  ENTER_HEIGHT,
  ENTER_LAST_NAME,
  ENTER_NICKNAME,
  ENTER_PASSOWRD,
  ENTER_PRONOUN,
  ENTER_PROVINCE,
  ENTER_RELATIONSHIP,
  ENTER_WEIGHT,
  FAMILY_HISTORY_COMPLETED,
  FINAL_ONBOARDING_SCREEN,
  FINAL_ONBOARDING_SCREEN_ADD_PROFILE,
  FINAL_ONBOARDING_SCREEN_LIFESTYLE,
  FINISH,
  HUMAN_ANATOMY,
  INITIAL_STAGE,
  IS_TRAVELLED,
  LEARN_MORE,
  LINK_ACCOUNTS,
  SECOND_PASS,
  SELECT_DISEASE_START_DATE,
  SELECT_RECOVER_DATE,
  SKIP_DRINK,
  SKIP_HEIGHT,
  SKIP_SMOKE,
  SKIP_WEIGHT,
  SMOKING,
  SUBMIT_HEIGHT,
  SUBMIT_WEIGHT,
} from 'utils';
import AnimatableBubble from '../../reusables/animated-reusables/AnimatableChatBubble';
import Bubble from '../lib/Bubble';
import DefaultChatBubble from './DefaultChatBubble';
import HumanAnatomyChatSvg from './HumanAnatomyChatSvg';

export default function BubbleSelector(props) {
  const {step, sub_step} = props.currentMessage;
  let tag;

  switch (step) {
    case HUMAN_ANATOMY:
      return (
        <AnimatableBubble {...props}>
          <Bubble
            {...props}
            renderCustomView={() => {
              return <HumanAnatomyChatSvg tag={HUMAN_ANATOMY} {...props} />;
            }}
            useNativeDriver={useNativeDriver}
            wrapperStyle={{
              left: {
                backgroundColor: Colors.systemChatBubblesColor,
                borderTopLeftRadius: 26,
                borderTopRightRadius: 26,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 26,
                overflow: 'hidden',
              },
              right: {
                backgroundColor: Colors.chatMessageContainerColor,
                borderTopLeftRadius: 26,
                borderTopRightRadius: 26,
                borderBottomLeftRadius: 26,
                borderBottomRightRadius: 8,
                overflow: 'hidden',
              },
            }}
          />
        </AnimatableBubble>
      );
    case ENTER_GENDER:
      return (
        <AnimatableBubble {...props}>
          <Bubble
            {...props}
            renderCustomView={() => {
              return <DefaultChatBubble tag={ENTER_GENDER} {...props} />;
            }}
            useNativeDriver={useNativeDriver}
            wrapperStyle={{
              left: {
                backgroundColor: Colors.systemChatBubblesColor,
                borderTopLeftRadius: 26,
                borderTopRightRadius: 26,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 26,
                overflow: 'hidden',
              },
              right: {
                backgroundColor: Colors.chatMessageContainerColor,
                borderTopLeftRadius: 26,
                borderTopRightRadius: 26,
                borderBottomLeftRadius: 26,
                borderBottomRightRadius: 8,
                overflow: 'hidden',
              },
            }}
          />
        </AnimatableBubble>
      );

    case ENTER_LAST_NAME:
    case ENTER_FIRST_NAME:
    case ENTER_EMAIl:
    case ENTER_PASSOWRD:
    case ENTER_NICKNAME:
    case ENTER_BIRTHDATE:
    case ENTER_BIRTH_MONTH:
    case ENTER_COUNTRY:
    case ENTER_PROVINCE:
    case ENTER_CITY:
    case ENTER_PRONOUN:
    case SELECT_DISEASE_START_DATE:
    case SELECT_RECOVER_DATE:
    case FINAL_ONBOARDING_SCREEN:
    case FINAL_ONBOARDING_SCREEN_ADD_PROFILE:
    case FINAL_ONBOARDING_SCREEN_LIFESTYLE:
    case ENTER_RELATIONSHIP:
    case ADD_PROFILE_SEX:
    case FINISH:
    case LEARN_MORE:
    case ENTER_HEIGHT:
    case ENTER_WEIGHT:
    case SUBMIT_HEIGHT:
    case SUBMIT_WEIGHT:
    case SMOKING:
    case DRINKING:
    case IS_TRAVELLED:
    case SKIP_WEIGHT:
    case SKIP_HEIGHT:
    case SKIP_SMOKE:
    case SKIP_DRINK:
    case LINK_ACCOUNTS:
    case FAMILY_HISTORY_COMPLETED:
    case INITIAL_STAGE:
    case ASSESSMENT_QUESTION_ANSWER_STAGE:
    case SECOND_PASS:
      tag =
        step === INITIAL_STAGE || step === ASSESSMENT_QUESTION_ANSWER_STAGE
          ? sub_step
          : step;

      let BubbleL = (
        <Bubble
          {...props}
          renderCustomView={() => {
            return <DefaultChatBubble tag={tag} {...props} />;
          }}
          useNativeDriver={useNativeDriver}
          wrapperStyle={{
            left: {
              borderTopLeftRadius: 26,
              borderTopRightRadius: 26,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 26,
              marginRight: 20,
            },
            right: {
              borderTopLeftRadius: 26,
              borderTopRightRadius: 26,
              borderBottomLeftRadius: 26,
              borderBottomRightRadius: 8,
            },
          }}
        />
      );

      return props.currentMessage.shouldAnimate ? (
        <AnimatableBubble {...props}>{BubbleL}</AnimatableBubble>
      ) : (
        <>{BubbleL}</>
      );
    default:
      return (
        <AnimatableBubble {...props}>
          <Bubble
            {...props}
            textStyle={{
              right: styles.textPrimary,
              left: styles.textPrimary,
            }}
            wrapperStyle={{
              left: {
                borderTopLeftRadius: 26,
                borderTopRightRadius: 26,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 26,
                overflow: 'hidden',
                marginRight: 5,
              },
              right: {
                borderTopLeftRadius: 26,
                borderTopRightRadius: 26,
                borderBottomLeftRadius: 26,
                borderBottomRightRadius: 8,
                backgroundColor: Colors.chatMessageContainerOrangeColor,
                overflow: 'hidden',
              },
            }}
          />
        </AnimatableBubble>
      );
  }
}

const styles = StyleSheet.create({
  textPrimary: {
    color: 'yellow',
    fontSize: 16,
    lineHeight: 20,
  },
});
