import {storage} from 'libs';

export enum UserPrivacy {
  PrivacyKey = 'UserCheckedPrivacy',
  Checked = 'Checked',
  Unchecked = 'Unchecked',
}

class UserPrivacyStorage {
  getStatus() {
    return storage.getItem(UserPrivacy.PrivacyKey);
  }

  setChecked() {
    storage.setItem(UserPrivacy.PrivacyKey, UserPrivacy.Checked);
  }

  setUnchecked() {
    storage.setItem(UserPrivacy.PrivacyKey, UserPrivacy.Unchecked);
  }
}

export const userPrivacyStorage = new UserPrivacyStorage();
