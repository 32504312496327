import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {fma297441, fma297447, fma305670, fma44623, fma73060} from 'utils';
import {getColorFma} from 'utils';

export const Knee_Left_Back_fma24883 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.79 146.018">
      <Path
        id="Path_513"
        dataName="Path 513"
        d="M1.648,141a1.471,1.471,0,0,1-.581-1.587c4.206-16.261,5.6-47.441,4.641-56.779-.975-9.511.976-31.7,1.22-42.92C7.119,30.936,2.228,11.549.1,3.538A2.816,2.816,0,0,1,2.847,0L77,.668A2.813,2.813,0,0,1,79.79,3.5a2.974,2.974,0,0,1-.037.432C76.489,24.044,74.886,65.224,73.5,76.3,72.039,88,64.236,98.244,64.723,107.267c.424,7.834,2.686,27.845,3.274,32.242a1.474,1.474,0,0,1-.824,1.533c-3.511,1.692-16.769,5.993-37.81,4.755C10.175,144.669,3.714,142.456,1.648,141Z"
        transform="translate(-0.001 0)"
        fill="#ead9e7"
      />

      <Path
        id={fma73060}
        dataName="Path 514"
        d="M30.222,35.564l21.414-.1a1.427,1.427,0,0,1,1.434,1.42q0,.048,0,.1l-2.7,43.344-2.1,37.116a1.428,1.428,0,0,1-1.433,1.347l-20.849-.11a1.426,1.426,0,0,1-1.419-1.433c0-.022,0-.045,0-.067l4.239-80.255A1.427,1.427,0,0,1,30.222,35.564Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma73060, selectedBodyPart)}
        onPressIn={() => onPressClick(fma73060)}
        onClick={() => onPressClick(fma73060)}
      />

      <Path
        id={fma297447}
        dataName="Path 515"
        d="M21.383,118.659,4.5,118.579C5.9,103.8,6.3,88.428,5.7,82.636a22.885,22.885,0,0,1-.244-3.17l17.839.406a1.427,1.427,0,0,1,1.4,1.458l0,.042-1.88,35.934A1.429,1.429,0,0,1,21.383,118.659Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma297447, selectedBodyPart)}
        onPressIn={() => onPressClick(fma297447)}
        onClick={() => onPressClick(fma297447)}
      />

      <Path
        id={fma305670}
        dataName="Path 516"
        d="M6.928,39.716a37.891,37.891,0,0,0-.2-4.247l18.658-.083a1.426,1.426,0,0,1,1.434,1.419c0,.027,0,.054,0,.081L24.833,76.739A1.426,1.426,0,0,1,23.375,78.1l-17.92-.407C5.275,66.829,6.72,49.285,6.928,39.716Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma305670, selectedBodyPart)}
        onPressIn={() => onPressClick(fma305670)}
        onClick={() => onPressClick(fma305670)}
      />

      <Path
        id={fma44623}
        dataName="Path 517"
        d="M56.094,35.5l20.428-.346C75.233,52.341,74.329,68.08,73.5,74.691a36.617,36.617,0,0,1-.751,4.023l-19,.245a1.426,1.426,0,0,1-1.445-1.408c0-.034,0-.069,0-.1l2.384-40.6A1.428,1.428,0,0,1,56.094,35.5Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma44623, selectedBodyPart)}
        onPressIn={() => onPressClick(fma44623)}
        onClick={() => onPressClick(fma44623)}
      />

      <Path
        id={fma297441}
        dataName="Path 518"
        d="M72.751,80.32c-2.422,10.046-8.459,18.973-8.028,26.947.144,2.654.478,6.915.877,11.6l-13.988.092a1.426,1.426,0,0,1-1.437-1.416c0-.03,0-.061,0-.091l2.018-35.618a1.427,1.427,0,0,1,1.412-1.347Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma297441, selectedBodyPart)}
        onPressIn={() => onPressClick(fma297441)}
        onClick={() => onPressClick(fma297441)}
      />

      <Path
        id="Path_556"
        dataName="Path 556"
        d="M57.9,80.686s-22.312-4.756-42.8,0"
        transform="translate(-0.001 0)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
