import {analytics, logEvent} from 'config';
import {ScreenNames} from 'modules';
import {EventType} from './AnalyticsEventTypeEnum';

// TODO implement
class AnalyticsService {
  static async logEvent(event: EventType, payload?: any): Promise<void> {
    payload ? logEvent(analytics, event, payload) : logEvent(analytics, event);
  }

  static async logScreenView(
    screenName: ScreenNames,
    screenClass: string,
  ): Promise<void> {
    logEvent(analytics, 'screen_view', {
      firebase_screen: screenName,
      firebase_screen_class: screenClass,
    });
  }
}

export const useAnalytics = () => {
  return {
    logEvent: AnalyticsService.logEvent,
    logScreenView: AnalyticsService.logScreenView,
  };
};
