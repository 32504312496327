import React from 'react';
import {StyleSheet} from 'react-native';
import {useTheme} from 'utils';
import {ButtonBase} from './ButtonBase';
import {IButtonPropsBase} from './IButtonPropsBase';
import {Colors, Typography, MARKPRO_MEDIUM} from 'utils';

interface IButtonViewAllProps extends IButtonPropsBase {}

export function ButtonViewAll({
  title,
  onPress,
  disabled,
  type,
}: IButtonViewAllProps) {
  const theme = useTheme();

  return (
    <ButtonBase
      title={title}
      onPress={onPress}
      titleStyle={styles.buttonTitleText}
      buttonStyle={styles.buttonStyle}
      disabled={disabled}
      type={type}
    />
  );
}

const styles = StyleSheet.create({
  buttonStyle: {
    height: 35,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.lightWhite,
    borderRadius: 20,
    marginTop: 15,
    marginBottom: 25,
    paddingHorizontal: 10,
  },
  buttonTitleText: {
    color: Colors.white,
    fontFamily: MARKPRO_MEDIUM,
    fontSize: Typography.body4,
  },
});
