import React from 'react';
import {View, Image, StyleSheet} from 'react-native';
import {caraCalm, Colors, Typography, MARKPRO_MEDIUM} from 'utils';
import {Text} from 'libs';
import {Row} from 'modules';
import {Spacing} from 'utils';

export function AssessmentTextBox() {
  return (
    <Row
      style={styles.assessmentTextBoxContainer}
      marginHorizontal={Spacing.medium}
      marginTop={Spacing.medium35}
      marginBottom={Spacing.small25}
      backgroundColor={Colors.borderPink}
      height={Spacing.large85}>
      <Image source={caraCalm} style={styles.caraCalmImageStyle} />

      <View style={styles.rightTextContainer}>
        <Text.Body
          variant="medium"
          color={Colors.textPurple}
          size="body4"
          style={styles.textBoxStyle}>
          Based on your responses, here are some of the conditions that you
          should consider. Click below to learn more.
        </Text.Body>
      </View>
    </Row>
  );
}

const styles = StyleSheet.create({
  assessmentTextBoxContainer: {
    borderRadius: 25,
    borderBottomLeftRadius: 10,
    overflow: 'hidden',
  },
  rightTextContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  caraCalmImageStyle: {
    width: 90,
    height: 90,
    resizeMode: 'contain',
    top: 15,
    right: 10,
  },
  textBoxStyle: {
    paddingRight: 15,
  },
});
