import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import Animated from 'react-native-reanimated';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  DOWN_ARROW,
  DOWN_ARROW_NOT_ANIMATED,
  EYE,
  MARKPRO_BOLD,
  SKIP_ICON,
} from 'utils';
import {Colors} from '../themes/Colors';

export function ActionToIconMapper(props) {
  const _onSend = (text, shouldInsert = true) => {
    if (props.element.hasReactToEnable) {
      if (!text) {
        return;
      }

      if (props.enable) {
        props.insertAnsweredQuestion(props.tag);
        props.onSend(
          {
            text: text.trim(),
            action: props.element.action,
          },
          true,
        );
        props.clearText();
      }
      return;
    }

    if (shouldInsert) {
      props.insertAnsweredQuestion(props.tag);
    }

    props.clearText();
    props.onSend(
      {
        text: text.trim(),
        action: props.element.action,
      },
      true,
    );
  };

  switch (props.element.iconName) {
    case SKIP_ICON:
      return (
        <TouchableOpacity onPress={() => _onSend('SKIP')}>
          <View style={styles.skipIcon}>
            <Text style={{color: Colors.primary, fontFamily: MARKPRO_BOLD}}>
              Skip
            </Text>
          </View>
        </TouchableOpacity>
      );

    case DOWN_ARROW:
      return (
        <Animated.View style={{transform: [{rotate: props.spin}]}}>
          <TouchableOpacity
            style={[styles.sendIconWrapper, styles.downIcon]}
            onPress={() => {
              props.animate(1);
              _onSend('', false);
            }}>
            <Icon name={'arrow-down'} color={Colors.primary} size={15} />
          </TouchableOpacity>
        </Animated.View>
      );
    case DOWN_ARROW_NOT_ANIMATED:
      return (
        <TouchableOpacity
          style={[styles.sendIconWrapper, styles.downIcon]}
          onPress={() => {
            props.animate(1);
            _onSend('', false);
          }}>
          <Icon name={'arrow-down'} color={Colors.primary} size={15} />
        </TouchableOpacity>
      );

    case EYE:
      return (
        <TouchableOpacity
          style={[styles.sendIconWrapper, styles.downIcon]}
          onPress={props.toggleSecureText}>
          <Icon
            name={props.isSecured ? 'eye-outline' : 'eye-off-outline'}
            color={Colors.primary}
            size={15}
          />
        </TouchableOpacity>
      );
    default:
      return (
        <TouchableOpacity
          style={styles.sendIconWrapper}
          onPress={() => _onSend(props.enterText)}>
          <Icon name={'arrow-right'} color={Colors.white} size={15} />
        </TouchableOpacity>
      );
  }
}

const styles = StyleSheet.create({
  sendIconWrapper: {
    backgroundColor: Colors.primary,
    height: 40,
    width: 40,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    shadowColor: 'lightgrey',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.35,
    shadowRadius: 4.65,
    elevation: 5,
  },
  skipIcon: {
    backgroundColor: Colors.white,
    height: 40,
    width: 60,
    borderWidth: 1,
    borderColor: Colors.primary,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  downIcon: {
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.primary,
  },
});
