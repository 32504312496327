// Conversion Process - Probability: This logic here is based from the: Cara Assessment Results Presentation document

// scale variable: is scaling factor which Cara should implement as a configuration variable (value for now will be 0.85)
// t variable: is a configuration variable which is needed to display the text: 'less than ...' (value for now will be 0.02, this is from the document).
const scale: number = 0.85,
  t: number = 0.02;

export const calculateDiagnosePercentage = (probability: number) => {
  // This calculation here is required from the document: p' = scale * probability
  const calculateProbability = scale * probability;

  // Multiplying the calculateProbability by 100 is to get the percentage, and also the Math.round it will round the number to the nearest integer. This requirement is from the document.
  const calculatePercentage = Math.round(calculateProbability * 100);

  // 85% is the max percentage that can be returned. This requirement is from the document.
  return calculateProbability < t
    ? `less than ${Math.round(t * 100)}%`
    : `${calculatePercentage > 85 ? 85 : calculatePercentage}%`;
};
