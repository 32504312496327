import React, {PropsWithChildren} from 'react';
import {
  GestureResponderEvent,
  ImageStyle,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

export interface ITextBaseProps {
  style?: ViewStyle | TextStyle | ImageStyle | Array<ViewStyle | TextStyle>;
  onPress?: (event: GestureResponderEvent) => void;
  numberOfLines?: number;
  ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip';
}

export function TextBase({
  children,
  style,
  onPress,
  numberOfLines,
  ellipsizeMode,
}: PropsWithChildren<ITextBaseProps>) {
  return (
    <Text
      style={style}
      onPress={onPress}
      numberOfLines={numberOfLines}
      ellipsizeMode={ellipsizeMode}>
      {children}
    </Text>
  );
}
