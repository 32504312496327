import {StyleSheet} from 'react-native';
import {Colors} from 'utils';
import {MARKPRO_BOLD, MARKPRO_BOOK} from 'utils';
import {Platform} from 'react-native';
const chatStyles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    backgroundColor: Colors.systemChatBubblesColor,
    paddingVertical: 15,
    paddingHorizontal: 15,
    borderColor: 'transparent',
    borderWidth: 1,
    borderRadius: 26,
    borderBottomLeftRadius: 8,
    alignItems: 'flex-start',
    minHeight: 70,
  },
  iconImage: {
    width: 50,
    borderBottomLeftRadius: 8,
  },
  iconPosition: {
    position: 'absolute',
    left: -1,
    bottom: 10,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  text: {
    fontSize: 16,
    maxWidth: Platform.OS === 'web' ? '45rem' : undefined,
    fontFamily: MARKPRO_BOOK,
    color: Colors.bubbleTextColor,
    lineHeight: 24,
  },
  headerText: {
    left: 0,
    fontSize: 16,
    lineHeight: 24,
    fontFamily: MARKPRO_BOLD,
    color: Colors.bubbleTextColor,
  },
  userName: {
    color: 'lightgrey',
    fontSize: 10,
    marginBottom: 8,
  },
});

export default chatStyles;
