import {Platform} from 'react-native';
import Config from 'react-native-config';

export const shouldSkipEmailValidationForQATesting = true;
export const socialLoginEnabled = Config.IS_SOCIAL_LOGIN_ENABLED === 'true';
export const isAnonymousFlow = Config.IS_ANONYMOUS_FLOW === 'true';
export const withHumanBodySvgScreen = Config.WITH_HUMAN_BODY_SVG === 'true';
export const bootStrapUrl = Config.BOOTSTRAP_URL;
export const apiBaseUrl = Config.API_BASE_URL;
export const userId = Config.USER_ID;
export const app = Config.APP;
export const isWeb = Platform.OS === 'web';
export const apiKey = Config.API_KEY;
export const authDomain = Config.AUTH_DOMAIN;
export const projectId = Config.PROJECT_ID;
export const storageBucket = Config.STORAGE_BUCKET;
export const messagingSenderId = Config.MESSAGING_SENDER_ID;
export const appId = Config.APP_ID;
export const measurementId = Config.MEASUREMENT_ID;
export const useNativeDriver = !isWeb;
