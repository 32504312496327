import Svg, {Circle, Rect} from 'react-native-svg';
import React from 'react';

export const CircleUnChecked = () => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <Circle cx="12" cy="12" r="12" fill="#EEEEEE" />
    <Rect
      x="15.5356"
      y="7.05078"
      width="2"
      height="12"
      rx="1"
      transform="rotate(45 15.5356 7.05078)"
      fill="#736969"
    />
    <Rect
      x="16.9497"
      y="15.5352"
      width="2"
      height="12"
      rx="1"
      transform="rotate(135 16.9497 15.5352)"
      fill="#736969"
    />
  </Svg>
);
