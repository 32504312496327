import React from 'react';
import {Spacing} from 'utils';
import {Column, AssessmentResult} from 'modules';

type DDOConditionLibrary = {
  description: string;
  source_links: string;
  risk_factors_list?: Array<string>;
  symptoms_list?: Array<string>;
  treatment_list?: Array<string>;
  prevention_list?: Array<string>;
};
export interface ITabResultsProps {
  title?: string;
  ddoConditionLibrary?: DDOConditionLibrary;
  index?: number;
  differentialDiagnosisItems?: Array<{
    diagnosis: {ddo_id: string};
    probability_multipliers_info: Array<{
      multiplier: number;
      description: string;
    }>;
  }>;
  differentialDiagnosis?: {
    diagnosis: {ddo_id: string};
    probability: number;
  };
}

enum TabItems {
  ResultDetails = 'Result Details',
  Description = 'Description',
  Risks = 'Risks',
  Symptoms = 'Symptoms',
  Treatment = 'Treatment',
  Prevention = 'Prevention',
}

export function TabResults({
  title,
  ddoConditionLibrary,
  differentialDiagnosisItems,
  index,
  differentialDiagnosis,
}: ITabResultsProps) {
  const getViews = () => {
    if (!ddoConditionLibrary) return null;
    switch (title) {
      case TabItems.ResultDetails:
        return (
          <AssessmentResult.Tab.ResultDetails
            title={title}
            index={index}
            differentialDiagnosisItems={differentialDiagnosisItems}
            differentialDiagnosis={differentialDiagnosis}
          />
        );
      case TabItems.Description:
        return (
          <AssessmentResult.Tab.Description
            title={title}
            description={ddoConditionLibrary?.description}
            source_links={ddoConditionLibrary?.source_links}
          />
        );
      case TabItems.Risks:
        return (
          <AssessmentResult.Tab.Risks
            title={title}
            riskFactorsList={ddoConditionLibrary?.risk_factors_list}
          />
        );
      case TabItems.Symptoms:
        return (
          <AssessmentResult.Tab.Symptoms
            title={title}
            symptomsList={ddoConditionLibrary?.symptoms_list}
          />
        );
      case TabItems.Treatment:
        return (
          <AssessmentResult.Tab.Treatment
            title={title}
            treatmentList={ddoConditionLibrary?.treatment_list}
          />
        );
      case TabItems.Prevention:
        return (
          <AssessmentResult.Tab.Prevention
            title={title}
            preventionList={ddoConditionLibrary?.prevention_list}
          />
        );
      default:
        return null;
    }
  };

  return <Column marginHorizontal={Spacing.small}>{getViews()}</Column>;
}
