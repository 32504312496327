import {Text} from 'libs';
import {AssessmentResult, Column, Layout, Row} from 'modules';
import React from 'react';
import {colors, Spacing} from 'utils';

type DiagnoseItem = {
  multiplier: number;
  description: string;
};

interface IResultDetailsItemProps {
  diagnoseProbabilityMultiplierItems?: Array<DiagnoseItem>;
}

export function ResultDetailsItem({
  diagnoseProbabilityMultiplierItems,
}: IResultDetailsItemProps) {
  if (!diagnoseProbabilityMultiplierItems) return null;

  return diagnoseProbabilityMultiplierItems?.map(
    ({description, multiplier}: DiagnoseItem, index: number) => (
      <Column
        key={index}
        borderBottomWidth={Spacing.tiny1}
        borderColor={colors.veryLightOrange}
        paddingVertical={Spacing.small}>
        <Row alignItems="center" marginBottom={Spacing.tiny}>
          <AssessmentResult.Tab.ResultDetailsSigns
            diagnoseMultiplier={multiplier}
          />
          <Layout>
            <Text.Body variant="bold" size="body1">
              {index === 0
                ? 'Patient Info'
                : description.substring(0, description.indexOf(':')).trim()}
            </Text.Body>
          </Layout>
        </Row>

        <Text.Caption size="caption1" variant="normal" color={colors.black}>
          {description
            .substring(description.indexOf(':') + 1)
            .replace(',', ' |')
            .replace(';', ' |')
            .trim()}
        </Text.Caption>
      </Column>
    ),
  );
}
