import React from 'react';
import {useTheme} from 'utils';
import {ButtonBase} from './ButtonBase';
import {IButtonPropsBase} from './IButtonPropsBase';

interface IButtonChatConfirmActionProps extends IButtonPropsBase {}

export function ButtonChatConfirmAction({
  title,
  onPress,
  disabled,
}: IButtonChatConfirmActionProps) {
  const theme = useTheme();

  return (
    <ButtonBase
      title={title}
      onPress={onPress}
      containerStyle={{
        alignItems: 'center',
        flexDirection: 'row-reverse',
        width: '100%',
        backgroundColor: theme.colors?.grey0,
        borderRadius: 0,
      }}
      titleStyle={{color: theme.colors?.secondary, fontSize: 16}}
      disabledStyle={{backgroundColor: theme.colors?.disabled}}
      disabledTitleStyle={{color: theme.colors?.white}}
      buttonStyle={{
        backgroundColor: 'transparent',

        borderColor: theme.colors?.secondary,
      }}
      disabled={disabled}
      type={'clear'}
    />
  );
}
