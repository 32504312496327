export enum ScreenNames {
  Welcome = 'Welcome',
  AssessmentChat = 'AssessmentChat',
  ResultsTab = 'ResultsTab',
  AssessmentResults = 'AssessmentResults',
  AssessmentResultLoading = 'AssessmentResultLoading',
  AssessmentResultItem = 'AssessmentResultItem',
  AssessmentResultPossibleCauses = 'AssessmentResultPossibleCauses',
  StaticTextScreen = 'StaticTextScreen',
  DataAndMethodology = 'DataAndMethodology',
  DiseaseCitations = 'DiseaseCitations',
  Privacy = 'Privacy',
  HumanBodySvg = 'HumanBodySvg',
  Settings = 'Settings',
  PossibleConditions = 'PossibleConditions',
}
