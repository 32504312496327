import {REFRESH_ASSESSMENT_HISTORY, SET_DID_YOU_KNOWS} from '../action-types';

const initialState = {
  refreshAssessmentHistory: false,
  didYouKnows: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REFRESH_ASSESSMENT_HISTORY:
      return {...state, refreshAssessmentHistory: action.payload};
    case SET_DID_YOU_KNOWS:
      return {...state, didYouKnows: action.payload};
    default:
      return state;
  }
}
