const maxNumSymptomQuestions = 'max_num_symptom_questions';
const maxNumModifierQuestions = 'max_num_modifier_questions';
const minScoreSymptomQuestions = 'min_score_symptom_questions';
const minScoreModifierQuestions = 'min_score_modifier_questions';
const platform_id = 'platform_id';

export const setPlatformId = (value) => {
  return localStorage.setItem(platform_id, value);
};

export const getPlatformId = () => {
  return localStorage.getItem(platform_id);
};

export const setMaxNumSymptomQuestion = (value) => {
  return localStorage.setItem(maxNumSymptomQuestions, value);
};

export const setMaxNumModifierQuestions = (value) => {
  return localStorage.setItem(maxNumModifierQuestions, value);
};

export const setMinScoreSymptomQuestions = (value) => {
  return localStorage.setItem(minScoreSymptomQuestions, value);
};

export const setMinScoreModifierQuestions = (value) => {
  return localStorage.setItem(minScoreModifierQuestions, value);
};

export const getMaxNumSymptomQuestion = () => {
  return localStorage.getItem(maxNumSymptomQuestions);
};

export const getMaxNumModifierQuestions = () => {
  return localStorage.getItem(maxNumModifierQuestions);
};

export const getMinScoreSymptomQuestions = () => {
  return localStorage.getItem(minScoreSymptomQuestions);
};

export const getMinScoreModifierQuestions = () => {
  return localStorage.getItem(minScoreModifierQuestions);
};
