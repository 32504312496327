import React from 'react';
import {View, StyleSheet} from 'react-native';
import {Colors} from 'utils';

export function SymptomItemSeparator() {
  return <View style={styles.itemSeparatorStyle} />;
}

const styles = StyleSheet.create({
  itemSeparatorStyle: {
    height: 1,
    backgroundColor: Colors.borderPink,
    marginVertical: 15,
  },
});
