import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Colors} from 'utils';
import {Text} from 'libs';
interface PossibleConditionsTitleProps {
  symptomType?: string;
}

export function PossibleConditionsTitle({
  symptomType,
}: PossibleConditionsTitleProps) {
  return (
    <View style={styles.possibleTitleContainer}>
      <Text.Body variant="normal" color={Colors.textBlackColor} size="body2">
        Based on the information you provided, here are the conditions I
        considered when assessing the possible causes of your{' '}
        {symptomType?.toLowerCase()}.
      </Text.Body>
    </View>
  );
}

const styles = StyleSheet.create({
  possibleTitleContainer: {
    alignSelf: 'center',
    paddingHorizontal: 15,
    marginVertical: 30,
  },
});
