import {useState} from 'react';

export default function useDisabledState() {
  const [disabled, setDisabled] = useState(false);

  function donePressHandler(callback?: () => void) {
    setDisabled(true);
    if (callback) callback();
  }

  return [disabled, donePressHandler];
}
