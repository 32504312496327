import {setBootstrapUrls} from 'app/redux/actions/Application';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {getBootstrapUrls} from 'utils';

export function Bootstrap() {
  const dispatch = useDispatch();

  useEffect(() => {
    getBootstrapUrls().then((res) => {
      dispatch(setBootstrapUrls(res.data));
    });
  }, []);

  return <></>;
}
