import LifeStyleMessages from '../../app/components/chat/message-store/models/LifeStyleMessages';

export const singletonLifeStyleMessages = (function () {
  let instance = null;
  return {
    getInstance: () => {
      if (instance == null) {
        instance = new LifeStyleMessages();
        instance.constructor = null;
      }

      return instance;
    },
  };
})();
