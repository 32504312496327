import React from 'react';

import {useTheme, checkboxOrangeChecked, Colors} from 'utils';
import {ICheckboxPropsBase} from './ICheckboxPropsBase';
import {CheckboxBase} from './CheckboxBase';
import {widthToDp} from 'utils';
import {StyleSheet, Image, View} from 'react-native';

interface ICheckboxPrimaryProps extends ICheckboxPropsBase {}

export function CheckboxPrimary({
  title,
  onPressed,
  isChecked,
}: ICheckboxPrimaryProps) {
  const theme = useTheme();

  return (
    <CheckboxBase
      title={title}
      isChecked={isChecked}
      checkedIcon={
        <Image source={checkboxOrangeChecked} style={styles.image} />
      }
      checkedColor={theme.colors?.primary}
      uncheckedIcon={<View style={styles.checkboxEmpty} />}
      size={42}
      onPressed={onPressed}
    />
  );
}

const styles = StyleSheet.create({
  image: {width: 32, height: 32, resizeMode: 'contain'},
  checkboxEmpty: {
    justifyContent: 'center',
    backgroundColor: 'white',
    width: widthToDp(8.5),
    height: widthToDp(8.5),
    borderColor: Colors.searchIconColor,
    borderWidth: 2,
    borderRadius: 8,
  },
});
