import React from 'react';
import {Image, Text, View, StyleSheet} from 'react-native';
import {Colors} from 'utils';
import {MARKPRO_MEDIUM} from 'utils';

export default function CaraExpressIndicatorBubble({imageStyles, image, text}) {
  return (
    <View style={styles.container}>
      <Image source={image} resizeMode={'contain'} style={imageStyles} />
      <View style={[styles.caraExpressIndicatorBubbleTextView]}>
        <Text style={[styles.caraExpressIndicatorBubbleText]}>{text}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  caraExpressIndicatorBubbleText: {
    fontFamily: MARKPRO_MEDIUM,
    fontSize: 14,
    lineHeight: 17,
    color: Colors.textPurple,
    right: 10,
  },
  caraExpressIndicatorBubbleTextView: {
    alignItems: 'center',
    marginLeft: 90,
  },
  container: {
    flexDirection: 'row',
    height: 66,
    backgroundColor: Colors.borderPink,
    marginVertical: 30,
    borderBottomRightRadius: 26,
    borderBottomLeftRadius: 8,
    borderTopRightRadius: 26,
    borderTopLeftRadius: 26,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
});
