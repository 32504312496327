import React from 'react';
import Svg, {Path, G} from 'react-native-svg';
import {fma54344, fma60698, fma60868} from 'utils';
import {getColorFma} from 'utils';

export const Big_Toe_Right_fma37854 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1242 2208">
      <Path
        id="Path_630"
        dataName="Path 630"
        d="M856.8,1673.4c36.7,0,66-39.5,62.3-76c-19.6-196.7,63.6-351.3,61.1-548.5c-2.7-217.5-41.4-514.2-286.1-514.2  c-256.5,0-422,120.3-422,526.1c0,113.9-14.8,313.6,37.2,563.3c6,28.9,31.7,49.4,61.2,49.4H856.8z"
        fill="#ead9e7"
      />
      <Path
        id="Path_633"
        dataName="Path 633"
        d="M838.4,635.4v273.1c0,70.2-57.1,127.3-127.3,127.3H552.3c-70.2,0-127.3-57.1-127.3-127.3V643.8"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id={fma54344}
        dataName="Path 634"
        d="M567,1007.9c-68.5,0-124.3-43.1-124.3-112.8V632.4c0-9.3,6-17.7,14.7-20.3c59.7-18.1,108.6-27.3,172.4-27.3  c86,0,140.1,16.9,173.3,26.9c8.6,2.6,14.7,10.9,14.7,20.2v263.1c0,69.7-62.1,112.8-109.7,112.8H567z"
        fill={getColorFma(fma54344, selectedBodyPart)}
        onPressIn={() => onPressClick(fma54344)}
        onClick={() => onPressClick(fma54344)}
      />
      <Path
        id={fma60698}
        dataName="Path 635"
        d="M806,1127.1c63.4,13.2,70.3,13.3,101.3,21.3c26.2,6.8,47.2,13.4,60.4,17.8c-1.4,13-3.7,32.7-6.9,56.7  c-7.5,56.1-12.1,76-16.6,107c-17,115.8-27,136.5-27.7,191.9c-0.3,25.2-20.8,45.4-46,45.5l-529.6,1.1c-21.7,0-40.5-15.1-45.2-36.4  c-5.9-26.9-22.2-47.8-24.2-156.8c-0.2-12.9-0.7-27.4-0.5-67c0.2-44.2,0.8-83.1,1.5-115.4c5.1-1.7,12.5-4.1,21.3-7.1  c17.7-6,23-8.1,24.9-8.9c11.2-4.5,18.6-8.4,23.1-10.7c17.4-8.7,32.8-13.4,58.7-21.3c16.8-5.1,19.2-5.2,57.1-15  c60-15.5,61.8-17.5,82.4-20.7c25.7-4.1,45.5-5.1,61.6-5.9c17.1-0.9,37.9-1.8,65.2,0c6.7,0.5,22.9,1.7,55.7,7.1  C751.6,1115.1,762.3,1118,806,1127.1z"
        fill={getColorFma(fma60698, selectedBodyPart)}
        onPressIn={() => onPressClick(fma60698)}
        onClick={() => onPressClick(fma60698)}
      />

      <G>
        <Path
          id={fma60868}
          dataName="Path 636"
          d="M527.2,1090.3c25.1-4.4,45-6.3,54.5-7.1c25.1-2.1,59-3.4,99.6,0c39.8,2.1,74.9,5.7,104.3,9.5   c40.1,5.2,94.7,14.1,126.8,22.5c13,3.4,26.6,4.7,39.1,9.5c11.1,4.2,19.9,9.1,25.3,5.2c4.9-3.5,4.4-11.9,4.3-14.7   c-1.5-29.6,3.8-59.5,0-88.9c-6.5-49.6,1.1-40.3-5.9-112.6c-2.7-27.9-6.1-52.6-9.5-73.5c-9.6-60-26.3-135.1-64-195.6   c-18.8-30.2-28.2-33.3-33.2-32c-5.7,1.5-8.3,9.6-9.6,14.3c-12.2,44-3.6,121.1-1,154c3.1,39.9,1.6,68.6,0,98.4   c-2.5,46.9-4,70.8-15.4,93.6c-24.1,48.2-68.9,67.4-76,70.2c-15.7,6.4-34,7.8-69.8,10.3c-56.4,3.9-90,1.2-145.8-3.6   c-25.8-2.2-44.5-4.6-64-16.6c-11-6.8-20.1-15.1-32-26.1c-12.5-11.6-21.1-19.6-28.4-32c0,0-8.4-14.1-12.3-33.2   c-6-29.2-8.6-150.9-4.9-315.9c-0.8-0.6-2-1.3-3.7-1.6c-26.2-5.5-77.5,104.7-103.6,199c-22.1,80.2-26.8,151.6-32,231.1   c-2.4,36.3-3.2,66.5-3.6,87.7c-0.6,5-0.6,11.9,3.6,15.4c5.2,4.4,14.2,1.3,29.6-4.7c32.1-12.6,41.5-18.3,58.1-23.7   c7.9-2.6,5.9-1.4,36.7-8.3c22.4-5,26.1-6.3,59.3-14.2C492.1,1097.6,511.3,1093,527.2,1090.3z"
          fill={getColorFma(fma60868, selectedBodyPart)}
          onPressIn={() => onPressClick(fma60868)}
          onClick={() => onPressClick(fma60868)}
        />
        <Path
          id={fma60868}
          dataName="Path 637"
          d="M854.8,981c20.7,13,68.6,46.2,112.1,98.9c4.6,5.5,13.5,2.6,13.8-4.6c0.5-10.3,0.8-21,0.7-30.1   c-1.8-144.5-16-317-99.5-421.2c-7.5-9.4-22.8-3.9-22.8,8.2l-0.3,190c0,0,3.5,86.5-10.8,140.9C846.1,970,848.7,977.3,854.8,981z"
          fill={getColorFma(fma60868, selectedBodyPart)}
          onPressIn={() => onPressClick(fma60868)}
          onClick={() => onPressClick(fma60868)}
        />
      </G>
    </Svg>
  );
};
