// @ts-nocheck

import React from 'react';
import Svg, {Path} from 'react-native-svg';

export function HomeSvg() {
  return (
    <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M15.653 6.776L8.65.25a.971.971 0 00-1.301 0L4.014 3.38v-.866c0-.266-.233-.5-.5-.5h-1c-.267 0-.5.234-.5.5v2.73L.345 6.777c-.7.633-.267 1.798.667 1.798h1v6.394c0 .566.434 1.032 1 1.032h2.002c.567 0 1-.466 1-1.032v-3.963c0-.566.434-1.032 1-1.032h2.001c.567 0 1 .466 1 1.032v3.963c0 .566.468 1.032 1.001 1.032h2.001c.567 0 1-.466 1-1.032V8.574h1.001c.9-.033 1.334-1.198.634-1.798z"
        fill="#D9ABD1"
      />
    </Svg>
  );
}
