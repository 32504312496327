import {
  ASSESSMENT_TOKEN,
  INITIALIZE_CLIN_BOT,
  INITIALIZE_CLIN_BOT_FAILED,
  LOGIN_PLATFORM,
  LOGIN_PLATFORM_FAILED,
  PLATFORM_TOKEN,
  PLATFORM_URL,
  SET_BOOTSTRAP_URLS,
  SET_INITIAL_TAB_SCREEN,
  SET_IS_USER_STARTING_AN_ASSESSMENT,
  SYSTEM_INITIALIZED_FAILED,
  SYSTEM_INITIALIZED_SUCCESS,
} from '../action-types';

const initialState = {
  platformUrl: '',
  platformToken: null,
  assessmentToken: null,
  isLoginPlatform: false,
  isLoginPlatformFailed: false,
  isInitializeClinBOT: false,
  isInitializeClinBOTFailed: false,
  isuserCreatingAccount: false,
  initialTabScreen: 'Home',
  isUserStartingAnAssessment: false,
  bootstrapUrls: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_PLATFORM:
      return {...state, isLoginPlatform: action.payload};
    case LOGIN_PLATFORM_FAILED:
      return {...state, isLoginPlatformFailed: action.payload};
    case INITIALIZE_CLIN_BOT:
      return {...state, isInitializeClinBOT: action.payload};
    case INITIALIZE_CLIN_BOT_FAILED:
      return {...state, isInitializeClinBOTFailed: action.payload};
    case PLATFORM_URL:
      return {...state, platformUrl: action.payload};
    case PLATFORM_TOKEN:
      return {...state, platformToken: action.payload};
    case ASSESSMENT_TOKEN:
      return {...state, assessmentToken: action.payload};
    case SET_INITIAL_TAB_SCREEN:
      return {...state, initialTabScreen: action.payload};
    case SET_IS_USER_STARTING_AN_ASSESSMENT:
      return {...state, isUserStartingAnAssessment: action.payload};
    case SYSTEM_INITIALIZED_SUCCESS:
      return {
        ...state,
        isInitializeClinBOT: false,
        isLoginPlatform: true,
        platformUrl: action.payload.platformUrl,
        platformToken: action.payload.platformToken,
        isLoginPlatformFailed: false,
      };
    case SYSTEM_INITIALIZED_FAILED:
      return {...state};
    case SET_BOOTSTRAP_URLS:
      return {
        ...state,
        bootstrapUrls: action.payload[0],
      };
    default:
      return state;
  }
}
