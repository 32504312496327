import {
  DRINKING,
  EMAIL_AUTH,
  ENTER_HEIGHT,
  ENTER_WEIGHT,
  FINAL_ONBOARDING_SCREEN_LIFESTYLE,
  IS_TRAVELLED,
  LINK_ACCOUNTS,
  SKIP_DRINK,
  SKIP_HEIGHT,
  SKIP_SMOKE,
  SKIP_WEIGHT,
  SMOKING,
  SUBMIT_HEIGHT,
  SUBMIT_WEIGHT,
} from 'utils';
import {
  CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
  CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
  CARA_WOW,
} from '../../../../assets/images/cara/CaraFaces';

export default class LifeStyleMessages {
  today = new Date();

  nextMessageIndex = 0;

  stepToSkip = [];

  authMethod = EMAIL_AUTH;

  systemUser = {
    avatar:
      'https://i.pinimg.com/originals/f0/48/9c/f0489ceb101a4bb4f8fd6f6f2c9e2762.jpg',
    name: null,
    type: 'Advisor',
    _id: '1234r',
  };

  customer = {
    avatar:
      'https://agent-profile-pics.s3.ap-south-1.amazonaws.com/default-customer.png',
    name: '',
    type: 'Customer',
    _id: '1',
  };

  _NewMessages = [
    {
      messages: [
        {
          _id: '4bbea3d1-e372-4a62-9d71-0090f784a8b7',
          createdAt: this.today,
          user: {...this.systemUser},
          messageType: 'Automated',
          shouldAnimate: true,
          type: 'text',
          last_message: 'How tall are you?',
          step: ENTER_HEIGHT,
          sub_step: null,
          icon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
          data: 'How tall are you?',
        },
      ],
      actions: [
        {
          createdAt: this.today,
          tag: ENTER_HEIGHT,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'Enter Height',
                key: 1,
                value: {
                  action: ENTER_HEIGHT,
                  option: '113131',
                },
              },
              {
                title: 'Skip',
                key: 1,
                value: {
                  action: SKIP_HEIGHT,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b163-ec00a997ac07',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e851-4a62-9d71-0090f608a8b71',
          createdAt: this.today,
          user: {...this.systemUser},
          messageType: 'Automated',
          shouldAnimate: true,
          type: 'text',
          data: 'How much do you weigh?',
          last_message: 'How much do you weigh?',
          step: ENTER_WEIGHT,
          sub_step: null,
          icon: CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
        },
      ],
      actions: [
        {
          createdAt: this.today,
          tag: ENTER_WEIGHT,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'Enter Weight',
                key: 1,
                value: {
                  action: ENTER_WEIGHT,
                  option: '113131',
                },
              },
              {
                title: 'Skip',
                key: 1,
                value: {
                  action: SKIP_WEIGHT,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b783-ec00a223ac071',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e548-4a99-9d71-0090f611a8b70',
          createdAt: this.today,
          shouldAnimate: true,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          data: 'Do you smoke cigarettes? If yes, approximately how often? ',
          last_message:
            'Do you smoke cigarettes? If yes, approximately how often?',
          step: SMOKING,
          icon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
          sub_step: null,
        },
      ],
      actions: [
        {
          createdAt: this.today,
          tag: SMOKING,
          inputQuickReplies: {},
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: '1/day',
                key: 1,
                value: {
                  action: SMOKING,
                  option: '113131',
                },
              },
              {
                title: 'Multiple/day',
                key: 1,
                value: {
                  action: SMOKING,
                  option: '113131',
                },
              },
              {
                title: 'Avg.1-3/week',
                key: 2,
                value: {
                  action: SMOKING,
                  option: '113131',
                },
              },
              {
                title: 'A few time/mo',
                key: 2,
                value: {
                  action: SMOKING,
                  option: '113131',
                },
              },
              {
                title: "i don't smoke",
                key: 3,
                value: {
                  action: SMOKING,
                  option: '113131',
                },
              },
              {
                title: 'Skip',
                key: 3,
                value: {
                  action: SKIP_SMOKE,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4512-b163-ec00a277ac070',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e585-4a99-9d71-0090f611a8b70',
          createdAt: this.today,
          shouldAnimate: true,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          data: 'Do you drink alcohol? If yes, approximately how often? ',
          last_message:
            'Do you drink alcohol? If yes, approximately how often?',
          step: DRINKING,
          icon: CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
          sub_step: null,
        },
      ],
      actions: [
        {
          createdAt: this.today,
          tag: DRINKING,
          inputQuickReplies: {},
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: '1/day',
                key: 1,
                value: {
                  action: DRINKING,
                  option: '113131',
                },
              },
              {
                title: 'Multiple/day',
                key: 1,
                value: {
                  action: DRINKING,
                  option: '113131',
                },
              },
              {
                title: 'Avg.1-3/week',
                key: 2,
                value: {
                  action: DRINKING,
                  option: '113131',
                },
              },
              {
                title: 'A few time/mo',
                key: 2,
                value: {
                  action: DRINKING,
                  option: '113131',
                },
              },
              {
                title: "i don't drink",
                key: 3,
                value: {
                  action: DRINKING,
                  option: '113131',
                },
              },
              {
                title: 'Skip',
                key: 3,
                value: {
                  action: SKIP_DRINK,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4512-b163-ec00a277ac070',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e236-4a99-9d71-0090f611a8b70',
          createdAt: this.today,
          shouldAnimate: true,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          data: 'Have you travelled in the past 90 days? ',
          last_message: 'Have you travelled in the past 90 days?',
          step: IS_TRAVELLED,
          icon: CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
          sub_step: null,
        },
      ],
      actions: [
        {
          createdAt: this.today,
          tag: IS_TRAVELLED,
          inputQuickReplies: {},
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'Yes',
                key: 1,
                value: {
                  action: IS_TRAVELLED,
                  option: '113131',
                },
              },
              {
                title: 'No',
                key: 1,
                value: {
                  action: IS_TRAVELLED,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4512-b163-ec00a277ac070',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e111-4a62-9d34-0090f664a8b77rr',
          createdAt: this.today,
          shouldAnimate: true,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          icon: CARA_WOW,
          data: 'Linking your Apple Health and Fitbit accounts helps improve the accuracy of your assessments and recommendations.',
          last_message:
            'Linking your Apple Health and Fitbit accounts helps improve the accuracy of your assessments and recommendations.',
          step: FINAL_ONBOARDING_SCREEN_LIFESTYLE,
          sub_step: null,
        },
      ],
      actions: [
        {
          createdAt: this.today,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'Finish',
                key: 1,
                value: {
                  action: FINAL_ONBOARDING_SCREEN_LIFESTYLE,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4345-b163-ec00a257ac045rr',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e856-4a62-9d34-0090f664a8b77rr',
          createdAt: this.today,
          shouldAnimate: true,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          icon: CARA_WOW,
          data: 'Linking your Apple Health and Fitbit accounts helps improve the accuracy of your assessments and recommendations.',
          last_message:
            'Linking your Apple Health and Fitbit accounts helps improve the accuracy of your assessments and recommendations.',
          step: FINAL_ONBOARDING_SCREEN_LIFESTYLE,
          sub_step: null,
        },
      ],
      actions: [
        {
          createdAt: this.today,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'Skip for now',
                key: 1,
                value: {
                  action: FINAL_ONBOARDING_SCREEN_LIFESTYLE,
                  option: '113131',
                },
              },
              {
                title: 'Link accounts',
                key: 1,
                value: {
                  action: LINK_ACCOUNTS,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4345-b163-ec00a257ac045rr',
        },
      ],
    },
  ];

  _finalQuickReplies = [
    {
      title: 'Skip for now',
      type: 'radio',
      key: 1,
      value: {
        action: FINAL_ONBOARDING_SCREEN_LIFESTYLE,
        option: '113131',
      },
    },
  ];

  _snapShotForStoring = {};

  _setAuthMethod = (method) => {
    this.authMethod = method;
  };

  _setStepsToSkip = (steps) => {
    this.stepToSkip.concat(steps);
  };

  getinitialMessages = (_) => {
    return new Promise((resolve, reject) => {
      resolve({
        messages: this._NewMessages[this.nextMessageIndex].messages,
        actions: this._NewMessages[this.nextMessageIndex].actions,
      });

      this.nextMessageIndex++;
    });
  };

  setInititalMessageIndex = (index) => {
    this.nextMessageIndex = index;
  };

  clearStore = () => {
    this.nextMessageIndex = 0;
    this._snapShotForStoring = {};
  };

  createUserMessage = (data) => {
    const obj = {
      _id: Math.random(),
      createdAt: this.today,
      user: {
        _id: '1',
        name: '',
        avatar: '',
        type: 'Customer',
      },
      messageType: 'Automated',
      type: data.type,
      data: data.data,
      last_message: data.last_message,
      step: null,
      sub_step: null,
      text: data.text,
    };

    return obj;
  };

  _createAndStoreData = (key, data) => {
    let objectToBeMergerged = {
      [key]: data,
    };
    this._snapShotForStoring = {
      ...this._snapShotForStoring,
      ...objectToBeMergerged,
    };
  };

  actionMapper = (
    messageObj,
    {userId = null, token = null, platformUrl = null, username = null},
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {action, data} = messageObj;
        switch (action) {
          case SUBMIT_HEIGHT:
            this._createAndStoreData('height', data);
            break;
          case SKIP_HEIGHT:
            this._createAndStoreData('height', '');
            break;
          case SUBMIT_WEIGHT:
            this._createAndStoreData('weight', data);
            break;
          case SKIP_WEIGHT:
            this._createAndStoreData('weight', '');
            break;
          case SMOKING:
            this._createAndStoreData('smoke', data);
            break;
          case SKIP_SMOKE:
            this._createAndStoreData('smoke', '');
            break;
          case DRINKING:
            this._createAndStoreData('drink', data);
            break;
          case SKIP_DRINK:
            this._createAndStoreData('drink', '');
            break;
          case IS_TRAVELLED:
            this._createAndStoreData('travel', data);
            break;
          default:
        }

        resolve('resolved');
      } catch (e) {
        reject(e);
      }
    });
  };

  _sendMessages = (msgs) => {};

  _getMessagesForTheAction(next, message, action, dependency) {
    let messages = [];
    let createdMessage;
    let actions = [];

    const STAGE = action ?? DRINKING;

    createdMessage = this.createUserMessage(message);
    messages = [...this._NewMessages[this.nextMessageIndex].messages];
    messages.push(createdMessage);
    if (dependency.hasDependency) {
      let {messageIndex, key, value} = dependency;
      messages[messageIndex][key] = value + messages[messageIndex][key];
    }

    if (
      STAGE === IS_TRAVELLED &&
      !dependency.linkedAccount &&
      dependency.mainUser
    ) {
      actions = this._NewMessages[6].actions;
    } else {
      actions = this._NewMessages[this.nextMessageIndex].actions
        ? this._NewMessages[this.nextMessageIndex].actions
        : [];
    }
    this.nextMessageIndex++;

    const mergeMessages = [...messages];
    const objTobePassed = {
      messages: mergeMessages,
      actions: actions,
    };
    return objTobePassed;
  }

  mergeValueWithArray = (
    callBack,
    limit,
    data,
    dependency,
    {userId = null, token = null, platformUrl = null, username = null},
  ) => {
    const objTobePassed = this._getMessagesForTheAction(
      limit,
      data,
      data.action,
      dependency,
    );
    this.actionMapper(data, {userId, token, platformUrl, username}).then(
      (res) => {
        callBack(objTobePassed);
      },
    );
  };
}
