import React, {PropsWithChildren} from 'react';
import {View} from 'react-native';

interface IColumnProps {
  margin?: number | string;
  marginVertical?: number | string;
  marginHorizontal?: number | string;
  marginTop?: number | string;
  marginBottom?: number | string;
  marginLeft?: number | string;
  marginRight?: number | string;
  justifyContent?:
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'space-evenly'
    | 'space-around'
    | 'space-between';
  alignItems?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch';
  alignSelf?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  backgroundColor?: string;
  width?: number | string;
  height?: number | string;
  borderColor?: string;
  borderRadius?: number;
  borderTopWidth?: number;
  borderBottomWidth?: number;
  position?: 'absolute' | 'relative';
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
}

export function Container({
  children,
  margin,
  marginVertical,
  marginHorizontal,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  justifyContent = 'flex-start',
  alignItems = 'stretch',
  alignSelf,
  flexWrap = 'nowrap',
  backgroundColor,
  width,
  height,
  borderRadius,
  borderColor,
  borderTopWidth,
  borderBottomWidth,
  position,
  top,
  bottom,
  left,
  right,
}: PropsWithChildren<IColumnProps>) {
  return (
    <View
      style={{
        margin,
        marginVertical,
        marginHorizontal,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        justifyContent,
        alignItems,
        alignSelf,
        flexWrap,
        backgroundColor,
        width,
        height,
        borderRadius,
        borderColor,
        borderTopWidth,
        borderBottomWidth,
        position,
        top,
        bottom,
        left,
        right,
      }}>
      {children}
    </View>
  );
}
