import React from 'react';
import {IconPropsBase, IconSize} from './IconPropsBase';
import {IconBase} from './IconBase';
import {Colors} from 'utils';

interface IconArrowProps extends IconPropsBase {}

export function IconArrow({
  size = IconSize.Small,
  color = Colors.black,
}: IconArrowProps) {
  return <IconBase name={'arrowright'} size={size} color={color} />;
}
