import React from 'react';
import {StyleSheet, ActivityIndicator} from 'react-native';
import {colors, Spacing} from 'utils';

interface LoadingProps {
  loaderColor?: string;
  loaderSize?: 'small' | 'large';
}

export function Loader({
  loaderColor = colors.purple,
  loaderSize = 'small',
}: LoadingProps) {
  return (
    <ActivityIndicator
      size={loaderSize}
      color={loaderColor}
      style={styles.loaderStyle}
    />
  );
}

const styles = StyleSheet.create({
  loaderStyle: {
    top: Spacing.small,
  },
});
