import React from 'react';
import {StyleSheet, View, FlatList} from 'react-native';
import {NewAssessmentButtonAndModal} from 'modules';
import {PossibleConditionListItem} from './PossibleConditionListItem';
import {PossibleConditionHeaderItem} from './PossibleConditionHeaderItem';
import {calculateDiagnosePercentage} from 'utils';
interface PossibleConditionsListProps {
  diagnosis?: any[];
  itemPressHandler: (item: object, index: number) => void;
  parentNavigation?: object;
}

export function PossibleConditionsList({
  diagnosis,
  itemPressHandler,
  parentNavigation,
}: PossibleConditionsListProps) {
  return (
    <View style={styles.possibleListContainer}>
      <FlatList
        keyExtractor={(index, item) => `${item}`}
        data={diagnosis}
        contentContainerStyle={styles.contentContainerStyle}
        ListHeaderComponent={PossibleConditionHeaderItem}
        renderItem={({item, index}) => (
          <PossibleConditionListItem
            key={index}
            itemTitle={
              item?.diagnosis.display_name_layman ||
              item?.diagnosis.display_name
            }
            itemPercentage={calculateDiagnosePercentage(item.probability)}
            onPress={() => itemPressHandler(item, index)}
          />
        )}
        ListFooterComponent={
          <View style={styles.continueButtonContainer}>
            <NewAssessmentButtonAndModal parentNavigation={parentNavigation} />
          </View>
        }
        ListFooterComponentStyle={styles.listFooterComponentStyle}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  possibleListContainer: {
    flex: 1,
    marginHorizontal: 25,
  },
  continueButtonContainer: {
    marginVertical: 30,
  },
  listFooterComponentStyle: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  contentContainerStyle: {
    flexGrow: 1,
  },
});
