import Svg, {Rect} from 'react-native-svg';
import React from 'react';

const PlusIcon = () => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <Rect x="7" y="2" width="2" height="12" rx="1" fill="#E9662F" />
    <Rect
      x="14"
      y="7"
      width="2"
      height="12"
      rx="1"
      transform="rotate(90 14 7)"
      fill="#E9662F"
    />
  </Svg>
);

export default PlusIcon;
