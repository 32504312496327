import React from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {Text, Icon} from 'libs';
import {Colors} from 'utils';
interface SettingsItem {
  itemName: string;
  handlePress: () => void;
  addMarginTop?: number;
}

export function SettingsItem({
  itemName,
  addMarginTop,
  handlePress,
}: SettingsItem) {
  return (
    <View style={[styles.settingsItemContainer, {marginTop: addMarginTop}]}>
      <TouchableOpacity
        style={styles.settingsButtonStyle}
        onPress={() => handlePress()}>
        <Text.Body variant="bold" size="body1" color={Colors.primary}>
          {itemName}
        </Text.Body>
        <Icon.Arrow color={Colors.primary} size={Icon.Size.Medium} />
      </TouchableOpacity>
      <View style={styles.rowStyle} />
    </View>
  );
}

const styles = StyleSheet.create({
  settingsItemContainer: {
    marginHorizontal: 25,
  },
  settingsButtonStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
  },
  rowStyle: {
    height: 2,
    backgroundColor: Colors.borderPink,
  },
});
