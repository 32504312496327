import {Icon} from 'libs';
import {IconSize} from 'libs/components/icon/IconPropsBase';
import React from 'react';
import {
  ActivityIndicator,
  GestureResponderEvent,
  Modal,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {WebView} from 'react-native-webview';
import {Colors} from 'utils';

interface IWebViewModalProps {
  link: string;
  visible: boolean;
  closeHandler: (event: GestureResponderEvent) => void;
}

export function WebViewModal({
  link,
  visible,
  closeHandler,
}: IWebViewModalProps) {
  return (
    <Modal visible={visible}>
      <TouchableOpacity style={styles.flex} onPress={closeHandler}>
        <View style={styles.modal}>
          <View style={styles.closeContainer}>
            <Icon.Close
              size={IconSize.Large}
              color={Colors.white}
              onPress={closeHandler}
            />
          </View>
          <TouchableWithoutFeedback>
            <View style={styles.modalContainer}>
              <WebView
                startInLoadingState={true}
                style={styles.flex}
                nestedScrollEnabled={true}
                source={{uri: link}}
                renderLoading={() => (
                  <ActivityIndicator
                    color={Colors.primary}
                    size="large"
                    style={styles.activityIndicatorStyle}
                  />
                )}
              />
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableOpacity>
    </Modal>
  );
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  closeContainer: {
    position: 'absolute',
    top: 40,
    right: 20,
  },
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContainer: {
    backgroundColor: Colors.white,
    width: '90%',
    height: '80%',
  },
  activityIndicatorStyle: {
    flex: 1,
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
});
