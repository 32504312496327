import {
  CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
  CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
  CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
} from '../../../../assets/images/cara/CaraFaces';
import {
  DOWN_ARROW,
  DOWN_ARROW_NOT_ANIMATED,
  ENTER_BIRTHDATE,
  ENTER_CITY,
  ENTER_COUNTRY,
  ENTER_EMAIl,
  ENTER_FIRST_NAME,
  ENTER_GENDER,
  ENTER_LAST_NAME,
  ENTER_NICKNAME,
  ENTER_PASSOWRD,
  ENTER_PRONOUN,
  ENTER_PROVINCE,
  EYE,
  FINAL_ONBOARDING_SCREEN,
  GO_TO_HOME,
  GO_TO_PROFILE,
  SEND_ICON,
  SKIP,
  SKIP_ICON,
  TRACK_SYMPTOM,
  START_SYMPTOM_ASSESSMENT,
} from 'utils';
import {customer, systemUser} from 'utils';

let today = new Date();

export const _NewMessages = [
  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b716',
        createdAt: today,
        user: {...systemUser},
        messageType: 'Automated',
        icon: CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
        type: 'text',
        shouldAnimate: true,
        data: "Me again! Your personal health assistant/orb of knowledge, here to help build your profile. What's your first name?",
        last_message: 'What is Your First Name? ',
        step: ENTER_FIRST_NAME,
        sub_step: null,
        text: '',
      },
    ],
    actions: [
      {
        createdAt: today,
        tag: ENTER_FIRST_NAME,
        inputQuickReplies: {
          placeHolder: 'Enter first name',
          editable: true,
          type: 'radio',
          values: [
            {
              _id: 1,
              action: ENTER_FIRST_NAME,
              iconName: SEND_ICON,
              hasReactToEnable: true,
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '98f530bd-cae9-4581-b163-ec00a257ac0716',
      },
    ],
  },
  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b718',
        createdAt: today,
        shouldAnimate: true,

        user: {...systemUser},
        messageType: 'Automated',
        type: 'text',
        data: '',
        step: ENTER_LAST_NAME,
        icon: CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
        sub_step: null,
        text: '',
      },
    ],
    actions: [
      {
        createdAt: today,
        tag: ENTER_LAST_NAME,
        inputQuickReplies: {
          placeHolder: 'Enter last name',
          editable: true,
          type: 'radio',
          values: [
            {
              action: ENTER_LAST_NAME,
              _id: 1,
              iconName: SEND_ICON,
              hasReactToEnable: true,
            },
          ],
        },
        quickReplies: {
          type: 'radio',
          values: [
            {
              title: 'Last Name',
              value: {
                action: ENTER_LAST_NAME,
                option: '113131',
              },
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '98f530bd-cae9-4581-b163-ec00a257ac0718',
      },
    ],
  },
  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b71w',
        createdAt: today,
        user: {...systemUser},
        shouldAnimate: true,
        messageType: 'Automated',
        type: 'text',
        data: "What's your email?",
        last_message: "What's your email?",
        step: ENTER_EMAIl,
        icon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
        sub_step: null,
      },
    ],
    actions: [
      {
        createdAt: today,
        tag: ENTER_EMAIl,
        inputQuickReplies: {
          placeHolder: 'Enter email',
          editable: true,
          type: 'radio',
          values: [
            {
              action: ENTER_EMAIl,
              _id: 1,
              iconName: SEND_ICON,
              hasReactToEnable: true,
            },
          ],
        },
        quickReplies: {
          type: 'radio',
          values: [
            {
              title: 'Email',
              value: {
                action: ENTER_EMAIl,
                option: '113131',
              },
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '98f530bd-cae9-4581-b163-ec00a257ac071w',
      },
    ],
  },
  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b71wf',
        createdAt: today,
        user: {...systemUser},
        shouldAnimate: true,
        messageType: 'Automated',
        type: 'text',
        text: '',
        last_message:
          'Got it. Now, please set a password for your Cara account',
        step: ENTER_PASSOWRD,
        sub_step: null,
        data: 'Got it. Now, please set a password for your Cara account',
      },
    ],
    actions: [
      {
        createdAt: today,
        tag: ENTER_PASSOWRD,
        inputQuickReplies: {
          validate: true,
          field: 'password',
          placeHolder: 'Enter password',
          editable: true,
          type: 'radio',
          values: [
            {
              action: EYE,
              _id: 1,
              iconName: EYE,
              hasReactToEnable: false,
            },
            {
              action: ENTER_PASSOWRD,
              _id: 2,
              iconName: SEND_ICON,
              hasReactToEnable: true,
            },
          ],
        },
        quickReplies: {
          type: 'radio',
          values: [
            {
              title: 'Password',
              value: {
                action: ENTER_PASSOWRD,
                option: '113131',
              },
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '98f530bd-cae9-4581-b163-ec00a257ac071wf',
      },
    ],
  },
  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a889',
        createdAt: today,
        user: {...systemUser},
        shouldAnimate: true,
        messageType: 'Automated',
        type: 'text',
        data: 'Thanks for all that. You’re officially on your way to better health management! First things first, what would you like me to call you? ',
        last_message:
          'Thanks for all that. You’re officially on your way to better health management! First things first, what would you like me to call you?',
        step: ENTER_NICKNAME,
        icon: CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
        sub_step: null,
      },
    ],
    actions: [
      {
        renderInputAndQuickReplies: true,
        createdAt: today,
        tag: ENTER_NICKNAME,
        inputQuickReplies: {
          type: 'radio',
          placeHolder: 'Enter preferred name',
          editable: true,
          values: [
            {
              action: SKIP,
              iconName: SKIP_ICON,
              _id: 2,
              hasReactToEnable: false,
            },
            {
              action: ENTER_NICKNAME,
              _id: 1,
              iconName: SEND_ICON,
              hasReactToEnable: true,
            },
          ],
        },
        quickReplies: {
          type: 'radio',
          values: [
            {
              title: 'Use firstname',
              value: {
                action: ENTER_NICKNAME,
                option: '113131',
              },
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a881',
      },
    ],
  },
  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b8',
        createdAt: today,
        user: {...systemUser},
        messageType: 'Automated',
        type: 'text',
        shouldAnimate: true,
        data: 'The more info I have, the more accurate your results. What’s your date of birth?',
        last_message: ' ',
        step: ENTER_BIRTHDATE,
        icon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
        sub_step: null,
      },
    ],
    actions: [
      {
        createdAt: today,
        tag: ENTER_BIRTHDATE,
        inputQuickReplies: {
          placeHolder: 'Select date of birth',
          editable: false,
          type: 'radio',
          values: [
            {
              _id: 1,
              action: ENTER_BIRTHDATE,
              iconName: DOWN_ARROW,
              hasReactToEnable: false,
            },
          ],
        },
        quickReplies: {
          type: 'radio',
          values: [
            {
              title: 'YY-MM-DD',
              value: {
                action: ENTER_BIRTHDATE,
                option: '113131',
              },
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '98f530bd-cae9-4581-b163-ec00a257ac08',
      },
    ],
  },

  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8bP',
        createdAt: today,
        user: {...systemUser},
        shouldAnimate: true,
        messageType: 'Automated',
        data: {
          info: {
            data: 'knowing what sex you were assigned at birth is essential to assess your health properly from a medical standpoint. There are biological difference between male and female.',
            title: 'What sex were you assigned at birth? ',
          },
          message: 'What sex were you assigned at birth?',
          icon: CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
        },
        step: ENTER_GENDER,
        sub_step: null,
      },
    ],
    actions: [
      {
        createdAt: today,
        tag: ENTER_GENDER,
        quickReplies: {
          type: 'radio',
          values: [
            {
              title: 'Female',
              key: 1,
              value: {
                action: ENTER_GENDER,
                option: '113131',
              },
            },
            {
              title: 'Male',
              key: 1,
              value: {
                action: ENTER_GENDER,
                option: '113131',
              },
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '98f530bd-cae9-4581-b163-ec00a257ac0E',
      },
    ],
  },
  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8bP2323111',
        createdAt: today,
        user: {...systemUser},
        shouldAnimate: true,
        messageType: 'Automated',
        data: 'What are your pronouns?',
        step: ENTER_PRONOUN,
        sub_step: null,
        icon: CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
      },
    ],
    actions: [
      {
        createdAt: today,
        tag: ENTER_PRONOUN,
        quickReplies: {
          type: 'radio',
          values: [
            {
              title: 'They/Them',
              key: 1,
              value: {
                action: ENTER_PRONOUN,
                option: '113131',
              },
            },
            {
              title: 'He/Him',
              key: 1,
              value: {
                action: ENTER_PRONOUN,
                option: '113131',
              },
            },
            {
              title: 'She/Her',
              key: 1,
              value: {
                action: ENTER_PRONOUN,
                option: '113131',
              },
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8bP2323222',
      },
    ],
  },

  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b7',
        createdAt: today,
        user: {...systemUser},
        messageType: 'Automated',
        shouldAnimate: true,
        type: 'text',
        last_message: 'I need to know Where your primary residence is ? ',
        step: ENTER_COUNTRY,
        sub_step: null,
        icon: CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
        data: 'Almost done. What country do you live in primarily?',
      },
    ],
    actions: [
      {
        createdAt: today,
        tag: ENTER_COUNTRY,
        inputQuickReplies: {
          placeHolder: 'Select country',
          editable: false,
          type: 'radio',
          values: [
            {
              _id: 1,
              action: ENTER_COUNTRY,
              iconName: DOWN_ARROW_NOT_ANIMATED,
              hasReactToEnable: false,
            },
          ],
        },
        quickReplies: {
          type: 'radio',
          values: [
            {
              title: 'Country',
              value: {
                action: ENTER_COUNTRY,
                option: '113131',
              },
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '98f530bd-cae9-4581-b163-ec00a257ac07',
      },
    ],
  },
  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b71',
        createdAt: today,
        user: {...systemUser},
        messageType: 'Automated',
        shouldAnimate: true,
        type: 'text',
        data: 'And what province?',
        last_message: 'And what province? ',
        step: ENTER_PROVINCE,
        sub_step: null,
        icon: CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
      },
    ],
    actions: [
      {
        createdAt: today,
        tag: ENTER_PROVINCE,
        inputQuickReplies: {
          placeHolder: 'Select province',
          editable: false,
          type: 'radio',
          values: [
            {
              _id: 1,
              action: ENTER_PROVINCE,
              iconName: DOWN_ARROW_NOT_ANIMATED,
              hasReactToEnable: false,
            },
          ],
        },
        quickReplies: {
          type: 'radio',
          values: [
            {
              title: 'Province',
              value: {
                action: ENTER_PROVINCE,
                option: '113131',
              },
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '98f530bd-cae9-4581-b163-ec00a257ac071',
      },
    ],
  },
  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b70',
        createdAt: today,
        shouldAnimate: true,
        user: {...systemUser},
        messageType: 'Automated',
        type: 'text',
        data: 'Very nice. I live inside an app. Finally, what city do you live in?',
        last_message:
          'Very nice. I live inside an app. Finally, what city do you live in?',
        step: ENTER_CITY,
        icon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
        sub_step: null,
      },
    ],
    actions: [
      {
        createdAt: today,
        tag: ENTER_CITY,
        inputQuickReplies: {
          placeHolder: 'Select your city',
          editable: false,
          type: 'radio',
          values: [
            {
              _id: 1,
              action: ENTER_CITY,
              iconName: DOWN_ARROW_NOT_ANIMATED,
              hasReactToEnable: false,
            },
          ],
        },
        quickReplies: {
          type: 'radio',
          values: [
            {
              title: 'City',
              value: {
                action: ENTER_CITY,
                option: '113131',
              },
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '98f530bd-cae9-4581-b163-ec00a257ac070',
      },
    ],
  },
  {
    messages: [
      {
        _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b79rr',
        createdAt: today,
        shouldAnimate: true,
        user: {...systemUser},
        messageType: 'Automated',
        type: 'text',
        icon: CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
        data: '',
        last_message: '',
        step: FINAL_ONBOARDING_SCREEN,
        sub_step: null,
      },
    ],
    actions: [
      {
        createdAt: today,
        quickReplies: {
          type: 'radio',
          values: [
            {
              title: 'Complete profile',
              key: 1,
              value: {
                action: GO_TO_PROFILE,
                option: '113131',
              },
            },
            {
              title: 'Track symptom',
              key: 1,
              value: {
                action: TRACK_SYMPTOM,
                option: '113131',
              },
            },
            {
              title: 'Assess symptom',
              key: 2,
              value: {
                action: START_SYMPTOM_ASSESSMENT,
                option: '113131',
              },
            },
            {
              title: 'Go to home',
              key: 2,
              value: {
                action: GO_TO_HOME,
                option: '113131',
              },
            },
          ],
        },
        step: null,
        sub_step: null,
        user: {...customer},
        _id: '98f530bd-cae9-4581-b163-ec00a257ac079rr',
      },
    ],
  },
];
