import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {fma27_1_2, fma73093} from 'utils';
import {getColorFma} from 'utils';

export const Foot_Left_Top_271 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1242 2208">
      <Path
        id="Path_354"
        dataName="Path 354"
        d="M502.2,1519.3l-9.1,4.5c0,0,18,127.8,17.3,179.3c-0.6,51.1-26.8,59.5-72.8,59.5c-60.3,0-97.8-29-99.2-88.5  c-1.3-57-0.7-287.6,1-366.5c2.9-69.9,35.1-492.3,33.6-539.7c-1.4-47.4-34.5-122.7-37.3-164.5c-1.7-25,3.2-69.8,7.5-101.9  c2.9-21.5,21.2-37.5,42.9-37.5h308.5c15.9,0,30.4,8.6,38,22.6c13.6,25,31.8,66.3,26.8,101.1c-7.7,53.9-21.2,76.2-7.7,120.9  c13.4,44.6,137.8,660,137.8,660s11.9,73.1,11.5,123.9c-0.4,59.1-51.8,46.1-59.8,25.4c-13.5-34.8-31.6-85.5-31.6-85.5l-4.6,1.7  c0,0,17.3,104.7,8.4,129c-8.7,23.7-41.9,32.3-58.9,9c-7.6-10.5-30.3-118.4-30.3-118.4l-6.5,1.9c0,0,24,137.6,19.6,166.8  c-4.6,30.9-62,47.4-80,18.1c-17.1-28-41.5-151.1-41.5-151.1l-5.9,1.4c0,0,19.7,172,12.2,196.9c-7.7,25.8-64.5,34.3-80.8,6.6  C528.7,1672.7,502.2,1519.3,502.2,1519.3z"
        fill="#ead9e7"
        style="mix-blend-mode: multiply;isolation: isolate"
      />

      <Path
        id={fma27_1_2}
        dataName="Path 358"
        d="M401.2,1500.7c13.4,2.3,45.8,6.8,123.4-4.7c26-3.9,60.2-10,100.1-20.1c0,0,215.8-35.3,252.6-79.4  c1.2-1.4,6.5-8.2,11.6-7.1c7.1,1.5,10,17.5,11.2,34.5c0.5,5.9,1,11.7,1.6,17.6c1.2,3.3,2.4,7.7,2.3,13c-0.2,16-11.5,26.6-14,28.9  c-61.4,34.6-130.7,73-207.1,114.3c-82.2,44.5-119.8,63.7-174.6,78.5c-58.5,15.7-109,18.9-143.2,19c-6.4-13.2-14.1-33.1-16.5-58  c-1.7-16.7-0.3-29.4,0.2-34.9c2.3-23.4,3.8-59.7,0.6-115.7C361.4,1491.1,379.2,1496.9,401.2,1500.7z"
        fill={getColorFma(fma27_1_2, selectedBodyPart)}
        onPressIn={() => onPressClick(fma27_1_2)}
        onClick={() => onPressClick(fma27_1_2)}
      />
      <Path
        id={fma73093}
        dataName="Path 359"
        d="M347.8,1403.3l28.7-514.2c2.2-39,11.3-77.2,26.8-112l17.5-39.3c22.3-50,33.9-105.3,33.9-161.5v-91.3  c0-16.8,11.8-30.4,26.4-30.4h159.5c14.6,0,26.4,13.6,26.4,30.4v55.1c0,81.6,24.5,160.7,69.4,224c25.2,35.5,42.6,77.4,50.7,122.3  l86.2,474.8c2.1,11.7-5.2,23-15.7,23.8c-64.8,27-145.8,54.2-240.8,72.5c-70.8,13.6-135.6,19.5-191.8,21.3c-25-6.5-49.9-13-74.9-19.6  C349.4,1440.6,348.6,1421.9,347.8,1403.3z"
        fill={getColorFma(fma73093, selectedBodyPart)}
        onPressIn={() => onPressClick(fma73093)}
        onClick={() => onPressClick(fma73093)}
      />
      <Path
        id="Path_355"
        dataName="Path 355"
        d="M485.4,1687.3c-3.7-53.5-18.8-74.8-61.3-71.1s-42.6,71.1-42.6,71.1"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_356"
        dataName="Path 356"
        d="M611.3,1656.1c-2.1-30.1-10.6-42.1-34.5-40s-23.9,40-23.9,40"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_357"
        dataName="Path 357"
        d="M725.2,1608.3c-2-28.8-10.1-40.2-33-38.2c-22.9,2-22.9,38.2-22.9,38.2"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_450"
        dataName="Path 450"
        d="M800.6,1542.1c-1.3-19.4-6.8-27.1-22.2-25.8s-15.4,25.8-15.4,25.8"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_451"
        dataName="Path 451"
        d="M892.5,1498.8c-1.3-19.4-6.8-27.1-22.2-25.8s-15.4,25.8-15.4,25.8"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
