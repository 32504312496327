import React from 'react';
import {Colors} from 'utils';
import {IconPropsBase, IconSize} from './IconPropsBase';
import {IconBase} from './IconBase';

interface IconCloseProps extends IconPropsBase {}

export function IconClose({
  size = IconSize.Medium,
  color = Colors.black,
  onPress,
}: IconCloseProps) {
  return (
    <IconBase
      name={'close'}
      size={size}
      color={color}
      onPress={onPress}
      style={{right: 15, padding: 10}}
    />
  );
}
