import React, {useState} from 'react';
import {CheckBox} from 'react-native-elements';
import {MARKPRO_BOOK} from 'utils';
import {ICheckboxPropsBase} from './ICheckboxPropsBase';

interface ICheckboxProps extends ICheckboxPropsBase {}

export function CheckboxBase({
  title,
  onPressed,
  checkedIcon,
  uncheckedIcon,
  size,
  // The component can be stateful or stateless
  // In the case where we use it in a FlatList or another PureComponent we need to be stateless
  // So in that case the checked state is persisted and passed down by the parent
  isChecked,
}: ICheckboxProps) {
  const [checked, setChecked] = useState(false);

  return (
    <CheckBox
      title={title}
      checked={isChecked !== null ? isChecked : checked}
      checkedIcon={checkedIcon}
      uncheckedIcon={uncheckedIcon}
      containerStyle={{
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      }}
      size={size}
      onPress={() => {
        onPressed(isChecked !== null ? !isChecked : !checked);
        setChecked(!checked);
      }}
    />
  );
}
