import React from 'react';
import {StyleSheet} from 'react-native';
import {Colors} from 'utils';
import {Text} from 'libs';
import {Row} from 'modules';
interface SearchSymptomsResultsProps {
  resultsNumber?: number;
  symptomText?: string;
}
export function SearchSymptomsResults({
  resultsNumber,
  symptomText,
}: SearchSymptomsResultsProps) {
  return (
    <Row marginHorizontal={25} flexWrap="wrap" marginTop={10}>
      <Text.Caption
        variant="bold"
        size="caption1"
        color={Colors.secondLightGreyColor}>
        We found {resultsNumber} symptoms like{' '}
      </Text.Caption>

      <Text.Caption
        variant="bold"
        size="caption1"
        color={Colors.primary}
        style={styles.symptomTextStyle}>
        {symptomText}
      </Text.Caption>
    </Row>
  );
}

const styles = StyleSheet.create({
  symptomTextStyle: {
    backgroundColor: Colors.primaryLight,
  },
});
