import React, {PropsWithChildren} from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

// TODO SafeAreaView should be part of the Layout component.
// This will require the implementation of a custom header when an svg needs to be used
// as part of the screen header

interface LayoutProps {
  style?: ViewStyle;
}

export function Layout({children, style}: PropsWithChildren<LayoutProps>) {
  return <View style={[styles.container, style]}>{children}</View>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
