// @ts-nocheck

import {AnimatedDoughNut} from 'app/components/reusables/animated-reusables/AnimatedDoghNut';
import React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import Svg, {Path} from 'react-native-svg';
import BackIcon from 'react-native-vector-icons/AntDesign';
import {connect} from 'react-redux';
import {
  Colors,
  MARKPRO_BOLD,
  MARKPRO_MEDIUM,
  viewStyles,
  viewWidth1200,
} from 'utils';

const TitleBar = () => (
  <View style={styles.mainView}>
    <View style={styles.titleBar}>
      <Text style={styles.nameText}>Kelly</Text>
      <Text style={styles.dateText}>January 22, 2021</Text>
    </View>
  </View>
);

const BackView = (props) => (
  <Pressable style={styles.backView} onPress={props.back}>
    <BackIcon name="arrowleft" color={Colors.white} size={20} />
  </Pressable>
);

const TopBar = ({calculateHeight, windowWidth}) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={windowWidth}
      height={calculateHeight}
      viewBox="0 0 1200 300">
      <Path
        id="Path_23"
        dataName="Path 23"
        d="M76.413-2658.072v300s-304.918-66.989-604.918-66.989-595.082,66.989-595.082,66.989v-300Z"
        transform="translate(1123.587 2658.072)"
        fill="#c882bb"
      />
    </Svg>
  );
};

function ResultsTopNavigator({state, descriptors, navigation, props}) {
  const initialWindowWidth = props.windowWidth + 10;
  const viewHeight = 300;
  const initialHeight = (initialWindowWidth * viewHeight) / viewWidth1200;

  return (
    <View style={styles.view}>
      <SafeAreaView
        style={viewStyles.flex1}
        forceInset={viewStyles.safeAreaBottomNever}>
        <SafeAreaView style={viewStyles.height80}>
          <BackView back={navigation.goBack} />
          <TitleBar />
        </SafeAreaView>
        <View style={styles.topBarView}>
          <TopBar
            calculateHeight={initialHeight}
            windowWidth={initialWindowWidth}
          />
          <Text style={styles.lifestyleText}>Acute bronchiolitis</Text>
          <Text style={styles.subTitleText}>(bron-kee-oh-LYE-tus)</Text>
        </View>
        <View style={styles.animatedDoughnutView}>
          <AnimatedDoughNut />
        </View>
        <SafeAreaView
          style={[styles.safeArea, {width: initialWindowWidth - 10}]}>
          {state.routes.map((route, index) => {
            const {options} = descriptors[route.key];
            const label =
              options.tabBarLabel !== undefined
                ? options.tabBarLabel
                : options.title !== undefined
                ? options.title
                : route.name;

            const isFocused = state.index === index;

            const onPress = () => {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
              });

              if (!isFocused && !event.defaultPrevented) {
                navigation.navigate(route.name);
              }
            };

            const onLongPress = () => {
              navigation.emit({
                type: 'tabLongPress',
                target: route.key,
              });
            };

            return (
              <TouchableOpacity
                accessibilityRole="button"
                key={label}
                accessibilityState={isFocused ? {selected: true} : {}}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                testID={options.tabBarTestID}
                onPress={onPress}
                onLongPress={onLongPress}
                style={[
                  styles.common,
                  isFocused ? styles.active : styles.inactive,
                ]}>
                <Text style={styles.labelText}>{label}</Text>
              </TouchableOpacity>
            );
          })}
        </SafeAreaView>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  view: {flex: 1, backgroundColor: Colors.topPurple},
  labelText: {
    color: Colors.primary,
    fontSize: 16,
    fontFamily: MARKPRO_MEDIUM,
    bottom: 2,
  },
  safeArea: {
    backgroundColor: Colors.white,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  animatedDoughnutView: {
    position: 'absolute',
    alignSelf: 'center',
    top: 165,
    backgroundColor: 'transparent',
    alignItems: 'center',
  },
  dateText: {
    color: Colors.white,
    fontSize: 14,
    fontFamily: MARKPRO_MEDIUM,
    lineHeight: 19,
    paddingRight: 5,
  },
  nameText: {
    color: Colors.white,
    fontSize: 18,
    fontFamily: MARKPRO_MEDIUM,
    lineHeight: 19,
    paddingRight: 5,
  },
  titleBar: {alignItems: 'center'},
  mainView: {
    flexDirection: 'row',
    width: '100%',
    position: 'absolute',
    justifyContent: 'center',
    height: 80,
    alignItems: 'center',
  },
  common: {
    alignItems: 'center',
    paddingHorizontal: 10,
    justifyContent: 'center',
    marginHorizontal: 5,
    marginVertical: 5,
    height: 36,
    borderRadius: 100,
    borderWidth: 1,
  },
  active: {
    backgroundColor: Colors.primaryLight,
    borderColor: Colors.transparent,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.85,
    elevation: 6,
  },

  backView: {
    width: 60,
    height: 60,
    position: 'absolute',
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
  },

  inactive: {
    borderColor: Colors.primaryLight,
  },

  lifestyleText: {
    fontSize: 28,
    fontFamily: MARKPRO_BOLD,
    color: Colors.white,
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    top: -5,
  },
  topBarView: {flex: 1, backgroundColor: Colors.white},
  subTitleText: {
    fontSize: 18,
    fontFamily: MARKPRO_MEDIUM,
    color: Colors.white,
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    top: 30,
  },
});

const mapStateToProps = (state) => ({
  windowWidth: state.appConfig.windowWidth,
});

export default connect(mapStateToProps)(ResultsTopNavigator);
