import {isWeb, withHumanBodySvgScreen} from 'config';
import {Layout, ScreenNames} from 'modules';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {changeAndroidStatusBarColor, Colors, TopBarSvg} from 'utils';
import {SettingsItem} from './components/SettingsItem';
import crashlytics from '@react-native-firebase/crashlytics';

enum SettingsTitle {
  TermsConditions = 'Terms and Conditions',
  PrivacyPolicy = 'Privacy Policy',
  DataAndMethodology = 'Data and Methodology',
  DiseaseCitations = 'Medical Conditions Citations',
  HumanBodySvg = "Human Body SVG's",
}

const SettingsScreen = ({navigation, route}: any) => {
  const bootstrapUrls = useSelector(
    (state: any) => state.application.bootstrapUrls,
  );

  useEffect(() => {
    changeAndroidStatusBarColor(Colors.topPurple);
    return () => {
      !route?.params?.keepStatusBarColorOnClose &&
        changeAndroidStatusBarColor(Colors.primary);
    };
  }, []);

  return (
    <Layout>
      {!isWeb ? <TopBarSvg fillColor={Colors.topPurple} /> : null}

      <SettingsItem
        itemName={SettingsTitle.TermsConditions}
        handlePress={() => {
          navigation.navigate(ScreenNames.StaticTextScreen, {
            title: SettingsTitle.TermsConditions,
            url: {uri: bootstrapUrls.termsAndConditions},
          });
        }}
        addMarginTop={25}
      />

      <SettingsItem
        itemName={SettingsTitle.PrivacyPolicy}
        handlePress={() => {
          navigation.navigate(ScreenNames.StaticTextScreen, {
            title: SettingsTitle.PrivacyPolicy,
            url: {uri: bootstrapUrls.privacyPolicy},
          });
        }}
      />

      <SettingsItem
        itemName={SettingsTitle.DataAndMethodology}
        handlePress={() => {
          navigation.navigate(ScreenNames.DataAndMethodology);
        }}
      />

      <SettingsItem
        itemName={SettingsTitle.DiseaseCitations}
        handlePress={() => {
          navigation.navigate(ScreenNames.DiseaseCitations);
        }}
      />

      {(withHumanBodySvgScreen && !isWeb) ? (
        <SettingsItem
          itemName={SettingsTitle.HumanBodySvg}
          handlePress={() => navigation.navigate(ScreenNames.HumanBodySvg)}
        />
      ) : null}
    </Layout>
  );
};

export default SettingsScreen;
