import * as Actions from '../action-types';

export const isLoginPlatform = (payload) => {
  return {type: Actions.LOGIN_PLATFORM, payload};
};
export const isLoginPlatformFailed = (payload) => {
  return {type: Actions.LOGIN_PLATFORM_FAILED, payload};
};

export const isInitializeClinBOT = (payload) => {
  return {type: Actions.INITIALIZE_CLIN_BOT, payload};
};
export const isInitializeClinBOTFailed = (payload) => {
  return {type: Actions.INITIALIZE_CLIN_BOT_FAILED, payload};
};

export const setPlatformUrl = (payload) => {
  return {type: Actions.PLATFORM_URL, payload};
};

export const setPlatformToken = (payload) => {
  return {type: Actions.PLATFORM_TOKEN, payload};
};

export const setAssessmentToken = (payload) => {
  return {type: Actions.ASSESSMENT_TOKEN, payload};
};

export const setInitialTabScreen = (payload) => {
  return {type: Actions.SET_INITIAL_TAB_SCREEN, payload};
};

export const IsUserStartingAnAssessment = (payload) => {
  return {type: Actions.SET_IS_USER_STARTING_AN_ASSESSMENT, payload};
};

export const setIsUserFirstTime = (payload) => {
  return {type: Actions.IS_USERS_FIRST_TIME, payload};
};

export const setBioMetricOn = (payload) => {
  return {type: Actions.SET_BIOMETRIC_ON, payload};
};

export const setSystemInitializationSuccess = (payload) => {
  return {type: Actions.SYSTEM_INITIALIZED_SUCCESS, payload};
};

export const setSystemInitializedFailed = (payload) => {
  return {type: Actions.SYSTEM_INITIALIZED_FAILED, payload};
};

export const setBootstrapUrls = (payload) => {
  return {type: Actions.SET_BOOTSTRAP_URLS, payload};
};
