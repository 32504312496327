import {
  InputAccessoryView,
  Keyboard,
  Text,
  View,
  Pressable,
  StyleSheet,
} from 'react-native';
import {Colors} from 'utils';
import {MARKPRO_MEDIUM} from 'utils';
import React from 'react';

const InputAccessoryViewiOS = (props) => (
  <InputAccessoryView nativeID={props.viewID}>
    <View style={[styles.inputAccessoryView]}>
      <Pressable
        onPress={() => {
          if (props.isPswField && !props.enable) {
            return;
          }
          Keyboard.dismiss();
          props.insertAnsweredQuestion(props.tag);
          if (!props.enterText) {
            return;
          }

          props.clearText();
          props.onSend(
            {
              text: props.enterText,
              action: props.senderAction.action,
            },
            true,
          );
        }}>
        <Text style={[styles.donePressable]}>Done</Text>
      </Pressable>
    </View>
  </InputAccessoryView>
);
const styles = StyleSheet.create({
  donePressable: {
    color: '#4484F6',
    fontSize: 16,
    fontFamily: MARKPRO_MEDIUM,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  inputAccessoryView: {
    backgroundColor: Colors.keyboardToolbarBackground,
    height: 44,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
});
export default InputAccessoryViewiOS;
