import React from 'react';
import {View, StyleSheet} from 'react-native';
import {viewColors, viewStyles} from 'utils';

const SplashScreen = (props) => {
  return <View style={splashScreenView} />;
};
const splashScreenView = StyleSheet.compose(
  {...viewStyles.flex1, ...viewStyles.alignItemsCenter},
  {...viewStyles.justifyContentCenter, ...viewColors.backgroundColorPrimary},
);
export default SplashScreen;
