import React from 'react';
import {ImageBase} from './ImageBase';
import {ImageStyle, StyleProp} from 'react-native';
import {caraWow} from 'utils';

export interface ImageCaraWowProps {
  style?: StyleProp<ImageStyle>;
}

export function ImageCaraWow({style}: ImageCaraWowProps) {
  return <ImageBase source={caraWow} style={style} />;
}
