import React from 'react';
import {Text, Pressable, TextInput, StyleSheet, Platform} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import {Colors} from 'utils';
import {MARKPRO_BOOK} from 'utils';
import {SearchBarCloseIcon} from 'components/reusables/components/SVGS';
import {viewStyles} from 'utils';

export default function SearchBar(props) {
  const closeIcon = (clear) => {
    return (
      <Pressable onPress={clear}>
        <SearchBarCloseIcon />
      </Pressable>
    );
  };

  const renderTextView = () => {
    if (props.editable) {
      return Platform.OS === 'ios' ? (
        <TextInput
          value={props.text}
          onChangeText={(text) => props.onTextEdit(text)}
          style={[styles.text]}
          placeholder={props.description}
          placeholderTextColor={Colors.searchIconColor}
          autoCorrect={false}
          autoFocus={props?.autoFocus ?? false}
        />
      ) : (
        <TextInput
          value={props.text}
          onChangeText={(text) => props.onTextEdit(text)}
          style={[styles.text, viewStyles.height44]}
          placeholder={props.description}
          placeholderTextColor={Colors.searchIconColor}
          autoCorrect={false}
          autoFocus={props?.autoFocus ?? false}
        />
      );
    }
    return (
      <Text
        onPress={props.onClick}
        style={[
          styles.text,
          {fontFamily: MARKPRO_BOOK, color: Colors.searchIconColor},
        ]}>
        {props.description}
      </Text>
    );
  };

  return (
    <Pressable onPress={props.onClick} style={styles.container}>
      <Icon
        onPress={props.onClick}
        name="search1"
        color={Colors.searchIconColor}
        size={20}
        style={viewStyles.paddingRight5}
      />
      {renderTextView()}
      {props.showClose && closeIcon(props.clear)}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  text: {
    flex: 1,
    margin: 5,
    fontFamily: MARKPRO_BOOK,
    fontSize: 16,
    color: 'black',
  },
  container: {
    flexDirection: 'row',
    backgroundColor: Colors.systemChatBubblesColor,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 13,
    borderRadius: 18,
    marginHorizontal: 20,
    marginVertical: 10,
  },
});
