import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getDiseaseCitations} from 'utils';

export interface ICitation {
  display_name: string;
  source_links: string;
}

export default function useCitations() {
  const platformUrl = useSelector(
    (state: any) => state.application.platformUrl,
  );
  const platformToken = useSelector(
    (state: any) => state.application.platformToken,
  );
  const [citations, setCitations] = useState([] as any);

  useEffect(() => {
    getDiseaseCitations(platformUrl, platformToken).then((res) => {
      let sortedCitations = res.data.data;

      sortedCitations.sort((a: ICitation, b: ICitation) =>
        a.display_name.localeCompare(b.display_name),
      );

      sortedCitations = sortedCitations.filter(
        (t: ICitation) => t.display_name.length > 0,
      );

      setCitations(sortedCitations);
    });
  }, []);

  return [citations];
}
