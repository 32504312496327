import {Dimensions} from 'react-native';
import {
  arcViewHeight56,
  arcViewWidth375,
  viewHeight335,
  viewWidth1200,
} from 'utils';

const getArcCalculatedHeight = () => {
  const arcWidth = Dimensions.get('screen').width + 15;
  return arcWidth * (arcViewHeight56 / arcViewWidth375);
};

const getTobBarHeight = () => {
  const initialWindowWidth = Dimensions.get('screen').width + 5;
  return (initialWindowWidth * viewHeight335) / viewWidth1200;
};

const getTopBarPrivacyHeight = () => {
  const initialWindowWidth = Dimensions.get('screen').width + 50;
  return (initialWindowWidth * 400) / viewWidth1200;
};

const initialState = {
  windowWidth: Dimensions.get('screen').width,
  windowHeight: Dimensions.get('screen').height,
  arcCalculatedHeight: getArcCalculatedHeight(),
  arcWidth: arcViewWidth375,
  topBarCalculatedHeight: getTobBarHeight(),
  topBarPrivacyHeight: getTopBarPrivacyHeight(),
};

export default function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
