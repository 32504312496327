import {
  ENTER_BIRTHDATE,
  ENTER_CITY,
  ENTER_COUNTRY,
  ENTER_FIRST_NAME,
  ENTER_GENDER,
  ENTER_LAST_NAME,
  ENTER_PROVINCE,
  SEND_RELATIONSHIP,
  SUBMIT_BIRTH_DATE_AND_TIME,
  SUBMIT_COUNTRY,
  SUBMIT_DATA,
  SUBMIT_PROVINCE,
} from 'utils';

export default class ProfileMessageStore {
  today = new Date();

  systemUser = {
    avatar:
      'https://i.pinimg.com/originals/f0/48/9c/f0489ceb101a4bb4f8fd6f6f2c9e2762.jpg',
    name: 'AskBot',
    type: 'Advisor',
    _id: '1234r',
  };

  customer = {
    avatar:
      'https://agent-profile-pics.s3.ap-south-1.amazonaws.com/default-customer.png',
    name: '',
    type: 'Customer',
    _id: '1',
  };

  _NewMessages = [
    {
      messages: [
        {
          createdAt: this.today,
          data: 'What is your relationship to this person ?',
          messageType: 'Automated',
          last_message: 'This is a Test Message 1',
          step: null,
          sub_step: null,
          user: {...this.systemUser},
          text: 'what is your relationship to this person ?',
          type: 'text',
          _id: '1234',
        },
      ],
      actions: [
        {
          createdAt: this.today,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'Child',
                value: {
                  action: SEND_RELATIONSHIP,
                  option: '113131',
                },
              },
              {
                title: 'Partner',
                value: {
                  action: SEND_RELATIONSHIP,
                  option: '113131',
                },
              },
              {
                title: 'Parent',
                value: {
                  action: SEND_RELATIONSHIP,
                  option: '113131',
                },
              },
              {
                title: 'Other',
                value: {
                  action: SEND_RELATIONSHIP,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b163-ec00a257ac0f',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e722-4a62-9d71-0090f664a8bP',
          createdAt: this.today,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          data: ' First Name ?',
          last_message: ' First Name  ?',
          step: null,
          sub_step: null,
          text: ' First Name  ?',
        },
      ],
      actions: [
        {
          createdAt: this.today,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'FirstName',
                value: {
                  action: ENTER_FIRST_NAME,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b163-ec00a257ac0E',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b8',
          createdAt: this.today,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          data: ' Last name ?',
          last_message: ' Last name ?',
          step: null,
          sub_step: null,
          text: ' Last name ?',
        },
      ],
      actions: [
        {
          createdAt: this.today,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'LastName',
                value: {
                  action: ENTER_LAST_NAME,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b163-ec00a257ac08',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b7',
          createdAt: this.today,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          data: ' sex at Birth ? ',
          last_message: ' sex at Birth ?',
          step: null,
          sub_step: null,
          text: ' sex at Birth ?',
        },
      ],
      actions: [
        {
          createdAt: this.today,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'Male',
                value: {
                  action: ENTER_GENDER,
                  option: '113131',
                },
              },
              {
                title: 'Female',
                value: {
                  action: ENTER_GENDER,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b163-ec00a257ac07',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b71',
          createdAt: this.today,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          data: 'date of Birth ? ',
          last_message: 'date of Birth ? ',
          step: null,
          sub_step: null,
          text: ' date of Birth ? ',
        },
      ],
      actions: [
        {
          createdAt: this.today,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'YYYY/MM/DD',
                value: {
                  action: ENTER_BIRTHDATE,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b163-ec00a257ac071',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b70',
          createdAt: this.today,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          data: ' primary Residence ? ',
          last_message: ' Partners primary Residence ?',
          step: null,
          sub_step: null,
          text: ' primary Residence ?',
        },
      ],
      actions: [
        {
          createdAt: this.today,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'Country',
                value: {
                  action: ENTER_COUNTRY,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b163-ec00a257ac070',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b716',
          createdAt: this.today,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          data: ' Primary residence ? ',
          last_message: ' Primary residence ? ',
          step: null,
          sub_step: null,
          text: ' Primary residence ?',
        },
      ],
      actions: [
        {
          createdAt: this.today,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'Province',
                value: {
                  action: ENTER_PROVINCE,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b163-ec00a257ac0716',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b718',
          createdAt: this.today,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          data: ' residence ? ',
          last_message: '  primary residence ? ',
          step: null,
          sub_step: null,
          text: ' residence ?',
        },
      ],
      actions: [
        {
          createdAt: this.today,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'City',
                value: {
                  action: ENTER_CITY,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b163-ec00a257ac0718',
        },
      ],
    },
    {
      messages: [
        {
          _id: '4bbea3d1-e722-4a62-9d71-0090f664a8b719',
          createdAt: this.today,
          user: {...this.systemUser},
          messageType: 'Automated',
          type: 'text',
          data: 'Sucessfull',
          last_message: 'Sucessfull',
          step: null,
          sub_step: null,
          text: 'Successfull',
        },
      ],
      actions: [
        {
          createdAt: this.today,
          quickReplies: {
            type: 'radio',
            values: [
              {
                title: 'G0 Back',
                value: {
                  action: 'GO_BACK',
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b163-ec00a257ac0719',
        },
      ],
    },
  ];

  _snapShotForStoring = {};

  getinitialMessages = (dependency = {}) => {
    return new Promise((resolve, reject) => {
      const messages = [...this._NewMessages[0].messages];
      if (dependency.hasDependency) {
        let {messageIndex, key, value} = dependency;
        messages[messageIndex][key] = value + messages[messageIndex][key];
      }
      resolve({
        messages: messages,
        actions: this._NewMessages[0].actions,
      });
    });
  };

  _createAndStoreData = (key, data) => {
    let objectToBeMergerged = {
      [key]: data,
    };
    this._snapShotForStoring = {
      ...this._snapShotForStoring,
      ...objectToBeMergerged,
    };
  };

  createUserMessage = (data) => {
    const obj = {
      _id: Math.random(),
      createdAt: this.today,
      user: {
        _id: '1',
        name: '',
        avatar: '',
        type: 'Customer',
      },
      messageType: 'Automated',
      type: data.type,
      data: data.data,
      last_message: data.last_message,
      step: null,
      sub_step: null,
      text: data.text,
    };

    return obj;
  };

  actionMapper = (
    messageObj,
    {userId = null, token = null, platformUrl = null, username = null},
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {action, data} = messageObj;

        switch (action) {
          case SEND_RELATIONSHIP:
            this._createAndStoreData('relationship', data);
            break;
          case ENTER_GENDER:
            this._createAndStoreData('sex_at_birth', data);
            break;
          case SUBMIT_COUNTRY:
            this._createAndStoreData('country', data);
            break;
          case SUBMIT_PROVINCE:
            this._createAndStoreData('state', data);
            break;
          case SUBMIT_BIRTH_DATE_AND_TIME:
            this._createAndStoreData('date_of_birth', data);
            break;
          case ENTER_FIRST_NAME:
            this._createAndStoreData('first_name', data);
            this._createAndStoreData('username', data);
            this._createAndStoreData('nickname', data);
            break;
          case ENTER_LAST_NAME:
            this._createAndStoreData('last_name', data);
            break;
          case SUBMIT_DATA:
            return;
          default:
        }

        resolve('resolved');
      } catch (e) {
        reject(e);
      }
    });
  };

  _sendMessages = (msgs) => {};

  mergeValueWithArray = (
    callBack,
    limit,
    data,
    dependency,
    {userId = null, token = null, platformUrl = null, username = null},
  ) => {
    const messages = [...this._NewMessages[limit].messages];

    // Setting The Typed Name Dynamically to the Message

    if (dependency.hasDependency) {
      let {messageIndex, key, value} = dependency;
      messages[messageIndex][key] = value + messages[messageIndex][key];
    }

    const createdMessage = this.createUserMessage(data);
    messages.push(createdMessage);
    const mergeMessages = [...messages];
    const objTobePassed = {
      messages: mergeMessages,
      actions: this._NewMessages[limit].actions
        ? this._NewMessages[limit].actions
        : [],
    };
    this.actionMapper(data, {userId, token, platformUrl, username}).then(
      (res) => {
        callBack(objTobePassed);
      },
    );
  };
}
