import React, {PropsWithChildren} from 'react';
import {StyleSheet, ViewStyle, ScrollView} from 'react-native';

interface IScrollLayoutProps {
  style?: ViewStyle;
  justifyContent?:
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'space-evenly'
    | 'space-around'
    | 'space-between';
  flexGrow?: number;
}

export function ScrollLayout({
  children,
  style,
  justifyContent = 'flex-start',
  flexGrow = 0,
}: PropsWithChildren<IScrollLayoutProps>) {
  return (
    <ScrollView
      style={[styles.container, style]}
      bounces={false}
      contentContainerStyle={{
        justifyContent,
        flexGrow,
      }}>
      {children}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
