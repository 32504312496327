import React, {PropsWithChildren} from 'react';
import {TextStyle} from 'react-native';
import {
  Colors,
  MARKPRO_BOLD,
  MARKPRO_BOOK,
  MARKPRO_MEDIUM,
  Typography,
} from 'utils';
import {ITextBaseProps, TextBase} from './TextBase';

interface ITextSubtitleProps extends ITextBaseProps {
  variant?: 'normal' | 'medium' | 'bold';
  size?: 'subtitle1' | 'subtitle2' | 'subtitle3' | 'subtitle4';
  color?: string;
  style?: TextStyle;
}

const fontFamily = {
  normal: MARKPRO_BOOK,
  medium: MARKPRO_MEDIUM,
  bold: MARKPRO_BOLD,
};

const fontSize = {
  subtitle1: Typography.subtitle1,
  subtitle2: Typography.subtitle2,
  subtitle3: Typography.subtitle3,
  subtitle4: Typography.subtitle4,
};

export function TextSubtitle({
  children,
  onPress,
  numberOfLines,
  ellipsizeMode,
  style,
  variant = 'normal',
  size = 'subtitle1',
  color = Colors.black,
}: PropsWithChildren<ITextSubtitleProps>) {
  return (
    <TextBase
      onPress={onPress}
      numberOfLines={numberOfLines}
      ellipsizeMode={ellipsizeMode}
      style={[
        {
          fontFamily: fontFamily[variant],
          fontSize: fontSize[size],
          color,
        },
        style as any,
      ]}>
      {children}
    </TextBase>
  );
}
