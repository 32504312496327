import axios from 'axios';
import {
  ADD_PATIENT_PROBLEMS,
  ADD_PATIENT_RISK_FACTORS,
  APPLICATION_JSON,
  CREATE_PATIENT_PROFILE,
  GET_PATIENT_ALLERGIES,
  GET_PATIENT_MEDICATIONS,
  GET_PATIENT_PROBLEMS,
  GET_PATIENT_RISK_FACTORS,
  MedProfileStatus,
  UPDATE_PATIENT_PROFILE,
} from 'utils';

// =====================================================
// Create Patient Profiles
// =====================================================
export const createPatientProfile = async (
  customerProfile,
  platformUrl,
  token,
) => {
  const url = platformUrl + CREATE_PATIENT_PROFILE;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {
    collection_id: null,
    firstname: customerProfile.firstname,
    nickname: customerProfile.nickname,
    lastname: customerProfile.lastname,
    username: customerProfile.username,
    date_of_birth: customerProfile.date_of_birth,
    sex_at_birth: customerProfile.sex_at_birth === 'Female' ? 'F' : 'M',
  };

  return new Promise((resolve, reject) => {
    axios
      .put(url, JSON.stringify(body), config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// =====================================================
// Update Patient Profiles
// =====================================================
export const updatePatientProfile = async (
  customerProfile,
  platformUrl,
  token,
) => {
  const url = platformUrl + UPDATE_PATIENT_PROFILE;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  const body = {
    patient_id: customerProfile.patient_id,
    location: customerProfile.country,
    date_of_birth: customerProfile.date_of_birth,
    sex_at_birth: customerProfile.sex_at_birth === 'Female' ? 'F' : 'M',
  };

  return axios.put(url, body, config);
};

// =====================================================
// Check Med Profiles Status
// =====================================================
export const checkMedProfileStatus = (customerProfile) => {
  let result = {
    med_profile_status: null,
    med_profile_percentage: null,
  };

  let total = 20;

  if (customerProfile?.problems_entered) {
    total += 20;
  }

  if (customerProfile?.family_history_entered) {
    total += 20;
  }

  if (customerProfile?.current_medical_entered) {
    total += 20;
  }

  if (customerProfile?.travel?.length > 0) {
    total += 20;
  }

  result.med_profile_status = MedProfileStatus.Started.index;
  result.med_profile_percentage = total;

  return {
    ...customerProfile,
    percentage: result,
  };
};

// =====================================================
// Add Patient Problems
// =====================================================
export const addPatientProblems = async (
  customerProfile,
  problemProfileItemList,
  platformUrl,
  token,
) => {
  let url = platformUrl + ADD_PATIENT_PROBLEMS;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {
    collection_id: null,
    patient_id: customerProfile.patient_id,
    problems: problemProfileItemList,
  };

  return axios.put(url, body, config);
};

// =====================================================
// Add Family History
// =====================================================
export const addFamilyHistory = async (
  customerProfile,
  problemProfileItemList,
  platformUrl,
  token,
) => {
  let url = platformUrl + ADD_PATIENT_RISK_FACTORS;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {
    collection_id: null,
    patient_id: customerProfile.patient_id,
    risk_factors: problemProfileItemList,
  };

  return axios.put(url, body, config);
};

// =====================================================
// Get Patient Problems
// =====================================================
export const getPatientProblems = async (
  customerProfile,
  platformUrl,
  token,
) => {
  let url = platformUrl + GET_PATIENT_PROBLEMS;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {
    collection_id: null,
    patient_id: customerProfile.patient_id,
  };

  return axios.put(url, body, config);
};

// =====================================================
// Get Patient Medications
// =====================================================
export const getPatientMedications = async (
  customerProfile,
  platformUrl,
  token,
) => {
  let url = platformUrl + GET_PATIENT_MEDICATIONS;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {
    collection_id: null,
    patient_id: customerProfile.patient_id,
  };

  return axios.put(url, body, config);
};

// =====================================================
// Get Patient Allergies
// =====================================================
export const getPatientAllergies = async (
  customerProfile,
  platformUrl,
  token,
) => {
  let url = platformUrl + GET_PATIENT_ALLERGIES;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {
    collection_id: null,
    patient_id: customerProfile.patient_id,
  };

  return axios.put(url, body, config);
};

// =====================================================
// Get Patient Risk Factors
// =====================================================
export const getPatientRiskFactors = async (
  customerProfile,
  platformUrl,
  token,
) => {
  let url = platformUrl + GET_PATIENT_RISK_FACTORS;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {
    collection_id: null,
    patient_id: customerProfile.patient_id,
  };

  return axios.put(url, body, config);
};

// =====================================================
// Remove Patient Problems
// =====================================================
export const removePatientProblems = async (
  customerProfile,
  problemProfileItemList,
  platformUrl,
  token,
) => {
  let url = platformUrl + ADD_PATIENT_PROBLEMS; //REMOVE_PATIENT_PROBLEMSÍ; - use add endpoint to remove - present: false

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {
    collection_id: null,
    patient_id: customerProfile.patient_id,
    problems: problemProfileItemList,
  };

  return axios.put(url, body, config);
};

// =====================================================
// Remove Family History
// =====================================================
export const removeFamilyHistory = async (
  customerProfile,
  familyHistoryList,
  platformUrl,
  token,
) => {
  let url = platformUrl + ADD_PATIENT_RISK_FACTORS; //REMOVE_PATIENT_PROBLEMS; - use add endpoint to remove - present: false

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {
    collection_id: null,
    patient_id: customerProfile.patient_id,
    risk_factors: familyHistoryList,
  };

  return axios.put(url, body, config);
};
