import React, {PropsWithChildren} from 'react';
import {
  Colors,
  MARKPRO_BOLD,
  MARKPRO_BOOK,
  MARKPRO_MEDIUM,
  Typography,
} from 'utils';
import {ITextBaseProps, TextBase} from './TextBase';
interface ITextHeadingProps extends ITextBaseProps {
  variant?: 'normal' | 'medium' | 'bold';
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  color?: string;
  textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
}

const fontFamily = {
  normal: MARKPRO_BOOK,
  medium: MARKPRO_MEDIUM,
  bold: MARKPRO_BOLD,
};

const fontSize = {
  h1: Typography.h1,
  h2: Typography.h2,
  h3: Typography.h3,
  h4: Typography.h4,
  h5: Typography.h5,
};

export function TextHeading({
  children,
  onPress,
  numberOfLines,
  ellipsizeMode,
  style,
  variant = 'normal',
  size = 'h1',
  color = Colors.black,
  textAlign = 'auto',
}: PropsWithChildren<ITextHeadingProps>) {
  return (
    <TextBase
      onPress={onPress}
      numberOfLines={numberOfLines}
      ellipsizeMode={ellipsizeMode}
      style={[
        {
          fontFamily: fontFamily[variant],
          fontSize: fontSize[size],
          color,
          textAlign,
        },
        style as any,
      ]}>
      {children}
    </TextBase>
  );
}
