import {useFocusEffect} from '@react-navigation/core';
import {WelcomeScreenTopBar} from 'components/reusables/components/SVGS';
import {isAnonymousFlow, isWeb} from 'config';
import {Button, Icon, Image, Text as TextLibs} from 'libs';
import {ScreenNames, useUserPrivacy} from 'modules';
import React, {useEffect, useRef, useState} from 'react';
import {Dimensions, ScrollView, StyleSheet, Text, View} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import SplashScreen from 'react-native-splash-screen';
import {
  changeAndroidStatusBarColor,
  Colors,
  EventType,
  heightToDp,
  singletonAuthMessage,
  textStyles,
  Typography,
  useAnalytics,
  viewStyles,
  widthToDp,
} from 'utils';
import {
  CARA_CALM,
  CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
  CARA_HAPPY_EYES_OPEN_MOUTH_OPEN,
} from '../../../assets/images/cara/CaraFaces';
import {BackIcon, IOSStatusBar} from '../../../components';

const MESSAGES = [
  {
    text: "Hi! I'm a dynamic virtual assistant, trained by a global team of doctors to help you assess your health. But you can call me Cara!",
    delay: 500,
  },
  {
    text: "I'm here for you.",
    delay: 1500,
  },
  {
    text: 'Any time, anywhere.',
    delay: 2000,
  },
];

const TitleBarWithBack = ({goBack, messageStore}) => (
  <View style={styles.titleBar}>
    {!isAnonymousFlow ? (
      <View style={styles.backIconView}>
        <BackIcon goBack={goBack} color={'white'} messageStore={messageStore} />
      </View>
    ) : null}
    <Text style={styles.meetCara}>Meet Cara</Text>
  </View>
);

const BottomButton = ({props}) => {
  const [disabled, setDisabled] = useState(true);
  const [hideArrow, setHideArrow] = useState(true);
  const [userPrivacyChecked] = useUserPrivacy();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisabled(false);
      setHideArrow(false);
    }, 4500);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <View style={[styles.optionsButtonView]}>
      <Button.Primary
        title="Continue"
        onPress={() => {
          const route =
            isAnonymousFlow && userPrivacyChecked
              ? ScreenNames.AssessmentChat
              : ScreenNames.Privacy;
          props.navigation.navigate(route);
        }}
        disabled={disabled}
        icon={<Icon.Arrow color="white" />}
        iconRight={true}
      />
    </View>
  );
};

const ChatBubble = React.memo(({text}) => {
  const height = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      height: height.value,
    };
  });

  const onLayoutEvent = (event) => {
    const value = event.nativeEvent.layout.height + heightToDp(2.5);
    height.value = withSpring(value, {damping: 13});
  };

  return (
    <Animated.View style={[animatedStyle]}>
      <View style={[styles.middleChatBubble]} onLayout={onLayoutEvent}>
        <Text style={[styles.animatedTextStyle]}>{text}</Text>
      </View>
    </Animated.View>
  );
});

export default function WelcomeScreen(props) {
  const [msgs, setMsgs] = useState([]);
  const initialWindowWidth = Dimensions.get('window').width + 5;
  const viewWidth = 400;
  const initialHeight = (initialWindowWidth * 35) / viewWidth;
  const scrollRef = useRef();
  const [caraImg, setCaraImg] = useState(CARA_HAPPY_EYES_OPEN_MOUTH_OPEN);
  const {logEvent} = useAnalytics();
  const scale = useSharedValue(0);
  const animatedCaraStyle = useAnimatedStyle(() => {
    return {
      transform: [{scaleX: scale.value}, {scaleY: scale.value}],
    };
  });

  useFocusEffect(
    React.useCallback(() => {
      !isWeb && changeAndroidStatusBarColor(Colors.primary);
    }, []),
  );

  useEffect(() => {
    if (isAnonymousFlow && !isWeb) {
      SplashScreen.hide();
    }
  }, []);
  useEffect(() => {
    logEvent(EventType.APP_OPEN, {timestamp: Date.now()});
  }, []);

  useEffect(() => {
    scale.value = withDelay(
      100,
      withTiming(1, {
        duration: 600,
      }),
    );
    const timer1 = setTimeout(() => {
      setCaraImg(CARA_CALM);
    }, 2300);
    const timer2 = setTimeout(() => {
      setCaraImg(CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE);
    }, 4000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  useEffect(() => {
    if (msgs.length < MESSAGES.length) {
      const msg = MESSAGES[msgs.length];
      const timer = setTimeout(() => {
        setMsgs([...msgs, msg.text]);
      }, msg.delay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [msgs]);

  return (
    <View style={[styles.welcomeScreenContainer]}>
      <IOSStatusBar />
      <View style={styles.container}>
        <View style={[styles.titleBarWithBackView, viewStyles.width100P]}>
          <TitleBarWithBack
            goBack={props.navigation.goBack}
            messageStore={singletonAuthMessage}
          />
        </View>
        {!isWeb ? (
          <WelcomeScreenTopBar
            calculateHeight={initialHeight}
            windowWidth={initialWindowWidth}
          />
        ) : null}

        <ScrollView
          ref={scrollRef}
          contentContainerStyle={[styles.contentContainerStyle]}
          onContentSizeChange={() =>
            scrollRef.current.scrollToEnd({animated: false})
          }>
          <View style={styles.middleChat}>
            <View style={styles.chatContainer}>
              {msgs.map((msg, index) => (
                <ChatBubble text={msg} key={index} />
              ))}
            </View>
            <View style={styles.caraFace}>
              <Animated.Image
                source={caraImg}
                style={[styles.animatedImage, animatedCaraStyle]}
                resizeMode={'contain'}
              />
            </View>
          </View>
        </ScrollView>
        <BottomButton props={props} />

        <View style={styles.poweredByLogoContainer}>
          <TextLibs.Body style={styles.poweredByText}>Powered by</TextLibs.Body>
          <Image.TreatmentLogo style={styles.treatmentLogoStyle} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  animatedImage: {height: heightToDp(10), left: -225},
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  titleBarWithBackView: {
    backgroundColor: Colors.primary,
    height: 50,
    width: '100%',
    justifyContent: 'center',
  },
  welcomeScreenContainer: {flex: 1},
  animatedTextStyle: {
    fontSize: widthToDp(4),
    fontFamily: 'MarkPro-Book',
    lineHeight: widthToDp(8),
    color: Colors.textBlackColor,
  },
  optionsButtonView: {
    paddingHorizontal: widthToDp(5),
    paddingBottom: widthToDp(9),
  },
  safeArea: {flex: 1},
  chatContainer: {
    overflow: 'hidden',
  },
  middleChat: {
    flexDirection: 'column',
    paddingHorizontal: 20,
    justifyContent: 'flex-end',
    flex: 1,
    marginBottom: heightToDp(4),
  },
  middleChatBubble: {
    position: 'absolute',
    borderTopLeftRadius: 26,
    borderTopRightRadius: 26,
    borderBottomEndRadius: 26,
    borderBottomLeftRadius: 8,
    backgroundColor: Colors.systemChatBubblesColor,
    padding: widthToDp(6),
    marginTop: heightToDp(2.5),
    alignSelf: 'baseline',
  },
  caraFace: {
    paddingTop: 20,
    paddingLeft: 15,
  },
  meetCara: {
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontSize: 18,
    fontFamily: 'MarkPro-Medium',
  },
  backIconView: {
    height: 40,
    width: 40,
    position: 'absolute',
    left: 13,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleBar: {
    height: 40,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  margin: {
    marginTop: 20,
    marginBottom: 20,
  },
  circularView: {
    height: 100,
    width: 100,
    padding: 20,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
  },
  buttonView: {
    bottom: 10,
    padding: 20,
  },
  poweredByLogoContainer: {
    flexDirection: 'row',
    paddingBottom: widthToDp(10),
    justifyContent: 'center',
  },
  poweredByText: {
    marginRight: 6,
    fontWeight: '400',
    ...textStyles.fontFamilyMARKPRO_BOOK,
    fontSize: Typography.caption1,
    color: Colors.lightGreen,
  },
  treatmentLogoStyle: {
    width: 90,
    height: 17,
    resizeMode: 'contain',
  },
});
