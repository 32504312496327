import {IS_USERS_FIRST_TIME, SET_BIOMETRIC_ON} from '../action-types';

const initialState = {
  isUsersFirstTime: true,
  isBiometricOn: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_USERS_FIRST_TIME:
      return {...state, isUsersFirstTime: action.payload};
    case SET_BIOMETRIC_ON:
      return {...state, isBiometricOn: action.payload};
    default:
      return state;
  }
}
