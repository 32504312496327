import React from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {Colors} from 'utils';

export default function RadioButton(props) {
  const styleColor = {
    borderColor: !props.selected ? Colors.black : Colors.transparent,
    backgroundColor: props.selected ? Colors.primary : 'white',
  };
  return (
    <Pressable
      onPress={props.select}
      style={[styles.radioButtonPressable, styleColor, props.style]}>
      {props.selected ? <View style={[styles.radioButtonSelected]} /> : null}
    </Pressable>
  );
}
const styles = StyleSheet.create({
  radioButtonSelected: {
    height: 13,
    width: 13,
    borderRadius: 10,
    backgroundColor: '#FFF',
  },
  radioButtonPressable: {
    height: 30,
    width: 30,
    borderRadius: 20,
    borderWidth: 1,

    alignItems: 'center',

    justifyContent: 'center',
  },
});
