import React, {useEffect} from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  View,
  ActivityIndicator,
} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import Animated, {
  useSharedValue,
  withTiming,
  withDelay,
  useAnimatedStyle,
} from 'react-native-reanimated';
import {MARKPRO_BOLD} from 'utils';

export default function DefaultButton({
  click,
  iconColor,
  name,
  style = {},
  disabled = false,
  hideArrow = true,
  isLoading = false,
}) {
  const animatedX = useSharedValue(10);
  const animatedTextStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: animatedX.value,
        },
      ],
    };
  });

  const animatedOpacity = useSharedValue(0);
  const animatedIconStyle = useAnimatedStyle(() => {
    return {
      opacity: animatedOpacity.value,
    };
  });

  useEffect(() => {
    if (hideArrow) {
      animatedX.value = withDelay(100, withTiming(10, {duration: 500}));
      animatedOpacity.value = withDelay(100, withTiming(0, {duration: 500}));
    } else {
      animatedX.value = withDelay(100, withTiming(0, {duration: 500}));
      animatedOpacity.value = withDelay(100, withTiming(1, {duration: 500}));
    }
  }, [hideArrow]);

  const colors = {backgroundColor: disabled ? '#FF8C64' : '#E9662F'};

  return (
    <TouchableOpacity
      onPress={click}
      style={[styles.button, colors, {...style}]}
      disabled={disabled || isLoading}>
      <View style={styles.buttonContent}>
        {isLoading ? (
          <ActivityIndicator color={iconColor} />
        ) : (
          <>
            <Animated.Text style={[styles.text, animatedTextStyle]}>
              {name}
            </Animated.Text>
            <Animated.View style={animatedIconStyle}>
              <Icon
                style={[styles.iconStyle]}
                name={'arrowright'}
                size={17}
                color={'white'}
              />
            </Animated.View>
          </>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  iconStyle: {marginTop: 3, fontWeight: 'bold'},
  buttonContent: {flexDirection: 'row', alignItems: 'center'},
  button: {
    flexDirection: 'row',
    height: 50,
    borderRadius: 18,
    alignItems: 'center',
    margin: 2,
    backgroundColor: '#E9662F',
    shadowColor: '#E9662F',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.35,
    shadowRadius: 4.65,
    elevation: 7,
    justifyContent: 'center',
  },
  text: {
    fontSize: 17,
    fontFamily: MARKPRO_BOLD,
    color: 'white',
    marginRight: 5,
  },
});
