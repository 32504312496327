import React from 'react';
import {TextInput} from 'react-native-gesture-handler';
import Animated, {useAnimatedProps} from 'react-native-reanimated';

const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

export const AnimatedText = ({animatedValue, style}) => {
  const animatedProps = useAnimatedProps(() => {
    return {
      text: `${animatedValue.value}`,
    };
  });

  return (
    <AnimatedTextInput
      style={style}
      editable={false}
      animatedProps={animatedProps}
    />
  );
};
