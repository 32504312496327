export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTH_USER = 'AUTH_USER';
export const IS_PLATFORM_CHECK = 'IS_PLATFORM_CHECK';
export const HAS_PLATFORM_ERROR = 'HAS_PLATFORM_ERROR';

// Application - Platform Login
export const LOGIN_PLATFORM = 'LOGIN_PLATFORM';
export const LOGIN_PLATFORM_FAILED = 'LOGIN_PLATFORM_FAILED';
export const PLATFORM_URL = 'PLATFORM_URL';
export const PLATFORM_TOKEN = 'PLATFORM_TOKEN';
export const ASSESSMENT_TOKEN = 'ASSESSMENT_TOKEN';
export const SET_INITIAL_TAB_SCREEN = 'SET_INITIAL_TAB_SCREEN';
export const SET_IS_USER_STARTING_AN_ASSESSMENT =
  'SET_IS_USER_STARTING_AN_ASSESSMENT';

// Application - Initialize ClinBOT
export const INITIALIZE_CLIN_BOT = 'INITIALIZE_CLIN_BOT';
export const INITIALIZE_CLIN_BOT_FAILED = 'INITIALIZE_CLIN_BOT_FAILED';

//DxSession
export const REFRESH_ASSESSMENT_HISTORY = 'REFRESH_ASSESSMENT_HISTORY';
export const SET_DID_YOU_KNOWS = 'SET_DID_YOU_KNOWS';

export const IS_USERS_FIRST_TIME = 'IS_USERS_FIRST_TIME';
export const SET_BIOMETRIC_ON = 'SET_BIOMETRIC_ON';

export const SYSTEM_INITIALIZED_SUCCESS = 'SYSTEM_INITIALIZED_SUCCESS';
export const SYSTEM_INITIALIZED_FAILED = 'SYSTEM_INITIALIZED_FAILED';


export const SET_BOOTSTRAP_URLS = 'SET_BOOTSTRAP_URLS';