import React, {useEffect, useState} from 'react';
import {View, StyleSheet, Text} from 'react-native';
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  withSpring,
  useSharedValue,
} from 'react-native-reanimated';
import {Colors} from 'utils';
import {PanGestureHandler} from 'react-native-gesture-handler';
import {round, snapPoint} from 'react-native-redash';
import {clamp} from 'utils';
import 'react-native-gesture-handler';
import SeverityTextTitle from '../components/SeverityTextTitle';
import {MARKPRO_BOLD} from 'utils';
import {viewStyles} from 'utils';

const SeverityText = ({severity, renderSeverityText}) => {
  if (renderSeverityText) {
    return renderSeverityText(severity);
  }

  return <SeverityTextTitle severity={severity} />;
};

function AnimatedSideBar({
  style = {},
  WIDTH,
  additionalData,
  symptomRange = {symptom: {}, tracks: []},
  circleImage,
  beizereCurveImage,
  rulerColor,
  onDragEnd,
  renderSeverityText,
}) {
  useEffect(() => {
    const severity =
      symptomRange?.tracks == null
        ? 0
        : symptomRange?.tracks[symptomRange?.tracks?.length - 1]?.severity;
    index.value = severity;
    x.value = withSpring(itemWidth * severity);
    setInitialValue(severity);
    setPreviousValue(severity);
  }, [
    symptomRange?.tracks?.length,
    symptomRange,
    symptomRange.symptom?.sdco_id,
  ]);

  const updateSeverity = (value) => {
    setInitialValue(value);
  };

  const [initialValue, setInitialValue] = useState(0);
  const [previousValue, setPreviousValue] = useState(0);
  const itemWidth = (WIDTH - 100) / 10;
  const snapPoints = new Array(11).fill(0).map((e, i) => i * itemWidth);
  const x = useSharedValue(0);
  const index = useSharedValue(initialValue);
  const opacity = useSharedValue(1);
  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      opacity.value = 0;
      ctx.startX = x.value;
    },
    onActive: (event, ctx) => {
      let value = clamp(ctx.startX + event.translationX, 0, WIDTH - 100);
      const snapPointX = snapPoint(value, 0, snapPoints);
      x.value = withSpring(snapPointX, {velocity: event.velocityX});
      index.value = round(snapPointX / itemWidth);
    },
    onEnd: (event, ctx) => {
      opacity.value = 1;
      const sp = {symptom_track_item: symptomRange, severity: index.value};
      runOnJS(updateSeverity)(index.value);
      runOnJS(onDragEnd)(sp);
    },
  });
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: x.value,
        },
      ],
    };
  });

  return (
    <View
      style={[
        styles.animatedSideBarContainer,
        {backgroundColor: rulerColor, width: WIDTH},
        {...style},
      ]}>
      <View style={[styles.severityTextView, viewStyles.width100P]}>
        <SeverityText
          severity={initialValue}
          renderSeverityText={renderSeverityText}
        />
      </View>

      <View style={styles.animationWrappingView}>
        {Array.from(Array(11).keys()).map((i) => {
          let fontSize;
          let fontColor;

          if (initialValue === i) {
            fontSize = 22;

            switch (initialValue) {
              case 0:
              case 1:
              case 2:
              case 3:
                fontColor = Colors.mild;
                break;
              case 4:
              case 5:
              case 6:
              case 7:
                fontColor = Colors.primary;
                break;
              case 8:
              case 9:
              case 10:
                fontColor = Colors.deleteDarkColor;
                break;
            }
          } else {
            fontSize = 14;
            fontColor = '#ABA6A3';
          }

          return (
            <View key={i} style={[styles.previousValueContainer]}>
              <Text
                key={i}
                style={[
                  styles.sideBarContainer,
                  {
                    width: itemWidth,
                    fontSize: fontSize,
                    color: fontColor,
                  },
                ]}>
                {i}
              </Text>
              {previousValue === i && (
                <View style={styles.previousValueStyle} />
              )}
            </View>
          );
        })}
      </View>
      <PanGestureHandler onGestureEvent={gestureHandler}>
        <Animated.View style={[animatedStyle, styles.animatedView]}>
          <Animated.Image
            source={beizereCurveImage}
            style={[styles.beizereCurveImage]}
            resizeMode={'contain'}
          />

          <Animated.Image
            source={circleImage}
            style={styles.circleImage}
            resizeMode={'contain'}
          />
        </Animated.View>
      </PanGestureHandler>
    </View>
  );
}

const styles = StyleSheet.create({
  circleImage: {
    height: 50,
    width: 50,
    position: 'absolute',
    bottom: 18,
    zIndex: 50,
  },
  beizereCurveImage: {
    height: 100,
    width: 100,
  },
  previousValueStyle: {
    width: 4,
    height: 4,
    backgroundColor: Colors.textBlackColor,
    borderRadius: 5,
  },
  previousValueContainer: {justifyContent: 'center', alignItems: 'center'},
  severityTextView: {
    paddingHorizontal: 37,
    width: '100%',
    alignItems: 'center',
  },
  animatedSideBarContainer: {
    height: 100,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primary,
  },
  textView: {
    padding: 5,
    marginVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 7,
    zIndex: 200,
  },
  animationWrappingView: {
    flexDirection: 'row',
    position: 'absolute',
    left: 37,
    bottom: 28,
    alignItems: 'flex-end',
  },
  sideBarContainer: {
    textAlign: 'center',
    fontSize: 18,
    fontFamily: MARKPRO_BOLD,
  },
  animatedView: {
    position: 'absolute',
    bottom: -38.5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  animatedText: {
    fontSize: 28,
    fontFamily: MARKPRO_BOLD,
    zIndex: 50,
    position: 'absolute',
    top: 2,
  },
});

export default AnimatedSideBar;
