import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {Colors} from 'utils';
import {MARKPRO_BOLD} from 'utils';

export const isSame = (currentMessage, previousMessage) => {
  if (!previousMessage.user) {
    return true;
  }
  if (currentMessage.user._id !== previousMessage.user._id) {
    return true;
  }
  return false;
};

export default function TextMessage({
  _onQuickReply,
  currentMessage,
  previousMessage,
  position,
}) {
  if (position === 'left') {
    return (
      <View>
        {typeof currentMessage.text === 'string' ? (
          <View
            style={[
              styles.bubble,
              styles.bubbleLeft,
              styles.bubbleRadius,
              styles.leftBubbleText,
            ]}>
            <Text style={styles.textPrimary}>{currentMessage.text}</Text>
          </View>
        ) : null}
      </View>
    );
  }

  if (typeof currentMessage.text === 'string') {
    return (
      <View style={[styles.bubble, styles.bubbleRight, styles.bubbleRadius]}>
        <Text
          style={[
            styles.textPrimary,
            styles.bubbleTextWhite,
            styles.rightBubbleText,
            styles.bubbleTextPrimary,
          ]}>
          {currentMessage.text}
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  textPrimary: {
    color: 'rgba(54,54,54,1)',
    fontSize: 16,
  },
  leftBubbleText: {
    lineHeight: 20,
  },
  rightBubbleText: {
    lineHeight: 22,
    textAlign: 'center',
  },
  bubble: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    minHeight: 50,
  },
  bubbleTextPrimary: {
    color: Colors.primary,
    fontFamily: MARKPRO_BOLD,
    fontSize: 16,
  },
  bubbleRadius: {
    borderBottomLeftRadius: 0,
  },
  bubbleRight: {
    borderColor: Colors.userChatBubblesColor,
    backgroundColor: Colors.userChatBubblesColor,
  },
  bubbleLeft: {
    backgroundColor: Colors.systemChatBubblesColor,
  },

  bubbleTextWhite: {
    color: 'white',
  },
  userName: {
    color: 'lightgrey',
    fontSize: 10,
    marginBottom: 8,
  },
});
