import {useFocusEffect} from '@react-navigation/native';
import {isWeb} from 'config';
import {Icon, Text} from 'libs';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import SafeAreaView from 'react-native-safe-area-view';
import {connect} from 'react-redux';
import {
  changeAndroidStatusBarColor,
  Colors,
  TopBarSvg,
  TopIcon,
  viewStyles,
} from 'utils';

function DataAndMethodologyScreen(props: any) {
  useFocusEffect(
    React.useCallback(() => {
      changeAndroidStatusBarColor(Colors.topPurple);
    }, []),
  );

  return (
    <View style={[styles.privacyPolicyScreenContainer]}>
      <SafeAreaView
        style={[styles.safeAreaView]}
        forceInset={{bottom: 'never'}}>
        <View style={styles.headerContainer}>
          <View style={styles.headerTitleContainer}>
            <Text.Subtitle variant="medium" size="subtitle3">
              Data and Methodology
            </Text.Subtitle>
          </View>
          <TopIcon style={{...viewStyles.zIndex100}} />
          <View style={styles.iconCloseContainer}>
            <Icon.Close
              onPress={props.navigation.goBack}
              size={Icon.Size.Medium}
            />
          </View>
        </View>
        {!isWeb ? <TopBarSvg fillColor={Colors.topPurple} /> : null}
        <ScrollView
          style={styles.container}
          contentContainerStyle={styles.contentContainer}>
          <Text.Subtitle variant="bold" style={styles.subTitle}>
            Who is Treatment?
          </Text.Subtitle>

          <Text.Body>
            Treatment is a team of physicians, engineers, mathematicians, and
            computer experts who are committed to the mission of improving
            everyone’s access to health information. We work closely with other
            doctors, health care professionals, and our customers to develop
            innovative products and services that provide our users with
            relevant, personalized information about their symptoms based on
            standard and broadly accepted Western medical approaches.
          </Text.Body>

          <Text.Subtitle variant="bold" style={styles.subTitle}>
            What is the Global Library of Medicine?
          </Text.Subtitle>

          <Text.Body>
            The Global Library of Medicine (GLM) is an online medical library
            built specifically to be accessible by modern computer technology.
            The GLM was developed in cooperation with a leading US Medical
            School and includes data entered by experienced medical doctors from
            around the world. The GLM provides a human reviewable and constantly
            updated data resource about a wide variety of diseases. Each disease
            description includes the cause, anatomy, geographic incidence,
            symptoms, risk factors, physical findings, lab tests, standardized
            assessment processes, and radiologic findings expected in the
            disease. Disease information is coded into industry standard
            hierarchies and ontologies. All doctors contributing to the GLM have
            been credentialed by Treatment, and have a medical degree from a
            medical school approved by the World Health Organization and
            recognized through the World Federation for Medical Education and
            the Foundation for Advancement of International Medical Education
            and Research. All data is curated and reviewed by other medical
            reviewers before being accepted by the GLM. GLM data is used for a
            variety of purposes, including training medical students, and
            assisting medical doctors in their usual tasks.
          </Text.Body>

          <Text.Subtitle variant="bold" style={styles.subTitle}>
            Where does the medical data come from?
          </Text.Subtitle>

          <Text.Body>
            The information in the GLM is originally extracted from standard
            medical textbooks, research articles, and widely accepted online
            resources such as the US Center for Disease Prevention and Control.
            The source of medical information used for each disease description
            is cited at the bottom of each disease description page and in the
            Settings tab under Sources. GLM credentialed medical doctors search
            out the latest data about a disease from reliable sources and enter
            it into the GLM Knowledgebase. The GLM Knowledge Base includes
            citations of all authors, reviewers, publishers, and medical sources
            of information from everyone authoring, reviewing, or updating GLM
            data. The entire GLM Knowledgebase is available online without
            charge for medical professionals and professional medical
            institutions that join the GLM. The GLM is a rapidly growing medical
            community committed to improving access to health care information.
            Since GLM data uses standard medical terminology to describe
            diseases, a team of professional medical editors, providers, medical
            translators, and users have translated medical terms and jargon into
            plain language.
          </Text.Body>

          <Text.Subtitle variant="bold" style={styles.subTitle}>
            What is the methodology used to generate health assessments?
          </Text.Subtitle>

          <Text.Body>
            A presenting symptom is used to generate a list of all diseases
            included in the dataset that have been identified as a potential
            cause of the symptom. In healthcare, a differential diagnosis
            (abbreviated DDx) is a method of analysis of a patient's history and
            physical examination to arrive at the correct diagnosis. It involves
            distinguishing a particular disease or condition from others that
            present with similar clinical features. We use Bayesian
            probabilistic methods to model the process of creating a DDx. After
            each question is answered, probabilities assigned to each diagnosis
            are updated and additional calculations are performed to identify
            the next most important question to ask given the user’s age, sex,
            location, and previous answers. The assessment process continues to
            ask pertinent questions until additional information is no longer
            considered likely to significantly change the assessment. After an
            initial assessment is complete, the user may be asked about
            additional medical conditions or risks that could influence the
            likelihood of a specific diagnosis. Although these questions are not
            specific to age or sex, the questions help to ensure that other
            important information has not been left out.
          </Text.Body>

          <Text.Subtitle variant="bold" style={styles.subTitle}>
            What do the + and - symbols next to symptoms mean on the results
            page?
          </Text.Subtitle>

          <Text.Body>
            When an assessment is complete, the user is provided with a list of
            conditions that are commonly associated with the combination of
            symptoms entered. Each symptom includes a number of + or - signs to
            indicate the extent that a symptom or risk factor increases or
            decreases the likelihood of a condition for a given age, sex, and
            location.
          </Text.Body>

          <Text.Subtitle variant="bold" style={styles.subTitle}>
            What does the probability next to a disease mean?
          </Text.Subtitle>

          <Text.Body>
            This is an estimate of the probability that the disease is the cause
            of the presenting symptom, under the assumption that actual cause of
            the symptom is a disease contained in the application dataset of
            common diagnoses.
          </Text.Body>

          <View style={styles.leftPaddingContainer}>
            <Text.Subtitle variant="bold" style={styles.subTitle}>
              Does a high probability mean that I have a disease? NO!
            </Text.Subtitle>

            <Text.Body>
              The assessment only considers a limited number of common
              diagnoses. Many diagnoses are not considered and will never
              appear. Please make sure to check with a doctor in addition to
              using the app and before making medical decisions.
            </Text.Body>

            <Text.Subtitle variant="bold" style={styles.subTitle}>
              Does a low probability mean I do not have a disease? NO!
            </Text.Subtitle>

            <Text.Body>
              The probability evaluates whether a set of symptoms represents a
              typical presentation of a disease. Almost any diagnosis can
              present in many different ways. Please make sure to check with a
              doctor in addition to using the app and before making medical
              decisions.
            </Text.Body>

            <Text.Subtitle variant="bold" style={styles.subTitle}>
              How do I interpret the probability of a disease?
            </Text.Subtitle>

            <Text.Body>
              Possible conditions to consider are listed in order of likelihood
              based on Bayesian probability calculations. Given a typical
              presentation of a condition, the probability suggests how often
              people with a specific condition would have symptoms that are
              similar to the ones described by the user. Although the top three
              conditions are important to consider, it is possible for any
              diagnosis to present with symptoms that are not typical. In those
              cases, a disease causing the presenting symptom may be lower than
              the third diagnosis, or not included at all. The presence of one
              or more additional diseases, the presence of chronic conditions,
              or complications of an illness can all cause symptoms that are not
              typically associated with a condition. The presence of more than
              one problem (comorbidity) can lead to errors when evaluating a
              particular set of symptoms. Finally, the dataset focuses on common
              diseases that occur commonly and are often seen in a primary care
              setting. A large number of possible diseases are not included. In
              particular the dataset does not not attempt to provide an
              evaluation of congenital problems or birth defects, mental health
              symptoms, obstetric symptoms (symptoms that occur during
              pregnancy), or problems caused by medicines. Please make sure to
              check with a doctor in addition to using the app and before making
              medical decisions.
            </Text.Body>
          </View>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {flex: 1},
  privacyPolicyScreenContainer: {flex: 1, backgroundColor: Colors.topPurple},
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  contentContainer: {
    padding: 15,
    paddingVertical: 20,
  },
  subTitle: {
    marginVertical: 15,
  },
  headerContainer: {
    height: isWeb ? 100 : 70,
    backgroundColor: Colors.topPurple,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitleContainer: {
    position: 'absolute',
    top: 45,
  },
  iconCloseContainer: {
    position: 'absolute',
    right: 5,
    bottom: 18,
  },
  leftPaddingContainer: {
    paddingLeft: 40,
  },
});

const mapStateToProps = (state: any) => ({
  windowWidth: state.appConfig.windowWidth,
  windowHeight: state.appConfig.windowHeight,
});

export default connect(mapStateToProps)(DataAndMethodologyScreen);
