import React, {useState} from 'react';
import {Button} from 'libs';
import {CommonActions} from '@react-navigation/native';
import {ScreenNames, Modal} from 'modules';

interface INewAssessmentButtonAndModalProps {
  parentNavigation?: any;
  buttonType?: string;
}

export function NewAssessmentButtonAndModal({
  parentNavigation,
  buttonType = 'primary',
}: INewAssessmentButtonAndModalProps) {
  const [showNewAssessmentModal, setShowNewAssessmentModal] = useState(false);

  const newAssessmentHandler = () => {
    parentNavigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [{name: ScreenNames.Welcome}],
      }),
    );
    setShowNewAssessmentModal(false);
  };

  return (
    <>
      {buttonType === 'primary' ? (
        <Button.Primary
          title="Start a new assessment"
          onPress={() => setShowNewAssessmentModal(true)}
        />
      ) : (
        <Button.Secondary
          title="Start a new assessment"
          onPress={() => setShowNewAssessmentModal(true)}
        />
      )}
      <Modal.NewAssessment
        visible={showNewAssessmentModal}
        closePressHandler={() => {
          setShowNewAssessmentModal(false);
        }}
        confirmPressHandler={() => {
          newAssessmentHandler();
        }}
      />
    </>
  );
}
