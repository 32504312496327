import React from 'react';
import {Text, View, Modal, StyleSheet, TouchableOpacity} from 'react-native';
import {Colors} from 'utils';
import IconClose from 'react-native-vector-icons/MaterialIcons';
import {MARKPRO_BOLD, MARKPRO_BOOK, MARKPRO_MEDIUM} from 'utils';
import {viewStyles} from 'utils';

const YesButton = (props) => {
  return (
    <TouchableOpacity style={styles.deleteButton} onPress={props.action}>
      <Text style={styles.deleteText}>Yes</Text>
    </TouchableOpacity>
  );
};

const NoButton = (props) => {
  return (
    <TouchableOpacity style={styles.cancelButton} onPress={props.action}>
      <Text style={styles.cancelText}>No</Text>
    </TouchableOpacity>
  );
};

export default function AssessmentCloseModal({visible, onYes, onNo}) {
  const title = 'Are you sure you want to cancel this assessment?';
  const description =
    'Your symptoms and assessment results will not be saved to your profile, and Cara will not follow up with you.';
  return (
    <Modal
      statusBarTranslucent={true}
      visible={visible}
      transparent
      animationType="slide">
      <View style={styles.container}>
        <View style={styles.centerView}>
          <TouchableOpacity onPress={onNo} style={styles.touchableOpacityStyle}>
            <IconClose
              name="close"
              size={25}
              color={Colors.textDarkGreyColor}
            />
          </TouchableOpacity>
          <View style={[styles.centerViewSubContainer, viewStyles.width100P]}>
            <Text style={styles.title}>{title}</Text>
            <View style={styles.descriptionView}>
              <Text style={styles.description}>{description}</Text>
            </View>
          </View>
          <View style={styles.actionButtonContainer}>
            <YesButton action={onYes} />
            <View style={styles.buttonGap} />
            <NoButton action={onNo} />
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  buttonGap: {padding: 10},
  descriptionView: {height: 95, top: 10},
  touchableOpacityStyle: {position: 'absolute', right: 15, top: 15},
  description: {
    fontFamily: MARKPRO_BOOK,
    fontSize: 15,
    lineHeight: 19,
    color: Colors.textBlackColor,
  },
  title: {
    color: Colors.primary,
    fontSize: 18,
    lineHeight: 22,
    fontFamily: MARKPRO_BOLD,
  },
  centerViewSubContainer: {
    position: 'absolute',
    top: 50,
    paddingHorizontal: 20,
    width: '100%',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  centerView: {
    backgroundColor: Colors.white,
    marginHorizontal: 15,
    height: 270,
    width: '90%',
    borderRadius: 18,
  },

  titleText: {
    fontSize: 22,
    color: Colors.primary,
    fontFamily: MARKPRO_BOLD,
    textAlign: 'justify',
  },
  descriptionText: {
    fontSize: 15,
    color: Colors.textBlackColor,
    fontFamily: MARKPRO_BOOK,
    textAlign: 'justify',
  },
  deleteButton: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: Colors.deleteTextColor,
  },
  deleteText: {
    fontSize: 16,
    color: Colors.deleteTextColor,
    fontFamily: MARKPRO_MEDIUM,
  },
  cancelButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    borderRadius: 18,
    backgroundColor: Colors.primary,
  },
  cancelText: {
    fontSize: 17,
    color: Colors.tickColor,
    fontFamily: MARKPRO_MEDIUM,
  },
  actionButtonContainer: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    padding: 20,
  },
});
