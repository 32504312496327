import {Row} from 'modules';
import React from 'react';
import {MinusOperator, PlusOperator, Spacing} from 'utils';

interface ResultDetailsSignsProps {
  diagnoseMultiplier: number;
}

// This T = 10 is from Cara Assessment Results Presentation document
const T = 10;

export function ResultDetailsSigns({
  diagnoseMultiplier,
}: ResultDetailsSignsProps) {
  let pluses = 0;
  let minuses = 0;

  // These formulas are from Cara Assessment Results Presentation document
  if (diagnoseMultiplier === 1) {
    return null;
  } else if (1 < diagnoseMultiplier && diagnoseMultiplier <= T) {
    pluses = 1;
  } else if (0 < diagnoseMultiplier && diagnoseMultiplier <= T * T) {
    pluses = 2;
  } else if (diagnoseMultiplier > T * T) {
    pluses = 3;
  } else if (1 / T < diagnoseMultiplier && diagnoseMultiplier < 1) {
    minuses = 1;
  } else if (1 / (T * T) < diagnoseMultiplier && diagnoseMultiplier < 1 / T) {
    minuses = 2;
  } else if (diagnoseMultiplier < 1 / (T * T)) {
    minuses = 3;
  }

  return (
    <Row marginRight={Spacing.base}>
      {Array.from(Array(pluses).keys()).map((i) => (
        <PlusOperator key={i} />
      ))}

      {Array.from(Array(minuses).keys()).map((i) => (
        <MinusOperator key={i} />
      ))}
    </Row>
  );
}
