import React, {useEffect, useState} from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import {Colors} from 'utils';
import {MARKPRO_BOLD} from 'utils';
import {debounce} from 'lodash';
import Animated, {
  interpolateColor,
  useSharedValue,
  useAnimatedStyle,
  useDerivedValue,
} from 'react-native-reanimated';
import {viewStyles} from 'utils';

const Chips = ({quickReply, onQuickReply, equalSizeChips}) => {
  const colorInterPolation = useSharedValue(0);

  const animateColors = (callBack) => {
    onQuickReply([{...quickReply, coordinates: null}]);
  };

  const interpolateColor1 = useDerivedValue(() => {
    return interpolateColor(
      colorInterPolation.value,
      [0, 1],
      [Colors.white, Colors.primary],
    );
  });

  const animatedStyles = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor1.value,
    };
  });

  const onClick = debounce(
    (coordinates) => {
      animateColors();
    },
    300,
    [{leading: true, trailing: false}],
  );

  return (
    <Animated.View
      style={[
        equalSizeChips ? styles.chipEqualSize : styles.chip,
        animatedStyles,
      ]}>
      <TouchableOpacity
        key={quickReply._id}
        style={[
          quickReply?.styleBg,
          styles.chipTextContainer,
          viewStyles.width100P,
        ]}
        onPress={(event) => onClick(event.nativeEvent)}>
        <Text style={[styles.chipText, quickReply?.styleTx]}>
          {quickReply.title}
        </Text>
      </TouchableOpacity>
    </Animated.View>
  );
};

export default function QuickRepliesSelector({
  currentMessage,
  onQuickReply,
  stackAsChips,
  equalSizeChips,
}) {
  const [groupReplies, setGroupReplies] = useState({});

  const quickReplies = currentMessage.quickReplies?.values;

  const _groupReplies = (_) => {
    let newValues = currentMessage.quickReplies?.values.reduce(
      (acc, currentItem) => {
        let array = acc[currentItem.key] ? acc[currentItem.key] : [];
        let obj = {
          ...acc,
          [currentItem.key]: [...array, currentItem],
        };
        return obj;
      },
      {},
    );

    setGroupReplies(newValues);
  };

  useEffect(() => {
    if (!stackAsChips) {
      _groupReplies();
    }
  }, [currentMessage.quickReplies]);

  const renderView = (viewTobeRendered) => {
    return Object.keys(viewTobeRendered).map((element, i) => {
      let elementArray = viewTobeRendered[element];
      let flexNumber = 1 / elementArray.length;

      return (
        <View key={i} style={styles.row}>
          {elementArray.map((e, index) => {
            const onClick = debounce(
              (coordinates) => onQuickReply([{...e, coordinates: coordinates}]),
              300,
              [{leading: true, trailing: false}],
            );
            return (
              <TouchableOpacity
                key={index}
                style={[styles.column, {flex: flexNumber}]}
                onPress={(event) => {
                  onClick(event.nativeEvent);
                }}>
                <Text style={styles.colText}>{e.title}</Text>
              </TouchableOpacity>
            );
          })}
        </View>
      );
    });
  };

  const chips = quickReplies?.map((el, index) => {
    return (
      <Chips
        key={index}
        onQuickReply={onQuickReply}
        quickReply={el}
        equalSizeChips={equalSizeChips}
      />
    );
  });

  if (stackAsChips) {
    return <View style={styles.chipsContainer}>{chips}</View>;
  }
  return (
    <View
      style={[
        viewStyles.displayFlex,
        viewStyles.paddingVertical20,
        viewStyles.paddingHorizontal5,
      ]}>
      {groupReplies != null && renderView(groupReplies)}
    </View>
  );
}

const styles = StyleSheet.create({
  chipsContainer: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  chipTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 15,
    height: '100%',
    width: '100%',
    borderRadius: 18,
  },
  chip: {
    borderRadius: 18,
    borderWidth: 1,
    borderColor: Colors.primary,
    height: 50,
    minWidth: 80,
    marginBottom: 10,
    marginRight: 5,
    marginLeft: 5,
    flexGrow: 1,
  },
  chipEqualSize: {
    borderRadius: 18,
    borderWidth: 1,
    borderColor: Colors.primary,
    height: 50,
    width: '46%',
    marginBottom: 10,
    marginRight: 5,
    marginLeft: 5,
    flexGrow: 1,
  },
  chipText: {
    color: Colors.primary,
    fontSize: 18,
    textAlign: 'center',
    fontFamily: MARKPRO_BOLD,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: 10,
    padding: 9,
    paddingVertical: 8,
  },
  column: {
    borderRadius: 18,
    borderWidth: 1,
    borderColor: Colors.primary,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5,
  },
  colText: {
    color: Colors.primary,
    fontSize: 16,
    fontWeight: '700',
    textAlign: 'center',
  },
});
