import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {
  fma24487,
  fma297441,
  fma297447,
  fma305670,
  fma35465,
  fma44623,
  fma73056,
} from 'utils';
import {getColorFma} from 'utils';

export const Knee_Left_Front_fma24883 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.915 151.985">
      <Path
        id="Path_512"
        dataName="Path 512"
        d="M77.747,0a2.665,2.665,0,0,1,2.664,3.058c-.958,6.432-3.12,21.1-3.684,26.178-.732,6.584.244,34.628.975,38.286s1.707,21.46.244,33.409c-1.425,11.641,5.481,16.339,6.965,43.854a2.67,2.67,0,0,1-2.061,2.755C74.368,149.476,42.8,155.809,20,148.68a2.675,2.675,0,0,1-1.886-2.74c.45-6.386,1.586-25.932-.641-34.035-1.794-6.52-12.439-30.97-13.413-57.551C3.272,33.01.916,11.354.019,3.646A2.661,2.661,0,0,1,2.355.695,2.841,2.841,0,0,1,2.646.677Z"
        transform="translate(-0.001 0)"
        fill="#ead9e7"
      />

      <Path
        id={fma24487}
        dataName="Path 519"
        d="M57.949,72.4,57.461,95.81s.2,6.84.756,14.76L36.23,112.53a102.351,102.351,0,0,0-4.13-15.5s-3.414-10.974-6.34-22.68C25.76,74.35,40.636,62.889,57.949,72.4Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma24487, selectedBodyPart)}
        onPressIn={() => onPressClick(fma24487)}
        onClick={() => onPressClick(fma24487)}
      />

      <Path
        id={fma73056}
        dataName="Path 520"
        d="M57.889,70.936c-16.889-9.388-32.5,1.89-32.5,1.89l-5.548,1.22L9.541,51.061C37.829,30.82,67.4,42.1,67.4,42.1L61.851,71Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma73056, selectedBodyPart)}
        onPressIn={() => onPressClick(fma73056)}
        onClick={() => onPressClick(fma73056)}
      />

      <Path
        id={fma305670}
        dataName="Path 521"
        d="M78.351,96.69,58.8,95.81,59.29,72.4h4.026L68.926,42.4l7.632,2.671c.2,9.7.715,20.294,1.146,22.446C78.352,70.768,79.19,85.143,78.351,96.69Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma305670, selectedBodyPart)}
        onPressIn={() => onPressClick(fma305670)}
        onClick={() => onPressClick(fma305670)}
      />

      <Path
        id={fma44623}
        dataName="Path 522"
        d="M19.176,75.57l5.242-.915c2.927,11.706,6.341,22.679,6.341,22.679l-17.091,3.419c-3.784-11.134-8.947-28.268-9.612-46.4-.008-.217-.018-.434-.026-.652L7.957,51.67Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma44623, selectedBodyPart)}
        onPressIn={() => onPressClick(fma44623)}
        onClick={() => onPressClick(fma44623)}
      />

      <Path
        id={fma35465}
        dataName="Path 523"
        d="M60.875,130.925c-9.267,4.633-22.923,2.194-22.923,2.194s.515-7.419-1.707-19.387l21.986-1.959C58.755,119.183,59.579,126.331,60.875,130.925Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma35465, selectedBodyPart)}
        onPressIn={() => onPressClick(fma35465)}
        onClick={() => onPressClick(fma35465)}
      />

      <Path
        id={fma297447}
        dataName="Path 524"
        d="M78.229,97.909c-.108,1.468-.118,1.675-.283,3.022-.915,7.468,1.6,12.087,3.911,21.695l-19.64,7.325a107.734,107.734,0,0,1-2.865-19.387c-.56-7.92-.611-13.29-.611-13.29Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma297447, selectedBodyPart)}
        onPressIn={() => onPressClick(fma297447)}
        onClick={() => onPressClick(fma297447)}
      />

      <Path
        id={fma297441}
        dataName="Path 525"
        d="M31.368,98.737A82.461,82.461,0,0,1,35.026,112.7a79.8,79.8,0,0,1,1.463,20c-6.29,0-12.454-3.008-17.69-5.852-.034-5.792-.367-11.435-1.33-14.938-.572-2.072-1.469-4.443-3.233-9.632Z"
        transform="translate(-0.001 0)"
        fill={getColorFma(fma297441, selectedBodyPart)}
        onPressIn={() => onPressClick(fma297441)}
        onClick={() => onPressClick(fma297441)}
      />

      <Path
        id="Path_526"
        dataName="Path 526"
        d="M56.974,77.277s9.056,9.7,0,23.289"
        transform="translate(-0.001 0)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_527"
        dataName="Path 527"
        d="M26.979,84.715s-1.463,10.242,2.926,16.1"
        transform="translate(-0.001 0)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
