import React, {Fragment, useEffect, useState} from 'react';
import {Text, View, StyleSheet, Pressable, Image} from 'react-native';

import {isSame} from './TextMessage';
import {Colors} from 'utils';
import {MARKPRO_BOLD, MARKPRO_BOOK} from 'utils';
import {CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE} from '../../../assets/images/cara/CaraFaces';
import {SHOW_INFO} from 'utils';
import IconInfo from 'react-native-vector-icons/Feather';
import {viewStyles} from 'utils';

export default function InfoChatBubble(props) {
  const {message, info} = props.currentMessage.data;

  const [showIcon, setShowIcon] = useState(true);

  useEffect(() => {
    if (isInAnswered()) {
      setShowIcon(false);
    } else {
      setShowIcon(true);
    }
  }, [props.answeredQuestions, props.tag]);

  const isInAnswered = () => {
    const list = props.answeredQuestions.filter((el) => el === props.tag);
    if (list.length > 0) {
      return true;
    }
    return false;
  };
  const marginLeft = {marginLeft: showIcon ? 50 : 0};
  return (
    <View>
      {isSame(props.currentMessage, props.previousMessage) ? (
        <Text style={styles.userName}>{props.currentMessage.user.name}</Text>
      ) : (
        <Fragment />
      )}
      <View style={styles.container}>
        <Text style={[styles.text, marginLeft]}>{message}</Text>
        <View style={viewStyles.flexRow}>
          <Pressable
            onPress={() =>
              props._onQuickReply({
                title: '',
                value: {
                  action: SHOW_INFO,
                  option: null,
                  data: {data: info.data, title: info.title},
                },
              })
            }
            style={styles.infoDescription}>
            <IconInfo
              name="info"
              color={Colors.primary}
              size={20}
              onPress={() => {}}
            />
            <Text style={styles.learnMore}>Learn More</Text>
          </Pressable>
        </View>
        {showIcon && (
          <Image
            source={CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE}
            style={styles.cara}
            resizeMode={'contain'}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.systemChatBubblesColor,
    paddingBottom: 10,
    paddingHorizontal: 15,
    borderColor: 'transparent',
    borderWidth: 1,
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    alignItems: 'center',
    minHeight: 50,
  },
  text: {
    fontSize: 16,
    marginRight: 5,
    fontFamily: MARKPRO_BOOK,
  },
  cara: {
    position: 'absolute',
    bottom: -15,
    left: -12,
    width: 70,
    height: 70,
  },
  userName: {
    color: 'lightgrey',
    fontSize: 10,
    marginBottom: 8,
  },
  learnMore: {
    color: Colors.primary,
    marginLeft: 5,
    fontFamily: MARKPRO_BOLD,
  },
  infoDescription: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
});
