import axios from 'axios';
import {
  APPLICATION_JSON,
  GET_DDO_LIST,
  PERSISTENCE_GET_HEADERS,
  PERSISTENCE_GET_SOME,
  coll_lbe,
  DDOHeadersCategory,
  PLATFORM_URL,
} from 'utils';
import firestore from '@react-native-firebase/firestore';

// =====================================================
// Get DDO Headers
// =====================================================
export const getDDOHeaders = (ddoHeadersCategory, uri, token) => {
  let url = uri + GET_DDO_LIST;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let ddoCategory;
  if (DDOHeadersCategory.Problems === ddoHeadersCategory) {
    ddoCategory = '/Disease';
  } else if (DDOHeadersCategory.Medication === ddoHeadersCategory) {
    ddoCategory = '/Medication';
  }

  let body = {
    general_category_values: [ddoCategory],
  };

  return axios.put(url, body, config);
  // Need to filter latest document version.
};

// =====================================================
// Get DDO  Medications
// =====================================================
export const getDDOHeadersMedications = (uri, token) => {
  return getDDOHeaders(DDOHeadersCategory.Medication, uri, token);
};

// =====================================================
// Get DDO  Problems
// =====================================================
export const getDDOHeadersProblems = (uri, token) => {
  return getDDOHeaders(DDOHeadersCategory.Problems, uri, token);
};

// =====================================================
// Get DDO  Family Risk
// =====================================================
export const getFamilyRisk = (token) => {
  return getOntologyCategories(token);
};

// =====================================================
// Get DDO  Current Medical Condition
// =====================================================
export const getCurrentMedicalCondition = (token) => {
  return getOntologyCategories(token);
};

export const getOntologyCategories = (token) => {
  let url = PLATFORM_URL + PERSISTENCE_GET_HEADERS;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  const opts = {
    project_id: 'trmt-iq',
    bucket_id: 'trmt-iq-knowledge-base',
    collection_id: 'collection-master',
    document_type_version: '1.2.0',
  };

  let body = {
    options: opts,
    document_type_name: 'catalog',
  };

  return axios.put(url, body, config);
};

export const getOntologyCategoriesGetSome = (token, id, version) => {
  let url = PLATFORM_URL + PERSISTENCE_GET_SOME;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  const opts = {
    project_id: 'trmt-iq',
    bucket_id: 'trmt-iq-knowledge-base',
    collection_id: 'collection-master',
    document_type_version: '1.2.0',
  };

  let body = {
    options: opts,
    document_type_name: 'catalog',
    document_references: [
      {
        document_id: id,
        version: version,
      },
    ],
  };

  return axios.put(url, body, config);
};

// =====================================================
// Filter Categories
// =====================================================
export const filterCategories = (filterValue, categories) => {
  let filtered = categories.filter((c) => {
    return c.category === 'general' && c.values.includes(filterValue);
  });
  return filtered.length > 0;
};

// =====================================================
// Sort Document
// =====================================================
export const sortVersionExt = (doc1, doc2) => {
  if (doc1.version == null || doc2.version == null) {
    return -1;
  }

  const doc1_ver = doc1.version;
  const doc2_ver = doc2.version;

  const a_ver =
    doc1_ver.split('.').length !== 4
      ? (doc1_ver + '.0').split('.')
      : doc1_ver.split('.');
  const b_ver =
    doc2_ver.split('.').length !== 4
      ? (doc2_ver + '.0').split('.')
      : doc2_ver.split('.');

  if (parseInt(a_ver[0], 10) < parseInt(b_ver[0], 10)) {
    return 1;
  } else if (parseInt(a_ver[0], 10) > parseInt(b_ver[0], 10)) {
    return -1;
  } else {
    if (parseInt(a_ver[1], 10) < parseInt(b_ver[1], 10)) {
      return 1;
    } else if (parseInt(a_ver[1], 10) > parseInt(b_ver[1], 10)) {
      return -1;
    } else {
      if (parseInt(a_ver[2], 10) < parseInt(b_ver[2], 10)) {
        return 1;
      } else if (parseInt(a_ver[2], 10) > parseInt(b_ver[2], 10)) {
        return -1;
      } else {
        if (parseInt(a_ver[3], 10) < parseInt(b_ver[3], 10)) {
          return 1;
        } else if (parseInt(a_ver[3], 10) > parseInt(b_ver[3], 10)) {
          return -1;
        } else {
          return -1;
        }
      }
    }
  }
};

// =====================================================
// Filter is equal complication
// =====================================================
export const isEqualComplication = (a, b) => {
  if (a.display_name != null && b.display_name != null) {
    return a.display_name.toLowerCase() === b.display_name.toLowerCase();
  } else {
    return true;
  }
};

export const fetchDiagnosisLayman = async (ddoList) => {
  try {
    // Fetch lbe symptom document
    const docRef = await firestore().collection(coll_lbe).get();
    ddoList.forEach((d) => {
      const ddoLBE = docRef.docs.filter((dLBE) => {
        return dLBE.data()?.document_id === d?.document_id;
      });

      if (ddoLBE.length > 0) {
        if (ddoLBE[0].data()?.name?.length > 0) {
          d.display_name = ddoLBE[0].data()?.name;
          d.display_name_layman = ddoLBE[0].data()?.layname;
        }
      }
    });
    return ddoList;
  } catch (e) {
    throw new Error(e.message);
  }
};
