// Hardcoded catalog names for second pass
// We don't have an API that returns them
// Each catalog has different types of risk factors
// Cara should ask a separate question for each catalog
// The questions also need to be hardcoded due to lack of API support on this

export const SECOND_PASS_FAMILY = 'family';
export const SECOND_PASS_DISEASE = 'disease';
export const SECOND_PASS_EVENTS = 'event';
export const SECOND_PASS_EXPOSURE = 'exposure';
export const SECOND_PASS_SOCIAL = 'social';
export const SECOND_PASS_DRUG = 'drug';

export const SECOND_PASS_FAMILY_QUESTION =
  'Do you have a family history of anything below?';
export const SECOND_PASS_DISEASE_QUESTION =
  'Have you had any of the diseases or procedures below?';
export const SECOND_PASS_EVENTS_QUESTION =
  'Have any of the following things happened?';
export const SECOND_PASS_EXPOSURE_QUESTION =
  'Have you been exposed to any of the following?';
export const SECOND_PASS_SOCIAL_QUESTION =
  'Do any of the following apply to you?';
export const SECOND_PASS_DRUG_QUESTION =
  'Do you currently, or have you used any of the following substances in the past?';

export function extractUniqueCatalogs(riskFactors: string[]) {
  const family = riskFactors.filter(
    (t) => t.split('/')[1] === SECOND_PASS_FAMILY,
  );
  const disease = riskFactors.filter(
    (t) => t.split('/')[1] === SECOND_PASS_DISEASE,
  );
  const events = riskFactors.filter(
    (t) => t.split('/')[1] === SECOND_PASS_EVENTS,
  );
  const exposure = riskFactors.filter(
    (t) => t.split('/')[1] === SECOND_PASS_EXPOSURE,
  );
  const social = riskFactors.filter(
    (t) => t.split('/')[1] === SECOND_PASS_SOCIAL,
  );
  const drug = riskFactors.filter((t) => t.split('/')[1] === SECOND_PASS_DRUG);
  return {
    SECOND_PASS_FAMILY: family,
    SECOND_PASS_DISEASE: disease,
    SECOND_PASS_EVENTS: events,
    SECOND_PASS_EXPOSURE: exposure,
    SECOND_PASS_SOCIAL: social,
    SECOND_PASS_DRUG: drug,
  };
}

export function getQuestionTextForCatalog(catalogName: string) {
  switch (catalogName) {
    case SECOND_PASS_FAMILY:
      return SECOND_PASS_FAMILY_QUESTION;
    case SECOND_PASS_DISEASE:
      return SECOND_PASS_DISEASE_QUESTION;
    case SECOND_PASS_EVENTS:
      return SECOND_PASS_EVENTS_QUESTION;
    case SECOND_PASS_EXPOSURE:
      return SECOND_PASS_EXPOSURE_QUESTION;
    case SECOND_PASS_SOCIAL:
      return SECOND_PASS_SOCIAL_QUESTION;
    case SECOND_PASS_DRUG:
      return SECOND_PASS_DRUG_QUESTION;
  }
}
