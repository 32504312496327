interface ISpacing {
  tiny1: number;
  tiny: number;
  base: number;
  base13: number;
  base15: number;
  base18: number;
  medium: number;
  medium35: number;
  medium40: number;
  small: number;
  small25: number;
  large: number;
  large85: number;
  large100: number;
  large300: number;
}

export const spacing: ISpacing = {
  tiny1: 1,
  tiny: 5,
  base: 10,
  base13: 13,
  base15: 15,
  base18: 18,
  small: 20,
  small25: 25,
  medium: 30,
  medium35: 35,
  medium40: 40,
  large: 50,
  large85: 85,
  large100: 100,
  large300: 300,
};
