import React from 'react';
import Svg, {Line, Path} from 'react-native-svg';
import {fma24187, fma321694, fma322080, fma322081, fma322083} from 'utils';
import {getColorFma} from 'utils';

export const Back_fma25056 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172.346 233.515">
      <Path
        id="Path_679"
        dataName="Path 679"
        d="M564.8,140.057c-.312-8.316-.319-17.521-1.2-25.79s-3.218-16.532-7.882-23.134c-6.179-8.746-14.229-13.758-23.717-17.647-9.168-3.757-21.975-6.817-30.274-12.865-.543-1.789-1.024-7.046-1.3-9.383a3.464,3.464,0,0,0-3.452-3.043l-33.954.064a3.463,3.463,0,0,0-3.439,3.056,75.526,75.526,0,0,1-1.269,8.706c-8.276,6.079-21.07,9.87-30.224,13.662-9.473,3.925-19.044,8.971-25.19,17.741-4.639,6.619-6.945,14.89-7.793,23.163s-.821,17.478-1.1,25.8c-.825,24.408-.343,36.019-.656,61.895a2.834,2.834,0,0,0,2.838,2.868l19.66-.037a2.829,2.829,0,0,0,2.824-2.71c.69-16.366,1.842-34.8,5.327-49.79a.568.568,0,0,1,1.115.055c.534,4,1.589,11.413,2.948,18.534.451,8.027.8,15.755.994,22.7.061,2.209-.942,6.818-1.358,9.559a59.194,59.194,0,0,1-1.9,7.887c-5.179,15.838-6.987,32.674-8.284,49.288-.607,7.773-.869,10.706-.944,17.956a2.562,2.562,0,0,0,2.554,2.588l120.4.535a2.562,2.562,0,0,0,2.573-2.694c-.4-7.726-.593-12.256-1.514-20.606-1.595-14.477-3.222-28.408-6.774-42.532-3.021-12.012-4.587-26-3.415-35.579,1.141-9.326,4.151-22.128,4.893-27.846a.567.567,0,0,1,1.114-.059c3.541,14.972,3.224,33.407,3.975,49.769a2.832,2.832,0,0,0,2.835,2.7l19.66-.037a2.834,2.834,0,0,0,2.827-2.88C565.283,176.075,565.721,164.462,564.8,140.057Z"
        transform="translate(-393.349 -48.195)"
        fill="#ead9e7"
      />
      <Path
        id={fma24187}
        dataName="Path 696"
        d="M535.72,132.746a3.477,3.477,0,0,0-1.165-.831c-2.295-1.014-2.641-1-3.087-.995l-5.308.01L489,131l-56.155-.072-3.3-.006-.153,0a5.562,5.562,0,0,0-5.541,6.212L427.6,164.68a5.561,5.561,0,0,0,5.525,4.943h.036a6.708,6.708,0,0,1,.993-.083l58.546-.166a5.763,5.763,0,0,0,.8-.06c12.362-.019,33.147-.021,33.685.185a5.177,5.177,0,0,0,5.2-4.778l4.731-27.588A5.569,5.569,0,0,0,535.72,132.746Z"
        transform="translate(-393.349 -48.195)"
        fill={getColorFma(fma24187, selectedBodyPart)}
        onPressIn={() => onPressClick(fma24187)}
        onClick={() => onPressClick(fma24187)}
      />
      <Path
        id={fma322081}
        dataName="Path 697"
        d="M536.441,232.068c-.229-.96-.429-2.141-.641-3.391-.229-1.355-.467-2.757-.772-4.086-.218-.95-.464-1.984-.728-3.094-2.471-10.4-6.6-27.809-4.38-44.624a5.714,5.714,0,0,0-1.258-4.524,5.548,5.548,0,0,0-4.189-1.938l-41.171.173-8.251.034-40.892.172a5.64,5.64,0,0,0-4.087,1.818,5.593,5.593,0,0,0-1.41,4.308c.106,1.045.239,2.175.376,3.359.594,5.067,1.267,10.811.609,15.456-.455,3.2-1.771,8.613-3.3,14.877-.907,3.732-1.847,7.593-2.678,11.3-.82,3.657-1.6,7.111-2.211,9.959a5.977,5.977,0,0,0,1.1,4.683c1.312,1.892,5.9,1.748,6.463,1.748l100.992.283c.547,0,3.817.526,5.258-1.247A5.2,5.2,0,0,0,536.441,232.068Z"
        transform="translate(-393.349 -48.195)"
        fill={getColorFma(fma322081, selectedBodyPart)}
        onPressIn={() => onPressClick(fma322081)}
        onClick={() => onPressClick(fma322081)}
      />
      <Path
        id={fma322080}
        dataName="Path 705"
        d="M485.343,129.753l40.7-.073a5.556,5.556,0,0,0,3.6-2.875,5.649,5.649,0,0,0-.44-5.9c-9.856-13.666-13.364-34.049-14.572-48.743a5.575,5.575,0,0,0-2.68-4.363,5.446,5.446,0,0,0-4.972-.375c-23.431,9.822-47.1,3.43-56.19.228a5.543,5.543,0,0,0-1.85-.321,5.468,5.468,0,0,0-3.15,1.006,5.6,5.6,0,0,0-2.4,4.6c-.22,24.105-7.505,39.408-13.577,48a5.67,5.67,0,0,0-.4,5.9,5.48,5.48,0,0,0,3.55,2.845l40.713.075C474,129.762,485.016,129.762,485.343,129.753Z"
        transform="translate(-393.349 -48.195)"
        fill={getColorFma(fma322080, selectedBodyPart)}
        onPressIn={() => onPressClick(fma322080)}
        onClick={() => onPressClick(fma322080)}
      />
      <Path
        id={fma322083}
        dataName="Path 706"
        d="M532.761,242.056a2.7,2.7,0,0,0-1.434-1.9,2.877,2.877,0,0,0-1.308-.32l-100.979-.283a2.887,2.887,0,0,0-1.313.315,2.7,2.7,0,0,0-1.445,1.906,2.652,2.652,0,0,0,1.677,3.022l39.048,15.976a.667.667,0,0,0,.55-.03.686.686,0,0,0,.349-.436,3.219,3.219,0,0,1,.161-.428,3.537,3.537,0,0,1,1.989-3.127,20.986,20.986,0,0,1,19.1.175c1.618.809,2.425,3.571,2.456,3.627a.678.678,0,0,0,.836.3l38.638-15.78A2.66,2.66,0,0,0,532.761,242.056Z"
        transform="translate(-393.349 -48.195)"
        fill={getColorFma(fma322083, selectedBodyPart)}
        onPressIn={() => onPressClick(fma322083)}
        onClick={() => onPressClick(fma322083)}
      />
      <Path
        id={fma321694}
        dataName="Path 707"
        d="M489.439,258.917a2.287,2.287,0,0,0-.888-.9c-.433-.241-.863-.456-1.292-.656a17.793,17.793,0,0,0-7.577-1.707,20.863,20.863,0,0,0-9.077,2.219,2.328,2.328,0,0,0-.541.369l-.014.012a2.318,2.318,0,0,0-.741,1.769c0,.041.016.078.019.119a2.247,2.247,0,0,0,1.323,1.945l8.149,3.937a2.249,2.249,0,0,0,1.879-.07l7.846-3.844a2.312,2.312,0,0,0,1.209-2.016l0-.009A2.37,2.37,0,0,0,489.439,258.917Z"
        transform="translate(-393.349 -48.195)"
        fill={getColorFma(fma321694, selectedBodyPart)}
        onPressIn={() => onPressClick(fma321694)}
        onClick={() => onPressClick(fma321694)}
      />
      <Line
        id="Line_79"
        dataName="Line 79"
        y2="106.966"
        transform="translate(86.173 84.681)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        strokeDasharray="4 5"
      />
    </Svg>
  );
};
