import React from 'react';
import {Text, TouchableOpacity, StyleSheet} from 'react-native';

export default function AuthButton({
  click,
  name,
  textStyle = {},
  style = {},
  disabled = false,
}) {
  const backgroundColor = {
    backgroundColor: disabled ? 'rgba(211, 211, 211, 0.4)' : 'lightgrey',
  };
  return (
    <TouchableOpacity
      onPress={click}
      style={[backgroundColor, styles.authButton, {...style}]}
      disabled={disabled}>
      <Text style={textStyle}>{name}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  authButton: {
    padding: 15,
    borderRadius: 18,
    alignItems: 'center',
    margin: 2,
    backgroundColor: 'lightgrey',
  },
});
