import {Bootstrap} from 'modules';
import React from 'react';
import {StatusBar} from 'react-native';
import {Provider} from 'react-redux';
import {theme, ThemeProvider} from 'utils';
import {AnonymousRoutes} from './src';
import {store} from './src/app/redux/stores';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StatusBar barStyle="dark-content" />
        <Bootstrap />
        <AnonymousRoutes />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
