import React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';
import {fma20375, fma20376, fma20389} from 'utils';
import {getColorFma} from 'utils';

export const Abdomen_fma20373 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1242 2208">
      <Path
        id="Path_4"
        dataName="Path 4"
        d="M206.3,1055.4c12,51.7,37,164.1,37,204.3c0,8.9-1,26.1-2.6,49.1c-2.1,29.7,3.2,59.2,12.4,87.6  c2.4,7.3,2,16.1-4.1,26c-14.2,23.2-22,49.7-24.6,76.8c-0.6,6.2-1.2,12.5-1.7,18.7c-1.3,15.2,10.6,28.2,25.9,28.2h742.9  c15.2,0,27.1-13,25.9-28.1c-0.7-8.8-1.5-17.6-2.4-26.3c-2.5-25-10.8-49-22.7-71.1c-4.2-7.8-6.5-17.2-3.1-27.1  c8.1-23.6,12.5-48.2,10.7-73.1c-2.2-28.8-3.5-50.3-3.5-60.7c0-44.2,31.8-189.9,42.3-236.6c2.2-9.8,3.3-19.8,3.2-29.9  c-0.3-38-2.9-246.3-8.3-315.9c-0.7-8.8-8-15.6-16.8-15.6l-797.2,0c-8.9,0-16.2,6.9-16.8,15.7c-4.3,67.1-2.8,273.5-2.8,322.2  C200,1018.5,202.1,1037.2,206.3,1055.4z"
        fill="#ead9e7"
      />
      <Path
        id={fma20375}
        dataName="Path 16"
        d="M409.8,1016.1c-21.4,15-84,59.9-98.6,69.3c-16.4,10.7-25,29.9-21.9,48.9c0.9,5.5,1.8,10.1,2.7,14.5  c3.3,17.1,5.7,29,3.9,71.3c-0.4,10.1-1.7,20.1-2.4,30.1c-1,13.1,3.5,26.1,12.3,35.9c9.2,10.1,22.2,15.9,35.9,15.9l250.7-0.9  c12.8,0,23.2-10.5,23.2-23.3l-0.1-217.5c0,0,0-0.1,0-0.1c-0.1-6.1-5.4-10.8-11.5-11.1c-52.4-2.5-105.9-13.9-159.6-34.1  C441.8,1014,426.3,1004.5,409.8,1016.1z"
        fill={getColorFma(fma20375, selectedBodyPart)}
        onPressIn={() => onPressClick(fma20375)}
        onClick={() => onPressClick(fma20375)}
      />
      <Path
        id={fma20376}
        dataName="Path 17"
        d="M802.3,1009.9c-21.4,11.3-78.5,36.8-161.6,39.6c-8.6,0.3-16.1,7-16.2,15.6c0,0.1,0,0.1,0,0.2v216.9  c0,10.5,8.5,19.1,19,19.1l253.2,0.9c13.6,0,26.5-5.6,35.6-15.5c9.2-10,13.9-23.5,12.8-37.1c-0.9-10.8-1.4-21.8-1.6-32.7  c-0.6-35.4,2.2-49.1,5.7-66.4c1-4.9,2-9.9,3-15.7c3.4-19.5-5.2-38.9-21.8-49.4c-17.2-10.9-84.7-61.7-99.5-73.3  C816.6,1001,804.6,1008.7,802.3,1009.9z"
        fill={getColorFma(fma20376, selectedBodyPart)}
        onPressIn={() => onPressClick(fma20376)}
        onClick={() => onPressClick(fma20376)}
      />
      <Path
        id={fma20389}
        dataName="Path 22"
        d="M804.1,975.1l-3.1-3.6c-26.4-30.8-51.4-59.8-74.1-94c-14-21.1-27.8-43.5-41.1-66.5c-4.8-8.3-15.1-11.5-24-7.5  c-6,3-23,6.8-41.8,6.2c-16.4-0.4-29.2-2.7-40.2-7.4c-2.9-1.1-5.4-1.6-7.7-1.6c-9.5,0-15,8.1-20.1,17.3c-1.2,2.1-2,3.6-3,5.2  c-8.6,14.6-17.4,28.8-26.8,43.3c-5.7,8.7-11.5,17.4-17.2,25.9c-10.6,15.5-59.9,74.9-66.1,82.3c-4.4,5.2-6.1,12.2-4.4,18.7  c0.8,3.1,2.3,5.9,4.3,8.3c2.3,2.7,5.1,4.8,8.5,6.1c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.3,0.1,0.5,0.2  c55.7,21,111.2,32.2,165.3,33.7c4.1,0.1,8.2,0.3,12.3,0.3c1.7,0,3.3-0.1,5-0.1c49.6-0.6,90.3-9.4,120.1-19.1  c22.4-7.3,38.8-15,48.1-19.9c0.4-0.2,0.7-0.5,1.1-0.8c2-1.2,3.7-2.8,5.1-4.6c1.6-2.2,2.7-4.7,3.2-7.4  C809.2,985,807.7,979.4,804.1,975.1z"
        fill={getColorFma(fma20389, selectedBodyPart)}
        onPressIn={() => onPressClick(fma20389)}
        onClick={() => onPressClick(fma20389)}
      />

      <Circle
        id="Ellipse_8"
        dataName="Ellipse 8"
        cx="620.8"
        cy="1304.1"
        r="17.6"
        fill="#241f21"
      />
      <Path
        id="Path_64"
        dataName="Path 64"
        d="M261.2,717.2c0,0,41.2,152.3,234.8,69.8"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_65"
        dataName="Path 65"
        d="M967.9,715.4c0,0-38.8,152.9-233.6,73.5"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
