import React, {useMemo} from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import {
  ActionToIconMapper,
  Colors,
  DOWN_ARROW,
  DOWN_ARROW_NOT_ANIMATED,
  SEND_ICON,
  textStyles,
  viewStyles,
} from 'utils';
import InputAccessoryViewiOS from '../../reusables/components/InputAccessoryView';

const ChatInput = (props) => {
  const inputAccessoryViewID = props.tag.toString();
  let downArrowAction;
  let senderAction;

  let inputActions = useMemo(() => {
    return props.inputActions?.values.map((element, index) => {
      if (
        element.iconName === DOWN_ARROW_NOT_ANIMATED ||
        element.iconName === DOWN_ARROW
      ) {
        downArrowAction = element;
      } else if (element.iconName === SEND_ICON) {
        senderAction = element;
      }

      return (
        <ActionToIconMapper
          isSecured={!props.secureTextEntry}
          key={element._id}
          element={element}
          {...props}
        />
      );
    });
  });

  const _onSend = () => {
    if (!props.editable) {
      props.clearText();
      props.onSend(
        {
          text: '',
          action: downArrowAction.action,
        },
        true,
      );
    }
  };

  const _getView = (_) => {
    let autoCapitalize = 'words';
    if (
      props.placeHolder === 'Enter email' ||
      props.placeHolder === 'Enter password'
    ) {
      autoCapitalize = 'none';
    }

    if (props.editable) {
      return (
        <TextInput
          secureTextEntry={props.secureTextEntry}
          editable={props.editable}
          style={[
            viewStyles.flex1,
            viewStyles.height44,
            textStyles.fontFamilyMARKPRO_BOOK,
            textStyles.colorTextBlack,
          ]}
          value={props.enterText}
          onChangeText={props.onChangeText}
          autoCorrect={false}
          autoCapitalize={autoCapitalize}
          autoCompleteType={'off'}
          keyboardType={'default'}
          spellCheck={false}
          placeholder={props.placeHolder}
          placeholderTextColor={'grey'}
          inputAccessoryViewID={inputAccessoryViewID}
        />
      );
    }

    return (
      <Text
        style={[
          textStyles.fontSize16,
          viewStyles.paddingLeft5,
          textStyles.fontFamilyMARKPRO_BOOK,
          textStyles.colorTextSearchColor,
        ]}>
        {props.placeHolder}
      </Text>
    );
  };

  return (
    <Pressable onPress={_onSend}>
      <View style={styles.customInput}>
        {_getView()}
        {inputActions}
        {Platform.OS === 'ios' && (
          <InputAccessoryViewiOS
            {...props}
            senderAction={senderAction}
            viewID={inputAccessoryViewID}
          />
        )}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  customInput: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 18,
    backgroundColor: Colors.systemChatBubblesColor,
    paddingHorizontal: 10,
    height: 54,
    marginHorizontal: 10,
    marginVertical: 10,
  },
});

export default React.memo(ChatInput);
