import React, {PropsWithChildren} from 'react';
import {
  Colors,
  MARKPRO_BOLD,
  MARKPRO_BOOK,
  MARKPRO_MEDIUM,
  Typography,
} from 'utils';
import {ITextBaseProps, TextBase} from './TextBase';
interface ITextBodyProps extends ITextBaseProps {
  variant?: 'normal' | 'medium' | 'bold';
  size?: 'body1' | 'body2' | 'body3' | 'body4';
  color?: string;
}

const fontFamily = {
  normal: MARKPRO_BOOK,
  medium: MARKPRO_MEDIUM,
  bold: MARKPRO_BOLD,
};

const fontSize = {
  body1: Typography.body1,
  body2: Typography.body2,
  body3: Typography.body3,
  body4: Typography.body4,
};

export function TextBody({
  children,
  onPress,
  numberOfLines,
  ellipsizeMode,
  style,
  variant = 'normal',
  size = 'body1',
  color = Colors.black,
}: PropsWithChildren<ITextBodyProps>) {
  return (
    <TextBase
      onPress={onPress}
      numberOfLines={numberOfLines}
      ellipsizeMode={ellipsizeMode}
      style={[
        {
          fontFamily: fontFamily[variant],
          fontSize: fontSize[size],
          color,
        },
        style as any,
      ]}>
      {children}
    </TextBase>
  );
}
