import React from 'react';
import {View, Text} from 'react-native';
import {textStyles, viewStyles} from 'utils';
export default (_props) => (
  <View style={viewStyles.padding20}>
    <Text style={[textStyles.colorTextAlizarin, textStyles.fontWeight600]}>
      Video is not implemented by GiftedChat.
    </Text>
    <Text style={[textStyles.colorTextAlizarin, textStyles.fontWeight600]}>
      You need to provide your own implementation by using renderMessageVideo
      prop.
    </Text>
  </View>
);
