export * from './ResultDetails';
export * from './Description';
export * from './Risks';
export * from './Symptoms';
export * from './Treatment';
export * from './Prevention';

export * from './TextTitle';
export * from './TextTitleList';
export * from './TreatmentExclamation';
export * from './RenderList';
export * from './ResultDetailsSigns';
