import React from 'react';
import {GestureResponderEvent} from 'react-native';
import {Icon} from 'react-native-elements';

interface IIconRoundedProps {
  onPress: (event: GestureResponderEvent) => void;
}

export function IconRoundedStar({onPress}: IIconRoundedProps) {
  return (
    <Icon
      raised
      name="star"
      type="font-awesome"
      color="#f50"
      onPress={onPress}
    />
  );
}
