import React from 'react';
import Svg, {Path} from 'react-native-svg';

export const ClockIcon = () => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8C15 11.8663 11.8663 15 8 15C4.13373 15 1 11.8663 1 8C1 4.13373 4.13373 1 8 1C11.8663 1 15 4.13373 15 8Z"
      stroke="#736969"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M10.2877 9.96211L7.77441 8.46278V5.23145"
      stroke="#736969"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
