import React from 'react';
import {View} from 'react-native';
import Send from '../lib/Send';
import {viewStyles} from 'utils';

export default function CustomSendButton(props) {
  return (
    <Send {...props} containerStyle={viewStyles.justifyContentCenter}>
      <View style={[viewStyles.marginRight10, viewStyles.marginBottom5]} />
    </Send>
  );
}
