import React from 'react';
import {TextStyle} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useTheme} from 'utils';
import {IconPropsBase, IconSize} from './IconPropsBase';

export interface IconSquareProps extends IconPropsBase {
  style?: TextStyle;
}

export function IconSquare({style, size, onPress, color}: IconSquareProps) {
  const theme = useTheme();

  return (
    <Icon
      name="checkbox-blank-outline"
      size={size || IconSize.Large}
      color={color || theme.colors?.white}
      onPress={onPress}
      style={{...style}}
    />
  );
}
