import {Pressable, Text, StyleSheet} from 'react-native';
import React from 'react';
import {Colors} from 'utils';
import {MARKPRO_MEDIUM} from 'utils';

const DoneButton = ({sendData, selectedValue}) => (
  <Pressable style={styles.toolbar} onPress={() => sendData(selectedValue)}>
    <Text style={styles.doneButton}>Done</Text>
  </Pressable>
);

const styles = StyleSheet.create({
  toolbar: {
    backgroundColor: '#F2F2F2',
    width: '100%',
    flexDirection: 'row-reverse',
    height: 44,
    alignItems: 'center',
  },
  doneButton: {
    color: Colors.doneButtonTextColor,
    fontSize: 16,
    fontFamily: MARKPRO_MEDIUM,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
});

export default DoneButton;
