import {isWeb} from 'config';
import {Layout, PossibleConditions, ScreenNames} from 'modules';
import React from 'react';
import {Colors, TopBarSvg} from 'utils';

export function PossibleConditionsScreen({navigation, route}: any) {
  const itemPressHandler = (item: any, index: number) => {
    navigation.navigate(ScreenNames.AssessmentResultItem, {
      index,
      title:
        item?.diagnosis.display_name_layman || item?.diagnosis.display_name,
      differentialDiagnosis: item,
      differentialDiagnosisItems: route.params.differentialDiagnosisItems,
      platformToken: route.params?.platformToken,
      platformUrl: route.params?.platformUrl,
    });
  };

  return (
    <Layout>
      {!isWeb ? <TopBarSvg fillColor={Colors.primary} /> : null}

      <PossibleConditions.Title symptomType={route.params.symptom} />

      <PossibleConditions.List
        diagnosis={route.params.diagnosis}
        itemPressHandler={itemPressHandler}
        parentNavigation={navigation}
      />
    </Layout>
  );
}
