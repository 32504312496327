import React from 'react';
import {StyleSheet} from 'react-native';
import {Spacing} from 'utils';
import {Text} from 'libs';

interface ITextTitleProps {
  title?: string;
}

export function TextTitle({title}: ITextTitleProps) {
  return (
    <Text.Subtitle
      variant="bold"
      size="subtitle1"
      style={styles.titleStylePosition}>
      {title}
    </Text.Subtitle>
  );
}

const styles = StyleSheet.create({
  titleStylePosition: {
    marginTop: Spacing.small25,
    marginBottom: Spacing.small,
  },
});
