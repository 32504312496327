import React, {PropsWithChildren} from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

interface IColumnProps {
  style?: ViewStyle;
  margin?: number | string;
  marginVertical?: number | string;
  marginHorizontal?: number | string;
  marginTop?: number | string;
  marginBottom?: number | string;
  marginLeft?: number | string;
  marginRight?: number | string;
  paddingVertical?: number | string;
  paddingHorizontal?: number | string;
  paddingTop?: number | string;
  paddingBottom?: number | string;
  paddingLeft?: number | string;
  paddingRight?: number | string;
  justifyContent?:
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'space-evenly'
    | 'space-around'
    | 'space-between';
  alignItems?: 'baseline' | 'center' | 'flex-end' | 'flex-start' | 'stretch';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  backgroundColor?: string;
  width?: number | string;
  height?: number | string;
  borderColor?: string;
  borderRadius?: number;
  borderTopWidth?: number;
  borderBottomWidth?: number;
}

export function Column({
  children,
  style,
  margin,
  marginVertical,
  marginHorizontal,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  justifyContent = 'flex-start',
  alignItems = 'stretch',
  flexWrap = 'nowrap',
  backgroundColor,
  width,
  height,
  borderRadius,
  borderColor,
  borderTopWidth,
  borderBottomWidth,
  paddingVertical,
  paddingHorizontal,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
}: PropsWithChildren<IColumnProps>) {
  return (
    <View
      style={[
        styles.container,
        {
          margin,
          marginVertical,
          marginHorizontal,
          marginTop,
          marginBottom,
          marginLeft,
          marginRight,
          justifyContent,
          alignItems,
          flexWrap,
          backgroundColor,
          width,
          height,
          borderRadius,
          borderColor,
          borderTopWidth,
          borderBottomWidth,
          paddingVertical,
          paddingHorizontal,
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
        },
        style,
      ]}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
});
