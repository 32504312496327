export enum EventType {
  APP_OPEN = 'app_open',
  ASSESSMENT_START = 'assessment_start',
  ASSESSMENT_END = 'assessment_end',
  RESULT_DESCRIPTION_VIEW = 'result_description_view',
  RESULT_PREVENTION_VIEW = 'result_prevention_view',
  RESULT_DETAILS_VIEW = 'result_details_view',
  RESULT_RISKS_VIEW = 'result_risks_view',
  RESULT_SYMPTOMS_VIEW = 'result_symptoms_view',
  RESULT_TREATMENT_VIEW = 'result_treatment_view',
}
