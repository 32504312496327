import {isWeb} from 'config';
import {Icon, Text} from 'libs';
import React from 'react';
import {GestureResponderEvent, StyleSheet, View} from 'react-native';
import {Colors, TopBarSvg} from 'utils';

interface ISearchSymptomHeaderProps {
  onPress: (event: GestureResponderEvent) => void;
}

export function SearchSymptomHeader({onPress}: ISearchSymptomHeaderProps) {
  return (
    <>
      <View style={styles.headerContainer}>
        <View style={styles.headerTitleContainer}>
          <Text.Subtitle variant="medium" size="subtitle3">
            Search for symptom
          </Text.Subtitle>
        </View>

        <View style={styles.iconCloseContainer}>
          <Icon.Close onPress={onPress} size={Icon.Size.Medium} />
        </View>
      </View>
      {!isWeb ? <TopBarSvg fillColor={Colors.topPurple} /> : null}
    </>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    height: 100,
    backgroundColor: Colors.topPurple,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitleContainer: {
    top: 20,
  },
  iconCloseContainer: {
    position: 'absolute',
    right: 5,
    bottom: 18,
  },
});
