import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Input} from '../../../components';

interface ITextInputProps {
  name: string;
  defaultValue?: string;
  label?: string;
  required?: boolean;
}

export function FormTextInput({
  name,
  defaultValue = '',
  label,
  required = true,
}: ITextInputProps) {
  const {control} = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{required}}
      render={({field: {onChange, onBlur, value}}) => {
        return (
          <Input.Text
            label={label}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
}
