import React from 'react';
import {useTheme} from 'utils';
import {IconPropsBase} from './IconPropsBase';
import {StyleSheet} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';

interface IconProps extends IconPropsBase {}

export function IconBase({name, size, color, style, onPress}: IconProps) {
  const theme = useTheme();

  return (
    <Icon
      name={name || ''}
      size={size || 17}
      color={color || 'white'}
      style={[style, styles.iconStyle]}
      onPress={onPress}
    />
  );
}

const styles = StyleSheet.create({
  iconStyle: {marginTop: 3, marginHorizontal: 5, fontWeight: '900'},
});
