import {debounce} from 'lodash';
import moment from 'moment';
import {Platform, StatusBar} from 'react-native';
import {createWorklet} from 'react-native-reanimated';
import {allBodyParts, Colors, entireBodyParts} from 'utils';

export const changeAndroidStatusBarColor = (
  color,
  content = 'dark-content',
) => {
  if (Platform.OS === 'android') {
    StatusBar.setBackgroundColor(color, true);
    StatusBar.setBarStyle(content, true);
  }
};

export const clamp = createWorklet((value, lowerBound, upperBound) => {
  return Math.min(Math.max(lowerBound, value), upperBound);
});
export const formattedTodayAddress = () => {
  return moment(new Date()).format('MMMM D, YYYY');
};
export const formatDateString = (date) => moment(date).format('MMMM D,YYYY');
export const debouncer = (method) => {
  return debounce(method, 300, [{leading: true, trailing: false}]);
};

export const checkNaN = createWorklet((value) => {
  if (isNaN(value)) {
    return 0;
  }
  return value;
});

export const encodedURLComp = (val) => encodeURIComponent(val);
export const randomGenerate = (length) =>
  Math.floor(
    Math.pow(10, length - 1) +
      Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1),
  );

export const getColorFma = (fma, selectedBodyPart) => {
  if (selectedBodyPart === fma) {
    return Colors.bodyPurple;
  }

  return Colors.bodyPink;
};

export const getMainFma = (qr) => {
  let mainPart;
  for (let abp of allBodyParts) {
    let foundAll = true;
    abp.sub_parts.forEach((sp) => {
      const filtered = qr.filter((qreply) => {
        return qreply.data.item_id === sp;
      });
      if (filtered.length === 0) {
        foundAll = false;
      }
    });

    if (foundAll) {
      mainPart = abp.main_part;
      break;
    } else {
    }
  }

  return mainPart;
};

export const getAllSubBodyPartsFromQr = (qr) => {
  let itemIds = [];
  qr.forEach((qreply) => {
    const itemId = qreply?.data?.item_id;
    itemIds = [...itemIds, itemId];
  });

  return itemIds;
};

export const getAnswerFma = (map) => {
  if (map?.answer_list == null || map?.answer_list?.length === 0) {
    return null;
  }

  const fma = map?.answer_list[0].item_id;
  if (fma.startsWith('fma')) {
    return fma;
  }

  return null;
};

export const isEntireSelection = (fma) => {
  const entirePartList = entireBodyParts;
  return entirePartList.includes(fma);
};

export const getIndexOfAnswerId = (answers, itemId) => {
  answers.forEach((a, index) => {
    if (a.item_id === itemId) {
      return index;
    }
  });

  return 0;
};

export const generateDataNamePlots = (index, rangeStart) => {
  /// GET THE CURRENT DATE
  let labelsArr = [];
  if (index === 0) {
    for (let i = 0; i < 7; i++) {
      labelsArr.push({
        displayDate: rangeStart.clone().add(i, 'days').format('ddd'),
        actualDate: rangeStart.clone().add(i, 'days'),
      });
    }
  } else if (index === 1) {
    for (let i = 0; i < 7; i++) {
      labelsArr.push({
        displayDate: rangeStart.clone().add(i, 'weeks').week(),
        actualDate: rangeStart.clone().add(i, 'weeks'),
      });
    }
  } else {
    for (let i = 0; i < 7; i++) {
      labelsArr.push({
        displayDate: rangeStart.clone().add(i, 'months').format('MMM'),
        actualDate: rangeStart.clone().add(i, 'months'),
      });
    }
  }
  return labelsArr;
};

export const getDateDiff = (startDate, endDate) => {
  let DateDifference;
  let DaysDifference = moment(endDate).diff(
    moment(new Date(startDate)),
    'days',
  );
  let WeeksDifference = moment(endDate).diff(
    moment(new Date(startDate)),
    'weeks',
  );
  let MonthsDifference = moment(endDate).diff(
    moment(new Date(startDate)),
    'months',
  );

  if (MonthsDifference > 0) {
    DateDifference = `${MonthsDifference} month${
      MonthsDifference > 1 ? 's' : ''
    }`;
  } else if (WeeksDifference > 0) {
    DateDifference = `${WeeksDifference} week${WeeksDifference > 1 ? 's' : ''}`;
  } else if (DaysDifference > 0) {
    DateDifference = `${DaysDifference} day${DaysDifference > 1 ? 's' : ''}`;
  } else {
    DateDifference = 'Today';
  }
  return DateDifference;
};

export const generateDataPlots = (
  selectedIndex,
  symptomTrackerData,
  labelsData,
) => {
  return labelsData.map((el, index) => {
    const dateToBeSearched = moment(el.actualDate).clone();
    const groupedData = symptomTrackerData.reduce(
      (acc, elToFilter) => {
        const date = moment(elToFilter.updated_date_full).clone();
        if (selectedIndex === 0) {
          if (dateToBeSearched.isSame(date, 'day')) {
            if (acc.smpCount == null) {
              acc.smpCount = 0;
            }
            acc.smpCount += elToFilter.severity;
            acc.totalFindings += 1;
          }
        } else if (selectedIndex === 1) {
          if (dateToBeSearched.isSame(date, 'W')) {
            if (acc.smpCount == null) {
              acc.smpCount = 0;
            }
            acc.smpCount += elToFilter.severity;
            acc.totalFindings += 1;
          }
        } else if (selectedIndex === 2) {
          if (dateToBeSearched.isSame(date, 'month')) {
            if (acc.smpCount == null) {
              acc.smpCount = 0;
            }
            acc.smpCount += elToFilter.severity;
            acc.totalFindings += 1;
          }
        }
        return acc;
      },
      {smpCount: null, totalFindings: 0},
    );
    let count =
      groupedData.smpCount == null
        ? null
        : Math.floor(groupedData.smpCount / groupedData.totalFindings);
    return count;
  });
};
