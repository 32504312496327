import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {Colors} from 'utils';
import {MARKPRO_BOLD, MARKPRO_BOOK, MARKPRO_MEDIUM} from 'utils';

function Description(props) {
  const title = 'Description';
  let description = props?.ddo
    ? props?.ddo.description
    : '-- No data available --';

  if (description.startsWith('#')) {
    description = description.replace('#', '');
  }

  description = description.replace('#', '');
  description = description.replace('  ', '');
  description = description.trim();

  return (
    <View
      style={[styles.descriptionContainer]}
      showsVerticalScrollIndicator={false}>
      <View style={[styles.titleMainView]}>
        <Text style={styles.titleText}>{title}</Text>
        <Text style={styles.descText}>{description}</Text>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  titleMainView: {backgroundColor: Colors.white, paddingHorizontal: 30},
  descriptionContainer: {
    flex: 1,
    backgroundColor: Colors.systemChatBubblesColor,
  },
  bottomButton: {paddingVertical: 30},
  titleText: {
    flex: 1,
    fontSize: 22,
    fontFamily: MARKPRO_BOLD,
    color: Colors.textDarkGreyColor,
    marginVertical: 20,
  },
  descText: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 16,
    fontFamily: MARKPRO_BOOK,
    color: Colors.textBlackColor,
  },
  reminderText: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 13,
    fontFamily: MARKPRO_BOOK,
    color: Colors.textBlackColor,
    marginVertical: 10,
  },
  warningText: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 14,
    fontFamily: MARKPRO_MEDIUM,
    color: Colors.deleteDarkColor,
  },
  deleteText: {
    textAlign: 'justify',
    fontSize: 16,
    fontFamily: MARKPRO_BOLD,
    color: Colors.deleteTextColor,
    paddingHorizontal: 10,
  },
});

export default Description;
