// @ts-nocheck

import axios from 'axios';
import {db} from 'config';
import {collection, doc, getDoc, getDocs} from 'firebase/firestore';
import {
  APPLICATION_JSON,
  coll_lbe,
  GET_DISEASE_CITATIONS,
  GET_SYMPTOM_LIST_FILTERED,
  SYMPTOM_SYNONYMS,
} from '../constants';

// =====================================================
// Get Symptom List
// =====================================================
export const getSymptomList = (uri, token, sex) => {
  const url = uri + GET_SYMPTOM_LIST_FILTERED;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    general_category_values: ['/Symptom'],
    sex_at_birth: sex,
  };

  return axios.put(url, body, config);
};

export const getDiseaseCitations = (uri, token) => {
  const url = uri + GET_DISEASE_CITATIONS;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios.put(url, {}, config);
};

export const fetchLBE = () =>
  new Promise(async (resolve, reject) => {
    try {
      // Fetch lbe symptom document
      const docData = await getDocs(collection(db, coll_lbe));

      if (docData !== undefined && docData.length !== 0) {
        let list = [];
        docData.forEach((documentSnapshot) => {
          let obj = documentSnapshot.data();
          list.push(obj);
        });
        resolve(list);
      } else {
        reject(null);
      }
    } catch (e) {
      reject(e);
    }
  });

// ==========================================================
// Symptom List -  Replace display name layman from LBE - if exist
// ==========================================================
export const fetchSymptomListLayman = (symptom) =>
  new Promise(async (resolve, reject) => {
    try {
      // Fetch lbe symptom document
      const docRef = doc(db, coll_lbe, symptom.sdco_id);
      const docSnapshot = await getDoc(docRef);

      let symptomUpdated = symptom;
      if (docSnapshot.exists()) {
        const docMap = docSnapshot.data();
        symptomUpdated = {
          ...symptom,
          edited_display_name_1: docMap.editedDisplayName1,
        };
      }

      resolve(symptomUpdated);
    } catch (e) {
      reject(e);
    }
  });

export const fetchAllSymptomListLayman = () =>
  new Promise(async (resolve, reject) => {
    try {
      // Fetch lbe symptom document
      const docData = await getDocs(collection(db, coll_lbe));

      if (docData !== undefined && docData.length !== 0) {
        let list = [];
        docData.forEach((documentSnapshot) => {
          let obj = documentSnapshot.data();
          const lbeSymptom = {
            document_id: obj.document_id,
            edited_display_name_1: obj.editedDisplayName1,
          };
          list.push(lbeSymptom);
        });
        resolve(list);
      } else {
        reject(null);
      }
    } catch (e) {
      reject(e);
    }
  });

// ===================================================================
// Get Reason for Encounter / Symptom List from Symptom Synonyms - New
// ===================================================================
export const getSymptomListFromSynonyms = (matchString, uri, token) => {
  const url = uri + SYMPTOM_SYNONYMS;

  const config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {search: matchString, lang_code: 'eng'};

  return axios.put(url, body, config);
};

// =====================================================
// Fetch Symptom Layman
// =====================================================
export const fetchSymptomLayman = (reasonForEncounterList) => {
  new Promise((resolve, reject) => {
    (async () => {
      try {
        const resultLBECollection = await getLBECollection();
        if (!resultLBECollection.data) {
          reject(resultLBECollection);
        }

        const rfeList = [];
        reasonForEncounterList.forEach((r) => {
          const rfe = r;

          resultLBECollection.docSnapshot.forEach((doc) => {
            if (r.sdco_id === doc.data().document_id) {
              const {description} = doc.data();
              rfe.description = description;
            }
          });

          rfeList.add(rfe);
        });

        resolve(rfeList);
      } catch (e) {
        reject(e);
      }
    })().catch((err) => {
      reject(err);
    });
  });
};

// ==================================================
// Get LBE Collection
// ==================================================
export const getLBECollection = async () => {
  try {
    const docSnapshot = await getDocs(collection(db, coll_lbe));

    return docSnapshot;
  } catch (e) {
    return e;
  }
};

// =====================================================
// Fetch Reason for Encounter Description Layman
// =====================================================
export const fetchReasonForEncounterLayman = (reasonForEncounter) =>
  new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, coll_lbe, reasonForEncounter.sdco_id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists) {
        const docMap = docSnapshot.data;
        reasonForEncounter.description = docMap.description;
      }

      resolve(reasonForEncounter);
    } catch (e) {
      reject(e);
    }
  });
