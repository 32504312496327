import {
  ADD_PROFILE_SEX,
  APPLE_AUTH,
  DOWN_ARROW,
  EMAIL_AUTH,
  ENTER_BIRTHDATE,
  ENTER_CITY,
  ENTER_COUNTRY,
  ENTER_FIRST_NAME,
  ENTER_LAST_NAME,
  ENTER_PASSOWRD,
  ENTER_PRONOUN,
  ENTER_PROVINCE,
  ENTER_RELATIONSHIP,
  FINAL_ONBOARDING_SCREEN_ADD_PROFILE,
  getNameNickname,
  REGISTER_COMPLETE,
  SEND_ICON,
  SUBMIT_BIRTH_DATE_AND_TIME,
  SUBMIT_CITY,
  SUBMIT_COUNTRY,
  SUBMIT_DATA,
  SUBMIT_PROVINCE,
} from 'utils';
import {
  CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
  CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
  CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
} from '../../../../assets/images/cara/CaraFaces';

export default class AddProfileMessages {
  constructor(mainProfile) {
    this.mainProfile = mainProfile;

    const name = getNameNickname(this.mainProfile);

    this._NewMessages = [
      {
        messages: [
          {
            _id: '4bbea3d1-e722-4a62-9d71-0090f412a8b876',
            createdAt: this.today,
            user: {...this.systemUser},
            messageType: 'Automated',
            icon: CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
            type: 'text',
            shouldAnimate: true,
            data: `Hi ${name}! Let's set up a new health profile. How do you know the person we're adding? Are they your child? Partner? Partner in crime? Everything you tell me is confidential.`,
            last_message: `Hi ${name}! What is your relationship to this person?`,
            step: ENTER_RELATIONSHIP,
            sub_step: null,
            text: '',
          },
        ],
        actions: [
          {
            createdAt: this.today,
            tag: ENTER_RELATIONSHIP,
            inputQuickReplies: {
              placeHolder: 'Enter relationship',
              editable: true,
              type: 'radio',
              values: [
                {
                  _id: 1,
                  action: ENTER_RELATIONSHIP,
                  iconName: SEND_ICON,
                  hasReactToEnable: true,
                },
              ],
            },
            quickReplies: {
              type: 'radio',
              values: [
                {
                  key: 1,
                  title: 'Child',
                  value: {
                    action: ENTER_RELATIONSHIP,
                    option: '113131',
                  },
                },
                {
                  key: 1,
                  title: 'Partner',
                  value: {
                    action: ENTER_RELATIONSHIP,
                    option: '113131',
                  },
                },

                {
                  key: 2,
                  title: 'Parent',
                  value: {
                    action: ENTER_RELATIONSHIP,
                    option: '113131',
                  },
                },
                {
                  key: 2,
                  title: 'Sibling',
                  value: {
                    action: ENTER_RELATIONSHIP,
                    option: '113131',
                  },
                },
              ],
            },
            step: null,
            sub_step: null,
            user: {...this.customer},
            _id: '98f530bd-cae9-4581-b163-ec00a257ac0716',
          },
        ],
      },
      {
        messages: [
          {
            _id: '4bbea3d1-e722-4a62-9d71-0290f665a8b342',
            createdAt: this.today,
            shouldAnimate: true,
            user: {...this.systemUser},
            messageType: 'Automated',
            type: 'text',
            data: 'Lovely! What is their first name?',
            step: ENTER_FIRST_NAME,
            icon: CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
            sub_step: null,
            text: '',
          },
        ],
        actions: [
          {
            createdAt: this.today,
            tag: ENTER_FIRST_NAME,
            inputQuickReplies: {
              placeHolder: 'Enter first name...',
              editable: true,
              type: 'radio',
              values: [
                {
                  action: ENTER_FIRST_NAME,
                  _id: 1,
                  iconName: SEND_ICON,
                  hasReactToEnable: true,
                },
              ],
            },
            quickReplies: {
              type: 'radio',
              values: [],
            },
            step: null,
            sub_step: null,
            user: {...this.customer},
            _id: '98f530bd-cae9-4581-b163-ec00a257ac0718',
          },
        ],
      },
      {
        messages: [
          {
            _id: '4bbea3d1-e722-4a34-9d71-0095f877a8b718',
            createdAt: this.today,
            shouldAnimate: true,
            user: {...this.systemUser},
            messageType: 'Automated',
            type: 'text',
            data: 'And their last name?',
            step: ENTER_LAST_NAME,
            icon: CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
            sub_step: null,
            text: '',
          },
        ],
        actions: [
          {
            createdAt: this.today,
            tag: ENTER_LAST_NAME,
            inputQuickReplies: {
              placeHolder: 'Enter last name...',
              editable: true,
              type: 'radio',
              values: [
                {
                  action: ENTER_LAST_NAME,
                  _id: 1,
                  iconName: SEND_ICON,
                  hasReactToEnable: true,
                },
              ],
            },
            quickReplies: {
              type: 'radio',
              values: [],
            },
            step: null,
            sub_step: null,
            user: {...this.customer},
            _id: '98f530bd-cae9-4581-b163-ec00a756ac0718',
          },
        ],
      },

      {
        messages: [
          {
            _id: '4bbea3d1-e722-4a62-9d71-0090f984a345',
            createdAt: this.today,
            user: {...this.systemUser},
            shouldAnimate: true,
            messageType: 'Automated',
            type: 'text',
            data: 'What sex were they assigned at birth?',
            last_message: 'What sex were they assigned at birth?',
            step: ADD_PROFILE_SEX,
            icon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
            sub_step: null,
          },
        ],
        actions: [
          {
            createdAt: this.today,
            tag: ADD_PROFILE_SEX,
            inputQuickReplies: {},
            quickReplies: {
              type: 'radio',
              values: [
                {
                  key: 1,
                  title: 'Female',
                  value: {
                    action: ADD_PROFILE_SEX,
                    option: '113131',
                  },
                },
                {
                  key: 1,
                  title: 'Male',
                  value: {
                    action: ADD_PROFILE_SEX,
                    option: '113131',
                  },
                },
              ],
            },
            step: null,
            sub_step: null,
            user: {...this.customer},
            _id: '4bbea3d1-e722-4a62-9d71-0090f664a331',
          },
        ],
      },

      {
        messages: [
          {
            _id: '4bbea3d1-e722-4a62-9d71-0090f664a8bP2323645',
            createdAt: this.today,
            user: {...this.systemUser},
            shouldAnimate: true,
            messageType: 'Automated',
            data: 'What are their pronouns?',
            step: ENTER_PRONOUN,
            sub_step: null,
            icon: CARA_HAPPY_EYES_OPEN_MOUTH_CLOSE,
          },
        ],
        actions: [
          {
            createdAt: this.today,
            tag: ENTER_PRONOUN,
            quickReplies: {
              type: 'radio',
              values: [
                {
                  title: 'They/Them',
                  key: 1,
                  value: {
                    action: ENTER_PRONOUN,
                    option: '113131',
                  },
                },
                {
                  title: 'He/Him',
                  key: 1,
                  value: {
                    action: ENTER_PRONOUN,
                    option: '113131',
                  },
                },
                {
                  title: 'She/Her',
                  key: 1,
                  value: {
                    action: ENTER_PRONOUN,
                    option: '113131',
                  },
                },
              ],
            },
            step: null,
            sub_step: null,
            user: {...this.customer},
            _id: '4bbea3d1-e722-4a62-9d71-0090f664a8bP2323212',
          },
        ],
      },
      {
        messages: [
          {
            _id: '4bbea3d1-e722-4a62-9d71-0090f923a8b8',
            createdAt: this.today,
            user: {...this.systemUser},
            messageType: 'Automated',
            type: 'text',
            shouldAnimate: true,
            data: 'What is their date of birth?',
            last_message: 'What is their date of birth?',
            step: ENTER_BIRTHDATE,
            icon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
            sub_step: null,
          },
        ],
        actions: [
          {
            createdAt: this.today,
            tag: ENTER_BIRTHDATE,
            inputQuickReplies: {
              placeHolder: 'Enter date of birth',
              editable: false,
              type: 'radio',
              values: [
                {
                  _id: 1,
                  action: ENTER_BIRTHDATE,
                  iconName: DOWN_ARROW,
                  hasReactToEnable: false,
                },
              ],
            },
            quickReplies: {
              type: 'radio',
              values: [],
            },
            step: null,
            sub_step: null,
            user: {...this.customer},
            _id: '98f530bd-cae9-4581-b163-ec00a344ac08',
          },
        ],
      },
      {
        messages: [
          {
            _id: '4bbea3d1-e722-4a62-9d71-0090f784a8b7',
            createdAt: this.today,
            user: {...this.systemUser},
            messageType: 'Automated',
            shouldAnimate: true,
            type: 'text',
            last_message: 'live in primarily? ',
            step: ENTER_COUNTRY,
            sub_step: null,
            icon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
            data: 'live in primarily?',
          },
        ],
        actions: [
          {
            createdAt: this.today,
            tag: ENTER_COUNTRY,
            inputQuickReplies: {
              placeHolder: 'Select country',
              editable: false,
              type: 'radio',
              values: [
                {
                  _id: 1,
                  action: ENTER_COUNTRY,
                  iconName: DOWN_ARROW,
                  hasReactToEnable: false,
                },
              ],
            },
            quickReplies: {
              type: 'radio',
              values: [],
            },
            step: null,
            sub_step: null,
            user: {...this.customer},
            _id: '98f530bd-cae9-4581-b163-ec00a997ac07',
          },
        ],
      },
      {
        messages: [
          {
            _id: '4bbea3d1-e722-4a62-9d71-0090f608a8b71',
            createdAt: this.today,
            user: {...this.systemUser},
            messageType: 'Automated',
            shouldAnimate: true,
            type: 'text',
            data: 'And what province?',
            last_message: 'And what province?',
            step: ENTER_PROVINCE,
            sub_step: null,
            icon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
          },
        ],
        actions: [
          {
            createdAt: this.today,
            tag: ENTER_PROVINCE,
            inputQuickReplies: {
              placeHolder: 'Select province',
              editable: false,
              type: 'radio',
              values: [
                {
                  _id: 1,
                  action: ENTER_PROVINCE,
                  iconName: DOWN_ARROW,
                  hasReactToEnable: false,
                },
              ],
            },
            quickReplies: {
              type: 'radio',
              values: [],
            },
            step: null,
            sub_step: null,
            user: {...this.customer},
            _id: '98f530bd-cae9-4581-b783-ec00a223ac071',
          },
        ],
      },
      {
        messages: [
          {
            _id: '4bbea3d1-e722-4a99-9d71-0090f611a8b70',
            createdAt: this.today,
            shouldAnimate: true,
            user: {...this.systemUser},
            messageType: 'Automated',
            type: 'text',
            data: 'live in? ',
            last_message: 'live in?',
            step: ENTER_CITY,
            icon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
            sub_step: null,
          },
        ],
        actions: [
          {
            createdAt: this.today,
            tag: ENTER_CITY,
            inputQuickReplies: {
              placeHolder: 'Enter city',
              editable: false,
              type: 'radio',
              values: [
                {
                  _id: 1,
                  action: ENTER_CITY,
                  iconName: DOWN_ARROW,
                  hasReactToEnable: false,
                },
              ],
            },
            quickReplies: {
              type: 'radio',
              values: [],
            },
            step: null,
            sub_step: null,
            user: {...this.customer},
            _id: '98f530bd-cae9-4512-b163-ec00a277ac070',
          },
        ],
      },
      {
        messages: [
          {
            _id: '4bbea3d1-e722-4a62-9d34-0090f664a8b77rr',
            createdAt: this.today,
            shouldAnimate: true,
            user: {...this.systemUser},
            messageType: 'Automated',
            type: 'text',
            icon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
            data: `Thank you, ${name}! We're all \n done here.`,
            last_message: `Thanks ${name}, We’re all done! One moment while I absorb this information.`,
            step: FINAL_ONBOARDING_SCREEN_ADD_PROFILE,
            sub_step: null,
          },
        ],
        actions: [
          {
            createdAt: this.today,
            quickReplies: {
              type: 'radio',
              values: [
                {
                  title: 'Finish',
                  key: 1,
                  value: {
                    action: FINAL_ONBOARDING_SCREEN_ADD_PROFILE,
                    option: '113131',
                  },
                },
              ],
            },
            step: null,
            sub_step: null,
            user: {...this.customer},
            _id: '98f530bd-cae9-4345-b163-ec00a257ac045rr',
          },
        ],
      },
    ];
  }

  mainProfile;

  today = new Date();

  nextMessageIndex = 0;

  stepToSkip = [];

  authMethod = EMAIL_AUTH;

  systemUser = {
    avatar:
      'https://i.pinimg.com/originals/f0/48/9c/f0489ceb101a4bb4f8fd6f6f2c9e2762.jpg',
    name: null,
    type: 'Advisor',
    _id: '1234r',
  };

  customer = {
    avatar:
      'https://agent-profile-pics.s3.ap-south-1.amazonaws.com/default-customer.png',
    name: '',
    type: 'Customer',
    _id: '1',
  };

  _NewMessages;

  _snapShotForStoring = {};

  _setAuthMethod = (method) => {
    this.authMethod = method;
  };

  _setStepsToSkip = (steps) => {
    this.stepToSkip.concat(steps);
  };

  addToInitialMessageIndex = (value) => {
    this.nextMessageIndex = this.nextMessageIndex - 1;
  };

  _getNextMesssageIndex = () => {
    return this.nextMessageIndex;
  };

  getinitialMessages = (_) => {
    return new Promise((resolve, reject) => {
      resolve({
        messages: this._NewMessages[this.nextMessageIndex].messages,
        actions: this._NewMessages[this.nextMessageIndex].actions,
      });

      this.nextMessageIndex++;
    });
  };

  setInititalMessageIndex = (index) => {
    this.nextMessageIndex = index;
  };

  clearStore = () => {
    this.nextMessageIndex = 0;
    this._snapShotForStoring = {};
  };

  createUserMessage = (data) => {
    const obj = {
      _id: Math.random(),
      createdAt: this.today,
      user: {
        _id: '1',
        name: '',
        avatar: '',
        type: 'Customer',
      },
      messageType: 'Automated',
      type: data.type,
      data: data.data,
      last_message: data.last_message,
      step: null,
      sub_step: null,
      text: data.text,
    };

    return obj;
  };

  _createAndStoreData = (key, data) => {
    let objectToBeMergerged = {
      [key]: data,
    };
    this._snapShotForStoring = {
      ...this._snapShotForStoring,
      ...objectToBeMergerged,
    };
  };

  actionMapper = (
    messageObj,
    {userId = null, token = null, platformUrl = null, username = null},
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {action, data} = messageObj;
        switch (action) {
          case ENTER_RELATIONSHIP:
            this._createAndStoreData('relationship', data);
            break;
          case ENTER_FIRST_NAME:
            this._createAndStoreData('first_name', data);
            this._createAndStoreData('nickname', data);
            this._createAndStoreData('username', data);
            break;
          case ENTER_LAST_NAME:
            this._createAndStoreData('last_name', data);
            break;
          case ENTER_PRONOUN:
            this._createAndStoreData('pronouns', data);
            break;
          case ADD_PROFILE_SEX:
            this._createAndStoreData('sex_at_birth', data);
            break;
          case SUBMIT_BIRTH_DATE_AND_TIME:
            this._createAndStoreData('date_of_birth', data);
            break;
          case SUBMIT_COUNTRY:
            this._createAndStoreData('country', data);
            break;
          case SUBMIT_PROVINCE:
            this._createAndStoreData('state', data);
            break;

          case SUBMIT_DATA:
            return;
          default:
        }

        resolve('resolved');
      } catch (e) {
        reject(e);
      }
    });
  };

  _sendMessages = (msgs) => {};

  _getMessagesForTheAction(next, message, action, dependency) {
    let messages = [];
    let createdMessage;
    let actions = [];

    if (action === ENTER_PASSOWRD) {
    } else if (action === REGISTER_COMPLETE) {
      messages = [...this._NewMessages[this.nextMessageIndex].messages];
      actions = this._NewMessages[this.nextMessageIndex].actions
        ? this._NewMessages[this.nextMessageIndex].actions
        : [];
      this.nextMessageIndex++;
    } else if (action === ENTER_LAST_NAME && this.authMethod === APPLE_AUTH) {
      createdMessage = this.createUserMessage(message);
      messages.push(createdMessage);
    } else if (action === SUBMIT_COUNTRY) {
      createdMessage = this.createUserMessage(message);
      messages = [...this._NewMessages[this.nextMessageIndex].messages];
      messages.push(createdMessage);
      if (dependency.hasDependency) {
        let {messageIndex, key, value} = dependency;
        messages[messageIndex][key] = value;
      }

      const stateProvince =
        message.data === 'United States' ? 'Select state' : 'Select province';

      actions = [
        {
          createdAt: this.today,
          tag: ENTER_PROVINCE,
          inputQuickReplies: {
            placeHolder: stateProvince,
            editable: false,
            type: 'radio',
            values: [
              {
                _id: 1,
                action: ENTER_PROVINCE,
                iconName: DOWN_ARROW,
                hasReactToEnable: false,
              },
            ],
          },
          quickReplies: {
            type: 'radio',
            values: [],
          },
          step: null,
          sub_step: null,
          user: {...this.customer},
          _id: '98f530bd-cae9-4581-b783-ec00a223ac071pr',
        },
      ];

      this.nextMessageIndex++;
    } else {
      createdMessage = this.createUserMessage(message);
      messages = [...this._NewMessages[this.nextMessageIndex].messages];
      messages.push(createdMessage);
      if (dependency.hasDependency) {
        let {messageIndex, key, value} = dependency;
        messages[messageIndex][key] = value;
      }
      actions = this._NewMessages[this.nextMessageIndex].actions
        ? this._NewMessages[this.nextMessageIndex].actions
        : [];

      this.nextMessageIndex++;
    }

    const mergeMessages = [...messages];
    const objTobePassed = {
      messages: mergeMessages,
      actions: actions,
    };
    return objTobePassed;
  }

  mergeValueWithArray = (
    callBack,
    limit,
    data,
    dependency,
    {userId = null, token = null, platformUrl = null, username = null},
  ) => {
    let objTobePassed = this._getMessagesForTheAction(
      limit,
      data,
      data.action,
      dependency,
    );

    if (data.action === SUBMIT_CITY) {
      callBack({...objTobePassed, clearQR: true});
      objTobePassed = {};
    }

    this.actionMapper(data, {userId, token, platformUrl, username}).then(
      (res) => {
        callBack(objTobePassed);
      },
    );
  };
}
