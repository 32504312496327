import moment from 'moment';
import React, {useState} from 'react';
import {Modal, Platform, StyleSheet, View} from 'react-native';
import {Colors, MARKPRO_MEDIUM, SUBMIT_BIRTH_DATE_AND_TIME} from 'utils';
import DateTimePicker from '../reusables/components/DateTimePicker';
import DoneButton from '../reusables/components/DoneButton';

//import MonthPicker from 'react-native-month-year-picker';

const DateTimeModal = ({
  isVisible,
  onSendData,
  maxDate,
  format = 'DD-MM-YYYY',
  minDate = moment(),
}) => {
  const submit = (date) => {
    onSendData({
      value: {
        action: SUBMIT_BIRTH_DATE_AND_TIME,
        option: {
          data: moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        },
      },
    });
  };

  const renderMonthPicker = () => {
    return (
      // <MonthPicker
      //   onChange={(_event, selectedDate) => {
      //     if (isIos) {
      //       setDate(selectedDate);
      //       return;
      //     }
      //     submit(selectedDate);
      //   }}
      //   value={date}
      //   minimumDate={minDate}
      //   maximumDate={maxDate}
      //   locale="ko"
      // />
      <></>
    );
  };

  const renderDateTimePicker = () => {
    return (
      <DateTimePicker
        title="Date*"
        placeholder="Today"
        type={isIos ? 'spinner' : 'default'}
        minDate={minDate}
        maxDate={maxDate}
        onChangeText={(_event, selectedDate) => {
          if (isIos) {
            setDate(selectedDate);
            return;
          }
          submit(selectedDate);
        }}
        value={date}
        mode="date"
        format={format}
      />
    );
  };

  const [date, setDate] = useState(new Date());
  const isIos = Platform.OS === 'ios';

  const iosDoneMessage = () => {
    return <DoneButton selectedValue={date} sendData={submit} />;
  };

  return (
    <Modal transparent visible={isVisible} animationType={'none'}>
      <View style={styles.modalView}>
        <View style={styles.isIosView}>
          {isIos === true && iosDoneMessage()}
          {format === 'MONTH' ? renderMonthPicker() : renderDateTimePicker()}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  isIosView: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    flex: 1,
    justifyContent: 'center',
    backgroundColor: Colors.white,
  },
  modalView: {flex: 1, backgroundColor: 'rgba(0 , 0 , 0 , 0.2)'},
  doneButton: {
    color: '#4484F6',
    fontSize: 16,
    fontFamily: MARKPRO_MEDIUM,
    paddingHorizontal: 5,
    paddingVertical: 5,
  },
  toolbar: {
    paddingHorizontal: 5,
    backgroundColor: '#F2F2F2',
    width: '100%',
    flexDirection: 'row-reverse',
    height: 40,
    alignItems: 'center',
  },
});

export default DateTimeModal;
