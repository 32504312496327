export const getFamilyHistoryDisplayName = (item) => {
  const nameSplitArray = item.split(':');
  let name = nameSplitArray[0];
  if (nameSplitArray.length > 2) {
    name = name + nameSplitArray[1];
  }
  return name.replace('/', '');
};
export const getCurrentMedicalConditionDisplayName = (item) => {
  const nameSplitArray = item.split(':');
  let name = nameSplitArray[0];
  if (nameSplitArray.length > 2) {
    name = name + nameSplitArray[1];
  }
  return name.replace('/', '');
};
