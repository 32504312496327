import React, {PropsWithChildren} from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {Spacing} from 'utils';

interface FlexProps {
  style?: ViewStyle;
}

export function Flex({children, style}: PropsWithChildren<FlexProps>) {
  return <View style={[styles.container, style]}>{children}</View>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Spacing.base,
  },
});
