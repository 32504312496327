import Svg, {Circle, Rect} from 'react-native-svg';
import React from 'react';

const CircleChecked = () => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <Circle cx="12" cy="12" r="12" fill="#EAD9E7" />
    <Rect
      x="15.667"
      y="6.75"
      width="2"
      height="10.9972"
      rx="1"
      transform="rotate(33.4819 15.667 6.75)"
      fill="#873E79"
    />
    <Rect
      x="12.2568"
      y="15.75"
      width="2"
      height="6"
      rx="1"
      transform="rotate(135 12.2568 15.75)"
      fill="#873E79"
    />
  </Svg>
);

export default React.memo(CircleChecked);
