import {WebViewModal} from 'libs';
import {useCitations} from 'modules';
import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import {Colors} from 'utils';
import DiseaseCitationsContent from './DiseaseCitationsContent';
import DiseaseCitationsHeader from './DiseaseCitationsHeader';

function DiseaseCitationsScreen(props: any) {
  const [openedLink, setOpenedLink] = useState('');

  //useChangeStatusBarColor(); TODO breaks the app, investigate and repair
  const [citations] = useCitations();

  return (
    <View style={[styles.privacyPolicyScreenContainer]}>
      <SafeAreaView
        style={[styles.safeAreaView]}
        forceInset={{bottom: 'never'}}>
        <DiseaseCitationsHeader {...props} />
        <DiseaseCitationsContent
          loading={citations.length === 0}
          filteredCitations={citations}
          setOpenedLink={setOpenedLink}
        />
        <WebViewModal
          visible={openedLink !== ''}
          link={openedLink}
          closeHandler={() => setOpenedLink('')}
        />
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {flex: 1},
  privacyPolicyScreenContainer: {flex: 1, backgroundColor: Colors.topPurple},
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  contentContainer: {
    padding: 15,
    paddingVertical: 20,
  },
  subTitle: {
    marginVertical: 15,
  },
});

export default DiseaseCitationsScreen;
