import React from 'react';
import {ViewStyle} from 'react-native';
import {Colors} from 'utils';
import {IconBase} from './IconBase';
import {IconPropsBase, IconSize} from './IconPropsBase';

interface IconArrowLeftProps extends IconPropsBase {
  style?: ViewStyle;
}

export function IconArrowLeft({
  size = IconSize.Large,
  color = Colors.white,
  style,
  onPress,
}: IconArrowLeftProps) {
  return (
    <IconBase
      name="arrowleft"
      size={size}
      color={color}
      style={{...style, padding: 10}}
      onPress={onPress}
    />
  );
}
