export const firebaseToken = 'firebaseToken';
export const APP_VERSION = 'Cara Consumer 1.0.0(47)';
export const APP_TYPE = 'consumer';

export const coll_lbe = 'lbe';
export const coll_config_cara_consumer = 'config_cara_consumer';

export const MedProfileStatus = Object.freeze({
  NotStarted: 1,
  Started: 2,
  Complete: 3,
});
export const DDOHeadersCategory = Object.freeze({
  Problems: 1,
  Medication: 2,
  Family: 3,
});
export const UserType = Object.freeze({MainUser: 1, SubUser: 2});

// API Response Messages
export const DOCUMENT_DOES_NOT_EXISTS = 'Document does not exists.';
export const NO_CURRENT_FIREBASE_USER_FOUND = 'No current Firebase user found.';
export const COULD_NOT_GET_NEXT_QUESTION = 'Could not get next question.';

export const FIREBASE_STORAGE_DIRECTORY = 'treatment_mobile/customer_profiles/';

export const SEND_RELATIONSHIP = 'SEND_RELATION_SHIP';

export const ENTER_PROFILE_CONDITION_DATE = 'ENTER_PROFILE_CONDITION_DATE';

// FONT FAMILIES

export const MARKPRO_MEDIUM = 'MarkPro-Medium';
export const MARKPRO_BOLD = 'MarkPro-Bold';
export const MARKPRO_BOOK = 'MarkPro-Book';

// AUTH METHODS

export const EMAIL_AUTH = 'email_auth';
export const APPLE_AUTH = 'apple_auth';
export const GMAIL_AUTH = 'apple_auth';

//ASSESMENT STAGES

export const INITIAL_STAGE = 'INITIAL_STAGE';
export const SYMPTOM_SELECT_STAGE = 'SYMPTOM_SELECT_STAGE';
export const ASSESSMENT_QUESTION_ANSWER_STAGE =
  'ASSESSMENT_QUESTION_ANSWER_STAGE';
export const ASSESSMENT_QUESTION_FIRST_ANSWER =
  'ASSESSMENT_QUESTION_FIRST_ANSWER';
export const ASSESSMENT_COMPLETE = 'ASSESSMENT_COMPLETE';

// MEDICAL MODULES
export const MEDICAL_HISTORY = 'MEDICAL_HISTORY';
export const MEDICATION = 'MEDICATION';
export const FAMILY_HISTORY = 'FAMILY_HISTORY';
export const CURRENT_MEDICAL_CONDITIONS = 'CURRENT_MEDICAL_CONDITIONS';
export const ALLERGIES = 'ALLERGIES';

// MEDICAL HISTORY CHAT STEPS
export const MEDICAL_HISTORY_ENTERED = 'MEDICAL_HISTORY_ENTERED';
export const MEDICAL_HISTORY_START_DATE = 'MEDICAL_HISTORY_START_DATE';
export const MEDICAL_HISTORY_END_DATE = 'MEDICAL_HISTORY_START_DATE';

// CURRENT MEDICAL CONDITIONS CHAT STEPS
export const CURRENT_MEDICAL_CONDITIONS_ENTERED =
  'CURRENT_MEDICAL_CONDITIONS_ENTERED';
export const CURRENT_MEDICAL_CONDITIONS_ENTERED_START_DATE =
  'CURRENT_MEDICAL_CONDITIONS_ENTERED_START_DATE';

// FAMILY HISTORY CHAT STEPS
export const FAMILY_HISTORY_ENTERED = 'FAMILY_HISTORY_ENTERED';

export const MEDICAL_SKIP = 'MEDICAL_SKIP';
export const MEDICAL_SKIP_AND_FINISH = 'MEDICAL_SKIP_AND_FINISH';

// TABBAR ICONS
export const HOME = 'Home';
export const TRACK = 'Track';
export const HISTORY = 'History';
export const PROFILE = 'Profile';

// DATE FORMATS

export const MONTH_PICKER = 'MONTH_PICKER';
export const YEAR_PICKER = 'YEAR_PICKER';
export const MONTH_PICKER_SIMPLIFIED = 'MONTH_PICKER_SIMPLIFIED';

// HUMAN ANATOMY - fma =============================================================

// Abdomen 1
export const fma9577 = 'fma9577';
export const fma20373 = 'fma20373';
export const fma20377 = 'fma20377';
export const fma24040 = 'fma24040';
export const fma14603 = 'fma14603';
export const fma321947 = 'fma321947';
export const fma14604 = 'fma14604';
export const fma14605 = 'fma14605';

// Abdomen 1 - Sub
export const fma24036 = 'fma24036';
export const fma24037 = 'fma24037';

// Abdomen 2
export const fma20364 = 'fma20364'; // - Full Part
export const fma20389 = 'fma20389';
export const fma20376 = 'fma20376';
export const fma20375 = 'fma20375';

// Abdomen 3
export const fma20365 = 'fma20365'; // - Full Part
export const fma20378 = 'fma20378';
export const fma20379 = 'fma20379';
export const fma14602 = 'fma14602';

// Back

export const fma25056 = 'fma25056';
export const fma24187 = 'fma24187';
export const fma322081 = 'fma322081';
export const fma322080 = 'fma322080';
export const fma322083 = 'fma322083';
export const fma321694 = 'fma321694';

// Chest

export const fma223729 = 'fma223729';
export const fma269176 = 'fma269176';
export const fma269440 = 'fma269440';
export const fma223623 = 'fma223623';
export const fma25558 = 'fma25558';
export const fma269442 = 'fma269442';
export const fma223625 = 'fma223625';
export const fma223673 = 'fma223673';
export const fma259119 = 'fma259119';
export const fma35349 = 'fma35349';
export const fma223671 = 'fma223671';
export const fma259117 = 'fma259117';
export const fma35348 = 'fma35348';
export const fma25559 = 'fma25559';

// Hand Right Back fma321784
export const fma15_1_0 = '15.1.0.';
export const fma222981 = 'fma222981';
export const fma40770 = 'fma40770';
export const fma38287 = 'fma38287';
export const fma317909 = 'fma317909';
export const fma15_1_5 = '15.1.5.';

// Hand Right Front fma321784_F
export const fma15_2_0 = '15.2.0.';
export const fma15_2_6 = '15.2.6.';
export const fma61070 = 'fma61070';
export const fma38293 = 'fma38293';
export const fma42352 = 'fma42352';
export const fma38290 = 'fma38290';
export const fma38284 = 'fma38284';

// Hand Left Back fma9714
export const fma19_1_0 = '19.1.0.';
export const fma9714 = 'fma9714';
export const fma222982 = 'fma222982';
export const fma40771 = 'fma40771';
export const fma38288 = 'fma38288';
export const fma317911 = 'fma317911';
export const fma19_1_5 = '19.1.5.';

// Hand Left Front fma9714
export const fma19_2_0 = '19.2.0.';
export const fma19_2_6 = '19.2.6.';
export const fma79172 = 'fma79172';
export const fma38294 = 'fma38294';
export const fma61528 = 'fma61528';
export const fma38291 = 'fma38291';
export const fma38285 = 'fma38285';

// Finger Front fma38325
export const fma38325 = 'fma38325';
export const fma60850 = 'fma60850';
export const fma54333 = 'fma54333';
export const fma60375 = 'fma60375';

// Foot Left Bottom
export const fma27_2 = '27.2.0';
export const fma27_2_4 = '27.2.4';
export const fma37850 = 'fma37850';
export const fma51131 = 'fma51131';
export const fma24995 = 'fma24995';

// Foot Left Inside
export const fma27_3 = '27.3.0';
export const fma35452 = 'fma35452';
export const fma258847 = 'fma258847';
export const fma51084 = 'fma51084';

// Foot Left Outside
export const fma27_4 = '27.4.0';
export const fma35503 = 'fma35503';
export const fma73092 = 'fma73092';
export const fma22552 = 'fma22552';

// Foot Left Top
export const fma27_1 = '27.1.0';
export const fma27_1_0 = '27.1.0.';
export const fma27_1_2 = '27.1.2';
export const fma73093 = 'fma73093';

// Knee Left Front
export const fma24883_F = 'fma24883_F';
export const fma24487 = 'fma24487';
export const fma73056 = 'fma73056';
export const fma35465 = 'fma35465';

// Knee Left Back
export const fma24883_B = 'fma24883_B';
export const fma73060 = 'fma73060';
export const fma297447 = 'fma297447';
export const fma305670 = 'fma305670';
export const fma44623 = 'fma44623';
export const fma297441 = 'fma297441';
export const fma305662 = 'fma305662';
export const fma45200 = 'fma45200';

// Knee Right Back
export const fma24977_B = 'fma24977_B';
export const fma22_2_0 = '22.2.0';
export const fma73058 = 'fma73058';
export const fma297445 = 'fma297445';
export const fma305666 = 'fma305666';
export const fma44622 = 'fma44622';
export const fma297439 = 'fma297439';

// Knee Right Front
export const fma24977_F = 'fma24977_F';
export const fma22_1_0 = '22.1.0';
export const fma24486 = 'fma24486';
export const fma73055 = 'fma73055';
export const fma35464 = 'fma35464';
export const fma305658 = 'fma305658';
export const fma45198 = 'fma45198';

// Big Toe Right
export const fma37854 = 'fma37854';
export const fma54344 = 'fma54344';
export const fma60698 = 'fma60698';
export const fma60868 = 'fma60868';

// Foot Right Bottom
export const fma23_2_0 = '23.2.0';
export const fma23_2_4 = '23.2.4';

// Foot Right Inside
export const fma23_3_0 = '23.3.0';
//export const fma158847 = 'fma158847';
//

// Foot Right Outside
export const fma23_4_0 = '23.4.0';

// Foot Right Top
export const fma23_1_0 = '23.1.0.';
export const fma23_1_2 = '23.1.2';

// Small Toe
export const small_toe = 'small_toe';
export const fma60546 = 'fma60546';
export const fma54348 = 'fma54348';
export const fma60866 = 'fma60866';

export const entireBodyParts = [
  fma9577,
  fma20364,
  fma20365,
  fma321947,
  fma27_1,
  fma27_1_0,
  fma27_2,
  fma22_1_0,
  fma22_2_0,
  fma15_1_0,
  fma15_2_0,
  fma19_1_0,
  fma19_2_0,
  fma27_3,
  fma27_4,
  fma23_1_0,
  fma23_2_0,
  fma23_3_0,
  fma23_4_0,
  fma14603,
];

export const allBodyParts = [
  {
    main_part: fma15_1_0, // Right hand back
    sub_parts: [fma15_1_0, fma15_1_5, fma222981, fma317909, fma38287, fma40770],
  },
  {
    main_part: fma38325, // Finger Front
    sub_parts: [fma54333, fma60375, fma60850],
  },
  {
    main_part: fma15_2_0, // Right Hand Front
    sub_parts: [
      fma15_2_0,
      fma15_2_6,
      fma38284,
      fma38290,
      fma38293,
      fma42352,
      fma61070,
    ],
  },
  {
    main_part: fma19_1_0, // Left Hand Back
    sub_parts: [fma19_1_0, fma19_1_5, fma222982, fma40771, fma38288, fma317911],
  },
  {
    main_part: fma19_2_0, // Left Hand Front
    sub_parts: [
      fma19_2_0,
      fma19_2_6,
      fma79172,
      fma38294,
      fma61528,
      fma38291,
      fma38285,
    ],
  },
  {
    main_part: fma27_2,
    sub_parts: [fma27_2, fma27_2_4, fma37850, fma51131, fma24995],
  },
  {
    main_part: fma27_3,
    sub_parts: [fma27_3, fma35452, fma258847, fma51084],
  },
  {
    main_part: fma27_4,
    sub_parts: [fma27_4, fma258847, fma35503, fma73092, fma22552],
  },
  {
    main_part: fma27_1,
    sub_parts: [fma27_1_0, fma73093, fma27_1_2],
  },
  {
    main_part: fma24883_F,
    sub_parts: [
      fma27_1,
      fma24487,
      fma73056,
      fma305670,
      fma44623,
      fma35465,
      fma297447,
      fma297441,
      fma305662,
      fma45200,
    ],
  },
  {
    main_part: fma24883_B,
    sub_parts: [
      fma27_2,
      fma73060,
      fma305662,
      fma297447,
      fma305670,
      fma44623,
      fma297441,
    ],
  },
  {
    main_part: fma24977_B,
    sub_parts: [
      fma22_2_0,
      fma73058,
      fma297445,
      fma305666,
      fma44622,
      fma297439,
      fma305658,
    ],
  },
  {
    main_part: fma37854,
    sub_parts: [fma54344, fma60698, fma60868],
  },
  {
    main_part: fma23_2_0,
    sub_parts: [fma23_2_0, fma23_2_4, fma24995, fma37850, fma51131], //fma23_2_4, fma24995, fma37850, fma51131,
  },
  {
    main_part: fma23_3_0, // Right Foot Inside
    sub_parts: [fma258847, fma35452, fma51084, fma23_3_0], // fma258847, fma35452, fma51084
  },
  {
    main_part: fma23_4_0,
    sub_parts: [fma23_4_0, fma22552, fma258847, fma35503, fma73092],
  },
  {
    main_part: fma23_1_0,
    sub_parts: [fma23_1_0, fma23_1_2, fma73093],
  },
  {
    main_part: fma24977_F,
    sub_parts: [
      fma22_1_0,
      fma24486,
      fma297439,
      fma297445,
      fma305666,
      fma35464,
      fma44622,
      fma73055,
      fma305658,
      fma45198,
    ],
  },
  {
    main_part: small_toe,
    sub_parts: [fma54348, fma60546, fma60866],
  },
  {
    main_part: fma9577,
    sub_parts: [fma9577, fma20373, fma20377, fma24040, fma14603],
  },
  {
    main_part: fma24040,
    sub_parts: [fma321947, fma24036, fma24037],
  },
  {
    main_part: fma20373,
    sub_parts: [fma20364, fma20389, fma20375, fma20376],
  },
  {
    main_part: fma20377,
    sub_parts: [fma20365, fma20378, fma20379, fma14602],
  },
  {main_part: fma14603, sub_parts: [fma14605, fma14604]},
  {
    main_part: fma25056,
    sub_parts: [fma322080, fma24187, fma322081, fma322083, fma321694],
  },
  {
    main_part: fma223729,
    sub_parts: [
      fma269442,
      fma25559,
      fma223625,
      fma223673,
      fma259119,
      fma35349,
      fma269176,
      fma269440,
      fma25558,
      fma223623,
      fma223671,
      fma259117,
      fma35348,
    ],
  },
];
