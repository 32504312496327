// @ts-nocheck

import {isWeb} from 'config';
import React from 'react';
import Svg, {Defs, G, Path} from 'react-native-svg';
import {Image} from 'react-native';
import {Tip} from 'app/assets/images';

export const LightSvg = () => {
  if (isWeb) {
    return <Image source={Tip} style={{width: 44, height: 58}} />;
  }
  return (
    <Svg width={44} height={58} viewBox="0 0 44 58">
      <G filter="url(#filter0_d_3704:90661)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.537 36.523c.277-.83.883-1.502 1.628-1.963 4.672-2.894 7.88-8.422 7.88-14.06 0-8.184-5.5-13.5-14.944-13.5-8.184 0-14.556 7.316-14.556 15.5 0 5.607 2.991 10.166 7.507 12.482.847.434 1.553 1.128 1.875 2.025l.777 2.158a3.293 3.293 0 003.317 2.17l2.818-.188a3.293 3.293 0 002.905-2.244l.793-2.38z"
          fill="#E9662F"
        />
        <Path
          d="M22.411 50.975c-.51 0-1.05-.01-1.59.005-.403.01-.702-.281-.765-.793-.062-.491.126-.893.536-1.038.268-.095.55-.166.825-.146 1.043.066 2.083.166 3.127.261.334.03.5.301.5.778 0 .441-.093.712-.441.857-.734.306-1.444-.21-2.192.076z"
          fill="#2D2D2D"
        />
        <Path
          d="M21.264 44.895c-.554 0-1.11-.015-1.664.004-.308.01-.614.086-.923.1-.379.015-.62-.32-.632-.825-.012-.492.26-.969.597-.997a40.858 40.858 0 011.704-.096c.961-.033 1.922-.081 2.88-.081.915 0 1.83.048 2.744.081.239.01.48.01.72.057.297.062.39.511.343.845-.043.3-.256.716-.425.759-.5.129-1.014.11-1.523.129-.822.033-1.646.071-2.467.066-.449 0-.897-.085-1.349-.133a.95.95 0 00-.005.09zM21.548 47.895c-.43 0-.863-.015-1.293.004-.24.01-.478.086-.718.1-.295.015-.483-.32-.492-.825-.01-.492.202-.969.464-.997.442-.048.884-.072 1.326-.096.747-.033 1.495-.081 2.24-.081.711 0 1.423.048 2.134.081.186.01.374.01.56.057.23.062.303.511.267.845-.034.3-.2.716-.33.759-.39.129-.79.11-1.186.129-.639.033-1.28.071-1.918.066-.35 0-.698-.085-1.05-.133a1.195 1.195 0 00-.004.09z"
          fill="#2B2B2B"
        />
        <Path
          d="M19.045 12c-1.5-.5-6 .501-7.5 6.5 3.5-3.499 4-4.999 7.5-6.5z"
          fill="#F9F6F4"
        />
      </G>
      <Defs></Defs>
    </Svg>
  );
};

export default LightSvg;
