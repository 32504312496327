import React from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native';
import {Colors} from 'utils';
import {Text, Icon} from 'libs';

interface PossibleConditionListItemProps {
  itemTitle?: string;
  itemPercentage?: string;
  onPress: (event: GestureResponderEvent) => void;
}

export function PossibleConditionListItem({
  itemTitle,
  itemPercentage,
  onPress,
}: PossibleConditionListItemProps) {
  return (
    <>
      <TouchableOpacity style={styles.itemButtonStyle} onPress={onPress}>
        <Text.Body
          numberOfLines={2}
          ellipsizeMode="tail"
          color={Colors.primary}
          size="body1"
          variant="bold"
          style={styles.itemTitleStyle}>
          {itemTitle}
        </Text.Body>

        <View style={styles.leftContainer}>
          <Text.Body
            variant="bold"
            color={Colors.textBlackColor}
            size="body1"
            style={styles.itemPercentageStyle}>
            {itemPercentage}
          </Text.Body>

          <Icon.Arrow color={Colors.primary} size={Icon.Size.Medium} />
        </View>
      </TouchableOpacity>
      <View style={styles.rowStyle} />
    </>
  );
}

const styles = StyleSheet.create({
  itemButtonStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
  },
  rowStyle: {
    height: 2,
    backgroundColor: Colors.borderPink,
  },
  itemTitleStyle: {
    flex: 1,
    paddingRight: 10,
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemPercentageStyle: {
    marginRight: 15,
  },
});
