import React from 'react';
import {Dimensions} from 'react-native';
import Svg, {Path} from 'react-native-svg';

const {width: screenWidth} = Dimensions.get('window');
enum SvgSize {
  width = screenWidth,
  height = (screenWidth * 35) / screenWidth,
}

interface TopBarSvgProps {
  fillColor: string;
}

export const TopBarSvg = ({fillColor}: TopBarSvgProps) => (
  <Svg
    height={SvgSize.height}
    width={SvgSize.width}
    preserveAspectRatio="xMinYMin slice"
    viewBox="0 0 398 35">
    <Path
      id="Path_16"
      d="M-93.923-941.056h400s-111.846,31.612-200.822,34.809S-93.923-941.056-93.923-941.056Z"
      transform="translate(93.923 941.056)"
      fill={fillColor}
    />
  </Svg>
);
