import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {fma297439, fma297445, fma305666, fma44622, fma73058} from 'utils';
import {getColorFma} from 'utils';

export const Knee_Right_Back_fma24977 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.791 146.018">
      <Path
        id="Path_453"
        dataName="Path 453"
        d="M78.142,141a1.471,1.471,0,0,0,.581-1.587c-4.205-16.262-5.6-47.441-4.641-56.78.976-9.51-.975-31.7-1.219-42.92-.191-8.78,4.7-28.168,6.831-36.178A2.814,2.814,0,0,0,76.944,0L2.792.668A2.813,2.813,0,0,0,0,3.5a2.975,2.975,0,0,0,.037.432C3.3,24.043,4.9,65.224,6.288,76.3c1.463,11.7,9.267,21.948,8.779,30.971-.423,7.834-2.685,27.844-3.274,32.242a1.474,1.474,0,0,0,.824,1.533c3.512,1.691,16.77,5.993,37.81,4.755C69.611,144.669,76.075,142.456,78.142,141Z"
        transform="translate(0 0)"
        fill="#ead9e7"
      />

      <Path
        id={fma73058}
        dataName="Path 454"
        d="M49.568,35.564l-21.414-.1a1.428,1.428,0,0,0-1.434,1.422c0,.031,0,.063,0,.094l2.7,43.344,2.1,37.116a1.427,1.427,0,0,0,1.432,1.346l20.849-.109a1.427,1.427,0,0,0,1.42-1.432c0-.023,0-.045,0-.068l-4.24-80.255A1.428,1.428,0,0,0,49.568,35.564Z"
        transform="translate(0 0)"
        fill={getColorFma(fma73058, selectedBodyPart)}
        onPressIn={() => onPressClick(fma73058)}
        onClick={() => onPressClick(fma73058)}
      />

      <Path
        id={fma297445}
        dataName="Path 455"
        d="M58.407,118.659l16.879-.081c-1.4-14.774-1.8-30.15-1.2-35.942a22.582,22.582,0,0,0,.244-3.17l-17.839.406a1.426,1.426,0,0,0-1.4,1.456l0,.044,1.881,35.933A1.426,1.426,0,0,0,58.407,118.659Z"
        transform="translate(0 0)"
        fill={getColorFma(fma297445, selectedBodyPart)}
        onPressIn={() => onPressClick(fma297445)}
        onClick={() => onPressClick(fma297445)}
      />

      <Path
        id={fma305666}
        dataName="Path 456"
        d="M72.863,39.716a37.654,37.654,0,0,1,.2-4.247L54.4,35.385a1.428,1.428,0,0,0-1.433,1.423c0,.025,0,.051,0,.077l1.984,39.854A1.429,1.429,0,0,0,56.416,78.1l17.92-.407C74.519,66.828,73.075,49.284,72.863,39.716Z"
        transform="translate(0 0)"
        fill={getColorFma(fma305666, selectedBodyPart)}
        onPressIn={() => onPressClick(fma305666)}
        onClick={() => onPressClick(fma305666)}
      />

      <Path
        id={fma44622}
        dataName="Path 457"
        d="M23.7,35.5,3.268,35.157C4.557,52.341,5.462,68.08,6.289,74.691a36.385,36.385,0,0,0,.752,4.023l19,.245a1.428,1.428,0,0,0,1.445-1.412c0-.033,0-.066,0-.1L25.1,36.848A1.427,1.427,0,0,0,23.7,35.5Z"
        transform="translate(0 0)"
        fill={getColorFma(fma44622, selectedBodyPart)}
        onPressIn={() => onPressClick(fma44622)}
        onClick={() => onPressClick(fma44622)}
      />

      <Path
        id={fma297439}
        dataName="Path 458"
        d="M7.04,80.32c2.421,10.046,8.458,18.973,8.027,26.947-.143,2.654-.477,6.915-.876,11.6l13.988.092a1.426,1.426,0,0,0,1.436-1.417c0-.03,0-.061,0-.091L27.595,81.839a1.427,1.427,0,0,0-1.412-1.346Z"
        transform="translate(0 0)"
        fill={getColorFma(fma297439, selectedBodyPart)}
        onPressIn={() => onPressClick(fma297439)}
        onClick={() => onPressClick(fma297439)}
      />

      <Path
        id="Path_496"
        dataName="Path 496"
        d="M21.9,80.685s22.314-4.755,42.8,0"
        transform="translate(0 0)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
