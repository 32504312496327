import {useEffect, useState, useCallback} from 'react';
import {userPrivacyStorage, UserPrivacy} from 'modules';

export default function useUserPrivacy() {
  const [userPrivacy, setUserPrivacyValue] = useState('');
  const userPrivacyChecked = userPrivacy === UserPrivacy.Checked;

  const checkForUserPrivacy = async () => {
    const response = await userPrivacyStorage.getStatus();
    const value =
      response === UserPrivacy.Checked
        ? UserPrivacy.Checked
        : UserPrivacy.Unchecked;
    setUserPrivacyValue(value);
  };

  useEffect(() => {
    checkForUserPrivacy();
  }, []);

  const setUserPrivacy = useCallback(() => {
    userPrivacyStorage.setChecked();
  }, []);

  return [userPrivacyChecked, setUserPrivacy];
}
