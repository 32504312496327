import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {fma22552, fma258847, fma35503, fma73092} from 'utils';
import {getColorFma} from 'utils';

export const Foot_Right_Outside_fma2340 = ({
  selectedBodyPart,
  onPressClick,
}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163.453 84.049">
      <Path
        id="Path_611"
        dataName="Path 611"
        d="M5224.993,260.6h40.86a5.851,5.851,0,0,1,5.843,5.527l.1,1.826c.413,7.422,6.216,16.162,12.9,19.422l54.477,26.578a18.124,18.124,0,0,0,6.672,1.541h1.075a12.119,12.119,0,0,1,5.885,1.525l8.694,4.83a12.438,12.438,0,0,0,4.965,1.318l7.036.082s2.673,6.688.532,10.8a11.5,11.5,0,0,1-10.189,6.1c-9.773,0-27.828,4.4-33.056,4.4H5319.5q-2.2,0-4.386-.166c-9.7-.741-49.929-3.7-74.26-3.7-8.8,0-25.189-2.139-28.516-11.407s3.219-19.249,4.288-27.625c.373-2.92,1.868-22.575,2.519-35.5A5.845,5.845,0,0,1,5224.993,260.6Z"
        transform="translate(-5211.408 -260.496)"
        fill="#ead9e7"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id="Path_612"
        dataName="Path 612"
        d="M5363.662,323.4a2.517,2.517,0,0,0,.861,3.267c1.553,1.052,6.936.683,9.387.505"
        transform="translate(-5211.408 -260.496)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id={fma258847}
        dataName="Path 613"
        d="M5229.332,262.694l.715,27.884a2.088,2.088,0,0,1-.1.712l-9.795,30.246a2.137,2.137,0,0,1-2.191,1.472l-6.485-.484c-.18-5.529,4.447-15.022,5.157-20.872.707-5.822,2.816-41.156,2.816-41.156l7.783.117A2.136,2.136,0,0,1,5229.332,262.694Z"
        transform="translate(-5211.408 -260.496)"
        fill={getColorFma(fma258847, selectedBodyPart)}
        onPressIn={() => onPressClick(fma258847)}
        onClick={() => onPressClick(fma258847)}
      />
      <Path
        id={fma35503}
        dataName="Path 614"
        d="M5231.1,263.608l.526,26.874a2.889,2.889,0,0,1-.134.93l-9.37,29.665a2.9,2.9,0,0,0,1.816,3.611l25.093,8.67a2.9,2.9,0,0,0,3.609-1.6l10.236-23.884a2.884,2.884,0,0,0,.234-1.087l.8-43.233a2.9,2.9,0,0,0-2.9-2.951l-27.021.049A2.9,2.9,0,0,0,5231.1,263.608Z"
        transform="translate(-5211.408 -260.496)"
        fill={getColorFma(fma35503, selectedBodyPart)}
        onPressIn={() => onPressClick(fma35503)}
        onClick={() => onPressClick(fma35503)}
      />
      <Path
        id={fma73092}
        dataName="Path 615"
        d="M5265.914,260.6h0c2.732.143,5.635,2.422,5.674,5.158h0a24.969,24.969,0,0,0,13.106,21.616h0l43.468,21.157a3.624,3.624,0,0,1,.8,5.984l-14.438,12.665a3.624,3.624,0,0,1-3.925.559l-43.784-20.154a3.625,3.625,0,0,1-2.09-3.314l.368-42.9A.776.776,0,0,1,5265.914,260.6Z"
        transform="translate(-5211.408 -260.496)"
        fill={getColorFma(fma73092, selectedBodyPart)}
        onPressIn={() => onPressClick(fma73092)}
        onClick={() => onPressClick(fma73092)}
      />
      <Path
        id={fma22552}
        dataName="Path 616"
        d="M5317.513,334.813l-.006,2.35a2.9,2.9,0,0,1-2.885,2.629h-38.85a2.892,2.892,0,0,1-.656-.075l-18.356-4.7a2.9,2.9,0,0,1-2.007-3.965l8.241-20.424a2.9,2.9,0,0,1,3.869-1.491l48.973,22.771A2.9,2.9,0,0,1,5317.513,334.813Z"
        transform="translate(-5211.408 -260.496)"
        fill={getColorFma(fma22552, selectedBodyPart)}
        onPressIn={() => onPressClick(fma22552)}
        onClick={() => onPressClick(fma22552)}
      />
    </Svg>
  );
};
