import axios from 'axios';
import {app, bootStrapUrl, userId} from 'config';
import {
  APPLICATION_JSON,
  COMPATIBILITY_CHECK,
  GET_ASSESSMENT_TOKEN,
  HEALTH_CHECK,
  SUBSYSTEM_STATUS_CHECK,
  UMA_TOKEN,
} from '../constants';
import {getSymptomList} from './SymptomService';

export const loginPlatform = async () => {
  let resultGetApplicationToken = await getApplicationToken();
  return new Promise((resolve, reject) => {
    if (resultGetApplicationToken != null) {
      resolve(resultGetApplicationToken);
    } else {
      reject(resultGetApplicationToken);
    }
  });
};

export const getApplicationToken = () => {
  let url = UMA_TOKEN;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      'Access-Control-Allow-Origin': '*',
    },
  };

  let body = {
    id: userId,
    app: app,
  };

  return new Promise((resolve, reject) => {
    axios
      .put(url, body, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAssessmentToken = (customerId, patientId) => {
  let url = GET_ASSESSMENT_TOKEN;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      'Access-Control-Allow-Origin': '*',
    },
  };

  let body = {
    id: userId,
    app: app,
    vc: {
      customer_id: customerId,
      patient_id: patientId,
      virtual_clinic: {
        clinic_id: customerId,
        country_code: 'usa',
        display_name: 'Home',
      },
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .put(url, body, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const initializeClinBOT = async (uri, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let dxSubsystemInitialized;
      let dxSubsystemCompatible;
      let resultSendDxSessionSubsystemStatusCheck;

      // Check Sub System Status
      resultSendDxSessionSubsystemStatusCheck =
        await sendDxSessionSubsystemStatusCheck(uri, token);

      let operational =
        resultSendDxSessionSubsystemStatusCheck.data.operational;
      let initialized =
        resultSendDxSessionSubsystemStatusCheck.data.initialized;
      dxSubsystemInitialized = operational && initialized;

      let resultSendDxSessionCompatibilityCheck =
        await sendDxSessionCompatibilityCheck(uri, token);

      if (!resultSendDxSessionCompatibilityCheck.data) {
        reject(resultSendDxSessionCompatibilityCheck);
      }

      let compatible = resultSendDxSessionCompatibilityCheck.data.compatible;
      dxSubsystemCompatible = compatible;

      if (!dxSubsystemInitialized || !dxSubsystemCompatible) {
        reject(
          'Diagnostic subsystem not initialized or not compatible.\nPlease try again later.',
        );
      }

      // Check Patient Agent Health Check
      let resultPatientAgentHealthCheck = await sendPatientAgentHealthCheck(
        uri,
        token,
      );

      if (!resultPatientAgentHealthCheck.data) {
        reject(
          'Diagnostic subsystem patient agent health check failed.\nPlease try again later.',
        );
      }

      let healthy = resultPatientAgentHealthCheck.data.healthy;
      if (!healthy) {
        reject(
          'Diagnostic subsystem patient agent health check failed.\nPlease try again later.',
        );
      }

      // Check Get Symptom List
      let resultSymptomList = await getSymptomList(uri, token, '');

      if (!resultSymptomList.data) {
        reject(
          'Diagnostic subsystem Get Symptom failed.\nPlease try again later.',
        );
      }
      resolve(resultSymptomList);
    } catch (err) {
      reject(err);
    }
  });
};

export const sendDxSessionSubsystemStatusCheck = (uri, token) => {
  let url = uri + SUBSYSTEM_STATUS_CHECK;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {};

  return axios.put(url, body, config);
};

export const sendDxSessionCompatibilityCheck = (uri, token) => {
  let url = uri + COMPATIBILITY_CHECK;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {};

  return new Promise((resolve, reject) => {
    axios
      .put(url, body, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendPatientAgentHealthCheck = (uri, token) => {
  let url = uri + HEALTH_CHECK;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = {};

  return new Promise((resolve, reject) => {
    axios
      .put(url, body, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getBootstrapUrls = () => {
  return axios.get(bootStrapUrl);
};
