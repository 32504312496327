import {Text} from 'libs';
import React, {PropsWithChildren} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Colors, Spacing} from 'utils';
import {Abdomen_fma20373} from './Abdomen_fma20373';
import {Abdomen_fma20377} from './Abdomen_fma20377';
import {Abdomen_fma24040} from './Abdomen_fma24040';
import {Abdomen_fma9577} from './Abdomen_fma9577';
import {Back_fma25056} from './Back_fma25056';
import {Big_Toe_Right_fma37854} from './Big_Toe_Right_fma37854';
import {Chest_fma223729_Male} from './Chest_fma223729_Male';
import {Finger_Front_fma38325} from './Finger_Front_fma38325';
import {Foot_Left_Bottom_fma272} from './Foot_Left_Bottom_fma27.2';
import {Foot_Left_Inside_fma273} from './Foot_Left_Inside_fma27.3';
import {Foot_Left_Outside_fma274} from './Foot_Left_Outside_fma27.4';
import {Foot_Left_Top_271} from './Foot_Left_Top_27.1';
import {Foot_Right_Bottom_fma2320} from './Foot_Right_Bottom_fma23.2.0';
import {Foot_Right_Inside_fma2330} from './Foot_Right_Inside_fma23.3.0';
import {Foot_Right_Outside_fma2340} from './Foot_Right_Outside_fma23.4.0';
import {Foot_Right_Top_fma2310} from './Foot_Right_Top_fma23.1.0';
import {Hand_Left_Back_fma9714} from './Hand_Left_Back_fma9714';
import {Hand_Left_Front_fma9714} from './Hand_Left_Front_fma9714';
import {Hand_Right_Back_fma321784} from './Hand_Right_Back_fma321784';
import {Hand_Right_Front_fma321784} from './Hand_Right_Front_fma321784';
import {Knee_Left_Back_fma24883} from './Knee_Left_Back_fma24883';
import {Knee_Left_Front_fma24883} from './Knee_Left_Front_fma24883';
import {Knee_Right_Back_fma24977} from './Knee_Right_Back_fma24977';
import {Knee_Right_Front_fma24977} from './Knee_Right_Front_fma24977';
import {Small_Toe} from './Small_Toe';

const selectedBodyPart = '';
const pressHandler = () => null;

export function HumanBodySvgScreen() {
  return (
    <SafeAreaView>
      <ScrollView>
        <Container>
          <Title>15.1.0.</Title>
          <Hand_Right_Back_fma321784
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>15.2.0.</Title>
          <Hand_Right_Front_fma321784
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma38325</Title>
          <Finger_Front_fma38325
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>19.1.0.</Title>
          <Hand_Left_Back_fma9714
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>19.2.0.</Title>
          <Hand_Left_Front_fma9714
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>27.2.0</Title>
          <Foot_Left_Bottom_fma272
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>27.3.0</Title>
          <Foot_Left_Inside_fma273
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>27.4.0</Title>
          <Foot_Left_Outside_fma274
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>27.1.0</Title>
          <Foot_Left_Top_271
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma24883_F</Title>
          <Knee_Left_Front_fma24883
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma24883_B</Title>
          <Knee_Left_Back_fma24883
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma24977_B</Title>
          <Knee_Right_Back_fma24977
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma37854</Title>
          <Big_Toe_Right_fma37854
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>23.2.0</Title>
          <Foot_Right_Bottom_fma2320
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>23.3.0</Title>
          <Foot_Right_Inside_fma2330
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>23.4.0</Title>
          <Foot_Right_Outside_fma2340
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>23.1.0.</Title>
          <Foot_Right_Top_fma2310
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma24977_F</Title>
          <Knee_Right_Front_fma24977
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>small_toe</Title>
          <Small_Toe
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma9577</Title>
          <Abdomen_fma9577
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma24040</Title>
          <Abdomen_fma24040
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma20373</Title>
          <Abdomen_fma20373
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma20377</Title>
          <Abdomen_fma20377
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma25056</Title>
          <Back_fma25056
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>

        <Container>
          <Title>fma223729</Title>
          <Chest_fma223729_Male
            selectedBodyPart={selectedBodyPart}
            onPressClick={pressHandler}
          />
        </Container>
      </ScrollView>
    </SafeAreaView>
  );
}

function Container({children}: PropsWithChildren<{}>) {
  return (
    <View style={styles.container}>
      {children}
      <Separator />
    </View>
  );
}

function Title({children}: PropsWithChildren<{}>) {
  return (
    <View style={styles.title}>
      <Text.Body>{children}</Text.Body>
    </View>
  );
}

function Separator() {
  return <View style={styles.separator} />;
}

const styles = StyleSheet.create({
  container: {
    width: 230,
    height: 230,
    marginBottom: Spacing.large,
    flexDirection: 'column',
    alignItems: 'center',
    padding: Spacing.base,
  },
  title: {
    marginVertical: Spacing.medium,
  },
  separator: {
    width: 300,
    height: 4,
    marginTop: Spacing.base,
    backgroundColor: Colors.topPurple,
  },
});
