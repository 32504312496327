import React from 'react';
import {View, Text, FlatList, StyleSheet} from 'react-native';
import {Colors} from 'utils';
import {MARKPRO_BOLD, MARKPRO_BOOK, MARKPRO_MEDIUM} from 'utils';
import {TipItem} from './Risks';
import {AssessmentSummary} from '../../../../components';

function Symptoms(props) {
  const title = 'Symptoms';

  const renderFooter = () => {
    return (
      <View>
        <View style={[styles.backgroundColorWhite]}>
          <AssessmentSummary
            clinicalPresentations={props.clinicalPresentations}
            title={'Your Symptoms'}
          />
        </View>
      </View>
    );
  };

  if (props?.ddoConditionLibrary == null) {
    return (
      <View style={styles.commonContainer}>
        <Text style={styles.titleText}>{title}</Text>
        <Text style={[styles.noDataAvailable]}>
          {'-- No data available --'}
        </Text>
      </View>
    );
  }

  return (
    <FlatList
      style={[styles.preventionFlatList]}
      showsVerticalScrollIndicator={false}
      data={
        props?.ddoConditionLibrary == null
          ? []
          : props.ddoConditionLibrary.symptoms_list
      }
      ListHeaderComponent={
        <View style={styles.commonContainer}>
          <Text style={styles.titleText}>{title}</Text>
        </View>
      }
      renderItem={({item, index}) => {
        return <TipItem description={item} />;
      }}
      ListFooterComponent={renderFooter()}
      keyExtractor={(item) => `${item}`}
    />
  );
}

const styles = StyleSheet.create({
  preventionFlatList: {flex: 1, backgroundColor: Colors.systemChatBubblesColor},
  noDataAvailable: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 16,
    fontFamily: MARKPRO_BOOK,
    color: Colors.textBlackColor,
  },
  bottomButton: {paddingVertical: 30},
  paddingHorizontal: {paddingHorizontal: 30},
  backgroundColorWhite: {backgroundColor: Colors.white},
  titleText: {
    flex: 1,
    fontSize: 22,
    fontFamily: MARKPRO_BOLD,
    color: Colors.textDarkGreyColor,
    marginVertical: 20,
  },
  descText: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 16,
    fontFamily: MARKPRO_BOOK,
    color: Colors.textBlackColor,
  },
  tipTitleText: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 17,
    fontFamily: MARKPRO_MEDIUM,
    color: Colors.textDarkGreyColor,
    marginVertical: 10,
  },
  reminderText: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 13,
    fontFamily: MARKPRO_BOOK,
    color: Colors.textBlackColor,
    marginVertical: 10,
  },
  warningText: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 14,
    fontFamily: MARKPRO_MEDIUM,
    color: Colors.deleteDarkColor,
  },
  deleteText: {
    textAlign: 'justify',
    fontSize: 16,
    fontFamily: MARKPRO_BOLD,
    color: Colors.deleteTextColor,
    paddingHorizontal: 10,
  },

  tipText: {
    flex: 1,
    color: Colors.textBlackColor,
    fontSize: 14,
    fontFamily: MARKPRO_BOOK,
    marginHorizontal: 10,
    marginVertical: 5,
  },
  commonContainer: {backgroundColor: Colors.white, paddingHorizontal: 30},

  symptomTitle: {
    fontFamily: MARKPRO_MEDIUM,
    fontSize: 17,
    color: Colors.textDarkGreyColor,
  },
  symptomDesc: {
    fontFamily: MARKPRO_BOOK,
    fontSize: 14,
    color: Colors.textBlackColor,
  },
  iconStyle: {
    marginRight: 10,
    width: 24,
    height: 24,
    textAlign: 'center',
    textAlignVertical: 'center',
    backgroundColor: Colors.borderPink,
    borderRadius: 100,
  },

  devider: {
    borderWidth: 0.75,
    borderColor: Colors.borderPink,
    marginBottom: 20,
  },
});

export default Symptoms;
