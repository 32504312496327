import {colors} from './colors';
import {ITheme} from './ThemeProvider';
import {typography} from './typography';

export const theme: ITheme = {
  Button: {
    raised: true,
    titleStyle: {fontFamily: 'MarkPro-Bold', fontWeight: '700'},
    buttonStyle: {borderRadius: 18, height: 48},
    containerStyle: {borderRadius: 18, height: 48},
  },
  CheckBox: {
    textStyle: {fontFamily: 'MarkPro-Medium', fontSize: typography.body1},
  },
  colors: {
    primary: colors.primary,
    disabled: colors.primaryDisabled,
    secondary: colors.secondary,
    grey0: colors.grey0,
  },
};
