import {isWeb} from 'config';
import lodash from 'lodash';
import {
  Layout,
  SearchSymptomHeader,
  SearchSymptomsBox,
  SearchSymptomsResults,
  SymptomItem,
  SymptomItemSeparator,
} from 'modules';
import React, {useCallback, useEffect, useState} from 'react';
import {
  ActivityIndicator,
  FlatList,
  Keyboard,
  Modal,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import {connect} from 'react-redux';
import {
  changeAndroidStatusBarColor,
  Colors,
  fetchAllSymptomListLayman,
  getSymptomList,
  getSymptomListFromSynonyms,
  logger,
  viewStyles,
  widthToDp,
} from 'utils';
import SearchBar from '../reusables/components/SearchBar';

interface ISymptomListModalProps {
  visible: boolean;
  platformUrl: string;
  platformToken: string;
  profileSexAtBirth: string;
  isForTracker: boolean;
  symptomQueueToExclude: any[];
  setSessionId: any;
  symptomListStage: any;
  symptomListTag: any;
  closeModal: () => void;
  chatInputAction: (message: any) => void;
}

function SymptomListModal({
  visible,
  platformToken,
  platformUrl,
  symptomQueueToExclude,
  isForTracker,
  setSessionId,
  symptomListStage,
  symptomListTag,
  closeModal,
  chatInputAction,
  profileSexAtBirth = 'Male',
}: ISymptomListModalProps) {
  const [enteredText, setEnteredText] = useState('');
  const [symptomList, setSymptomList] = useState<any>([]);
  const [lbeSymptomList, setLbeSymptomList] = useState([]);
  const [symptomFilteredList, setSymptomFilteredList] = useState([]);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    // fetch only if the gender is defined
    profileSexAtBirth && fetchInitialSymptoms();

    async function fetchInitialSymptoms() {
      const resLbeSymptomList = await fetchAllSymptomListLayman();

      if (resLbeSymptomList != null) {
        setLbeSymptomList(resLbeSymptomList as any);
      }

      const sexAtBirth = profileSexAtBirth === 'Male' ? 'm' : 'f';

      try {
        const {data} = await getSymptomList(
          platformUrl,
          platformToken,
          sexAtBirth,
        );
        setInitialList(data.sdco_references);
      } catch (err) {
        logger('Get symptom list ', err as any);
      }
    }
  }, [profileSexAtBirth]);

  useEffect(() => {
    const _keyboardDidShow = (e: any) => {
      if (Platform.OS === 'ios') {
        setKeyboardHeight(e.endCoordinates.height);
      }
    };

    const _keyboardDidHide = () => {
      setKeyboardHeight(0);
    };

    const didShowEmitterSubscribtion = Keyboard.addListener(
      'keyboardDidShow',
      _keyboardDidShow,
    );
    const didHideEmitterSubscribtion = Keyboard.addListener(
      'keyboardDidHide',
      _keyboardDidHide,
    );

    return () => {
      didShowEmitterSubscribtion.remove();
      didHideEmitterSubscribtion.remove();
    };
  }, [visible]);

  useEffect(
    React.useCallback(() => {
      const color = visible ? Colors.topPurple : Colors.primary;
      changeAndroidStatusBarColor(color);
    }, [visible]),
  );

  const setInitialList = (data: any) => {
    const dif = lodash.differenceBy(data, symptomQueueToExclude, 'sdco_id');

    setSymptomList(dif as any);
  };

  const searchMatchingSymptoms = (text: string) => {
    setIsSearching(true);

    getSymptomListFromSynonyms(text, platformUrl, platformToken)
      .then(async ({data}: any) => {
        let filteredSymptomList: any = [];

        if (data != null) {
          data.data.forEach((synonyms: any) => {
            const synonymsId = synonyms.sdco_id.trim();

            const found = symptomList.filter((symp: any) => {
              const symptomId = symp.sdco_id.trim();
              return symptomId === synonymsId;
            });

            if (found?.length > 0) {
              filteredSymptomList = [
                ...filteredSymptomList,
                {...found[0], description: synonyms.description},
              ];
            }
          });
        }

        const symptomUpdatedList: any = [];

        if (filteredSymptomList.length > 0) {
          filteredSymptomList.forEach((symptom: any) => {
            const filtered: any = lbeSymptomList.filter(
              (lbe: any) => lbe.document_id === symptom.sdco_id,
            );

            symptomUpdatedList.push({
              ...symptom,
              edited_display_name_1: filtered[0].edited_display_name_1,
            });
          });
        }

        setSymptomFilteredList(symptomUpdatedList);
        setIsSearching(false);
      })
      .catch(() => {
        setIsSearching(false);
      });
  };

  const symptomItemPressHandler = useCallback((selectedItem: any) => {
    const item = {
      display_name: selectedItem.display_name,
      display_name_layman: selectedItem.display_name_layman,
      sdco_id: selectedItem.sdco_id,
      edited_display_name_1: selectedItem.edited_display_name_1,
    };

    if (isForTracker) {
      setSessionId('', {...item});
      closeModal();
      return;
    }

    setSymptomFilteredList([]);
    setEnteredText('');

    const msg = {
      title: item.edited_display_name_1,
      value: {action: symptomListStage, option: '113131'},
      data: {item: item},
      tag: symptomListTag,
    };

    chatInputAction(msg);
    closeModal();
    setSessionId(null, item);
  }, []);

  const clear = () => {
    setEnteredText('');
    setSymptomFilteredList([]);
  };

  return (
    <Modal visible={visible} animationType="slide">
      <Layout style={isWeb ? styles.outerContainer : {}}>
        <Layout style={styles.container}>
          <SearchSymptomHeader
            onPress={() => {
              clear();
              closeModal();
            }}
          />
          <View style={styles.symptomListView}>
            {symptomList?.length > 0 ? (
              <SearchBar
                text={enteredText}
                onTextEdit={(text: string) => {
                  setEnteredText(text);

                  if (text.length < 3) {
                    setSymptomFilteredList([]);
                    return;
                  }

                  searchMatchingSymptoms(text);
                }}
                clear={clear}
                autoFocus={true}
                description="Search here"
                showClose
                editable
              />
            ) : (
              <ActivityIndicator color={Colors.primary} size={25} />
            )}
          </View>
          {!!enteredText && enteredText.length >= 3 ? (
            <SearchSymptomsResults
              resultsNumber={symptomFilteredList?.length}
              symptomText={enteredText}
            />
          ) : null}

          {symptomList?.length && !enteredText ? (
            <SearchSymptomsBox emptyContent={false} />
          ) : null}

          <View
            style={
              (styles.isSearching,
              {
                paddingHorizontal: widthToDp(4),
                flex: 1,
                paddingTop: 20,
                paddingBottom: keyboardHeight,
              })
            }>
            {isSearching ? (
              <ActivityIndicator color={Colors.primary} />
            ) : enteredText &&
              enteredText.length >= 3 &&
              symptomFilteredList.length === 0 ? (
              <SearchSymptomsBox emptyContent />
            ) : (
              <FlatList
                keyExtractor={(item: any) => item.sdco_id}
                data={symptomFilteredList}
                contentContainerStyle={styles.listStyle}
                renderItem={({item, index}: any) => (
                  <SymptomItem
                    key={index}
                    itemTitle={item?.edited_display_name_1}
                    enteredText={enteredText}
                    item={item}
                    openSessionAssessmentHandler={symptomItemPressHandler}
                  />
                )}
                keyboardShouldPersistTaps="always"
                ItemSeparatorComponent={SymptomItemSeparator}
                ListFooterComponent={ListFooter}
              />
            )}
          </View>
        </Layout>
      </Layout>
    </Modal>
  );
}

function ListFooter() {
  return <View style={styles.footerStyle} />;
}

const styles = StyleSheet.create({
  isSearching: {
    paddingHorizontal: widthToDp(4),
    flex: 1,
    paddingTop: 20,
  },
  symptomListView: {
    height: 84,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
  },
  listStyle: {
    marginHorizontal: 10,
  },
  footerStyle: {
    height: 50,
  },
  container: {
    maxWidth: isWeb ? '840px' : 'auto',
    minWidth: isWeb ? '50vw' : 'auto',
  },
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const mapStateToProps = (state: any) => {
  return {
    platformUrl: state.application.platformUrl,
    platformToken: state.application.platformToken,
  };
};

export default connect(mapStateToProps)(SymptomListModal);
