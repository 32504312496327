import {isAnonymousFlow} from 'config';
import {Button, Icon as IconLibs} from 'libs';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors, getNameNickname, heightToDp, viewStyles} from 'utils';
import {
  BackIcon,
  DateShadowContainer,
  NameWithDateContainer,
} from '../../../components';

export function ChatHeaderAssessment(props: any) {
  return (
    <View>
      <View style={[styles.nameWithDateContainerView, viewStyles.width100P]}>
        {props.showBackButton && (
          <View style={[styles.backIconView]}>
            <BackIcon color={Colors.white} goBack={props.onBackButton} />
          </View>
        )}

        {isAnonymousFlow ? (
          <View style={styles.row}>
            {props.showRestartButton ? (
              <View style={styles.restartButton}>
                <Button.Secondary
                  title="Restart"
                  onPress={props.showRestartModal}
                  type="clear"
                  buttonStyle={styles.restartButtonStyle}
                />
              </View>
            ) : null}
            <View style={styles.iconSettings}>
              <IconLibs.Settings onPress={props.navigateToSettings} />
            </View>
          </View>
        ) : (
          <>
            <NameWithDateContainer
              name={getNameNickname(props.user)}
              animatedDateStyles={{opacity: props.animatedOpacity}}
              patientPicture={props.user?.patient_picture}
              customerProfile={props.user}
              dateContainerStyles={[styles.dateContainerStyles]}
              showDate={true}
            />
            <View style={[styles.closeView]}>
              <Icon
                name="close"
                onPress={props.setShowCloseWarning}
                size={25}
                color={Colors.white}
              />
            </View>
          </>
        )}
      </View>
      <View>
        {!isAnonymousFlow ? (
          <Animated.View
            style={[
              {transform: [{translateY: props.translateY}]},
              viewStyles.zIndex1,
            ]}>
            <DateShadowContainer />
          </Animated.View>
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  dateContainerStyles: {
    minHeight: heightToDp(2),
    top: 0,
    backgroundColor: Colors.primary,
    height: 50,
  },
  nameWithDateContainerView: {
    backgroundColor: Colors.primary,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: 65,
    zIndex: 2,
  },
  closeView: {
    position: 'absolute',
    right: 10,
    zIndex: 20,
  },
  backIconView: {
    position: 'absolute',
    left: 0,
    zIndex: 20,
  },
  restartButtonStyle: {
    width: 130,
    height: 46,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  iconSettings: {
    marginLeft: 'auto',
  },
  restartButton: {
    marginLeft: 'auto',
    transform: [{translateX: 21}],
  },
});
