import React from 'react';
import Svg, {Line, Path} from 'react-native-svg';
import {
  fma19_2_6,
  fma38285,
  fma38291,
  fma38294,
  fma61528,
  fma79172,
} from 'utils';
import {getColorFma} from 'utils';

export const Hand_Left_Front_fma9714 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.829 200.301">
      <Path
        id="Path_86"
        dataName="Path 86"
        d="M117.314,200.018l-45.753.283A6.517,6.517,0,0,1,65,194c-.309-8.94-1.1-26.442-7.013-31.582C49.58,155.1,38,137.789,31.782,133.4S23.37,110.354,16.054,103.77c-1.829-1.646-3.979-3.429-6.1-5.275-6.361-5.539-12.466-11.643-8.9-16.307,4.755-6.218,26.7-4.389,42.8,27.435l2.925-16.1S42.779,46.6,41.1,21.4c-.338-5.129,2.989-9.389,7.195-9.876a8.448,8.448,0,0,1,9.144,6.95c4.3,24.917,7.866,56.881,7.866,56.881l4.572-.549,1.681-63.9a12.919,12.919,0,0,1,1.784-6.115c1.28-2.1,3.849-4.949,7.326-4.787C88.537.37,88.3,11.039,88.3,11.039l.239,63.222,3.657.182L99.089,18.9s.975-9.3,7.555-9.023c8.779.365,6.71,16.338,6.71,16.338l-2.961,53.163,5.212,1.829,7.078-42.372s.732-6.4,6.95-6.035,5.121,8.23,5.121,8.23l-7.133,93.827L121.4,163.027l2.372,29.953A6.518,6.518,0,0,1,117.793,200C117.634,200.01,117.475,200.017,117.314,200.018Z"
        transform="translate(0.001 0.003)"
        fill="#ead9e7"
        style="mix-blend-mode: multiply;isolation: isolate"
      />

      <Path
        id={fma19_2_6}
        dataName="Path 87"
        d="M43.854,109.624C35.939,93.976,26.613,85.589,18.609,81.836a1.908,1.908,0,0,0-2.423.717L11.5,90a1.9,1.9,0,0,0,.382,2.471c7.665,6.372,17.1,16.549,25.834,31.937a1.9,1.9,0,0,0,2.416.816,21.991,21.991,0,0,0,11.28-11.091C54.012,108.219,43.854,109.624,43.854,109.624Z"
        transform="translate(0.001 0.003)"
        fill={getColorFma(fma19_2_6, selectedBodyPart)}
        onPressIn={() => onPressClick(fma19_2_6)}
        onClick={() => onPressClick(fma19_2_6)}
      />

      <Path
        id={fma19_2_6}
        dataName="Path 88"
        d="M45.331,76.225a2.44,2.44,0,0,0,2.633,2.231c.078-.007.156-.017.234-.031l14.851-2.66a2.439,2.439,0,0,0,2-2.68c-.692-5.964-2.694-22.765-5.232-39.753a1.084,1.084,0,0,0-1.126-.92l-15.647.824a1.073,1.073,0,0,0-1.023,1.123v.027C43.045,48.192,44.348,64.3,45.331,76.225Z"
        transform="translate(0.001 0.003)"
        fill={getColorFma(fma19_2_6, selectedBodyPart)}
        onPressIn={() => onPressClick(fma19_2_6)}
        onClick={() => onPressClick(fma19_2_6)}
      />

      <Path
        id={fma19_2_6}
        dataName="Path 89"
        d="M69.942,72.464a2.224,2.224,0,0,0,2.165,2.279c.041,0,.081,0,.122,0l14.143-.416a2.22,2.22,0,0,0,2.156-2.23l-.189-50.288a1.08,1.08,0,0,0-1.085-1.075h-.015l-14.912.293a1.079,1.079,0,0,0-1.057,1.05Z"
        transform="translate(0.001 0.003)"
        fill={getColorFma(fma19_2_6, selectedBodyPart)}
        onPressIn={() => onPressClick(fma19_2_6)}
        onClick={() => onPressClick(fma19_2_6)}
      />

      <Path
        id={fma19_2_6}
        dataName="Path 90"
        d="M92.453,72.373A2.412,2.412,0,0,0,94.21,75l13.4,3.665a2.413,2.413,0,0,0,2.964-1.691,2.37,2.37,0,0,0,.082-.509L113,32.762a1.082,1.082,0,0,0-.876-1.118l-13.151-2.5A1.08,1.08,0,0,0,97.713,30c0,.024-.008.047-.011.071Z"
        transform="translate(0.001 0.003)"
        fill={getColorFma(fma19_2_6, selectedBodyPart)}
        onPressIn={() => onPressClick(fma19_2_6)}
        onClick={() => onPressClick(fma19_2_6)}
      />

      <Path
        id={fma19_2_6}
        dataName="Path 91"
        d="M115.963,79.336a2.185,2.185,0,0,0,1.4,2.4,89.275,89.275,0,0,1,10.854,4.917,2.177,2.177,0,0,0,2.947-.889,2.146,2.146,0,0,0,.253-.866l2.673-35.153a1.081,1.081,0,0,0-.826-1.132l-10.617-2.527a1.078,1.078,0,0,0-1.3.8c-.006.024-.011.048-.015.073Z"
        transform="translate(0.001 0.003)"
        fill={getColorFma(fma19_2_6, selectedBodyPart)}
        onPressIn={() => onPressClick(fma19_2_6)}
        onClick={() => onPressClick(fma19_2_6)}
      />

      <Path
        id={fma79172}
        dataName="Path 99"
        d="M47.72,107.7l-3.5-.03,2.564-14.144s-.043-3.827-.766-12.559c7.37-1.846,36.488-9.6,63.922,0,0,0,15.793,6.073,20.851,9.053l-2.938,41.853-26.143-.731-26.154,1.1L46.12,134.7a2.2,2.2,0,0,1-2.011-.971l-3.584-5.373a1.181,1.181,0,0,1,.359-1.631.428.428,0,0,1,.042-.026,32.347,32.347,0,0,0,11.461-10.12C55.585,111.727,52.365,107.552,47.72,107.7Z"
        transform="translate(0.001 0.003)"
        fill={getColorFma(fma79172, selectedBodyPart)}
        onPressIn={() => onPressClick(fma79172)}
        onClick={() => onPressClick(fma79172)}
      />

      <Path
        id={fma38294}
        dataName="Path 100"
        d="M72.153,133.754l-23.676,1.882a1.8,1.8,0,0,0-1.279,2.845L65.9,165.091a1.723,1.723,0,0,0,1.6.733l8.3-.919a1.785,1.785,0,0,0,1.546-1.994l0-.013-3.326-27.575A1.757,1.757,0,0,0,72.153,133.754Z"
        transform="translate(0.001 0.003)"
        fill={getColorFma(fma38294, selectedBodyPart)}
        onPressIn={() => onPressClick(fma38294)}
        onClick={() => onPressClick(fma38294)}
      />

      <Path
        id={fma61528}
        dataName="Path 101"
        d="M101.878,160.51l-1.533-26.259a1.765,1.765,0,0,0-1.812-1.687l-20.9.884a1.78,1.78,0,0,0-1.673,1.881c0,.046.008.092.014.137L79.266,162.5a1.757,1.757,0,0,0,1.876,1.567l19.144-1.657A1.78,1.78,0,0,0,101.878,160.51Z"
        transform="translate(0.001 0.003)"
        fill={getColorFma(fma61528, selectedBodyPart)}
        onPressIn={() => onPressClick(fma61528)}
        onClick={() => onPressClick(fma61528)}
      />

      <Path
        id={fma38291}
        dataName="Path 102"
        d="M102.01,134.457l1.6,26.036a1.768,1.768,0,0,0,1.732,1.682l14.7.078a1.754,1.754,0,0,0,1.712-1.409l5.46-25.477a1.786,1.786,0,0,0-1.34-2.14,1.837,1.837,0,0,0-.314-.043l-21.769-.636A1.772,1.772,0,0,0,102,134.3C102,134.353,102.006,134.4,102.01,134.457Z"
        transform="translate(0.001 0.003)"
        fill={getColorFma(fma38291, selectedBodyPart)}
        onPressIn={() => onPressClick(fma38291)}
        onClick={() => onPressClick(fma38291)}
      />

      <Path
        id={fma38285}
        dataName="Path 103"
        d="M79.742,165.8l-18.336,2.469a63.916,63.916,0,0,1,3.076,16.1l58.347-3.334-1.348-17.005-17.582-.42Z"
        transform="translate(0.001 0.003)"
        fill={getColorFma(fma38285, selectedBodyPart)}
        onPressIn={() => onPressClick(fma38285)}
        onClick={() => onPressClick(fma38285)}
      />

      <Line
        id="Line_1"
        dataName="Line 1"
        y1="0.732"
        x2="8.413"
        transform="translate(53.726 52.684)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.25"
      />
      <Line
        id="Line_2"
        dataName="Line 2"
        x2="10.608"
        y2="0.366"
        transform="translate(74.211 44.637)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.25"
      />
      <Line
        id="Line_3"
        dataName="Line 3"
        x2="5.853"
        y2="1.463"
        transform="translate(120.301 66.95)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.25"
      />
      <Path
        id="Path_105"
        dataName="Path 105"
        d="M73.844,91.456s17.924,17.558,40.6,14.266"
        transform="translate(0.001 0.003)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.25"
      />
      <Path
        id="Path_106"
        dataName="Path 106"
        d="M65.931,101.985c9.415,2.923,23.338,8.983,33.514,20.929"
        transform="translate(0.001 0.003)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke-width="1.25"
      />
    </Svg>
  );
};
