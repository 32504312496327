// @ts-nocheck

import React from 'react';
import Svg, {Circle, Rect} from 'react-native-svg';

export function ExclamationPointSvg() {
  return (
    <Svg width={4} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
      <Circle cx={2} cy={12.75} r={1.25} fill="#CC3030" />
      <Rect x={1} y={0.5} width={2} height={9} rx={1} fill="#CC3030" />
    </Svg>
  );
}
