import React, {PropsWithChildren} from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

interface IRowProps {
  style?: ViewStyle;
  margin?: number | string;
  marginVertical?: number | string;
  marginHorizontal?: number | string;
  marginTop?: number | string;
  marginBottom?: number | string;
  marginLeft?: number | string;
  marginRight?: number | string;
  padding?: number | string;
  paddingVertical?: number | string;
  paddingHorizontal?: number | string;
  justifyContent?: ViewStyle['justifyContent'];
  alignItems?: ViewStyle['alignItems'];
  flexWrap?: ViewStyle['flexWrap'];
  backgroundColor?: string;
  width?: number | string;
  height?: number | string;
  borderColor?: string;
  borderRadius?: number;
  borderTopWidth?: number;
  borderBottomWidth?: number;
}

export function Row({
  children,
  style,
  margin,
  marginVertical,
  marginHorizontal,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  padding,
  paddingVertical,
  paddingHorizontal,
  justifyContent = 'flex-start',
  alignItems = 'stretch',
  flexWrap = 'nowrap',
  backgroundColor,
  height,
  width,
  borderRadius,
  borderColor,
  borderTopWidth,
  borderBottomWidth,
}: PropsWithChildren<IRowProps>) {
  return (
    <View
      style={[
        styles.container,
        {
          margin,
          marginVertical,
          marginHorizontal,
          marginTop,
          marginBottom,
          marginLeft,
          marginRight,
          justifyContent,
          alignItems,
          flexWrap,
          backgroundColor,
          height,
          width,
          padding,
          paddingVertical,
          paddingHorizontal,
          borderRadius,
          borderColor,
          borderTopWidth,
          borderBottomWidth,
        },
        style,
      ]}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
});
