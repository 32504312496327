import React from 'react';
import {Pressable, Text, StyleSheet} from 'react-native';
import {Colors} from 'utils';
import {MARKPRO_BOLD} from 'utils';
import {ListRowEditSvg} from 'components/reusables/components/SVGS';

export const ListRowEdit = ({onPress}) => (
  <Pressable onPress={onPress} style={[styles.listRowEditPressable]}>
    <ListRowEditSvg />
    <Text style={[styles.listRowEditText]}>Edit</Text>
  </Pressable>
);
const styles = StyleSheet.create({
  listRowEditText: {
    color: Colors.primary,
    fontSize: 14,
    fontFamily: MARKPRO_BOLD,
    top: 2,
  },
  listRowEditPressable: {
    width: 75,
    height: 75,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 7,
  },
});
