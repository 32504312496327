import React, {PropsWithChildren} from 'react';
import {
  Colors,
  MARKPRO_BOLD,
  MARKPRO_BOOK,
  MARKPRO_MEDIUM,
  Typography,
} from 'utils';
import {ITextBaseProps, TextBase} from './TextBase';
interface ITextCaptionProps extends ITextBaseProps {
  variant?: 'normal' | 'medium' | 'bold';
  size?: 'caption1' | 'caption2';
  color?: string;
}

const fontFamily = {
  normal: MARKPRO_BOOK,
  medium: MARKPRO_MEDIUM,
  bold: MARKPRO_BOLD,
};

const fontSize = {
  caption1: Typography.caption1,
  caption2: Typography.caption2,
};

export function TextCaption({
  children,
  onPress,
  numberOfLines,
  ellipsizeMode,
  style,
  variant = 'normal',
  size = 'caption1',
  color = Colors.black,
}: PropsWithChildren<ITextCaptionProps>) {
  return (
    <TextBase
      onPress={onPress}
      numberOfLines={numberOfLines}
      ellipsizeMode={ellipsizeMode}
      style={[
        {
          fontFamily: fontFamily[variant],
          fontSize: fontSize[size],
          color,
        },
        style as any,
      ]}>
      {children}
    </TextBase>
  );
}
