import React, {PropsWithChildren} from 'react';
import {Theme, ThemeProvider as ThemeProviderRNE} from 'react-native-elements';

export interface ITheme extends Theme {}

interface IThemeProviderProps {
  theme: ITheme;
}

export function ThemeProvider({
  theme,
  children,
}: PropsWithChildren<IThemeProviderProps>) {
  return <ThemeProviderRNE theme={theme}>{children}</ThemeProviderRNE>;
}
