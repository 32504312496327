import React from 'react';
import {StyleSheet, View} from 'react-native';
import {colors, LightSvg} from 'utils';
import {Text, Image} from 'libs';
import {Row} from 'modules';

export function DiseaseCitationsEmptyContent() {
  return (
    <Row style={styles.boxContainer} marginTop={20}>
      <View style={styles.leftContainer}>
        <View style={styles.lightSvgContainer}>
          <LightSvg />
        </View>
        <View style={styles.caraWowImgContainer}>
          <Image.CaraWow style={styles.imageCaraWowStyle} />
        </View>
      </View>
      <View style={styles.rightContainer}>
        <Text.Body
          size="body1"
          variant="bold"
          color={colors.greyMedium}
          style={styles.boxTitle}>
          {'Oops, no entry found!'}
        </Text.Body>

        <Text.Body size="body1" variant="normal" color={colors.greyMedium}>
          "I'm still learning about the condition you entered. Please try
          another option and I'll do my best to help you find the information
          you need."
        </Text.Body>
      </View>
    </Row>
  );
}

const styles = StyleSheet.create({
  boxContainer: {
    backgroundColor: colors.darkWhite,
    width: '90%',
    height: 180,
    alignSelf: 'center',
    borderRadius: 25,
    borderBottomLeftRadius: 10,
    overflow: 'hidden',

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.51,
    shadowRadius: 13.16,
    elevation: 20,
  },
  imageCaraWowStyle: {
    width: 75,
    height: 90,
    resizeMode: 'contain',
  },
  leftContainer: {
    justifyContent: 'center',
  },
  rightContainer: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: 15,
    paddingRight: 20,
  },
  lightSvgContainer: {
    alignItems: 'center',
    top: 20,
    right: 5,
  },
  caraWowImgContainer: {
    top: 40,
    right: 5,
    zIndex: -100,
  },
  boxTitle: {
    marginBottom: 10,
  },
});
