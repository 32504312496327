import React, {useEffect} from 'react';
import {View, Image, StyleSheet} from 'react-native';
import {Colors} from 'utils';
import {widthToDp} from 'utils';
import LottieView from 'lottie-react-native';
import {useFocusEffect} from '@react-navigation/core';
import Animated, {
  useSharedValue,
  withTiming,
  withDelay,
  useAnimatedStyle,
} from 'react-native-reanimated';
import {connect} from 'react-redux';
import {CaraCalm} from '../../../assets/images';

function CaraThinkingBubble({isTyping, windowWidth}) {
  let animationPlay;
  let isInitialLoad = true;
  const translateY = useSharedValue(-windowWidth);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{translateX: translateY.value}],
    };
  });

  useEffect(() => {
    translateY.value = withDelay(
      isInitialLoad ? 400 : 0,
      withTiming(translateY.value === 0 ? -windowWidth : 0, {
        duration: 200,
      }),
    );
    isInitialLoad = false;
  }, [isTyping]);
  useFocusEffect(() => {
    animationPlay?.play();
    return () => {
      animationPlay?.pause();
    };
  });

  return isTyping ? (
    <Animated.View style={[styles.container, animatedStyle]}>
      <Image
        style={[styles.caraCalmImage]}
        resizeMode={'contain'}
        source={CaraCalm}
      />
      <View style={styles.circle}>
        <LottieView
          ref={(animation) => {
            animationPlay = animation;
          }}
          source={require('../../../assets/animation-json/Cara_Writing .json')}
          resizeMode={'contain'}
        />
      </View>
    </Animated.View>
  ) : null;
}

const styles = StyleSheet.create({
  caraCalmImage: {height: 71, width: 71, left: -10.3, bottom: -10.5},
  container: {
    marginLeft: 20,
    width: 45,
    borderRadius: 15,
    overflow: 'hidden',
    marginBottom: 10,
    backgroundColor: Colors.systemChatBubblesColor,
    flexDirection: 'row',
    minHeight: 70,
    minWidth: widthToDp(25),
  },
  circle: {
    height: 50,
    width: 50,
    position: 'absolute',
    right: widthToDp(2.5),
    top: 0,
  },
});

const mapStateToProps = (state) => ({
  windowWidth: state.appConfig.windowWidth,
});

export default connect(mapStateToProps)(CaraThinkingBubble);
