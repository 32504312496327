import * as Modal from './modal';
import * as Header from './header';
import {NewAssessmentButtonAndModal} from './NewAssessmentButtonAndModal';
import {AssessmentResultDiseaseItem} from './AssessmentResultDiseaseItem';
import {ResultDetailsDescription} from './ResultDetailsDescription';

export {
  Modal,
  Header,
  NewAssessmentButtonAndModal,
  AssessmentResultDiseaseItem,
  ResultDetailsDescription,
};
