import {InputAccessoryView} from 'react-native';
import PlatformReLogin from '../containers/PlatformReLogin';
import {Bubble, GiftedChat} from './chat/lib/GiftedChat';
import AddProfileMessages from './chat/message-store/models/AddProfileMessages';
import AssessmentMessage from './chat/message-store/models/AssessmentMessage';
import CurrentMedicalConditionsMessages from './chat/message-store/models/CurrentMedicalConditionsMessages';
import LifeStyleMessages from './chat/message-store/models/LifeStyleMessages';
import ProfileMessageStore from './chat/message-store/models/ProfileMessage';
import Socket from './chat/socket/Socket';
import BubbleSelector from './chat/ui/BubbleSelector';
import Chat from './chat/ui/Chat';
import ChatInput from './chat/ui/ChatInput';
import CustomSendButton from './chat/ui/CustomSendButtons';
import DefaultChatBubble from './chat/ui/DefaultChatBubble';
import HumanAnatomyChatSvg from './chat/ui/HumanAnatomyChatSvg';
import InfoChatBubble from './chat/ui/InfoChatBubble';
import QuickRepliesSelector from './chat/ui/QuickRepliesSelector';
import TextMessage from './chat/ui/TextMessage';
import AssessmentCloseModal from './modals/AssessmentCloseModal';
import DateTimeModal from './modals/DateTimeModal';
import HumanAnatomyModal from './modals/HumanAnatomyModal';
import {default as SymptomListModal} from './modals/SymptomListModal';
import AnimatableChatBubble from './reusables/animated-reusables/AnimatableChatBubble';
import AnimatedDoghNut from './reusables/animated-reusables/AnimatedDoghNut';
import AnimatedSideBar from './reusables/animated-reusables/AnimatedSideBar';
import AnimatedSwitch from './reusables/animated-reusables/AnimatedSwitch';
import {AnimatedText} from './reusables/animated-reusables/AnimatedText';
import AnimatedToast from './reusables/animated-reusables/AnimatedToast';
import AddNewButton from './reusables/components/AddNewButton';
import {ArrowForwardOrange} from './reusables/components/ArrowForwardOrange';
import {AssessmentIcon} from './reusables/components/AssessmentIcon';
import AssessmentSummary from './reusables/components/AssessmentSummary';
import AuthButton from './reusables/components/AuthButton';
import {BackIcon} from './reusables/components/BackIcon';
import {Calendar} from './reusables/components/Calendar';
import CaraExpressIndicatorBubble from './reusables/components/CaraExpressionIndicatorBubble';
import CaraThinkingBubble from './reusables/components/CaraThinkingBubble';
import ChatInputContainer from './reusables/components/ChatInputContainer';
import CircleChecked from './reusables/components/CircleChecked';
import {CircleUnChecked} from './reusables/components/CircleUnChecked';
import {ClockIcon} from './reusables/components/ClockIcon';
import DatePickerIOS from './reusables/components/DatePickerIOS';
import DateTimePicker from './reusables/components/DateTimePicker';
import DefaultButton from './reusables/components/DefaultButton';
import DeleteButton from './reusables/components/DeleteButton';
import DeleteIcon from './reusables/components/DeleteIcon';
import DoneButton from './reusables/components/DoneButton';
import IOSStatusBar from './reusables/components/IOSStatusBar';
import {ListRowDelete} from './reusables/components/ListRowDelete';
import {ListRowEdit} from './reusables/components/ListRowEdit';
import LogoutIconSVG from './reusables/components/LogoutIconSVG';
import NameWithDateContainer from './reusables/components/NameWithDateContainer';
import PlusIcon from './reusables/components/PlusIcon';
import ProgressBarCustom from './reusables/components/ProgressBarCustom';
import RadioButton from './reusables/components/RadioButton';
import * as Cluster from './reusables/components/ReusedCluster';
import {
    AndroidStatusBar, BackDrop, CustomCheckBox, DateShadowContainer, Dots, DropdownButton, ExtractHeightWrapper, ListItemInput,
    ListItemRadioContainer,
    ListItemWithDropDown, MarginSpacer, RadioWithDescription, SectionTitleText, SlidingView, Spacer
} from './reusables/components/ReusedCluster';
import {RowMoreOptionIcon} from './reusables/components/RowMoreOptionIcon';
import ScaffoldContainer from './reusables/components/ScaffoldContainer';
import SearchBar from './reusables/components/SearchBar';
import * as SVGS from './reusables/components/SVGS';
import {
    allergies,
    Arc,
    BeiZereCurve, Cancel, CaraMainBubbleSVG, CaraUmbrella,
    ChatCurve,
    Chevron,
    CloseCircledSVG,
    CompleteLifestyleScreenTopBar,
    CurrentlyTracking,
    CurrentMedicalConditionSearchScreenListButton,
    CurrentMedicalConditionSearchScreenTopBar,
    CurrentMedicalConditionTopBar,
    DeleteCell,
    dont_deal_with_prenancy,
    DropDown,
    emergency,
    EyeTouchingHands,
    FamilyHistoryDeleteIcon,
    FamilyHistorySearchScreenListButton,
    FamilyHistorySearchScreenTopBar,
    FinishedTracking, LeftIcon,
    LifestyleChatRoomTopBar, LifestyleDetailsEditScreenCancelIcon,
    LifestyleDetailsEditScreenSaveIcon,
    LifeStyleInfoModalApple,
    LifestyleInfoModalTopBar, Mango,
    MangoImage, MangoImageLarge,
    MangoImageMedium,
    MangoImageSmall,
    MangoMaskTr,
    Mark, MedicalHistoryInfoModalCaraFace,
    MedicalHistoryInfoModalTopBar, medical_conditions,
    medical_history, NotificationBlack,
    Notifications,
    RightIcon,
    RoundedTick,
    Settings,
    ShareIcon,
    StartAssessment,
    StopIcon,
    Tick,
    TopBarOutWard,
    TrackSymptom,
    vaccines,
    WarningIcon,
    welcome_to_profile
} from './reusables/components/SVGS';
import UserLocationPickerIOS from './reusables/components/UserLocationPickerIOS';

export {
    AddNewButton,
    ArrowForwardOrange,
    AssessmentIcon,
    AssessmentSummary,
    AuthButton,
    BackIcon,
    Calendar,
    CaraExpressIndicatorBubble,
    CaraThinkingBubble,
    ChatInputContainer,
    CircleUnChecked,
    ClockIcon,
    DatePickerIOS,
    DateTimePicker,
    DefaultButton,
    DeleteButton,
    DeleteIcon,
    DoneButton,
    InputAccessoryView,
    IOSStatusBar,
    ListRowDelete,
    ListRowEdit,
    LogoutIconSVG,
    NameWithDateContainer,
    PlatformReLogin,
    PlusIcon,
    ProgressBarCustom,
    RadioButton,
    RowMoreOptionIcon,
    ScaffoldContainer,
    SearchBar,
    UserLocationPickerIOS,
    //REUSEDCLUSTER
    Cluster,
    SVGS,
    //ANIMATED
    AnimatableChatBubble,
    AnimatedDoghNut,
    AnimatedSideBar,
    AnimatedText,
    AnimatedToast,
    //MODALS
    AssessmentCloseModal,
    DateTimeModal,
    SymptomListModal,
    //CHAT
    BubbleSelector,
    Chat,
    ChatInput,
    CustomSendButton,
    DefaultChatBubble,
    HumanAnatomyChatSvg,
    InfoChatBubble,
    QuickRepliesSelector,
    TextMessage,
    RadioWithDescription,
    ListItemInput,
    ListItemRadioContainer,
    ListItemWithDropDown,
    SectionTitleText,
    Spacer,
    SlidingView,
    AndroidStatusBar,
    DateShadowContainer,
    MarginSpacer,
    Dots,
    CustomCheckBox,
    BackDrop,
    ExtractHeightWrapper,
    DropdownButton,
    LeftIcon,
    LifestyleChatRoomTopBar,
    Mango,
    MangoImage,
    allergies,
    Arc,
    BeiZereCurve,
    CaraMainBubbleSVG,
    Cancel,
    CaraUmbrella,
    ChatCurve,
    Chevron,
    CloseCircledSVG,
    CompleteLifestyleScreenTopBar,
    CurrentlyTracking,
    CurrentMedicalConditionSearchScreenListButton,
    CurrentMedicalConditionSearchScreenTopBar,
    CurrentMedicalConditionTopBar,
    DeleteCell,
    dont_deal_with_prenancy,
    DropDown,
    emergency,
    EyeTouchingHands,
    FamilyHistoryDeleteIcon,
    FamilyHistorySearchScreenListButton,
    FamilyHistorySearchScreenTopBar,
    FinishedTracking,
    LifestyleDetailsEditScreenCancelIcon,
    LifestyleDetailsEditScreenSaveIcon,
    LifeStyleInfoModalApple,
    LifestyleInfoModalTopBar,
    MangoImageLarge,
    MangoImageMedium,
    MangoImageSmall,
    MangoMaskTr,
    Mark,
    medical_conditions,
    medical_history,
    MedicalHistoryInfoModalCaraFace,
    MedicalHistoryInfoModalTopBar,
    NotificationBlack,
    Notifications,
    RightIcon,
    RoundedTick,
    Settings,
    ShareIcon,
    StartAssessment,
    StopIcon,
    Tick,
    TopBarOutWard,
    TrackSymptom,
    vaccines,
    WarningIcon,
    welcome_to_profile,
    GiftedChat,
    Bubble,
    Socket,
    AddProfileMessages,
    AssessmentMessage,
    CurrentMedicalConditionsMessages,
    ProfileMessageStore,
    LifeStyleMessages,
    CircleChecked,
    AnimatedSwitch,
    HumanAnatomyModal
};

