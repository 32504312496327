import React from 'react';
import {useTheme} from 'utils';
import {ButtonBase} from './ButtonBase';
import {IButtonPropsBase} from './IButtonPropsBase';

interface IButtonSecondaryProps extends IButtonPropsBase {}

export function ButtonSecondary({
  title,
  type,
  buttonStyle,
  onPress,
}: IButtonSecondaryProps) {
  const theme = useTheme();

  return (
    <ButtonBase
      title={title}
      onPress={onPress}
      buttonStyle={[
        buttonStyle,
        {
          backgroundColor: theme.colors?.white,
        },
      ]}
      titleStyle={{
        color: theme.colors?.primary,
      }}
      type={type}
    />
  );
}
