import {Pressable, Text, View, StyleSheet} from 'react-native';
import {Colors} from 'utils';
import PlusIcon from './PlusIcon';
import {MARKPRO_BOLD} from 'utils';
import React from 'react';

export default function AddNewButton({
  onPress,
  text = 'Add new',
  customStyles = {},
}) {
  return (
    <Pressable
      style={[styles.buttonContainer, {...customStyles}]}
      onPress={onPress}>
      <View style={[styles.plusIconView]}>
        <PlusIcon />
      </View>
      <Text style={[styles.addNewButtonText]}>{text}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  addNewButtonText: {
    color: Colors.primary,
    fontSize: 16,
    fontFamily: MARKPRO_BOLD,
  },
  plusIconView: {paddingHorizontal: 4},
  buttonContainer: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: Colors.primary,
    borderWidth: 1,
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 18,
  },
});
