import {
  extractUniqueCatalogs,
  getQuestionTextForCatalog,
} from 'data/SecondPassHardcodedMessages';
import {
  ASSESSMENT_COMPLETE,
  ASSESSMENT_QUESTION_ANSWER_STAGE,
  customer,
  DOWN_ARROW,
  DOWN_ARROW_NOT_ANIMATED,
  ENTER_BIRTHDATE,
  ENTER_BIRTH_MONTH,
  ENTER_COUNTRY,
  ENTER_GENDER,
  generateMessages,
  generateQuickReplies,
  getDiagnosticQuestion,
  getIndexOfAnswerId,
  getMainFma,
  getMinSetOfRiskFactors,
  handleDiagnosticQuestion,
  INITIAL_STAGE,
  isEntireSelection,
  SECOND_PASS,
  SELECT_SYMPTOM,
  SELECT_SYMPTOM_YES,
  sessionStepBack,
  submitAnswer,
  SUBMIT_BIRTH_DATE_AND_TIME,
  SUBMIT_BIRTH_MONTH,
  SUBMIT_COUNTRY,
  systemUser,
} from 'utils';
import uuid from 'uuid';
import {
  CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
  CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
} from '../../../../assets/images/cara/CaraFaces';
import {store} from '../../../../redux/stores';

export default class AssessmentMessage {
  stage = INITIAL_STAGE;
  today = new Date();
  toggleTyping = undefined;
  state = store.getState();
  platformUrl = undefined;
  assessmentToken = undefined;
  symptomListForQueue = [];
  isSecondPassCompleted = false;
  catalogs = null;

  _setStage = (stage) => (this.stage = stage);

  initAnimationTyping = (typingAnimationHandler) => {
    this.toggleTyping = typingAnimationHandler;
  };

  symptomSuggestionsQuickReplies = [
    {
      key: 1,
      text: 'Cough',
      action: SELECT_SYMPTOM,
      data: {
        item: {
          display_name: 'Cough',
          display_name_layman:
            'A sudden, audible expulsion of air from the lungs ',
          sdco_id: 'cough@C0010200',
        },
      },
    },
    {
      key: 1,
      text: 'Sore throat',
      action: SELECT_SYMPTOM,
      data: {
        item: {
          display_name: 'Sore throat',
          display_name_layman: 'Throat pain',
          sdco_id: 'sore_throat@C0242429',
        },
      },
    },

    {
      key: 2,
      text: 'Fever',
      action: SELECT_SYMPTOM,
      data: {
        item: {
          display_name: 'History of fever or chills',
          display_name_layman: 'History of fever or chills',
          sdco_id: 'history_of_fever_or_chills@C0015967',
        },
      },
    },
    {
      key: 2,
      text: 'Headache',
      action: SELECT_SYMPTOM,
      data: {
        item: {
          display_name: 'Headache',
          display_name_layman: 'Headache',
          sdco_id: 'headache',
        },
      },
    },

    {
      key: 3,
      text: 'Abdominal pain',
      action: SELECT_SYMPTOM,
      data: {
        item: {
          display_name: 'Abdominal Pain',
          display_name_layman: 'Abdominal Pain',
          sdco_id: 'abdominal_pain@C0000737',
        },
      },
    },
    {
      key: 3,
      text: 'Nausea',
      action: SELECT_SYMPTOM,
      data: {
        item: {
          display_name: 'Nausea',
          display_name_layman: 'Nausea',
          sdco_id: 'nausea@C0027497',
        },
      },
    },
  ];

  setSymptomQueue = (symptomList) => (this.symptomListForQueue = symptomList);

  assessmentBackStep = async (callBack, sessionID) => {
    return sessionStepBack(sessionID, this.platformUrl, this.assessmentToken)
      .then((resSessionStepBack) => {
        if (resSessionStepBack?.status === 200) {
          return handleDiagnosticQuestion(
            sessionID,
            this.platformUrl,
            this.assessmentToken,
            this.symptomListForQueue,
          ).then(async (resHandleDiagnosticQuestion) => {
            if (resHandleDiagnosticQuestion.question_type === 'none') {
              callBack({});
              return;
            }

            let question =
              resHandleDiagnosticQuestion.symptom_display_name_layman;
            if (question?.length === 0) {
              question = resHandleDiagnosticQuestion.symptom_display_name;
            }

            let modifier =
              resHandleDiagnosticQuestion.modifier_display_name_layman;
            if (modifier?.length === 0) {
              modifier = resHandleDiagnosticQuestion.modifier_display_name;
            }

            let isAnswerQuestionIdentifier = uuid.v4();

            const message = {
              caraIcon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
              type: 'text',
              lastMessage: 'This is the Last Message',
              data: modifier || modifier?.length > 0 ? modifier : question,
              shouldAnimate: true,
              shouldWaitTime: 0,
              duration: this.messageDuration,
              step: ASSESSMENT_QUESTION_ANSWER_STAGE,
              sub_step: isAnswerQuestionIdentifier,
              text: null,
            };

            let quickReplies = resHandleDiagnosticQuestion.answer_list.map(
              (answer, index) => {
                let displayName = answer.display_name_layman;

                if (displayName == null || displayName.length === 0) {
                  displayName = answer.display_name;
                }

                return {
                  text: displayName,
                  data: {
                    session_id: sessionID,
                    answer_index: index,
                    display_name: answer.display_name,
                    display_name_layman: answer.display_name_layman,
                    question_id: resHandleDiagnosticQuestion.question_id,
                    item_id: answer?.item_id,
                    modifier_branch_image_reference_list:
                      resHandleDiagnosticQuestion?.modifier_branch_image_reference_list,
                    modifier_image_reference:
                      resHandleDiagnosticQuestion?.modifier_image_reference,
                  },
                };
              },
            );

            let quickRepliesAll = [
              ...quickReplies,
              {
                text: "Don't know",
                data: {
                  session_id: sessionID,
                  answer_index: '-1',
                  display_name: 'do not know',
                  question_id: resHandleDiagnosticQuestion.question_id,
                  item_id: 0,
                  modifier_branch_image_reference_list: [],
                  modifier_image_reference: '',
                },
              },
            ];

            const mainFma = getMainFma(quickReplies);
            // Image selection questions
            if (mainFma) {
              const filteredAns =
                resHandleDiagnosticQuestion?.answer_list.filter((ans) => {
                  return isEntireSelection(ans?.item_id);
                });
              quickRepliesAll = [
                {
                  text: 'Localize further',
                  data: {
                    session_id: sessionID,
                    answer_index: '-2',
                    display_name: 'Localize further',
                    question_id: resHandleDiagnosticQuestion.question_id,
                    item_id: 0,
                  },
                  qr: [...quickReplies],
                  tag: isAnswerQuestionIdentifier,
                  modifier_branch_image_reference_list:
                    resHandleDiagnosticQuestion.modifier_branch_image_reference_list,
                  modifier_image_reference:
                    resHandleDiagnosticQuestion.modifier_image_reference,
                },
              ];

              if (filteredAns?.length > 0) {
                const indexEntire = getIndexOfAnswerId(
                  resHandleDiagnosticQuestion?.answer_list,
                  filteredAns[0].item_id,
                );
                quickRepliesAll = [
                  {
                    text: filteredAns[0].display_name_layman,
                    data: {
                      session_id: sessionID,
                      answer_index: indexEntire,
                      display_name: filteredAns[0].display_name_layman,
                      question_id: resHandleDiagnosticQuestion.question_id,
                      item_id: filteredAns[0].item_id,
                    },
                    qr: [...quickReplies],
                    tag: isAnswerQuestionIdentifier,
                    modifier_branch_image_reference_list:
                      resHandleDiagnosticQuestion.modifier_branch_image_reference_list,
                    modifier_image_reference:
                      resHandleDiagnosticQuestion?.modifier_image_reference,
                  },
                  ...quickRepliesAll,
                ];
              }
            }

            this._setStage(ASSESSMENT_QUESTION_ANSWER_STAGE);
            this.toggleTyping();

            Promise.all([
              generateMessages([message], systemUser),
              generateQuickReplies(
                quickRepliesAll,
                this.stage,
                {tag: isAnswerQuestionIdentifier},
                resHandleDiagnosticQuestion?.type,
              ),
            ]).then((res) => {
              callBack(
                {
                  messages: res[0],
                  quickReplies: res[1],
                },
                true,
                false,
                false,
              );
            });
          });
        }
      })
      .catch(() => {});
  };

  getNonEmptyCatalog = () => {
    let catalogs = {...this.catalogs};
    let currentCatalog = null;

    if (catalogs.SECOND_PASS_FAMILY.length > 0) {
      currentCatalog = catalogs.SECOND_PASS_FAMILY;
      catalogs.SECOND_PASS_FAMILY = [];
      this.catalogs = catalogs;
      return currentCatalog;
    }
    if (catalogs.SECOND_PASS_DISEASE.length > 0) {
      currentCatalog = catalogs.SECOND_PASS_DISEASE;
      catalogs.SECOND_PASS_DISEASE = [];
      this.catalogs = catalogs;
      return currentCatalog;
    }
    if (catalogs.SECOND_PASS_EVENTS.length > 0) {
      currentCatalog = catalogs.SECOND_PASS_EVENTS;
      catalogs.SECOND_PASS_EVENTS = [];
      this.catalogs = catalogs;
      return currentCatalog;
    }
    if (catalogs.SECOND_PASS_EXPOSURE.length > 0) {
      currentCatalog = catalogs.SECOND_PASS_EXPOSURE;
      catalogs.SECOND_PASS_EXPOSURE = [];
      this.catalogs = catalogs;
      return currentCatalog;
    }
    if (catalogs.SECOND_PASS_SOCIAL.length > 0) {
      currentCatalog = catalogs.SECOND_PASS_SOCIAL;
      catalogs.SECOND_PASS_SOCIAL = [];
      this.catalogs = catalogs;
      return currentCatalog;
    }
    if (catalogs.SECOND_PASS_DRUG.length > 0) {
      currentCatalog = catalogs.SECOND_PASS_DRUG;
      catalogs.SECOND_PASS_DRUG = [];
      this.catalogs = catalogs;
      return currentCatalog;
    }

    return [];
  };

  getRiskFactorsForSecondPass = (sessionId, callBack) => {
    getMinSetOfRiskFactors(
      this.platformUrl,
      this.assessmentToken,
      sessionId,
    ).then((res) => {
      const riskFactorList = res.data.risk_factor_list;
      if (riskFactorList && riskFactorList.length > 0) {
        this.catalogs = extractUniqueCatalogs(riskFactorList);
        const catalog = this.getNonEmptyCatalog();
        callBack(
          {
            messages: [
              {
                _id: uuid.v4(),
                createdAt: new Date(),
                messageType: 'Automated',
                shouldAnimate: true,
                type: 'text',
                step: SECOND_PASS,
                sub_step: null,
                icon: CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
                data: getQuestionTextForCatalog(catalog[0].split('/')[1]),
              },
            ],
            quickReplies: [{}],
            checkboxData: catalog,
          },
          true,
          false,
          false,
        );
      } else {
        callBack({});
      }
    });
  };

  assessmentSubmitBodyPart = async (callBack, sessionId, qr) => {
    const answerMap = {
      answer_index: qr.data.answer_index,
      display_name: qr.data.display_name,
      question_id: qr.data.question_id,
    };

    return this._submitAnswer(
      answerMap,
      sessionId,
      ASSESSMENT_QUESTION_ANSWER_STAGE,
    )
      .then((resSubmitAnswer) => {
        if (resSubmitAnswer === ASSESSMENT_COMPLETE) {
          if (!this.isSecondPassCompleted) {
            this.isSecondPassCompleted = true;
            this.getRiskFactorsForSecondPass(sessionId, callBack);
            return;
          } else {
            callBack({});
            return;
          }
        }
        this.toggleTyping();
        callBack(
          {
            messages: resSubmitAnswer[0],
            quickReplies: resSubmitAnswer[1],
          },
          true,
          false,
          false,
        );
      })
      .catch(() => {});
  };

  setAssessmentToken = (token) => (this.assessmentToken = token);

  createUserMessage = (data, dependency) => {
    let ob = {};
    if (dependency?.user === 'system') {
      const {key, value} = dependency;
      ob = {
        [key]: value,
      };
    }
    const obj = {
      _id: Math.random(),
      createdAt: new Date(),
      user: {
        _id: '1',
        name: '',
        avatar: '',
        type: 'Customer',
      },
      messageType: 'Automated',
      type: data.type,
      data: data.data,
      last_message: data.last_message,
      step: null,
      sub_step: null,
      text: data.text,
      ...ob,
    };
    return obj;
  };

  getGenderQuestion = (createdUserMessage) => {
    return {
      messages: [
        {
          _id: uuid.v4(),
          createdAt: new Date(),
          shouldAnimate: true,
          messageType: 'Automated',
          type: 'text',
          data: 'Knowing what sex was listed on your birth certificate by your doctor gives me a better understanding of your body, so that I can provide more accurate, personalized results.',
          icon: CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
          step: ENTER_GENDER,
          sub_step: ENTER_GENDER,
        },
        {
          _id: uuid.v4(),
          createdAt: new Date(),
          user: {...systemUser},
          shouldAnimate: true,
          messageType: 'Automated',
          type: 'text',
          data: 'What sex were you assigned at birth?',
          icon: null,
          step: ENTER_GENDER,
          sub_step: ENTER_GENDER,
        },
        {...createdUserMessage},
      ],
      actions: [
        {
          createdAt: new Date(),
          tag: ENTER_GENDER,
          quickReplies: {
            type: 'radio',
            tag: ENTER_GENDER,
            values: [
              {
                title: 'Female',
                key: 1,
                value: {
                  action: ENTER_GENDER,
                  option: '113131',
                },
              },
              {
                title: 'Male',
                key: 1,
                value: {
                  action: ENTER_GENDER,
                  option: '113131',
                },
              },
            ],
          },
          step: null,
          sub_step: null,
          user: {...customer},
          _id: uuid.v4(),
        },
      ],
    };
  };

  handleQuestionAnswerFlow = async (stage, sessionId) => {
    return handleDiagnosticQuestion(
      sessionId,
      this.platformUrl,
      this.assessmentToken,
      this.symptomListForQueue,
    ).then(async (resHandleDiagnosticQuestion) => {
      if (resHandleDiagnosticQuestion.question_type === 'none') {
        return ASSESSMENT_COMPLETE;
      }

      let question = resHandleDiagnosticQuestion.symptom_display_name_layman;
      if (question?.length === 0) {
        question = resHandleDiagnosticQuestion.symptom_display_name;
      }

      let modifier = resHandleDiagnosticQuestion.modifier_display_name_layman;
      if (modifier?.length === 0) {
        modifier = resHandleDiagnosticQuestion.modifier_display_name;
      }

      let isAnswerQuestionIdentifier = uuid.v4();

      const message = {
        caraIcon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
        type: 'text',
        lastMessage: 'This is the Last Message',
        data: modifier || modifier?.length > 0 ? modifier : question,
        shouldAnimate: true,
        shouldWaitTime: 0,
        duration: this.messageDuration,
        step: stage,
        sub_step: isAnswerQuestionIdentifier,
        text: null,
      };

      let quickReplies = resHandleDiagnosticQuestion.answer_list.map(
        (answer, index) => {
          let displayName = answer.display_name_layman;

          if (displayName == null || displayName.length === 0) {
            displayName = answer.display_name;
          }

          return {
            text: displayName,
            data: {
              session_id: sessionId,
              answer_index: index,
              display_name: answer.display_name,
              display_name_layman: answer.display_name_layman,
              question_id: resHandleDiagnosticQuestion.question_id,
              item_id: answer?.item_id,
              modifier_branch_image_reference_list:
                resHandleDiagnosticQuestion?.modifier_branch_image_reference_list,
              modifier_image_reference:
                resHandleDiagnosticQuestion?.modifier_image_reference,
            },
          };
        },
      );

      let quickRepliesAll = [
        ...quickReplies,
        {
          text: "Don't know",
          data: {
            session_id: sessionId,
            answer_index: '-1',
            display_name: 'do not know',
            question_id: resHandleDiagnosticQuestion.question_id,
            item_id: 0,
            modifier_branch_image_reference_list: [],
            modifier_image_reference: '',
          },
        },
      ];

      this._setStage(stage);

      const mainFma = getMainFma(quickReplies);
      // Image selection questions
      if (mainFma) {
        const filteredAns = resHandleDiagnosticQuestion?.answer_list.filter(
          (ans) => {
            return isEntireSelection(ans?.item_id);
          },
        );
        quickRepliesAll = [
          {
            text: 'Localize further',
            data: {
              session_id: sessionId,
              answer_index: '-2',
              display_name: 'Localize further',
              question_id: resHandleDiagnosticQuestion.question_id,
              item_id: 0,
            },
            qr: [...quickReplies],
            tag: isAnswerQuestionIdentifier,
            modifier_branch_image_reference_list:
              resHandleDiagnosticQuestion.modifier_branch_image_reference_list,
            modifier_image_reference:
              resHandleDiagnosticQuestion?.modifier_image_reference,
          },
        ];

        if (filteredAns?.length > 0) {
          const indexEntire = getIndexOfAnswerId(
            resHandleDiagnosticQuestion?.answer_list,
            filteredAns[0].item_id,
          );

          quickRepliesAll = [
            {
              text: filteredAns[0].display_name_layman,
              data: {
                session_id: sessionId,
                answer_index: indexEntire,
                display_name: filteredAns[0].display_name_layman,
                question_id: resHandleDiagnosticQuestion.question_id,
                item_id: filteredAns[0].item_id,
              },
              qr: [...quickReplies],
              tag: isAnswerQuestionIdentifier,
              modifier_branch_image_reference_list:
                resHandleDiagnosticQuestion.modifier_branch_image_reference_list,
              modifier_image_reference:
                resHandleDiagnosticQuestion?.modifier_image_reference,
            },
            ...quickRepliesAll,
          ];
        }
      }

      return Promise.all([
        generateMessages([message], systemUser),
        generateQuickReplies(
          quickRepliesAll,
          this.stage,
          {tag: isAnswerQuestionIdentifier},
          resHandleDiagnosticQuestion?.type,
        ),
      ]);
    });
  };

  mergeValueWithArray = async (
    callBack,
    limit,
    incomingData,
    dependency,
    {
      userId = null,
      token = null,
      platformUrl = null,
      username = null,
      sessionId = null,
    },
    toggleTyping,
  ) => {
    const sessionID = sessionId ? sessionId : incomingData?.data?.session_id;

    return (async () => {
      const action = incomingData.value?.action || incomingData.action;

      const STAGE = action ?? INITIAL_STAGE;

      if (STAGE === ENTER_BIRTHDATE) {
        this.toggleTyping();

        callBack(
          {
            messages: [
              {
                _id: uuid.v4(),
                createdAt: new Date(),
                user: {...systemUser},
                messageType: 'Automated',
                type: 'text',
                shouldAnimate: true,
                data: "Hi! Let's get started on a health assessment. Before I get started, I need some more information so I can provide the most accurate results possible. What’s your year of birth?",
                last_message: ' ',
                step: STAGE,
                icon: CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
                sub_step: STAGE,
              },
            ],
            actions: [
              {
                createdAt: new Date(),
                tag: STAGE,
                inputQuickReplies: {
                  placeHolder: 'Select year of birth',
                  editable: false,
                  type: 'radio',
                  values: [
                    {
                      _id: 1,
                      action: STAGE,
                      iconName: DOWN_ARROW,
                      hasReactToEnable: false,
                    },
                  ],
                },
                quickReplies: {
                  type: 'radio',
                  values: [
                    {
                      title: 'YY-MM-DD',
                      value: {
                        action: STAGE,
                        option: '113131',
                      },
                    },
                  ],
                },
                step: STAGE,
                sub_step: STAGE,
                user: {...customer},
                _id: uuid.v4(),
              },
            ],
          },
          true,
          false,
          false,
        );
      }
      if (STAGE === SUBMIT_BIRTH_DATE_AND_TIME) {
        let createdMessage = this.createUserMessage(
          {
            ...incomingData.value.option,
            type: 'text',
            text: incomingData.value.option.data,
          },
          dependency,
        );
        const birthYear = +incomingData.value.option.data;
        if (new Date().getFullYear() - birthYear <= 2) {
          callBack(
            {
              messages: [
                {
                  _id: uuid.v4(),
                  createdAt: new Date(),
                  user: {...systemUser},
                  messageType: 'Automated',
                  type: 'text',
                  shouldAnimate: true,
                  data: 'What’s your month of birth?',
                  last_message: ' ',
                  step: ENTER_BIRTH_MONTH,
                  duration: 300,
                  icon: null,
                  sub_step: STAGE,
                },
                {...createdMessage},
              ],
              actions: [
                {
                  createdAt: new Date(),
                  tag: ENTER_BIRTH_MONTH,
                  inputQuickReplies: {
                    placeHolder: 'Select month of birth',
                    editable: false,
                    type: 'radio',
                    values: [
                      {
                        _id: 1,
                        action: ENTER_BIRTH_MONTH,
                        iconName: DOWN_ARROW,
                        hasReactToEnable: false,
                      },
                    ],
                  },
                  quickReplies: {
                    type: 'radio',
                    values: [
                      {
                        title: 'YY-MM-DD',
                        value: {
                          action: ENTER_BIRTH_MONTH,
                          option: '113131',
                        },
                      },
                    ],
                  },
                  step: null,
                  sub_step: null,
                  user: {...customer},
                  _id: uuid.v4(),
                },
              ],
            },
            true,
            false,
            false,
          );
        } else {
          callBack(this.getGenderQuestion(createdMessage), true, false, false);
        }
      }
      if (STAGE === SUBMIT_BIRTH_MONTH) {
        let createdMessage = this.createUserMessage(
          {
            ...incomingData.value.option,
            type: 'text',
            text: incomingData.value.option.data,
          },
          dependency,
        );
        callBack(this.getGenderQuestion(createdMessage), true, false, false);
      }
      if (STAGE === ENTER_GENDER) {
        let createdMessage = this.createUserMessage(
          {
            ...incomingData.value.option,
            type: 'text',
            text: incomingData.title,
          },
          dependency,
        );
        callBack(
          {
            messages: [
              {
                _id: uuid.v4(),
                createdAt: new Date(),
                messageType: 'Automated',
                shouldAnimate: true,
                type: 'text',
                step: ENTER_COUNTRY,
                sub_step: null,
                icon: CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
                data: 'This will give me a better understanding of your health risk so that I can provide more accurate results.',
              },
              {
                _id: uuid.v4(),
                createdAt: new Date(),
                user: {...systemUser},
                messageType: 'Automated',
                shouldAnimate: true,
                type: 'text',
                step: ENTER_COUNTRY,
                sub_step: null,
                icon: null,
                data: 'What country do you live in primarily?',
              },
              {...createdMessage},
            ],
            actions: [
              {
                createdAt: new Date(),
                tag: ENTER_COUNTRY,
                inputQuickReplies: {
                  placeHolder: 'Select country',
                  editable: false,
                  type: 'radio',
                  values: [
                    {
                      _id: 1,
                      action: ENTER_COUNTRY,
                      iconName: DOWN_ARROW_NOT_ANIMATED,
                      hasReactToEnable: false,
                    },
                  ],
                },
                quickReplies: {
                  type: 'radio',
                  tag: ENTER_COUNTRY,
                  values: [
                    {
                      title: 'Country',
                      value: {
                        action: ENTER_COUNTRY,
                        option: '113131',
                      },
                    },
                  ],
                },
                step: null,
                sub_step: null,
                user: {...customer},
                _id: uuid.v4(),
              },
            ],
          },
          true,
          false,
          false,
        );
      }

      if (STAGE === SUBMIT_COUNTRY) {
        this.sendRepliesMessageToUser(callBack, {
          ...incomingData,
          type: 'text',
          title: incomingData.value.option.selectedCountry,
        });
        const state = this.state;
        this.platformUrl = state.application.platformUrl;

        //Fetch The Symptoms and bind it to quickReplies
        setTimeout(() => {
          Promise.all([
            generateMessages(
              [
                {
                  caraIcon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
                  type: 'text',
                  lastMessage: 'This is the Last Message',
                  data: "Thanks for that! Now, what's the main symptom that's bothering you?",
                  step: INITIAL_STAGE,
                  shouldAnimate: true,
                  duration: this.messageDuration,
                  sub_step: SELECT_SYMPTOM,
                  text: null,
                },
              ],
              systemUser,
            ),
            generateQuickReplies(
              this.symptomSuggestionsQuickReplies,
              SELECT_SYMPTOM,
              {tag: SELECT_SYMPTOM},
            ),
          ]).then((res) => {
            this.toggleTyping();
            callBack(
              {
                messages: res[0],
                quickReplies: res[1],
              },
              true,
              false,
              true,
            );
          });
        }, 1000);
      } else if (STAGE === SECOND_PASS) {
        this.sendRepliesMessageToUser(callBack, incomingData);
        const catalog = this.getNonEmptyCatalog();
        if (catalog.length > 0) {
          setTimeout(() => {
            callBack(
              {
                messages: [
                  {
                    _id: uuid.v4(),
                    createdAt: new Date(),
                    messageType: 'Automated',
                    shouldAnimate: true,
                    type: 'text',
                    step: SECOND_PASS,
                    sub_step: null,
                    icon: CARA_HAPPY_EYES_CLOSE_MOUTH_CLOSE,
                    data: getQuestionTextForCatalog(catalog[0].split('/')[1]),
                  },
                ],
                quickReplies: [{}],
                checkboxData: catalog,
              },
              true,
              false,
              false,
            );
          }, 750);
        } else {
          if (!this.hasSubmittedRiskFactors) {
            this.hasSubmittedRiskFactors = true;
            callBack({submitRiskFactors: true});
            return;
          }
          this.handleQuestionAnswerFlow(
            ASSESSMENT_QUESTION_ANSWER_STAGE,
            sessionID,
            callBack,
          ).then((res) => {
            if (res === ASSESSMENT_COMPLETE) {
              callBack({});
            } else {
              callBack(
                {
                  messages: res[0],
                  quickReplies: res[1],
                },
                true,
                false,
                false,
              );
            }
          });
        }
      } else if (STAGE === SELECT_SYMPTOM || STAGE === SELECT_SYMPTOM_YES) {
        this.sendRepliesMessageToUser(callBack, incomingData);
        getDiagnosticQuestion(sessionID, this.platformUrl, this.assessmentToken)
          .then((res) => {
            const answerMap = {
              answer_index: 1,
              display_name: 'Present',
              question_id: res.question_id,
            };

            if (res.question_type === 'none') {
              callBack({});
              return;
            }

            this._submitAnswer(
              answerMap,
              sessionID,
              ASSESSMENT_QUESTION_ANSWER_STAGE,
            )
              .then((resSubmitAnswer) => {
                if (resSubmitAnswer === ASSESSMENT_COMPLETE) {
                  this.toggleTyping();
                  if (!this.isSecondPassCompleted) {
                    this.isSecondPassCompleted = true;
                    this.getRiskFactorsForSecondPass(sessionID, callBack);
                    return;
                  } else {
                    callBack({});
                    return;
                  }
                }

                this.toggleTyping();
                callBack(
                  {
                    messages: resSubmitAnswer[0],
                    quickReplies: resSubmitAnswer[1],
                  },
                  true,
                  false,
                  false,
                );
              })
              .catch(() => {});
          })
          .catch(() => {});
      } else if (STAGE === ASSESSMENT_QUESTION_ANSWER_STAGE) {
        this.sendRepliesMessageToUser(callBack, incomingData);
        const answerMap = incomingData.data;

        this._submitAnswer(
          answerMap,
          sessionID,
          ASSESSMENT_QUESTION_ANSWER_STAGE,
        ).then((res1) => {
          if (res1 === ASSESSMENT_COMPLETE) {
            if (!this.isSecondPassCompleted) {
              this.isSecondPassCompleted = true;
              this.getRiskFactorsForSecondPass(sessionID, callBack);
              return;
            } else {
              callBack({});
              return;
            }
          }
          this.toggleTyping();
          callBack(
            {
              messages: res1[0],
              quickReplies: res1[1],
            },
            true,
            false,
            false,
          );
        });
      }
    })().catch(() => {});
  };
  messageDuration = 300;

  _submitAnswer = (answerMap, sessionId, STAGE) => {
    return submitAnswer(answerMap, this.platformUrl, this.assessmentToken).then(
      (resSubmitAnswer) => {
        if (resSubmitAnswer.status === 200) {
          return this.handleQuestionAnswerFlow(STAGE, sessionId);
        }
      },
    );
  };

  sendRepliesMessageToUser = (updateCallBack, data) => {
    const {
      title = null,
      coordinates = null,
      step = null,
      svg = null,
      msgText = null,
    } = data;
    generateMessages(
      [
        {
          caraIcon: CARA_HAPPY_EYES_CLOSE_MOUTH_OPEN,
          type: 'text',
          lastMessage: 'This is the Last Message',
          data: 'This is the Test',
          duration: this.messageDuration,
          step: step,
          text: title,
          coordinates,
          svg: svg,
          msgText: msgText,
        },
      ],
      customer,
    )
      .then((res) => {
        updateCallBack(
          {
            messages: res,
            quickReplies: [],
          },
          true,
          true,
        );
      })
      .catch(() => {});
  };
  generateNextMessageForAction = (updateCallBack, data) => {};
}
