import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {Analytics, getAnalytics, logEvent} from 'firebase/analytics';
import {
  apiKey,
  appId,
  authDomain,
  isWeb,
  measurementId,
  messagingSenderId,
  projectId,
  storageBucket,
} from './config';

// Initialize Firebase
let config = {
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId,
};

let db = {};
let analytics: Analytics;

// we do not want to initialize `firebase` when the platform is not web
if (isWeb) {
  const app = initializeApp(config);
  db = getFirestore(app);
  analytics = getAnalytics(app);
}

export {db, analytics, logEvent};
