import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {fma23_1_2, fma73093} from 'utils';
import {getColorFma} from 'utils';

export const Foot_Right_Top_fma2310 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1242 2208">
      <Path
        id="Path_576"
        dataName="Path 576"
        d="M738,1510l9.1,4.5c0,0-18,127.8-17.3,179.3c0.6,51.1,26.8,59.5,72.8,59.5c60.3,0,97.8-29,99.2-88.5  c1.3-57,0.7-287.6-1-366.5c-2.9-69.9-35.1-492.3-33.6-539.7c1.4-47.4,34.5-122.7,37.3-164.5c1.7-25-3.2-69.8-7.5-101.9  c-2.9-21.5-21.2-37.5-42.9-37.5H545.6c-15.9,0-30.4,8.6-38,22.6c-13.6,25-31.8,66.3-26.8,101.1c7.8,53.9,21.2,76.2,7.8,120.8  c-13.4,44.6-137.8,660-137.8,660s-11.9,73.1-11.5,123.9c0.4,59.1,51.8,46.1,59.8,25.4c13.5-34.8,31.6-85.5,31.6-85.5l4.6,1.7  c0,0-17.3,104.7-8.4,129c8.7,23.7,41.9,32.3,58.9,9c7.6-10.5,30.3-118.4,30.3-118.4l6.5,1.9c0,0-24,137.6-19.6,166.8  c4.6,30.9,62,47.4,80,18.1c17.1-28,41.5-151.1,41.5-151.1l5.9,1.4c0,0-19.7,172-12.2,196.9c7.7,25.8,64.5,34.3,80.8,6.6  C711.6,1663.4,738,1510,738,1510z"
        fill="#ead9e7"
        style="mix-blend-mode: multiply;isolation: isolate"
      />

      <Path
        id={fma23_1_2}
        dataName="Path 580"
        d="M848.4,1516.1c-13.6,2.3-46.6,6.8-125.6-4.7c-26.5-3.9-61.3-10-101.9-20.1c0,0-219.7-35.3-257.2-79.4  c-1.2-1.4-6.7-8.2-11.9-7.1c-7.2,1.5-10.2,17.5-11.4,34.5c-0.5,5.9-1.1,11.7-1.6,17.6c-1.3,3.3-2.4,7.7-2.4,13  c0.2,16,11.7,26.6,14.3,28.9c62.5,34.6,133.1,73,210.9,114.3c83.7,44.5,122,63.7,177.8,78.5c59.6,15.7,111,18.9,145.8,19  c6.5-13.2,14.3-33.1,16.8-58c1.7-16.7,0.3-29.4-0.2-34.9c-2.3-23.4-3.9-59.7-0.6-115.7C888.9,1506.5,870.9,1512.3,848.4,1516.1z"
        fill={getColorFma(fma23_1_2, selectedBodyPart)}
        onPressIn={() => onPressClick(fma23_1_2)}
        onClick={() => onPressClick(fma23_1_2)}
      />
      <Path
        id={fma73093}
        dataName="Path 581"
        d="M899.5,1403.3L870,889.1c-2.2-39-11.6-77.2-27.6-112l-18-39.3c-22.9-50-34.8-105.3-34.8-161.5v-91.3  c0-16.8-12.2-30.4-27.2-30.4H598.3c-15,0-27.2,13.6-27.2,30.4v55.1c0,81.6-25.2,160.7-71.4,224c-25.9,35.5-43.8,77.4-52.2,122.3  l-88.7,474.8c-2.2,11.7,5.4,23,16.1,23.8c66.7,27,150,54.2,247.7,72.5c72.9,13.6,139.5,19.5,197.3,21.3c25.7-6.5,51.4-13,77-19.6  C897.9,1440.6,898.7,1421.9,899.5,1403.3z"
        fill={getColorFma(fma73093, selectedBodyPart)}
        onPressIn={() => onPressClick(fma73093)}
        onClick={() => onPressClick(fma73093)}
      />
      <Path
        id="Path_579"
        dataName="Path 579"
        d="M350.7,1498.8c1.3-19.4,6.8-27.1,22.2-25.8s15.4,25.8,15.4,25.8"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_577"
        dataName="Path 577"
        d="M442.7,1542.1c1.3-19.4,6.8-27.1,22.2-25.8s15.4,25.8,15.4,25.8"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_578"
        dataName="Path 578"
        d="M518,1608.3c-2.9-13.8,2-27.2,12.3-34.1c1.2-0.8,10.2-6.7,20.7-4.2c13.4,3.2,23.9,18.9,22.9,38.2"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_672"
        dataName="Path 672"
        d="M631.9,1656.1c2.1-30.1,10.6-42.1,34.5-40s23.9,40,23.9,40"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_673"
        dataName="Path 673"
        d="M757.8,1687.3c3.7-53.5,18.8-74.8,61.3-71.1c42.5,3.8,42.6,71.1,42.6,71.1"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
