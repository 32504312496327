import LottieView from 'lottie-react-native';
import moment from 'moment';
import React, {useEffect} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {connect} from 'react-redux';
import {
  closeSession,
  Colors,
  EventType,
  getDifferentialDiagnosis,
  getDxSessionRecord,
  MARKPRO_BOLD,
  useAnalytics,
} from 'utils';

function AssessmentResultLoading(props) {
  const {logEvent} = useAnalytics();
  useEffect(() => {
    getSummary();
    logEvent(EventType.ASSESSMENT_END, {timestamp: Date.now()});
  }, []);

  const getSummary = async () => {
    const differentialDiagnosis = await getDifferentialDiagnosis(
      props.route.params.session_id,
      props.platformUrl,
      props.platformToken,
    );
    // Close and save session
    await closeSession(
      props.route.params.session_id,
      props.platformUrl,
      props.platformToken,
    );
    const resultDxSessionRecord = await getDxSessionRecord(
      props.route.params.selected_patient_id,
      props.route.params.session_id,
      props.platformUrl,
      props.platformToken,
    );

    const date = moment().add(7, 'days');
    // Update Assessment Customer Profile
    const assessmentNotification = {
      date: date.format('YYYY-MM-DD'),
      hour: date.hour().toString(),
      min: date.minutes().toString(),
      date_time: date.toISOString(),
      day_of_the_week: '',
      type: 'symptom_assessment_follow_up',
      data: resultDxSessionRecord.data.document.reason_for_encounter,
    };

    props.navigation.navigate('AssessmentResults', {
      session_id: props.route.params.session_id,
      selected_patient_id: props.route.params.selected_patient_id,
      main_symptom: resultDxSessionRecord.data.document.reason_for_encounter,
      diagnostic_session_record: resultDxSessionRecord.data.document,
      differentialDiagnosisItems:
        differentialDiagnosis?.data?.differential_diagnosis,
    });
  };

  return (
    <View style={[styles.assessmentResultLoadingContainer]}>
      <Text style={[styles.assessmentResultLoadingText]}>
        {'One moment while I\nabsorb your chart...'}
      </Text>
      <View style={[styles.lottieView]}>
        <LottieView
          source={require('../../../../assets/animation-json/Cara_Loading_Animation.json')}
          autoPlay={true}
          loop={true}
          resizeMode={'cover'}
        />
      </View>
    </View>
  );
}

const mapStateToProps = (state) => {
  return {
    platformToken: state.application.platformToken,
    platformUrl: state.application.platformUrl,
    assessmentToken: state.application.assessmentToken,
    refreshAssessmentHistory: state.dxSession.refreshAssessmentHistory,
  };
};

const styles = StyleSheet.create({
  lottieView: {width: 300, height: 300},
  assessmentResultLoadingText: {
    color: Colors.white,
    fontSize: 28,
    lineHeight: 34,
    fontFamily: MARKPRO_BOLD,
  },
  assessmentResultLoadingContainer: {
    backgroundColor: Colors.primary,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
export default connect(mapStateToProps)(AssessmentResultLoading);
