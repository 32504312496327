export const ENTER_NAME = 'enter_name';
export const SUBMIT_BIRTH_DATE_AND_TIME = 'submit_birth_date_and_time';
export const ENTER_BIRTH_MONTH = 'enter_birth_month';
export const SUBMIT_BIRTH_MONTH = 'submit_birth_month';
export const ENTER_BIRTHDATE = 'enter_birthday';
export const SUBMIT_DATA = 'submit_data';
export const ENTER_PROVINCE = 'enter_province';
export const ENTER_GENDER = 'enter_gender';
export const ENTER_EMAIl = 'enter_email';
export const ENTER_CITY = 'enter_city';
export const ENTER_FIRST_NAME = 'enter_first_name';
export const ENTER_LAST_NAME = 'enter_last_name';
export const ENTER_COUNTRY = 'enter_country';
export const SUBMIT_PROVINCE = 'submit_province';
export const SUBMIT_COUNTRY = 'submit_country';
export const SUBMIT_CITY = 'submit_city';
export const GO_TO_PROFILE = 'got_to_profile';
export const START_SYMPTOM_ASSESSMENT = 'START_SYMPTOM_ASSESSMENT';
export const TRACK_SYMPTOM = 'TRACK_SYMPTOM';
export const GO_TO_HOME = 'got_to_home';
export const ENTER_PASSOWRD = 'ENTER_PASSWORD';
export const SHOW_SEX_INFO_MODAL = 'SHOW_SEX_INFO';
export const SHOW_INFO = 'SHOW_INFO';
export const REGISTER_COMPLETE = 'REGISTER_COMPLETE';
export const ENTER_NICKNAME = 'ENTER_NICKNAME';
export const SKIP_AND_FINISH = 'SKIP_AND_FINISH';
export const GREETINGS_MESSAGE = 'GREETINGS_MESSAGE';
export const SECOND_PASS = 'SECOND_PASS';

export const SKIP = 'SKIP';
export const FINAL_ONBOARDING_SCREEN = 'FINAL_ONBOARDING_SCREEN';
export const FINAL_ONBOARDING_SCREEN_ADD_PROFILE =
  'FINAL_ONBOARDING_SCREEN_ADD_PROFILE';
export const FINAL_ONBOARDING_SCREEN_LIFESTYLE =
  'FINAL_ONBOARDING_SCREEN_LIFESTYLE';

export const SEND_ICON = 'SEND_ICON';
export const SKIP_ICON = 'SKIP_ICON';
export const EYE = 'eye';

export const ENTER_PRONOUN = 'ENTER_PRONOUN';
export const THEY_THEM = 'THEY_THEM';
export const HE_HIM = 'HE_HIM';
export const SHE_HER = 'SHE_HER';

// CARA FACIAL EXPRESSIONS

export const CARA_HAPPY = 'CARA_HAPPY';
export const CARA_WATCHING = 'CARA_WATCHING';
export const CARA_CALM = 'CARA_CALM';
export const DOWN_ARROW = 'DOWN_ARROW';
export const CARA_HAPPY_EYES_CLOSE = 'CARA_HAPPY_EYES_CLOSE';
export const CARA_HAPPY_EYES_OPEN_MOUTH_OPEN =
  'CARA_HAPPY_EYES_OPEN_MOUTH_OPEN';
export const CARA_WOW = 'CARA_WOW';
export const DOWN_ARROW_NOT_ANIMATED = 'DOWN_ARROW_NOT_ANIMATED';

// MEDICAL HISTORY

export const SELECT_DISEASE_START_DATE = 'SELECT_DISEASE_START_DATE';
export const SELECT_RECOVER_DATE = 'SELECT_RECOVER_DATE';
export const SUBMIT_RECOVER_DATE = 'SUBMIT_RECOVER_DATE';
export const SUBMIT_DISEASE_DATE = 'SUBMIT_DISEASE_DATE';
export const SKIP_START_DATE = 'SKIP_START_DATE';
export const SKIP_END_DATE = 'SKIP_END_DATE';
export const FINISH = 'FINISH';

// CURRENT MEDICAL CONDITIONS
export const YES_CURRENT_MEDICAL_CONDITIONS = 'YES_CURRENT_MEDICAL_CONDITIONS';
export const NO_CURRENT_MEDICAL_CONDITIONS = 'NO_CURRENT_MEDICAL_CONDITIONS';
export const CURRENT_MEDICAL_CONDITIONS_FINISH =
  'CURRENT_MEDICAL_CONDITIONS_FINISH';

// FAMILY HISTORY

export const YES_SEARCH = 'YES_SEARCH';
export const NO_FINISH = 'NO_FINISH';
export const FAMILY_HISTORY_COMPLETED = 'FAMILY_HISTORY_COMPLETED';
export const AWESOME = 'AWESOME';

// ASSESSMENT MESSAGE ACTIONS

export const SELECT_SYMPTOM = 'SELECT_SYMPTOM';
export const SELECT_SYMPTOM_NO = 'SELECT_SYMPTOM_NO';
export const SELECT_SYMPTOM_YES = 'SELECT_SYMPTOM_YES';
export const SELECT_SYMPTOM_AGAIN = 'SELECT_SYMPTOM_AGAIN';
export const SELECT_SYMPTOM_SUGGESTION = 'SELECT_SYMPTOM_SUGGESTION';

export const HUMAN_ANATOMY_SELECT = 'HUMAN_ANATOMY_SELECT';
export const HUMAN_ANATOMY = 'HUMAN_ANATOMY';

// Add Profile
export const ENTER_RELATIONSHIP = 'enter_relationship';
export const ADD_PROFILE_SEX = 'add_profile_sex';
export const LEARN_MORE = 'learn_more';

// LifeStyle
export const ENTER_HEIGHT = 'enter_height';
export const ENTER_WEIGHT = 'enter_weight';
export const SMOKING = 'smoking';
export const DRINKING = 'drinking';
export const IS_TRAVELLED = 'is_travelled';
export const SUBMIT_HEIGHT = 'submit_height';
export const SUBMIT_WEIGHT = 'submit_weight';

export const SKIP_HEIGHT = 'SKIP_HEIGHT';
export const SKIP_WEIGHT = 'SKIP_WEIGHT';
export const SKIP_SMOKE = 'SKIP_SMOKE';
export const SKIP_DRINK = 'SKIP_DRINK';

export const LINK_ACCOUNTS = 'LINK_ACCOUNTS';
