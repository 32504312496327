import {ENTER_PROFILE_CONDITION_DATE, SKIP} from 'utils';

export default class CurrentMedicalConditionsMessages {
  constructor(props) {
    this.selectedCurrentMedicalCondition = props.ddo;
    this.customerProfile = props.customerProfile;

    this._newMessages = [
      {
        messages: [
          {
            createdAt: this.today,
            data:
              'When were you diagnosed with ' +
              this.selectedCurrentMedicalCondition.display_name +
              ' ?',
            messageType: 'Automated',
            last_message: 'This is a Test Message 1',
            step: null,
            sub_step: null,
            user: {...this.systemUser},
            text:
              'When were you diagnosed with ' +
              this.selectedCurrentMedicalCondition.display_name +
              ' ?',
            type: 'text',
            _id: 'when_were_you_diagnosed_with_q',
          },
        ],
        actions: [
          {
            createdAt: this.today,
            quickReplies: {
              type: 'radio',
              values: [
                {
                  title: 'Enter Date',
                  value: {
                    action: ENTER_PROFILE_CONDITION_DATE,
                    option: '113131',
                  },
                },
                {
                  title: 'Skip, continue',
                  value: {
                    action: SKIP,
                    option: '113131',
                  },
                },
              ],
            },
            step: null,
            sub_step: null,
            user: {...this.customer},
            _id: 'when_were_you_diagnosed_with_a',
          },
        ],
      },
    ];
  }

  selectedCurrentMedicalCondition = null;
  customerProfile = null;

  today = new Date();

  systemUser = {
    avatar:
      'https://i.pinimg.com/originals/f0/48/9c/f0489ceb101a4bb4f8fd6f6f2c9e2762.jpg',
    name: 'AskBot',
    type: 'Advisor',
    _id: '1234r',
  };

  customer = {
    avatar:
      'https://agent-profile-pics.s3.ap-south-1.amazonaws.com/default-customer.png',
    name: '',
    type: 'Customer',
    _id: '1',
  };

  _newMessages = [];

  _snapShotForStoring = {};

  getinitialMessages = (dependency = {}) => {
    return new Promise((resolve, reject) => {
      const messages = [...this._newMessages[0].messages];
      if (dependency.hasDependency) {
        let {messageIndex, key, value} = dependency;
        messages[messageIndex][key] = value + messages[messageIndex][key];
      }
      resolve({
        messages: messages,
        actions: this._newMessages[0].actions,
      });
    });
  };

  _createAndStoreData = (key, data) => {
    let objectToBeMergerged = {
      [key]: data,
    };
    this._snapShotForStoring = {
      ...this._snapShotForStoring,
      ...objectToBeMergerged,
    };
  };

  createUserMessage = (data) => {
    const obj = {
      _id: Math.random(),
      createdAt: this.today,
      user: {
        _id: '1',
        name: '',
        avatar: '',
        type: 'Customer',
      },
      messageType: 'Automated',
      type: data.type,
      data: data.data,
      last_message: data.last_message,
      step: null,
      sub_step: null,
      text: data.text,
    };

    return obj;
  };

  actionMapper = (
    messageObj,
    {userId = null, token = null, platformUrl = null, username = null},
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {action, data} = messageObj;

        switch (action) {
          case ENTER_PROFILE_CONDITION_DATE:
            this._createAndStoreData('relationship', data);
            break;
          case SKIP:
            this._createAndStoreData('sex_at_birth', data);
            break;
          default:
        }

        resolve('resolved');
      } catch (e) {
        reject(e);
      }
    });
  };

  mergeValueWithArray = (
    callBack,
    limit,
    data,
    dependency,
    {userId = null, token = null, platformUrl = null, username = null},
  ) => {
    const messages = [...this._newMessages[limit].messages];

    // Setting The Typed Name Dynamically to the Message

    if (dependency.hasDependency) {
      let {messageIndex, key, value} = dependency;
      messages[messageIndex][key] = value + messages[messageIndex][key];
    }

    const createdMessage = this.createUserMessage(data);
    messages.push(createdMessage);
    const mergeMessages = [...messages];
    const objTobePassed = {
      messages: mergeMessages,
      actions: this._newMessages[limit].actions
        ? this._newMessages[limit].actions
        : [],
    };
    this.actionMapper(data, {userId, token, platformUrl, username}).then(
      (res) => {
        callBack(objTobePassed);
      },
    );
  };
}
