import {combineReducers} from 'redux';
import AppConfig from '../reducers/AppConfigReducer';
import Application from '../reducers/ApplicationReducer';
import DxSession from '../reducers/DxSessionReducer';
import PersistReducer from './PersistReducer';

export default combineReducers({
  persistReducer: PersistReducer,
  application: Application,
  dxSession: DxSession,
  appConfig: AppConfig,
});
