// @ts-nocheck

import {AssessmentResult} from 'modules';
import React, {useEffect} from 'react';
import {
  EventType,
  firstElementStartWithHashtag,
  getParsedHashtagText,
  useAnalytics,
} from 'utils';
import {ITabResultsProps} from '../TabResults';

interface ISymptomsProps extends ITabResultsProps {
  symptomsList?: Array<string>;
}

export function Symptoms({title, symptomsList}: ISymptomsProps) {
  const {logEvent} = useAnalytics();
  useEffect(() => {
    logEvent(EventType.RESULT_SYMPTOMS_VIEW, {timestamp: Date.now()});
  }, []);

  return (
    <>
      <AssessmentResult.Tab.TextTitle title={title} />

      {firstElementStartWithHashtag(symptomsList) && (
        <AssessmentResult.Tab.TextTitleList
          title={getParsedHashtagText(symptomsList?.[0])}
        />
      )}

      <AssessmentResult.Tab.RenderList items={symptomsList} />
    </>
  );
}
