import React from 'react';
import {View, StyleSheet, GestureResponderEvent} from 'react-native';
import {ModalBase, ModalPropsBase} from './ModalBase';
import {Button} from 'libs';

interface ModalPropsRestart extends ModalPropsBase {
  confirmPressHandler: (event: GestureResponderEvent) => void;
}

export function ModalRestart({
  visible,
  closePressHandler,
  confirmPressHandler,
}: ModalPropsRestart) {
  return (
    <ModalBase
      visible={visible}
      title="Warning!"
      description='Are you sure you want to Restart this Assessment? If you press on "Yes, restart" all your data from this assessment will be lost.'
      closePressHandler={closePressHandler}>
      <View style={styles.buttonsContainer}>
        <View style={styles.leftButtonContainer}>
          <Button.LightGray
            title="Cancel"
            onPress={closePressHandler}
            type="clear"
          />
        </View>

        <View style={styles.rightButtonContainer}>
          <Button.Primary title="Yes, Restart" onPress={confirmPressHandler} />
        </View>
      </View>
    </ModalBase>
  );
}

const styles = StyleSheet.create({
  buttonsContainer: {
    marginTop: 35,
    flexDirection: 'row',
  },
  leftButtonContainer: {
    flex: 1,
    marginRight: 5,
  },
  rightButtonContainer: {
    flex: 1,
    marginLeft: 5,
  },
});
