import {APPLICATION_JSON, GET_DID_YOU_KNOWS} from 'utils';
import axios from 'axios';

export const getDidYouKnows = (uri, token, map) => {
  let url = uri + GET_DID_YOU_KNOWS;

  let config = {
    headers: {
      'Content-Type': APPLICATION_JSON,
      Authorization: 'Bearer ' + token,
    },
  };

  let body = map;

  return axios.put(url, body, config);
};
