import uuid from 'uuid';

export const systemUser = {
  avatar:
    'https://i.pinimg.com/originals/f0/48/9c/f0489ceb101a4bb4f8fd6f6f2c9e2762.jpg',
  name: null,
  type: 'Advisor',
  _id: '1234r',
};
export const customer = {
  avatar:
    'https://agent-profile-pics.s3.ap-south-1.amazonaws.com/default-customer.png',
  name: '',
  type: 'Customer',
  _id: '1',
};

export const evaluatePromises = (promises) => {
  return Promise.all(promises).then((res) => {
    let messages = res[0];
    let quickReplies = promises.length > 1 ? res[1] : [];
    return {
      messages: messages,
      quickReplies: quickReplies,
    };
  });
};

export const generateMessages = (messageTexts, user) => {
  return new Promise((resolve, reject) => {
    let transformedMessages = messageTexts.map((el, index) => {
      return {
        _id: uuid.v4(),
        createdAt: new Date(),
        user: el.userToCreate ? el.userToCreate : user,
        messageType: 'Automated',
        icon: el.caraIcon,
        type: el.type,
        shouldAnimate: el.shouldAnimate,
        data: el.data,
        last_message: el.lastMessage,
        step: el.step,
        sub_step: el.sub_step ? el.sub_step : null,
        text: el.text,
        coordinates: el?.coordinates,
        svg: el?.svg,
        msgText: el?.msgText,
      };
    });
    resolve(transformedMessages);
  });
};

export const generateQuickReplies = (
  quickReplies,
  stage = null,
  quickRepliesAction = {},
  answerType = 'default',
) => {
  return new Promise((resolve, reject) => {
    let transformedQuickReplies = quickReplies.map((el) => {
      return {
        title: el.text,
        styleBg: el?.styleBg,
        styleTx: el?.styleTx,
        value: {
          action: el.action ? el.action : stage,
          option: '113131',
        },
        data: el.data,
        tag: quickRepliesAction.tag ? quickRepliesAction.tag : _id,
        key: el.key ? el.key : 0,
        qr: el?.qr ? el?.qr : [],
        modifier_branch_image_reference_list:
          el?.modifier_branch_image_reference_list
            ? el?.modifier_branch_image_reference_list
            : [],
        modifier_image_reference: el?.modifier_image_reference
          ? el?.modifier_image_reference
          : '',
      };
    });

    let _id = uuid.v4();

    let quickRepliesObj = {
      type: 'radio',
      values: transformedQuickReplies,
      step: null,
      sub_step: null,
      user: customer,
      _id,
    };
    let action = {
      createdAt: new Date(),
      tag: quickRepliesAction.tag ? quickRepliesAction.tag : _id,
      quickReplies: quickRepliesObj,
      answer_type: answerType,
    };
    resolve([action]);
  });
};
