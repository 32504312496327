import {Spacer} from 'app/components';
import {isWeb} from 'config';
import {Text, WebViewModal} from 'libs';
import {AssessmentResult} from 'modules';
import React, {useEffect, useState} from 'react';
import {Colors, EventType, getParsedHashtagText, useAnalytics} from 'utils';
import {ITabResultsProps} from '../TabResults';

interface IDescriptionProps extends ITabResultsProps {
  description?: string;
  source_links?: string;
}

export function Description({
  title,
  description,
  source_links,
}: IDescriptionProps) {
  const [sourceLinksArray, setSourceLinksArray] = useState([] as any);
  const [openedLink, setOpenedLink] = useState('');
  const {logEvent} = useAnalytics();

  useEffect(() => {
    const links = source_links?.split('\n');
    setSourceLinksArray(links);
  }, [source_links]);

  useEffect(() => {
    logEvent(EventType.RESULT_DESCRIPTION_VIEW, {timestamp: Date.now()});
  }, []);

  function linkPressed(link: string) {
    setOpenedLink(link);
  }

  function closeModalPressHandler() {
    setOpenedLink('');
  }

  return (
    <>
      <WebViewModal
        visible={openedLink !== ''}
        link={openedLink}
        closeHandler={closeModalPressHandler}
      />

      <AssessmentResult.Tab.TextTitle title={title} />

      <Text.Subtitle variant="normal" size="subtitle4">
        {getParsedHashtagText(description)}
      </Text.Subtitle>

      {sourceLinksArray && (
        <>
          <Spacer height={30} color={Colors.keyboardToolbarBackground}></Spacer>
          <AssessmentResult.Tab.TextTitle title="Citations" />
          {sourceLinksArray.map((link: string) => {
            return (
              <>
                <Text.Subtitle
                  color={'blue'}
                  onPress={() =>
                    isWeb ? window.open(link, '_blank') : linkPressed(link)
                  }
                  variant="normal"
                  size="subtitle4">
                  {link}
                </Text.Subtitle>
                <Spacer
                  height={10}
                  color={Colors.keyboardToolbarBackground}></Spacer>
              </>
            );
          })}
        </>
      )}
    </>
  );
}
