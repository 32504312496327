// @ts-nocheck

import React from 'react';
import Svg, {Rect} from 'react-native-svg';

export function MinusOperator() {
  return (
    <Svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <Rect
        x={14}
        y={7}
        width={2}
        height={12}
        rx={1}
        transform="rotate(90 14 7)"
        fill="#E9662F"
      />
    </Svg>
  );
}
