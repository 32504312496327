import React from 'react';
import {View, StyleSheet} from 'react-native';
import {Row} from 'modules';
import {Colors, Spacing, getParsedHashtagText} from 'utils';
import {Text} from 'libs';

interface IUnorderedPrimaryListItemProps {
  text: string;
}

export function UnorderedPrimaryListItem({
  text,
}: IUnorderedPrimaryListItemProps) {
  return (
    <Row
      alignItems="center"
      marginTop={Spacing.base13}
      marginHorizontal={Spacing.tiny}>
      <View style={styles.circleStyle} />
      <Text.Body variant="normal" size="body2" style={{flex: 1}}>
        {getParsedHashtagText(text)}
      </Text.Body>
    </Row>
  );
}

const styles = StyleSheet.create({
  circleStyle: {
    width: Spacing.base,
    height: Spacing.base,
    backgroundColor: Colors.primary,
    borderRadius: Spacing.base,
    marginRight: Spacing.base18,
  },
});
