import {StyleSheet} from 'react-native';
import {MARKPRO_BOLD, MARKPRO_BOOK, MARKPRO_MEDIUM} from 'utils';
import {Colors} from './Colors';

const viewStyles_Padding = {
  padding0: {padding: 0},
  padding5: {padding: 5},
  padding10: {padding: 10},
  padding15: {padding: 15},
  padding20: {padding: 20},
  padding25: {padding: 25},
  padding30: {padding: 30},
  padding35: {padding: 35},
  paddingRight3: {paddingRight: 3},
  paddingRight5: {paddingRight: 5},
  paddingRight10: {paddingRight: 10},
  paddingRight25: {paddingRight: 25},
  paddingLeft5: {paddingLeft: 5},
  paddingLeft8: {paddingLeft: 8},
  paddingLeft20: {paddingLeft: 20},
  paddingLeft25: {paddingLeft: 25},
  paddingLeft15: {paddingLeft: 15},
  paddingTop2: {paddingTop: 2},
  paddingTop3: {paddingTop: 3},
  paddingTop5: {paddingTop: 5},
  paddingTop8: {paddingTop: 8},
  paddingTop10: {paddingTop: 10},
  paddingTop20: {paddingTop: 20},
  paddingTop25: {paddingTop: 25},
  paddingTop13: {paddingTop: 13},
  paddingBottom5: {paddingBottom: 5},
  paddingBottom8: {paddingBottom: 8},
  paddingBottom10: {paddingBottom: 10},
  paddingBottom15: {paddingBottom: 15},
  paddingBottom20: {paddingBottom: 20},
  paddingBottom30: {paddingBottom: 30},
  paddingBottom40: {paddingBottom: 40},
  paddingBottom50: {paddingBottom: 50},
  paddingBottom80: {paddingBottom: 80},
  paddingBottom85: {paddingBottom: 85},
  paddingBottom90: {paddingBottom: 90},
  paddingHorizontal12: {paddingHorizontal: 12},
  paddingHorizontal15: {paddingHorizontal: 15},
  paddingHorizontal17: {paddingHorizontal: 17},
  paddingHorizontal0: {paddingHorizontal: 0},
  paddingHorizontal5: {paddingHorizontal: 5},
  paddingHorizontal10: {paddingHorizontal: 10},
  paddingHorizontal20: {paddingHorizontal: 20},
  paddingHorizontal25: {paddingHorizontal: 25},
  paddingHorizontal28: {paddingHorizontal: 28},
  paddingHorizontal30: {paddingHorizontal: 30},
  paddingHorizontal40: {paddingHorizontal: 40},
  paddingVertical_10: {paddingVertical: -10},
  paddingVertical0: {paddingVertical: 0},
  paddingVertical3: {paddingVertical: 3},
  paddingVertical5: {paddingVertical: 5},
  paddingVertical8: {paddingVertical: 8},
  paddingVertical10: {paddingVertical: 10},
  paddingVertical13: {paddingVertical: 13},
  paddingVertical15: {paddingVertical: 15},
  paddingVertical20: {paddingVertical: 20},
  paddingVertical25: {paddingVertical: 25},
  paddingVertical30: {paddingVertical: 30},
};

const viewStyles_Margin = {
  margin5: {margin: 5},
  margin9: {margin: 9},
  marginLeft6: {marginLeft: 6},
  marginLeft8: {marginLeft: 8},
  marginLeft5: {marginLeft: 5},
  marginLeft10: {marginLeft: 10},
  marginLeft20: {marginLeft: 20},
  marginLeft80: {marginLeft: 80},
  marginLeft90: {marginLeft: 90},
  marginRight10: {marginRight: 10},
  marginRight20: {marginRight: 20},
  marginRight40: {marginRight: 40},
  marginTop0: {marginTop: 0},
  marginTop7_2: {marginTop: 7.2},
  marginTop10: {marginTop: 10},
  marginTop20: {marginTop: 20},
  marginTop15: {marginTop: 15},
  marginTop30: {marginTop: 30},
  marginTop50: {marginTop: 50},
  marginVertical15: {
    marginVertical: 15,
  },
  marginTop80: {marginTop: 80},
  marginVertical10: {marginVertical: 10},
  marginVertical20: {marginVertical: 20},
  marginVertical30: {marginVertical: 30},
  marginHorizontal0: {marginHorizontal: 0},
  marginHorizontal1: {marginHorizontal: 1},
  marginHorizontal15: {marginHorizontal: 15},
  marginHorizontal20: {marginHorizontal: 20},
  marginHorizontal25: {marginHorizontal: 25},
  marginHorizontal30: {marginHorizontal: 30},
  marginBottom_2: {marginBottom: -2},
  marginBottom0_5: {marginBottom: 0.5},
  marginBottom2: {marginBottom: 2},
  marginBottom3: {marginBottom: 3},
  marginBottom5: {marginBottom: 5},
  marginBottom7: {marginBottom: 7},
  marginBottom10: {marginBottom: 10},
  marginBottom15: {marginBottom: 15},
  marginBottom20: {marginBottom: 20},
  marginBottom35: {marginBottom: 35},
  marginBottom40: {marginBottom: 40},
  marginBottom100: {marginBottom: 100},
  marginBottom130: {marginBottom: 130},
};

const viewStyles_Height = {
  height1: {height: 1},
  height10: {height: 10},
  height16: {height: 16},
  height24: {height: 24},
  height25: {height: 25},
  height5: {height: 5},
  height36: {height: 36},
  height20: {height: 20},
  height30: {height: 30},
  height40: {height: 40},
  height44: {height: 44},
  height45: {height: 45},
  height64: {height: 64},
  height65: {height: 65},
  height48: {height: 48},
  height50: {height: 50},
  height60: {height: 60},
  height66: {height: 66},
  height70: {height: 70},
  height71: {height: 71},
  height75: {height: 75},

  height80: {height: 80},
  height85: {height: 85},
  height85_3: {height: 85.3},
  height88: {height: 88},
  height90: {height: 90},
  height92: {height: 92},
  height100: {height: 100},
  height100P: {height: '100%'},
  height101: {height: 101},
  height110: {height: 110},
  height115: {height: 115},
  height120: {height: 120},
  height130: {height: 130},
  height150: {height: 150},
  height190: {height: 190},
  height200: {height: 200},
  minHeight0: {minHeight: 0},
  minHeight100: {minHeight: 100},
  minHeight500: {minHeight: 500},
  lineHeight19: {lineHeight: 19},
  lineHeight24: {lineHeight: 24},
  height260: {height: 260},
  height280: {height: 280},
  height300: {height: 300},
  height360: {height: 360},
  height600: {height: 600},
};

const viewStyles_Width = {
  width16: {width: 16},
  width20: {width: 20},
  width24: {width: 24},
  width25: {width: 25},
  width30: {width: 30},
  width30P: {width: '30%'},
  width40: {width: 40},
  width48: {width: 48},
  width55: {width: 55},
  width60: {width: 60},
  width71: {width: 71},
  width74_25: {width: 74.25},
  width75: {width: 75},
  width80: {width: 80},
  width88: {width: 88},
  width70P: {width: '70%'},
  width80P: {width: '80%'},
  width85P: {width: '85%'},
  width90P: {width: '90%'},
  width92: {width: 92},
  width100: {width: 100},
  width105: {width: 105},
  width150: {width: 150},
  width100P: {width: '100%'},
};

const viewStyles_Position = {
  positionAbsolute: {position: 'absolute'},
  right0: {right: 0},
  right2: {right: 2},
  right10: {right: 10},
  right15: {right: 15},
  right20: {right: 20},
  right24: {right: 24},
  right28: {right: 28},
  left0: {left: 0},
  left2: {left: 2},
  left17: {left: 17},
  left18: {left: 18},
  left20: {left: 20},
  left24: {left: 24},
  left_5: {left: -5},
  left_7: {left: -7},
  left_9: {left: -9},
  left_10: {left: -10},
  top30: {top: 30},
  top50: {top: 50},
  left_13: {left: -13},
  top_5: {top: -5},
  top_10: {top: -10},
  top0: {top: 0},
  top2: {top: 2},
  top10: {top: 10},
  top12: {top: 12},
  top15: {top: 15},
  bottom0: {bottom: 0},
  bottom5: {bottom: 5},
  bottom10: {bottom: 10},
  bottom51: {bottom: 51},
  bottom80: {bottom: 80},
  bottom_3: {bottom: -3},
  bottom_5: {bottom: -5},
  bottom_10: {bottom: -10},
  bottom_15: {bottom: -15},
  bottom_16: {bottom: -16},
  bottom_17: {bottom: -17},
};

const viewStyles_zIndex = {
  zIndex_1: {zIndex: -1},
  zIndex1: {zIndex: 1},
  zIndex2: {zIndex: 2},
  zIndex3: {zIndex: 3},
  zIndex5: {zIndex: 5},
  zIndex10: {zIndex: 10},
  zIndex11: {zIndex: 11},
  zIndex15: {zIndex: 15},
  zIndex40: {zIndex: 40},
  zIndex100: {zIndex: 100},
  zIndex110: {zIndex: 110},
  zIndex200: {zIndex: 200},
  zIndex300: {zIndex: 300},
  zIndex600: {zIndex: 600},
  zIndex1000: {zIndex: 1000},
};

const viewStyles_Border = {
  borderColorPrimary: {borderColor: Colors.primary},
  borderColorSearchIconColor: {borderColor: Colors.searchIconColor},
  borderBottomColorTransparent: {borderColor: Colors.transparent},
  borderRadius4: {borderRadius: 4},
  borderRadius5: {borderRadius: 5},
  borderRadius12: {borderRadius: 12},
  borderRadius16: {borderRadius: 16},
  borderRadius18: {borderRadius: 18},
  borderRadius20: {borderRadius: 20},
  borderRadius25: {borderRadius: 25},
  borderRadius50: {borderRadius: 50},
  borderWidth1: {borderWidth: 1},
  borderWidth2: {borderWidth: 2},
  borderTopWidth0: {borderTopWidth: 0},
  borderTopWidth1: {borderTopWidth: 1},
  borderBottomWidth1: {borderBottomWidth: 1},
  borderBottomLeftRadius8: {borderBottomLeftRadius: 8},
  borderTopLeftRadius18: {borderTopLeftRadius: 18},
  borderTopRightRadius18: {borderTopRightRadius: 18},
  borderTopRightRadius26: {borderTopRightRadius: 26},
  borderTopLeftRadius26: {borderTopLeftRadius: 26},
  borderBottomLeftRadius30: {borderBottomLeftRadius: 30},
  borderBottomRightRadius26: {borderBottomRightRadius: 26},
  borderBottomRightRadius30: {borderBottomRightRadius: 30},
};

const viewStyles_Flex = {
  flex1: {flex: 1},
  flexWrapStyle: {flexWrap: 'wrap'},
  flexGrow1: {flexGrow: 1},
  flexRow: {flexDirection: 'row'},
  flexColumn: {flexDirection: 'column'},
  displayFlex: {display: 'flex'},
};

const viewStyles_Alignment = {
  alignItemsCenter: {alignItems: 'center'},
  alignSelfCenter: {alignSelf: 'center'},
  alignItemsFlexStart: {alignItems: 'flex-start'},
  justifyContentSpaceBetween: {justifyContent: 'space-between'},
  justifyContentSpaceAround: {justifyContent: 'space-around'},
  justifyContentCenter: {justifyContent: 'center'},
  justifyContentFlexStart: {justifyContent: 'flex-start'},
  justifyContentFlexEnd: {justifyContent: 'flex-end'},
  overflowHidden: {overflow: 'hidden'},
};

const viewStyles_SafeArea = {
  safeAreaBottomNever: {bottom: 'never'},
  safeAreaTopAlways: {top: 'always'},
  safeAreaTopNever: {top: 'never'},
};

const viewStyle_Shadow = {
  shadowColorBlack: {shadowColor: Colors.black},
  shadowColorDeleteTextColor: {shadowColor: Colors.deleteTextColor},
  shadowOffsetW0H1: {shadowOffset: {width: 0, height: 1}},
  shadowOffsetW0H2: {shadowOffset: {width: 0, height: 2}},
  shadowOpacity04: {shadowOpacity: 0.4},
  shadowOpacity08: {shadowOpacity: 0.8},
  shadowRadius5: {shadowRadius: 5},
  shadowRadius6: {shadowRadius: 6},
  opacity0: {opacity: 0},
};

const viewStyles = StyleSheet.create({
  ...viewStyles_Flex,
  ...viewStyles_Height,
  ...viewStyles_Width,
  ...viewStyles_Padding,
  ...viewStyles_Position,
  ...viewStyles_Margin,
  ...viewStyles_zIndex,
  ...viewStyles_Border,
  ...viewStyles_Alignment,
  ...viewStyles_SafeArea,
  ...viewStyle_Shadow,
});

const textStyles = StyleSheet.create({
  fontFamilyMARKPRO_MEDIUM: {fontFamily: MARKPRO_MEDIUM},
  fontFamilyMARKPRO_BOLD: {fontFamily: MARKPRO_BOLD},
  fontFamilyMARKPRO_BOOK: {fontFamily: MARKPRO_BOOK},
  textAlignCenter: {textAlign: 'center'},
  textAlignJustify: {textAlign: 'justify'},
  textAlignRight: {textAlign: 'right'},
  colorTextWhite: {color: Colors.white},
  colorTextAlizarin: {color: Colors.alizarin},
  colorTextBlack: {color: 'black'},
  colorTextPurple: {color: Colors.textPurple},
  colorTextDanger: {color: Colors.danger},
  colorTextDeleteDarkColor: {color: Colors.deleteDarkColor},
  colorTextPrimary: {color: Colors.primary},
  colorTextInputPrimary: {color: Colors.textPrimary},
  colorTextBlackColor: {color: Colors.textBlackColor},
  colorTextDeleteTextColor: {color: Colors.deleteTextColor},
  colorTextBubbleTextColor: {color: Colors.bubbleTextColor},
  colorTextSearchColor: {color: Colors.searchIconColor},
  colorTextDarkGreyColor: {color: Colors.textDarkGreyColor},
  colorTextEditSuccessTextColor: {color: Colors.editSuccessTextColor},
  fontWeightBOLD: {fontWeight: 'bold'},
  fontWeight300: {fontWeight: '300'},
  fontWeight600: {fontWeight: '600'},
  lineHeight19: {lineHeight: 19},
  lineHeight22: {lineHeight: 22},
  lineHeight24: {lineHeight: 24},
  fontSize12: {fontSize: 12},
  fontSize14: {fontSize: 14},
  fontSize15: {fontSize: 15},
  fontSize16: {fontSize: 16},
  fontSize17: {fontSize: 17},
  fontSize18: {fontSize: 18},
  fontSize20: {fontSize: 20},
  fontSize22: {fontSize: 22},
  fontSize24: {fontSize: 24},
  fontSize28: {fontSize: 28},
});

const viewColors = StyleSheet.create({
  backgroundColorTransparent: {backgroundColor: Colors.transparent},
  backgroundColorDeleteToastBgColor: {
    backgroundColor: Colors.deleteToastBgColor,
  },
  backgroundColorWhite: {backgroundColor: Colors.white},
  backgroundColorDeleteTextColor: {backgroundColor: Colors.deleteTextColor},
  backgroundColorTopPurple: {backgroundColor: Colors.topPurple},
  backgroundColorPrimary: {backgroundColor: Colors.primary},
  backgroundColorEditSuccessToastBgColor: {
    backgroundColor: Colors.editSuccessToastBgColor,
  },
  backgroundBorderPink: {backgroundColor: Colors.borderPink},
  backgroundColorSystemChatBubblesColor: {
    backgroundColor: Colors.systemChatBubblesColor,
  },
  backgroundColorChatMessageContainerOrangeColor: {
    backgroundColor: Colors.chatMessageContainerOrangeColor,
  },
  backgroundColorBackDrop: {backgroundColor: 'rgba(0 , 0 , 0 , 0.3)'},
  backgroundColorBorderPink: {backgroundColor: Colors.borderPink},
  borderBottomColorBorderPink: {borderBottomColor: Colors.borderPink},
  borderColorDeleteTextColor: {borderColor: Colors.deleteTextColor},
  borderColorPrimary: {borderColor: Colors.primary},
  borderColorTextSecondary: {borderColor: Colors.textSecondary},
  borderColorButtonOnHighlight: {borderColor: Colors.buttonOnHighlight},
  backgroundColorUpdatePasswordButton: {
    backgroundColor: Colors.updatePasswordButton,
  },
});

export {viewStyles, textStyles, viewColors};
