import React from 'react';
import {Modal, StyleSheet} from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import HumanAnatomy from '../../containers/dashBoard/humanBodyTest/HumanAnatomy';

export default function HumanAnatomyModal({
  showBodySelectionModal,
  humanAnatomyDataObject,
  onPressHumanAnatomy,
  close,
}) {
  return (
    <Modal visible={showBodySelectionModal} animationType={'slide'}>
      <SafeAreaView style={styles.safeArea} forceInset={styles.safeAreaForce}>
        {humanAnatomyDataObject != null && (
          <HumanAnatomy
            humanAnatomyDataObject={humanAnatomyDataObject}
            onPressQuickReply={onPressHumanAnatomy}
            close={close}
          />
        )}
      </SafeAreaView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  safeArea: {flex: 1},
  safeAreaForce: {top: 'never', bottom: 'always'},
});
