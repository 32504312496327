import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {initializeClinBOT, loginPlatform, PLATFORM_URL} from 'utils';
import {PlatformReLogin, SplashScreen} from '../../app/containers';
import {
  isInitializeClinBOTFailed,
  isLoginPlatformFailed,
  setPlatformToken,
  setSystemInitializationSuccess,
} from '../../app/redux/actions/Application';
import {AnonymousNavigator} from './AnonymousNavigator';

// TODO refactor! Remove AnonymousRoutes; the login logic should be in a hook
// TODO typings
function AnonymousRoutes(props: any) {
  const navigation = React.useRef(null);

  const _checkPlatformLogin = async (platformUrl: string) => {
    let resultLoginPlatform, platformToken;
    try {
      resultLoginPlatform = await loginPlatform();

      if (resultLoginPlatform.data) {
        const token = resultLoginPlatform.data.token;
        platformToken = resultLoginPlatform.data.token;
        props.dispatch(setPlatformToken(token));
        await _initializeCLINBOT(platformToken, platformUrl);
      } else {
        props.dispatch(isLoginPlatformFailed(true));
        return null;
      }
    } catch (error) {
      props.dispatch(isLoginPlatformFailed(true));
      return null;
    }
  };

  const _initializeCLINBOT = async (
    platformToken: string,
    platformUrl: string,
  ) => {
    let resultInitializeClinBOT;
    if (platformUrl) {
      try {
        resultInitializeClinBOT = await initializeClinBOT(
          platformUrl,
          platformToken,
        );

        if (resultInitializeClinBOT.data) {
          props.dispatch(
            setSystemInitializationSuccess({
              platformToken: platformToken,
              platformUrl: platformUrl,
            }),
          );
        } else {
          props.dispatch(isInitializeClinBOTFailed(true));
          return;
        }
      } catch (err) {
        props.dispatch(isInitializeClinBOTFailed(true));
        return;
      }
    } else {
      props.dispatch(isInitializeClinBOTFailed(true));
      return;
    }
  };

  async function login() {
    await _checkPlatformLogin(PLATFORM_URL);
  }

  useEffect(() => {
    login();
  }, []);

  // TODO re-login should happen behind the scenes
  if (props.isInitializeClinBOTFailed || props.isLoginPlatformFailed) {
    return <PlatformReLogin checkPlatformLogin={login} />;
  }

  if (!props.isLoginPlatform) {
    return <SplashScreen status="Loading" />;
  }

  return <AnonymousNavigator navigation={navigation} />;
}

// TODO refactor!!!!!
const mapStateToProps = (state: any) => {
  return {
    platformUrl: state.application.platformUrl,
    platformToken: state.application.platformToken,
    isLoginPlatform: state.application.isLoginPlatform,
    isLoginPlatformFailed: state.application.isLoginPlatformFailed,
    isInitializeClinBOTFailed: state.application.isInitializeClinBOTFailed,
  };
};

export default connect(mapStateToProps, null)(AnonymousRoutes);
