import React from 'react';
import Svg, {Line, Path} from 'react-native-svg';
import {fma15_1_5, fma222981, fma317909, fma38287, fma40770} from 'utils';
import {getColorFma} from 'utils';

export const Hand_Right_Back_fma321784 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.828 199.511">
      <Path
        id="Path_268"
        dataName="Path 268"
        d="M117.314,199.228l-45.753.283a6.518,6.518,0,0,1-6.557-6.3c-.308-8.94-1.7-24.735-7.615-29.875C48.975,156.02,38,137,31.782,132.609s-8.413-23.045-15.728-29.629c-1.828-1.646-3.978-3.43-6.1-5.276C3.593,92.166-2.511,86.062,1.054,81.4c4.756-6.219,26.7-4.39,42.8,27.434l2.926-16.1S41.5,45.871,39.83,20.671c-.34-5.129,2.987-9.389,7.194-9.877a8.45,8.45,0,0,1,9.144,6.95c4.3,24.918,7.438,57.064,7.438,57.064h4.756l3.2-64.7a12.929,12.929,0,0,1,1.783-6.115A7.486,7.486,0,0,1,80.251,0C88.115.365,88.3,10.242,88.3,10.242l-1.647,63.1,5.488.732,6.949-55.967s1.1-8.687,7.683-8.413c8.778.366,6.583,15.729,6.583,15.729l-2.926,54.5,5.121,1.1,7.134-42.981s.731-6.4,6.949-6.036,5.121,8.231,5.121,8.231l-7.133,93.826L121.4,162.23l2.372,29.953a6.518,6.518,0,0,1-5.971,7.024Q117.56,199.227,117.314,199.228Z"
        transform="translate(0.001 0.006)"
        fill="#ead9e7"
      />
      <Path
        id={fma222981}
        dataName="Path 269"
        d="M43.879,108.693l2.9-15.954s-.216-2.23-.728-7.065a1.665,1.665,0,0,1,2.111-1.794,19.59,19.59,0,0,0,7.214.567c4.447-.338,7.412-4.7,9.065-8.61a1.644,1.644,0,0,1,1.478-.99h0a1.645,1.645,0,0,1,1.567,1c.9,2.111,3.628,5.849,11.241,5.189a11.049,11.049,0,0,0,8.625-6.248,1.679,1.679,0,0,1,1.73-1.036l.557.064a1.651,1.651,0,0,1,1.446,1.355c.64,3.826,2.693,9.524,9.71,10.62a10.284,10.284,0,0,0,10.267-4.5,1.676,1.676,0,0,1,1.742-.807l.526.1a1.684,1.684,0,0,1,1.306,1.158c2.753,9.5,12.261,8.939,16.181,8.758L127.8,129.991l-79.607.421a1.079,1.079,0,0,1-1.072-.912Z"
        transform="translate(0.001 0.006)"
        fill={getColorFma(fma222981, selectedBodyPart)}
        onPressIn={() => onPressClick(fma222981)}
        onClick={() => onPressClick(fma222981)}
      />
      <Path
        id={fma40770}
        dataName="Path 270"
        d="M43.421,134.288l3.623-1.315a.99.99,0,0,1,1.109.3c1.876,2.308,9.184,11.314,11.734,14.7,3.63,4.813,7.227,12.324,8.466,15.008a.95.95,0,0,1-.468,1.258.911.911,0,0,1-.132.049l-7.846,2.335c-.111-.2-.219-.4-.336-.588a11.54,11.54,0,0,0-2.185-2.7c-7.03-6.112-14.941-19.487-21.278-26.581Z"
        transform="translate(0.001 0.006)"
        fill={getColorFma(fma40770, selectedBodyPart)}
        onPressIn={() => onPressClick(fma40770)}
        onClick={() => onPressClick(fma40770)}
      />
      <Path
        id={fma38287}
        dataName="Path 271"
        d="M70.378,165.466H70.4c.536-.062,1.075-.1,1.612-.137l49.391-3.086,1.287,16.249c-21.732-2.43-46,1.229-58.553,3.634-.726-5.678-1.354-10.289-3.577-14.275l6.369-1.733A22.982,22.982,0,0,1,70.378,165.466Z"
        transform="translate(0.001 0.006)"
        fill={getColorFma(fma38287, selectedBodyPart)}
        onPressIn={() => onPressClick(fma38287)}
        onClick={() => onPressClick(fma38287)}
      />
      <Path
        id={fma317909}
        dataName="Path 272"
        d="M69.581,161.874c-4.39-10.974-14.788-22.881-20.119-29.081a.632.632,0,0,1,.481-1.042l77.891-.147-.12,2.519L122.12,159.46l-.337,1.527S79.538,163.5,72.164,163.84c0,0-1.737.274-2.057-.6C69.7,162.157,69.581,161.874,69.581,161.874Z"
        transform="translate(0.001 0.006)"
        fill={getColorFma(fma317909, selectedBodyPart)}
        onPressIn={() => onPressClick(fma317909)}
        onClick={() => onPressClick(fma317909)}
      />
      <Path
        id="Path_274"
        dataName="Path 274"
        d="M40.806,17.263s1.828,8.047,8.656,7.316c6.323-.678,5-8.9,5-8.9"
        transform="translate(0.001 0.006)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id="Path_275"
        dataName="Path 275"
        d="M7.274,94.567l2.8-2.316c2.44-2.317-4.633-8.536-4.633-8.536"
        transform="translate(0.001 0.006)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id="Path_276"
        dataName="Path 276"
        d="M73.361,4.582s1.219,9.389,6.584,9.389,6.706-8.78,6.706-8.78"
        transform="translate(0.001 0.006)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id="Path_277"
        dataName="Path 277"
        d="M101.4,12.873s-1.951,8.535,4.633,8.535,5.487-7.559,5.487-7.559"
        transform="translate(0.001 0.006)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id="Path_278"
        dataName="Path 278"
        d="M125.3,34.577s-.365,8.169,3.414,8.657,3.658-8.779,3.658-8.779"
        transform="translate(0.001 0.006)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Line
        id="Line_58"
        dataName="Line 58"
        x1="4.877"
        y2="14.998"
        transform="translate(103.113 102.987)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Line
        id="Line_59"
        dataName="Line 59"
        x1="0.488"
        y1="20.241"
        transform="translate(87.262 96.524)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Line
        id="Line_60"
        dataName="Line 60"
        x1="5.121"
        y1="16.583"
        transform="translate(67.753 100.914)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id={fma15_1_5}
        dataName="Path 279"
        d="M131.031,89.217c-14.217,1.052-15.482-8.184-15.482-8.184l5.63-33.921a2.315,2.315,0,0,1,2.64-1.907l8.324,1.306a2.313,2.313,0,0,1,1.947,2.461Z"
        transform="translate(0.001 0.006)"
        fill={getColorFma(fma15_1_5, selectedBodyPart)}
        onPressIn={() => onPressClick(fma15_1_5)}
        onClick={() => onPressClick(fma15_1_5)}
      />
      <Path
        id={fma15_1_5}
        dataName="Path 280"
        d="M110.428,79.936a8.808,8.808,0,0,1-9.388,4.511c-7.561-1.339-8.9-10.364-8.9-10.364l5.974-48.11a2.313,2.313,0,0,1,2.562-2.013l10.519,1.221a2.315,2.315,0,0,1,2.044,2.422Z"
        transform="translate(0.001 0.006)"
        fill={getColorFma(fma15_1_5, selectedBodyPart)}
        onPressIn={() => onPressClick(fma15_1_5)}
        onClick={() => onPressClick(fma15_1_5)}
      />
      <Path
        id={fma15_1_5}
        dataName="Path 281"
        d="M86.651,73.351A9.565,9.565,0,0,1,78.6,79.692c-8.814.81-10.242-4.877-10.242-4.877L71.1,19.488a2.313,2.313,0,0,1,2.213-2.2l12.344-.522a2.312,2.312,0,0,1,2.41,2.212c0,.053,0,.107,0,.16Z"
        transform="translate(0.001 0.006)"
        fill={getColorFma(fma15_1_5, selectedBodyPart)}
        onPressIn={() => onPressClick(fma15_1_5)}
        onClick={() => onPressClick(fma15_1_5)}
      />
      <Path
        id={fma15_1_5}
        dataName="Path 282"
        d="M42.659,28.639l12.773-1.474a2.313,2.313,0,0,1,2.554,1.952c3.336,22.332,5.62,45.7,5.62,45.7s-2.3,7.385-8.413,8.291a13.965,13.965,0,0,1-9.646-1.6c-1.362-12.643-3.546-33.6-4.93-50.382A2.309,2.309,0,0,1,42.659,28.639Z"
        transform="translate(0.001 0.006)"
        fill={getColorFma(fma15_1_5, selectedBodyPart)}
        onPressIn={() => onPressClick(fma15_1_5)}
        onClick={() => onPressClick(fma15_1_5)}
      />
      <Path
        id={fma15_1_5}
        dataName="Path 283"
        d="M21.3,93.957c8.68,12.118,21.624,31.671,24.814,36.507a1.061,1.061,0,0,1-.3,1.47,1.1,1.1,0,0,1-.214.108l-3.21,1.208-7.26,2.509a21.535,21.535,0,0,0-3.343-3.15c-6.219-4.389-8.413-23.045-15.728-29.629-2-1.8-4.392-3.772-6.7-5.806v-.009a43.771,43.771,0,0,0,8.048-4.653,2.293,2.293,0,0,1,3.177.461C20.814,93.294,21.056,93.621,21.3,93.957Z"
        transform="translate(0.001 0.006)"
        fill={getColorFma(fma15_1_5, selectedBodyPart)}
        onPressIn={() => onPressClick(fma15_1_5)}
        onClick={() => onPressClick(fma15_1_5)}
      />
    </Svg>
  );
};
