import React from 'react';
import {Pressable, StyleSheet, GestureResponderEvent} from 'react-native';
import {Column, Row, Container} from 'modules';
import {Text} from 'libs';
import {colors, HomeSvg, HospitalSvg, Spacing} from 'utils';

interface IAssessmentResultDiseaseItemProps {
  diagnoseName?: string;
  diagnoseDescription?: string;
  percentageGreenColor?: boolean;
  diagnosePercentage?: string;
  onPress: (event: GestureResponderEvent) => void;
}

export function AssessmentResultDiseaseItem({
  diagnoseName,
  diagnoseDescription,
  percentageGreenColor,
  diagnosePercentage,
  onPress,
}: IAssessmentResultDiseaseItemProps) {
  return (
    <Pressable onPress={onPress}>
      <Row
        alignItems="center"
        justifyContent="space-between"
        borderRadius={Spacing.small}
        marginHorizontal={Spacing.small}
        marginBottom={Spacing.base}
        backgroundColor={colors.white}
        padding={Spacing.small}>
        <Column style={styles.flexOne}>
          <Text.Subtitle
            variant="medium"
            size="subtitle3"
            color={colors.primary}>
            {diagnoseName}
          </Text.Subtitle>
          {/* We will need this in the future */}
          {/* <Row
            marginTop={Spacing.base}
            marginRight={Spacing.base15}
            alignItems="center">
            <HomeSvg />
            <HospitalSvg />
            <Text.Caption
              style={styles.descriptionText}
              color={colors.darkGrey}>
              {diagnoseDescription}
            </Text.Caption>
          </Row> */}
        </Column>
        <Container>
          <Text.Subtitle
            variant="bold"
            size="subtitle1"
            color={percentageGreenColor ? colors.green : colors.black}>
            {diagnosePercentage}
          </Text.Subtitle>
        </Container>
      </Row>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  flexOne: {
    flex: 1,
  },
  descriptionText: {
    marginLeft: Spacing.base,
  },
});
