import React from 'react';
import Svg, {Circle, G, Path} from 'react-native-svg';
import {
  fma24036,
  fma24037,
  fma24040,
  fma20373,
  fma20377,
  fma14605,
  fma14604,
} from 'utils';
import {getColorFma} from 'utils';

export const Abdomen_fma24040 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1242 2208">
      <G id="Group_1" dataName="Group 1">
        <Path
          id="Path_1"
          dataName="Path 1"
          d="M123.9,1886.3c-10.3,0-18.7-8.2-19.1-18.4c-3.7-106.4-3.7-251.4,0.7-293c10.2-125,89.8-516.9,89.8-576.8   c0-45.5-28.3-172.7-41.9-231.1c-4.8-20.7-7.2-41.8-7.2-63c0-55.1-1.7-288.5,3.2-364.4c0.6-10,9-17.8,19-17.8h901.7   c10,0,18.2,7.7,19,17.6c6.2,78.7,9.1,314.3,9.4,357.3c0.1,11.4-1.1,22.7-3.6,33.8c-11.8,52.8-47.8,217.6-47.8,267.6   c0,59.9,79.6,451.8,89.8,576.8c4.5,43.5,4.3,181.5,0.1,293.1c-0.4,10.2-8.8,18.4-19.1,18.4H123.9z"
          fill="#ead9e7"
        />
      </G>
      <Path
        id={fma24040}
        dataName="Path 11"
        d="M747.2,1762.5c10.2,4,21.7,0.5,28.2-8.6l268.1-411.3c15.5-22.3,19.8-50.9,11.7-76.6l-16.6-52.5  c-4.2-13.2-15.2-22.5-28.9-24.5c-1.7-0.2-3.4-0.4-5.1-0.4c-11.7,0-22.7,5.8-29.4,15.7l-222.4,312.6c-13.7,20.5-14.8,48.6-15.7,73.4  l-0.1,1.3c-0.2,5-0.4,10-0.7,15l-4,130.6C731.1,1748.5,737.1,1758.5,747.2,1762.5z"
        fill="#E8CDE3"
      />
      <Path
        id={fma20373}
        dataName="Path 12"
        d="M957.7,979.7c0.1-1.1,0.1-2.2,0.1-2.2c0.2-4.6,0.3-11.9-0.3-31.5c-0.3-10.4-0.4-9.1-0.5-13.6  c-0.3-11.4-0.6-28.2,2-48.5c0.8-5.9,2-14.1,4.2-23.8l0.2-0.9c0.5-2.5,1-5.1,1.6-7.9c0.9-4.4,1.4-7.7,1.6-8.7c0.7-4,2-10.8,4.6-22.9  c-11.4-8.8-22.8-17.6-34.1-26.4c-32-23.9-54.3-46.7-68.2-62.4c-55.1-61.8-172.6-220.4-186.2-243.9c-5.4-9.4-17.1-13.1-27-8.6  c-7,3.2-20,6.9-42.2,6.9c-23.9,0-38.8-4.3-47.2-7.9c-2.7-1.2-5.6-1.8-8.4-1.8c-7.3,0-14.4,3.8-18.2,10.5  c-5.8,10-104.9,166-186.7,244.8c-33,31.8-70.3,58-70.3,58c-12.7,8.9-23.7,15.9-31.6,20.7c0.8,14.2,2.4,25.1,3.7,32.6  c0.7,4,1.4,7.6,2.9,16.1c1.3,7.3,2.5,13.9,3.3,19.8c1.2,8.2,3.5,23.9,3.4,43.2c0,7.2-0.4,7.4-0.5,20.2c-0.1,7.6,0,8.8,0,22.2  c0,13.4-0.1,15.6,0,23.8c0.2,16.1,0.8,19.8,1.7,23.2c1.7,7,4.5,12.5,6.6,16.1c29.4,0.5,58.9,1,88.3,1.5l248.9-0.9l249.9,0.9  c29.8-0.3,59.7-0.7,89.5-1C952.6,1012.7,956.6,994.4,957.7,979.7z"
        fill="#E8CDE3"
      />
      <Path
        id={fma20377}
        dataName="Path 13"
        d="M551.3,1559.9h125.5c18.2,0,35.2-9.5,45.3-25.5c79.2-120,158.4-240.1,237.7-360.1l0,0  c10.8-40.5,10.8-40.5,10.8-40.5c2.6-23-3.1-41.8-6.4-52.7c-4.6-15.2-10.6-26.9-15.3-34.8c-14.2-1.2-29-1.5-44.2-0.7  c-1,0-1.9,0.1-2.9,0.2l-43.4-0.2H365.8l-45.5,0.2c-1,0-2-0.1-3-0.2c-14.3-0.5-28.5-1-42.8-1.4c-2.8,3.3-5.5,7.1-8.2,11.4  c-10.9,17.8-14.3,35.8-15.3,48.1l-0.2,1c-0.2,1.1-0.4,2-0.5,2.6c-4.7,16.9-2,34.9,7.3,49.5l17.6,26.8c1.5,1.8,2.9,3.6,4.2,5.7  l114.1,174.2l112.3,170.8C516.1,1550.4,533.1,1559.9,551.3,1559.9z"
        fill="#E8CDE3"
      />

      <Path
        id={fma14605}
        dataName="Path 13"
        d="M971.3,1022.6c-0.2,11.9-0.3,17.8,0,21.6c1.8,23.9,9.1,43.9,10.4,47.2c2,5.3,5.1,13.6,8.6,26.1  c0.7,2.4,1.3,5,1.8,8.3c4.6,26.4-3,39.2,2.4,43.2c5,3.7,19.6-1,65.3-44.5c-5.8-42.2-11.5-84.3-17.3-126.5  c0.2-12.3,1.1-30.9,4.8-53.1c8.6-52.3,24.6-101.3,19.3-103.3c-1-0.4-1.8,1.4-6,3c-5.6,2.2-11.2,1.8-15.4,1  c-9.7-2.1-19.9-4.5-30.4-7.4c-8.4-2.3-16.4-4.7-24-7.1c-1.7,5.1-3.8,11.7-5.8,19.4c-0.8,2.9-3.5,13.2-6.2,27.7  c-1.7,8.8-3.7,19.6-5,33.9c-1,11-1.1,19.3-1.2,27.2c-0.2,16.2,0.6,17,0.6,32.3C973.1,991.5,971.7,994.5,971.3,1022.6z"
        fill="#E8CDE3"
      />

      <Path
        id={fma14604}
        dataName="Path 13"
        d="M250.8,1011.9c0.2,11.9,0.2,17.8,0,21.6c-1.5,23.9-7.8,43.9-8.9,47.2c-1.7,5.3-4.3,13.6-7.3,26.1  c-0.6,2.4-1.1,5-1.6,8.3c-3.9,26.4,2.6,39.2-2,43.2c-4.2,3.7-16.7-1-55.6-44.5c4.9-42.2,9.8-84.3,14.7-126.5  c-0.2-12.3-1-30.9-4.1-53.1c-6.8-48.8-29.6-95.6-16.5-103.3c0.2-0.1,0.6-0.3,1.1-0.5c4,0.7,9.7,1.5,16.4,1.4  c8.6-0.1,15-1.4,20.5-2.6c6.8-1.5,16.1-4.1,26.6-8.8c1.5,5.1,3.2,11.7,5,19.4c0.7,2.9,3,13.2,5.3,27.7c1.4,8.8,3.1,19.6,4.2,33.9  c0.8,11,1,19.3,1.1,27.2c0.2,16.2-0.5,17-0.5,32.3C249.3,980.8,250.4,983.8,250.8,1011.9z"
        fill="#E8CDE3"
      />
      <Path
        id={fma24036}
        dataName="Path 10"
        d="M227.9,1186.3c-1.7,0-3.4,0.1-5.1,0.4c-13.5,1.9-24.5,11.2-28.8,24.1l-22.5,52c-8.1,25.5-3.8,54,11.5,76.1  l272.8,416.2c6.2,8.6,17.7,12.1,27.9,8.1c10.1-4,16.1-13.9,14.9-24.6l0-0.6c0-3.1,0.1-7.4,0.2-12.6c0.6-42.6,2.5-172.1-11.7-193.4  l-110.5-158.6l-123-175.8C246.8,1190.4,237.6,1186.3,227.9,1186.3z"
        fill={getColorFma(fma24036, selectedBodyPart)}
        onPressIn={() => onPressClick(fma24036)}
        onClick={() => onPressClick(fma24036)}
      />
      <Path
        id={fma24037}
        dataName="Path 11"
        d="M747.2,1762.5c10.2,4,21.7,0.5,28.2-8.6l268.1-411.3c15.5-22.3,19.8-50.9,11.7-76.6l-16.6-52.5  c-4.2-13.2-15.2-22.5-28.9-24.5c-1.7-0.2-3.4-0.4-5.1-0.4c-11.7,0-22.7,5.8-29.4,15.7l-222.4,312.6c-13.7,20.5-14.8,48.6-15.7,73.4  l-0.1,1.3c-0.2,5-0.4,10-0.7,15l-4,130.6C731.1,1748.5,737.1,1758.5,747.2,1762.5z"
        fill={getColorFma(fma24037, selectedBodyPart)}
        onPressIn={() => onPressClick(fma24037)}
        onClick={() => onPressClick(fma24037)}
      />
      <Circle
        id="Ellipse_1"
        dataName="Ellipse 1"
        cx="628.3"
        cy="1048.2"
        r="19.9"
        fill="#241f21"
      />
      <Path
        id="Path_24"
        dataName="Path 24"
        d="M215.5,384.4c0,0,46.6,172.2,265.5,78.9"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_25"
        dataName="Path 25"
        d="M1014.8,382.3c0,0-43.9,172.9-264.3,83.1"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
