// @ts-nocheck

import {useFocusEffect} from '@react-navigation/native';
import {CloseCircledSVG, RoundedTick} from 'app/components';
import {Button} from 'libs';
import {
  AssessmentResultDiseaseItem,
  Container,
  Header as HeaderModules,
  Layout,
  Modal as ModulesModal,
  NewAssessmentButtonAndModal,
  ScreenNames,
} from 'modules';
import React, {useEffect, useState} from 'react';
import {BackHandler, FlatList, StyleSheet, Text, View} from 'react-native';
import {connect} from 'react-redux';
import {
  calculateDiagnosePercentage,
  changeAndroidStatusBarColor,
  Colors,
  fetchNameForDDO,
  heightToDp,
  MARKPRO_BOLD,
  MARKPRO_BOOK,
  MARKPRO_MEDIUM,
  Spacing,
} from 'utils';

export const Line = ({color, margin = 0}) => (
  <View style={[styles.lineView, {borderBottomColor: color, margin: margin}]} />
);

export const renderAssessmentSummaryItem = (description, title) => {
  let view = <RoundedTick />;
  if (title?.length === 0) {
    view = <CloseCircledSVG />;
  }
  return (
    <View style={[styles.renderAssessmentSummaryItemContainer]} key={title}>
      <Text style={[styles.renderAssessmentSummaryItemTitle]}>{title} :</Text>
      <View style={styles.assessmentSummaryItem}>
        {view}
        <Text style={[styles.assessmentSummaryItemDescription]}>
          {description}
        </Text>
      </View>
    </View>
  );
};

function AssessmentResults(props) {
  const [differentialDiagnosis, setDifferentialDiagnosis] = useState([]);
  const [selectedCustomerProfile, setSelectedCustomerProfile] = useState(null);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [generatePdf, setGeneratePdf] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      changeAndroidStatusBarColor(Colors.primary);
    }, []),
  );

  useEffect(() => {
    const handleBackButtonClick = () => {
      return true;
    };
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      handleBackButtonClick,
    );

    if (props.route.params?.fromHistory) {
      setShowReminderModal(false);
    } else {
      setShowReminderModal(true);
    }

    const sortedDifferentialDiagnosis =
      props.route.params?.diagnostic_session_record?.differential_diagnosis?.sort(
        (m1, m2) => {
          return m2.probability - m1.probability;
        },
      );
    let differentialDiagnosisList: any[] = [];
    differentialDiagnosisList = sortedDifferentialDiagnosis;

    Promise.all(
      differentialDiagnosisList.map((item) => fetchNameForDDO(item)),
    ).then((values) => {
      setDifferentialDiagnosis(values);
    });

    return () => {
      backHandler.remove();
    };
  }, []);

  const getDiagnosis = () => {
    // Here the diagnosis array will slice into 3 elements, because the max number of rows for diagnosis list is 3
    const numberOfRows = 3;

    return differentialDiagnosis.slice(0, numberOfRows);
  };
  const shouldViewAllButtonRender = (index: number) => {
    return Boolean(index === getDiagnosis()?.length - 1);
  };

  return (
    <Layout style={styles.layoutContainer}>
      {differentialDiagnosis.length > 0 && (
        <FlatList
          bounces={false}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.primaryColoredContainer}
          ListHeaderComponent={<HeaderModules.AssessmentTextBox />}
          ListFooterComponent={
            <Container
              marginHorizontal={Spacing.small}
              marginBottom={Spacing.medium}>
              <NewAssessmentButtonAndModal
                parentNavigation={props.navigation}
                buttonType="secondary"
              />
            </Container>
          }
          ListFooterComponentStyle={styles.listFooterComponentStyle}
          keyExtractor={(item) => item.diagnosis.ddo_id}
          data={getDiagnosis()}
          renderItem={({item, index}) => (
            <View key={index}>
              <AssessmentResultDiseaseItem
                diagnoseName={
                  item.diagnosis.display_name_layman ||
                  item.diagnosis.display_name
                }
                percentageGreenColor={index === 0}
                diagnosePercentage={calculateDiagnosePercentage(
                  item?.probability,
                )}
                onPress={() => {
                  props.navigation.navigate(ScreenNames.AssessmentResultItem, {
                    index,
                    title:
                      item?.diagnosis.display_name_layman ||
                      item?.diagnosis.display_name,
                    differentialDiagnosis: item,
                    differentialDiagnosisItems:
                      props.route.params.differentialDiagnosisItems,
                    platformToken: props.platformToken,
                    platformUrl: props.platformUrl,
                  });
                }}
              />

              {shouldViewAllButtonRender(index) && (
                <Button.ViewAll
                  title={`View all (${differentialDiagnosis.length})`}
                  onPress={() =>
                    props.navigation.navigate(ScreenNames.PossibleConditions, {
                      diagnosis: differentialDiagnosis,
                      symptom: props.route.params?.main_symptom.display_name,
                      differentialDiagnosisItems:
                        props.route.params.differentialDiagnosisItems,
                      platformToken: props.platformToken,
                      platformUrl: props.platformUrl,
                    })
                  }
                  type="clear"
                />
              )}
            </View>
          )}
        />
      )}

      <ModulesModal.Reminder
        statusBarTranslucent={true}
        visible={showReminderModal}
        continuePressHandler={() => {
          setShowReminderModal(false);
        }}
        closePressHandler={() => {
          setShowReminderModal(false);
        }}
      />
    </Layout>
  );
}

const styles = StyleSheet.create({
  layoutContainer: {
    backgroundColor: Colors.primary,
  },
  listFooterComponentStyle: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  commentModalContainer: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  reportButtonText: {
    fontSize: 15,
    fontFamily: MARKPRO_BOLD,
    color: Colors.searchIconColor,
  },
  reportButton: {
    backgroundColor: Colors.chatMessageContainerColor,
    width: 200,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginTop: 15,
  },
  defaultButton: {width: '100%'},
  reminderInfoText2: {
    marginTop: 10,
    marginBottom: heightToDp(5),
    fontFamily: MARKPRO_MEDIUM,
    color: Colors.deleteDarkColor,
  },
  reminderInfoText1: {
    fontFamily: MARKPRO_MEDIUM,
    color: Colors.searchIconColor,
  },
  reminderInfoContainer: {marginBottom: 10},
  arcView: {backgroundColor: Colors.white},
  footerBottomTitleWithDescriptionText2: {
    fontFamily: MARKPRO_BOOK,
    fontSize: 16,
    lineHeight: 20,
  },
  footerBottomTitleWithDescriptionText: {
    fontFamily: MARKPRO_BOLD,
    fontSize: 22,
    transform: [
      {
        translateY: -15,
      },
    ],
  },
  footerBottomTitleWithDescriptionContainer: {marginHorizontal: 28},
  percentageProfileText: {
    color: Colors.primary,
    fontSize: 14,
    fontFamily: MARKPRO_BOLD,
  },
  healthProfileText: {fontSize: 15, fontFamily: MARKPRO_BOLD},
  healthProfileBarContainer: {marginHorizontal: 28},
  healthProfileDataViewText: {
    fontFamily: MARKPRO_MEDIUM,
    fontSize: 17,
    lineHeight: 20,
    marginVertical: 10,
  },
  healthProfileDataView: {marginTop: 10, marginHorizontal: 28},
  deviceDataText: {
    fontFamily: MARKPRO_MEDIUM,
    fontSize: 17,
    lineHeight: 20,
    marginBottom: 15,
  },
  deviceDataView: {marginHorizontal: 28},
  deviceDataItemIcon: {
    marginHorizontal: 5,
  },
  deviceDataItemView: {flexDirection: 'row', alignItems: 'center'},
  healthProfileDataItemDisplayName: {marginLeft: 10},
  healthProfileDataItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  assessmentSummaryItemDescription: {marginLeft: 10},
  renderAssessmentSummaryItemTitle: {fontFamily: MARKPRO_MEDIUM},
  renderAssessmentSummaryItemContainer: {paddingVertical: 10},
  thankForCompletingYourProfileInfoText: {
    flex: 1,
    marginLeft: 10,
    fontFamily: MARKPRO_MEDIUM,
    color: Colors.deleteDarkColor,
  },
  thankForCompletingYourProfileInfoImage: {width: 24, height: 24},
  thankForCompletingYourProfileInfoView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
  },
  completeProfileText: {
    flex: 1,
    marginLeft: 10,
    fontFamily: MARKPRO_MEDIUM,
    color: Colors.deleteDarkColor,
  },
  completeYourProfileInfoView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
  },
  lineView: {
    borderBottomWidth: 1,
    height: 2,
  },
  footerBottomContainer: {backgroundColor: Colors.white},
  fragmentView: {height: heightToDp(4), backgroundColor: Colors.primary},
  headerComponentPressableText: {
    color: Colors.white,
    fontSize: 14,
    fontFamily: MARKPRO_BOLD,
  },
  headerComponentPressable: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: 40,
  },

  backIconView: {position: 'absolute', left: -10, zIndex: 100},
  logoutIconContainerPressable: {
    position: 'absolute',
    right: 0,
    top: heightToDp(2),
    zIndex: 10,
  },
  titleText: {
    fontSize: 22,
    color: Colors.primary,
    fontFamily: MARKPRO_BOLD,
    textAlign: 'justify',
  },
  header: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  primaryColoredContainer: {
    backgroundColor: Colors.primary,
    flexGrow: 1,
  },
  paddingHorizontal: {
    paddingHorizontal: heightToDp(2),
  },
  marginHorizontal: {
    marginHorizontal: heightToDp(2),
  },
  title: {
    fontSize: 24,
    fontFamily: MARKPRO_BOLD,
    textAlign: 'center',
    color: Colors.white,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: Colors.white,
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  percentageContainer: {
    flexDirection: 'row',
    padding: 5,
    flex: 0.3,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  healthProfileBarTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: heightToDp(2),
  },
  healthProfileBar: {
    paddingBottom: 20,
    paddingHorizontal: 16,
    backgroundColor: Colors.systemChatBubblesColor,
    borderRadius: 20,
    justifyContent: 'space-between',
  },
  assessmentSummaryItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    marginBottom: 5,
  },
});

const mapStateToProps = (state) => {
  return {
    platformToken: state.application.platformToken,
    platformUrl: state.application.platformUrl,
    refreshAssessmentHistory: state.dxSession.refreshAssessmentHistory,
    windowWidth: state.appConfig.windowWidth,
  };
};

export default connect(mapStateToProps)(AssessmentResults);
