import React from 'react';
import {StyleSheet} from 'react-native';
import {colors, Spacing} from 'utils';
import {Text} from 'libs';

interface ITextTitleListProps {
  title?: string;
}

export function TextTitleList({title}: ITextTitleListProps) {
  return (
    <Text.Subtitle
      variant="medium"
      size="subtitle4"
      style={styles.titleStylePosition}
      color={colors.black}>
      {title}
    </Text.Subtitle>
  );
}

const styles = StyleSheet.create({
  titleStylePosition: {
    marginTop: Spacing.tiny,
    marginBottom: Spacing.base13,
  },
});
