import {Pressable, StyleSheet} from 'react-native';
import React from 'react';
import {LogoutSvg} from 'components/reusables/components/SVGS';

const Logout = ({signout}) => (
  <Pressable onPress={() => signout()} style={styles.logout}>
    <LogoutSvg />
  </Pressable>
);

const styles = StyleSheet.create({
  logout: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Logout;
