import {isWeb} from 'config';
import {Icon, Text} from 'libs';
import React, {PropsWithChildren} from 'react';
import {GestureResponderEvent, Modal, StyleSheet, View} from 'react-native';
import {Colors, textStyles, Typography} from 'utils';
export interface ModalPropsBase {
  visible: boolean;
  title?: string;
  description?: string;
  closePressHandler: (event: GestureResponderEvent) => void;
}

export function ModalBase({
  children,
  visible,
  title,
  description,
  closePressHandler,
}: PropsWithChildren<ModalPropsBase>) {
  return (
    <Modal
      statusBarTranslucent={true}
      visible={visible}
      transparent
      animationType="slide">
      <View style={styles.modalContainer}>
        <View style={styles.containerView}>
          <View style={styles.closeModalButtonContainer}>
            <Icon.Close onPress={closePressHandler} />
          </View>

          <Text.Heading style={styles.titleModalTextStyle}>
            {title}
          </Text.Heading>

          {!!description && (
            <Text.Body style={styles.descriptionModalTextStyle}>
              {description}
            </Text.Body>
          )}

          {children}
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.darkLightColor,
  },
  containerView: {
    backgroundColor: Colors.white,
    width: '90%',
    maxWidth: isWeb ? '840px' : 'auto',
    borderRadius: 18,
    paddingHorizontal: 20,
    paddingBottom: 35,
  },
  closeModalButtonContainer: {
    alignSelf: 'flex-end',
    top: 15,
    left: 20,
  },
  titleModalTextStyle: {
    color: Colors.primary,
    fontSize: Typography.subtitle1,
    ...textStyles.fontFamilyMARKPRO_BOLD,
    lineHeight: 22,
    marginTop: 30,
  },
  descriptionModalTextStyle: {
    marginTop: 15,
    ...textStyles.fontFamilyMARKPRO_BOOK,
    fontSize: Typography.body1,
    lineHeight: 19,
    color: Colors.textBlackColor,
  },
});
