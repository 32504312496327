import React from 'react';
import {Row} from 'modules';
import {Spacing} from 'utils';
import {Button} from 'libs';

interface ITabActionsProps {
  selectedTitle?: string;
  setSelectedTitle: (item: string) => void;
}

const TAB_ITEMS = [
  'Result Details',
  'Description',
  'Risks',
  'Symptoms',
  'Treatment',
  'Prevention',
];

export function TabActions({
  selectedTitle,
  setSelectedTitle,
}: ITabActionsProps) {
  return (
    <Row
      flexWrap="wrap"
      justifyContent="center"
      marginTop={Spacing.medium}
      marginHorizontal={Spacing.small}>
      {TAB_ITEMS.map((title, index) => (
        <Button.Action
          key={index}
          title={title}
          highlighted={title === selectedTitle}
          onPress={() => setSelectedTitle(title)}
        />
      ))}
    </Row>
  );
}
