import CurvedWh from './general/curvedpath_wh.png';
import SideBarIndicatorPrimary from './general/sidebarindicator_primary.png';
import AnimatedImage from './general/animated_image.png';
import Apple from './general/apple.png';
import AssessmentIcon from './general/assessmenticon.png';
import Calendar from './general/calendar.png';
import CaraCalm from './general/cara_calm.png';
import CaraHappyEyesCloseFull from './general/cara_happy_eyes_close_full.png';
import CaraHappyEyesCloseMouthOpen from './general/cara_happy_eyes_close_mouth_open.png';
import CaraHappyEyesOpen from './general/cara_happy_eyes_open.png';
import CaraHappyEyesOpenMouthClose from './general/cara_happy_eyes_open_mouth_close.png';
import CaraHappyEyesOpenMouthOpen from './general/cara_happy_eyes_open_mouth_open.png';
import CaraLogoLetters from './general/cara_logo_letters.png';
import CaraSadEyesCloseMouthClose from './general/cara_sad_eyes_close_mouth_close.png';
import CaraSadEyesOpenMouthClose from './general/cara_sad_eyes_open_mouth_close.png';
import CaraSadEyesOpenMouthOpen from './general/cara_sad_eyes_open_mouth_open.png';
import CaraSmile from './general/cara_smile.png';
import CaraTalk from './general/cara_talk.png';
import CaraUnhappy from './general/cara_unhappy.png';
import CaraWatching from './general/cara_watching.png';
import CaraWow from './general/cara_wow.png';
import CaraAstonished from './general/caraastonished.png';
import CheckedBoxGrey from './general/checked_box_grey.png';
import CheckedBoxOrange from './general/checked_box_orange.png';
import CurvedPath from './general/curvedpath.png';
import CurvedPathWhite from './general/curvedpath_white.png';
import DashboardEmpty from './general/dashboard-empty.png';
import DeleteButtonIcon from './general/deleteButtonIcon.png';
import DeleteButtonIconWhite from './general/deleteButtonIconWhite.png';
import FaceSmile from './general/face_smile.png';
import FitBitIcon from './general/fitbit.png';
import GoogleIcon from './general/google.png';
import HappyEyesClose from './general/happy_eyes_close.png';
import HeaderBg from './general/header_bg.png';
import HeartRate from './general/heart_rate.png';
import IceCorn from './general/ice_corn.png';
import MangoMask from './general/mangomask.png';
import MaskedTest from './general/maskedtest.png';
import Missing from './general/missing.png';
import PadLock from './general/pad_lock.png';
import PencilGreen from './general/pencil_green.png';
import ProfileTransitionImage from './general/profiletransitionimage.png';
import PurpleTop from './general/purple_top.png';
import RightCurve from './general/right_curve.png';
import SideBarIndicator from './general/sidebarindicator.png';
import SignOutButtonIcon from './general/signoutButtonIcon.png';
import SignUpBottomLeft from './general/signup_bottom_left.png';
import SignUpTopRight from './general/signup_top_right.png';
import Success from './general/success.png';
import TermsAndConditions from './general/TermsAndConditions.png';
import User from './general/user.png';
import Waring from './general/waring.png';
import World from './general/world.png';
import Tip from './general/tip.png';
export {
  AnimatedImage,
  Apple,
  Calendar,
  AssessmentIcon,
  CaraCalm,
  CaraHappyEyesCloseFull,
  CaraHappyEyesCloseMouthOpen,
  CaraHappyEyesOpen,
  CaraHappyEyesOpenMouthClose,
  CaraHappyEyesOpenMouthOpen,
  CaraLogoLetters,
  CaraSadEyesCloseMouthClose,
  CaraSadEyesOpenMouthClose,
  CaraSadEyesOpenMouthOpen,
  CaraSmile,
  CaraTalk,
  CaraUnhappy,
  CaraWatching,
  CaraWow,
  CaraAstonished,
  CheckedBoxGrey,
  CheckedBoxOrange,
  CurvedPath,
  CurvedWh,
  CurvedPathWhite,
  DashboardEmpty,
  DeleteButtonIcon,
  DeleteButtonIconWhite,
  FaceSmile,
  FitBitIcon,
  GoogleIcon,
  HappyEyesClose,
  HeaderBg,
  HeartRate,
  IceCorn,
  MangoMask,
  MaskedTest,
  Missing,
  PadLock,
  PencilGreen,
  ProfileTransitionImage,
  PurpleTop,
  RightCurve,
  SideBarIndicator,
  SideBarIndicatorPrimary,
  SignOutButtonIcon,
  SignUpBottomLeft,
  SignUpTopRight,
  Success,
  TermsAndConditions,
  User,
  Waring,
  World,
  Tip
};
