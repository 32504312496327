import React from 'react';
import {TextStyle} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import {useTheme} from 'utils';
import {IconPropsBase, IconSize} from './IconPropsBase';

export interface IconSettingsProps extends IconPropsBase {
  style?: TextStyle;
}

export function IconSettings({style, size, onPress, color}: IconSettingsProps) {
  const theme = useTheme();

  return (
    <Icon
      name="settings-outline"
      size={size || IconSize.Large}
      color={color || theme.colors?.white}
      onPress={onPress}
      style={{...style, padding: 10}}
    />
  );
}
