import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {fma258847, fma35452, fma51084} from 'utils';
import {getColorFma} from 'utils';

export const Foot_Right_Inside_fma2330 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164.509 80.955">
      <Path
        id="Path_572"
        dataName="Path 572"
        d="M4483.07,844.41h-43.334a4.33,4.33,0,0,0-4.323,4.089h0a29.908,29.908,0,0,1-16.747,25.22l-50.436,24.607a15.385,15.385,0,0,1-6.743,1.557h0a12.241,12.241,0,0,0-5.946,1.541l-7.488,4.16a12.255,12.255,0,0,1-5.8,1.54l-7.11.083s-3.948,7.745-1.051,11.422c3.215,4.081,6.686,5.448,10.3,5.448,9.876,0,11.375.691,16.9-2.611,3.324-1.987,3.032,2.671,10,2.431s12.728.961,21.853-1.681,13.208-3.362,18.491-3.362c3.763,0,19.224.488,27.808.768a33.238,33.238,0,0,1,10.347,2c4.712,1.725,12.17,4.022,17.8,3.718,8.886-.48,25.455-2.161,28.817-11.527s-1.681-16.089-2.641-19.691c-.762-2.858-3.791-32.468-5.011-44.585A5.7,5.7,0,0,0,4483.07,844.41Z"
        transform="translate(-4333.028 -844.41)"
        fill="#ead9e7"
        style="mix-blend-mode: multiply;isolation: isolate"
      />

      <Path
        id="Path_601"
        dataName="Path 601"
        d="M4344.146,908.288c-1.8,2.041-6,2.762-8.765,2.521"
        transform="translate(-4333.028 -844.41)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />

      <Path
        id={fma35452}
        dataName="Path 602"
        d="M4477.17,846.193l-.18,27.545a1.6,1.6,0,0,0,.07.479l10,33.013a1.613,1.613,0,0,1-1.121,2.028,78.582,78.582,0,0,1-18.24,2.506c-8.064,0-24.878-.821-30.388-1.1a1.614,1.614,0,0,1-1.51-1.867l9.961-62.9a1.617,1.617,0,0,1,1.6-1.364l28.2.036A1.616,1.616,0,0,1,4477.17,846.193Z"
        transform="translate(-4333.028 -844.41)"
        fill={getColorFma(fma35452, selectedBodyPart)}
        onPressIn={() => onPressClick(fma35452)}
        onClick={() => onPressClick(fma35452)}
      />

      <Path
        id={fma258847}
        dataName="Path 603"
        d="M4478.986,846.056v25.27a16.989,16.989,0,0,0,.72,4.9l9.232,30.74a1.645,1.645,0,0,0,2.207,1.046l5.125-2.127a1.639,1.639,0,0,0,.991-1.808,34.787,34.787,0,0,0-2.471-7.418c-1.794-4.12-4.1-28.052-4.1-28.052l-1.94-18.982a1.377,1.377,0,0,0-.028-.178c-.153-.69-1.287-5.034-5.655-5.034h-2.438A1.646,1.646,0,0,0,4478.986,846.056Z"
        transform="translate(-4333.028 -844.41)"
        fill={getColorFma(fma258847, selectedBodyPart)}
        onPressIn={() => onPressClick(fma258847)}
        onClick={() => onPressClick(fma258847)}
      />

      <Path
        id={fma51084}
        dataName="Path 604"
        d="M4439.525,874.868l-5.627,34.069a1.612,1.612,0,0,1-1.588,1.351c-13.074.072-49.958,7.154-55.775,7.936a28.786,28.786,0,0,1-11.372-1.255,1.606,1.606,0,0,1-1.14-1.678c.222-2.6.552-5.851.723-7.5a1.6,1.6,0,0,1,1.126-1.374c7.017-2.158,49.176-15.538,71.062-33.082A1.612,1.612,0,0,1,4439.525,874.868Z"
        transform="translate(-4333.028 -844.41)"
        fill={getColorFma(fma51084, selectedBodyPart)}
        onPressIn={() => onPressClick(fma51084)}
        onClick={() => onPressClick(fma51084)}
      />
    </Svg>
  );
};
