import {useContext} from 'react';
import {ThemeContext} from 'react-native-elements';
import {ITheme} from '../ThemeProvider';

export function useTheme(): ITheme {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error(
      'No ThemeContext was found. useTheme must be used within a ThemeContext.',
    );
  }

  return themeContext.theme;
}
