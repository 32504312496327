import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {fma54333, fma60375, fma60850} from 'utils';
import {getColorFma} from 'utils';

export const Finger_Front_fma38325 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1242 2208">
      <Path
        id="Path_224"
        dataName="Path 224"
        d="M643.4,549c232.1,3.4,247,221,249.2,374.9c1.9,133.4,2.9,583.4,3.2,709.2c0,16.7-13.5,30.2-30.2,30.2l-448,0  c-16.5,0-30-13.2-30.2-29.8c-1.7-128-8.5-596.2-10.4-664.2C374.7,891.4,346.5,544.7,643.4,549z"
        fill="#ead9e7"
      />
      <Path
        id="Path 226"
        dataName="Path 226"
        d="M792.3,642.6c1.1,100.5-0.5,259-8.4,277.2c-2.6,5.9-7.9,13-13.9,20c-17.6,20.3-38,38.4-61.7,51  c-20.6,10.9-39.7,17.2-72.6,20.4c-111.4,10.9-146.9-61.8-160.4-100.7c-10.2-29.4-8.1-169.2-5.7-256.6"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id={fma54333}
        dataName="Path 225"
        d="M763.5,908.5c-2.4,5.7-7.2,12.6-12.8,19.4c-16.1,19.7-34.8,29.3-56.5,41.5c-18.9,10.6-28.4,16.7-58.6,19.8  c-102.1,10.6-126.7-51.9-139-89.7c-9.3-28.6-7.5-156.3-5.3-241.1c0.1-3.3,1.4-6.3,3.6-8.6c17-17.8,54.7-40.5,137.3-43.6  c96.4-3.6,117.3,17.2,134.6,31.7c2.8,2.3,4.4,5.8,4.4,9.6C772.2,745,770.8,890.9,763.5,908.5z"
        fill={getColorFma(fma54333, selectedBodyPart)}
        onPressIn={() => onPressClick(fma54333)}
        onClick={() => onPressClick(fma54333)}
      />

      <Path
        id="Path_331"
        dataName="Path 331"
        d="M548.6,1105.5c47.9,0.6,48.7,1.7,83.6,1.8c17.2,0,17.9-0.2,85.3-1.8c27.1-0.6,46.1-1,83.6-1.8  c23-0.5,53.9-1.1,90.7-1.8c-0.1,15.4,0.1,36.2,1.2,61c0.4,7.8,0.9,13.4,0.8,14.4c-2.4,17.8-1.7,139.7,1,327.3  c-104.4,1.6-439.6,1.1-509.2,2.2c-3.2-167.2-5.4-288.5-6.1-310.3c0-0.9,0-2.3,0-2.4c-0.7-36.5-1.9-65.2-3.2-86.8  c35.6-1,66.8-1.5,92.4-1.8C489.9,1105.3,515.2,1105.1,548.6,1105.5z"
        fill="#d9abd1"
      />

      <Path
        id={fma60375}
        dataName="Path 227"
        d="M477.5,958.6c13.8,15.1,49.7,50,106.4,61c61.5,11.9,112.1-11.5,133.6-21.4c14.6-6.7,26-13.7,33.8-18.9  c9.9-7.7,19.8-15.4,29.6-23.1c-0.8,39.5-1.6,79-2.4,118.5c-99.6-0.8-199.1-1.6-298.7-2.4C479.1,1034.4,478.3,996.5,477.5,958.6z"
        fill={getColorFma(fma60375, selectedBodyPart)}
        onPressIn={() => onPressClick(fma60375)}
        onClick={() => onPressClick(fma60375)}
      />

      <Path
        id={fma60850}
        dataName="Path 228"
        d="M406.2,718.4c13.9-34.6,41.9-93.8,52.3-90.6c5.9,1.9,3.2,23.1,1,55c-3.2,46.7-3.4,93.7-1.8,140.4  c3,83,5.9,165.9,8.9,248.9c-22.7,6.1-50.5,9.7-81.2,5c-3.8-0.6-7.4-1.3-11-2c-0.4-16.8-2.1-85.7-2.1-85.7l0,0c0,0,0,0,0,0  c-0.6-11.3-1.3-27.2-1.5-46.4c-0.7-48.4-1.1-80.4,4.7-118.5C382.9,776.8,396.7,742.2,406.2,718.4z"
        fill={getColorFma(fma60850, selectedBodyPart)}
        onPressIn={() => onPressClick(fma60850)}
        onClick={() => onPressClick(fma60850)}
      />
      <Path
        id={fma60850}
        dataName="Path 228"
        d="M790.4,983c0-72.4,3.3-119,5.6-168.7c2.1-46.4,4-113.2,2.3-195.9c11.4,11.8,22.8,23.7,34.1,35.5  c8.3,16.5,20.8,43.6,31.5,78.1c22.1,71.5,23,131.1,23.6,234.4c0.2,24.8,0,60.4-1.8,103.6c-31.2,1.2-62.4,2.4-93.6,3.6  C791.4,1053.3,790.4,1021.7,790.4,983z"
        fill={getColorFma(fma60850, selectedBodyPart)}
        onPressIn={() => onPressClick(fma60850)}
        onClick={() => onPressClick(fma60850)}
      />

      <Path
        id="Path_229"
        dataName="Path 229"
        d="M745.2,1221.8c0,0-151.7,10.8-216.7,0"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_230"
        dataName="Path 230"
        d="M701.9,1278.2c0,0-127.9-4.3-169,6.5"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
