import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {ResultsTopNavigator} from 'modules';
import React from 'react';
import {Description, Prevention, Risks, Symptoms, Treatment} from '../../../';

const Tab = createMaterialTopTabNavigator();

function ResultsTopTab() {
  return (
    <Tab.Navigator tabBar={(props) => <ResultsTopNavigator {...props} />}>
      <Tab.Screen name="Description" component={Description} />
      <Tab.Screen name="Symptoms" component={Symptoms} />
      <Tab.Screen name="Risks" component={Risks} />
      <Tab.Screen name="Treatment" component={Treatment} />
      <Tab.Screen name="Prevention" component={Prevention} />
    </Tab.Navigator>
  );
}

export default ResultsTopTab;
