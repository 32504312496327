import {isWeb} from 'config';
import {AppRegistry} from 'react-native';
import {appendFonts} from 'utils';
import {name as appName} from './app.json';
import App from './App.tsx';

AppRegistry.registerComponent(appName, () => App);

if (isWeb) {
    window.location.pathname !== '/' && window.location.replace('/');
    
    appendFonts(document); 
    
    AppRegistry.runApplication(appName, {
        rootTag: document.getElementById('root'),
    });
}
