import {useFocusEffect} from '@react-navigation/native';
import {isAnonymousFlow, isWeb} from 'config';
import {ScreenNames, useUserPrivacy} from 'modules';
import React, {useEffect} from 'react';
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from 'react-native-reanimated';
import SafeAreaView from 'react-native-safe-area-view';
import BackIcon from 'react-native-vector-icons/AntDesign';
import {connect} from 'react-redux';
import {
  changeAndroidStatusBarColor,
  Colors,
  MARKPRO_BOLD,
  MARKPRO_BOOK,
  MARKPRO_MEDIUM,
  viewStyles,
  widthToDp,
} from 'utils';
import {DefaultButton} from '../../../components';
import {
  OptionButtonViewSelected,
  TermsPrivacyDataUsageScreenTopBar,
  TermsPrivacyDataUsageScreenTopIcon,
} from '../../../components/reusables/components/SVGS';

const RawItem = (props) => {
  return (
    <View style={[styles.rawItemView]}>
      <TouchableOpacity
        key={props.id}
        onPress={() => props.setValue(!props.value)}
        style={[styles.optionButtonView]}>
        <View style={[styles.rawItemPressable]}>
          {props.value && (
            <View style={styles.optionButtonViewSelected}>
              <OptionButtonViewSelected />
            </View>
          )}
          {!props.value && <View style={styles.optionButtonViewUnSelected} />}
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={props.onPress}>
        <Text style={[styles.rawItemPressableText]}>
          <Text
            style={[
              styles.optionTextGrey,
              {
                color: props.isEnable
                  ? Colors.textBlackColor
                  : Colors.textGreyColor,
              },
            ]}>
            {props.nonColorText}
          </Text>
          <Text style={styles.optionTextOrange}>{'  ' + props.colorText}</Text>
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const BackView = (props) => (
  <Pressable style={styles.backView} onPress={props.back}>
    <BackIcon name="arrowleft" color={Colors.white} size={20} />
  </Pressable>
);
const TopIcon = ({initialHeight}) => {
  const top = {top: isWeb ? 190 : initialHeight - 55};
  return (
    <View style={[styles.topIconView, viewStyles.width100P, top]}>
      <TermsPrivacyDataUsageScreenTopIcon />
    </View>
  );
};

function TermsPrivacyDataUsageScreen(props) {
  const [termsAndConditions, setTermsAndConditions] = React.useState(false);
  const [privacyPolicy, setPrivacyPolicy] = React.useState(false);
  const [diseaseCitations, setdiseaseCitations] = React.useState(false);
  const [userPrivacyChecked, setUserPrivacy] = useUserPrivacy();

  const initialWindowWidth = props.windowWidth + 50;
  const scaleX = useSharedValue(1);
  const scaleY = useSharedValue(1);
  const zIndex = useSharedValue(0);
  const opacity = useSharedValue(1);

  useFocusEffect(
    React.useCallback(() => {
      changeAndroidStatusBarColor(Colors.primary);
    }, []),
  );

  useEffect(() => {
    return () => resetValues();
  }, []);

  const resetValues = () => {
    scaleX.value = 1;
    scaleY.value = 1;
    zIndex.value = 0;
    animatedColor.value = Colors.primary;
    opacity.value = 1;
  };

  const animatedTopStyle = useAnimatedStyle(() => {
    return {
      transform: [{scaleX: scaleX.value}, {scaleY: scaleY.value}],
      zIndex: zIndex.value,
      opacity: opacity.value,
    };
  });

  const animatedColor = useSharedValue(Colors.primary);
  const animatedViewStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: animatedColor.value,
    };
  });

  return (
    <Animated.View
      style={[styles.termsPrivacyDataUsageScreenContainer, animatedViewStyle]}>
      <SafeAreaView
        style={[styles.safeAreaView]}
        forceInset={{bottom: 'never'}}>
        <View style={styles.container}>
          <Animated.View style={animatedTopStyle}>
            <TermsPrivacyDataUsageScreenTopBar
              calculateHeight={props.topBarPrivacyHeight}
              windowWidth={initialWindowWidth}
            />
          </Animated.View>
          <TopIcon initialHeight={props.topBarPrivacyHeight} />
          <View style={[styles.beforeWeBeginText, viewStyles.width100P]}>
            <BackView back={props.navigation.goBack} />
            <Text style={styles.titleText}>Before we begin...</Text>
          </View>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={viewStyles.zIndex_1}>
            <View style={styles.middleView}>
              <Text style={styles.topTitleText}>
                We take your data privacy very seriously. It is kept completely
                confidential and never sold to anyone.
              </Text>
              <View style={styles.optionTitle}>
                <Text style={styles.optionTitleText}>
                  {'Please read and confirm the following:'}
                </Text>
              </View>
              <RawItem
                isEnable={termsAndConditions}
                setValue={setTermsAndConditions}
                value={termsAndConditions}
                nonColorText="I have read and accepted the"
                colorText={'Terms and conditions'}
                onPress={() => {
                  props.navigation.navigate(ScreenNames.StaticTextScreen, {
                    title: 'Terms and Conditions',
                    url: {uri: props.bootstrapUrls.termsAndConditions},
                  });
                }}
              />
              <RawItem
                isEnable={privacyPolicy}
                setValue={setPrivacyPolicy}
                value={privacyPolicy}
                nonColorText="I have read the"
                colorText="Privacy policy"
                onPress={() => {
                  props.navigation.navigate(ScreenNames.StaticTextScreen, {
                    title: 'Privacy Policy',
                    url: {uri: props.bootstrapUrls.privacyPolicy},
                  });
                }}
              />
              <RawItem
                isEnable={diseaseCitations}
                setValue={setdiseaseCitations}
                value={diseaseCitations}
                nonColorText="I have read the"
                colorText="Data and methodology"
                onPress={() => {
                  props.navigation.navigate(ScreenNames.DataAndMethodology);
                }}
              />
            </View>
          </ScrollView>
          <View style={[styles.conditionsPressableView]}>
            <DefaultButton
              hideArrow={
                !termsAndConditions || !privacyPolicy || !diseaseCitations
              }
              name={'Continue'}
              disabled={
                !termsAndConditions || !privacyPolicy || !diseaseCitations
              }
              click={() => {
                if (!termsAndConditions) {
                  // eslint-disable-next-line no-alert
                  alert('Please read and accept Terms & Conditions');
                  return;
                }

                if (!privacyPolicy) {
                  // eslint-disable-next-line no-alert
                  alert('Please read and accept Privacy Policy');
                  return;
                }

                const duration = 1000;

                scaleX.value = withTiming(10, {duration});
                scaleY.value = withTiming(12, {duration});
                zIndex.value = 1;
                //animatedColor.value = 'white'
                opacity.value = 0.5;
                opacity.value = withDelay(100, withTiming(1, {duration}));

                setUserPrivacy();

                setTimeout(() => {
                  if (isAnonymousFlow) {
                    props.navigation.navigate('AssessmentChat');
                  } else {
                    props.navigation.navigate('Signup', {
                      isFromProfile: false,
                    });
                  }
                  resetValues();
                }, 1000);
              }}
            />
          </View>
        </View>
      </SafeAreaView>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  conditionsPressableView: {
    bottom: 30,
    paddingHorizontal: 25,
    paddingTop: 5,
    backgroundColor: 'white',
  },
  safeAreaView: {flex: 1},
  termsPrivacyDataUsageScreenContainer: {flex: 1},
  topIconView: {
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',

    right: 0,
    left: 0,
  },
  policyText: {marginLeft: 15},
  dataAnalyzePolicyView: {flexDirection: 'row', alignItems: 'center'},
  setDataAnalyzePolicyView: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 20,
    marginRight: 20,
  },
  rawItemPressableText: {marginLeft: 15},
  rawItemPressable: {flexDirection: 'row', alignItems: 'center'},
  rawItemView: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 20,
    marginRight: 20,
  },
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  titleText: {
    color: 'white',
    fontSize: 18,
    fontFamily: MARKPRO_MEDIUM,
  },
  backView: {
    width: 60,
    height: 60,
    position: 'absolute',
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
  },
  beforeWeBeginText: {
    position: 'absolute',
    top: 0,
    height: 50,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  middleView: {
    paddingTop: 100,
    paddingHorizontal: 25,
    paddingBottom: 65,
    flex: 1,
    justifyContent: 'flex-start',
  },
  topTitleText: {
    fontSize: 16,
    fontFamily: MARKPRO_BOOK,
    lineHeight: 20,
    color: Colors.black,
    paddingBottom: 10,
  },
  optionTitle: {
    paddingVertical: 20,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  optionTitleText: {
    fontSize: 17,
    fontFamily: MARKPRO_MEDIUM,
    color: Colors.textDarkGreyColor,
  },
  optionTextGrey: {
    color: Colors.searchIconColor,
    fontSize: 16,
    fontFamily: MARKPRO_BOOK,
  },
  optionTextOrange: {
    color: Colors.primary,
    fontSize: 16,
    fontFamily: MARKPRO_BOLD,
  },
  optionButtonView: {
    borderRadius: 18,
    justifyContent: 'center',
  },
  optionButtonViewSelected: {
    backgroundColor: Colors.primary,
    width: widthToDp(8.5),
    height: widthToDp(8.5),
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionButtonViewUnSelected: {
    backgroundColor: 'white',
    width: widthToDp(8.5),
    height: widthToDp(8.5),
    borderColor: Colors.searchIconColor,
    borderWidth: 2,
    borderRadius: 8,
  },
});

const mapStateToProps = (state) => ({
  windowWidth: state.appConfig.windowWidth,
  topBarPrivacyHeight: state.appConfig.topBarPrivacyHeight,
  bootstrapUrls: state.application.bootstrapUrls,
});

export default connect(mapStateToProps)(TermsPrivacyDataUsageScreen);
