// @ts-nocheck

import {useFocusEffect} from '@react-navigation/native';
import React, {useState, useCallback} from 'react';
import {Image, Pressable, Text, TouchableHighlight, View} from 'react-native';
import 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  ASSESSMENT_QUESTION_ANSWER_STAGE,
  changeAndroidStatusBarColor,
  Colors,
  fma14603,
  fma15_1_0,
  fma15_2_0,
  fma19_1_0,
  fma19_2_0,
  fma20373,
  fma20377,
  fma223729,
  fma23_1_0,
  fma23_2_0,
  fma23_3_0,
  fma23_4_0,
  fma24040,
  fma24883_B,
  fma24883_F,
  fma24977_B,
  fma24977_F,
  fma25056,
  fma27_1,
  fma27_2,
  fma27_3,
  fma27_4,
  fma37854,
  fma38325,
  fma9577,
  getMainFma,
  HUMAN_ANATOMY,
  small_toe,
  textStyles,
  viewColors,
  viewStyles,
} from 'utils';
import {PurpleTop} from '../../../assets/images';
import {Abdomen_fma20373} from './Abdomen_fma20373';
import {Abdomen_fma20377} from './Abdomen_fma20377';
import {Abdomen_fma24040} from './Abdomen_fma24040';
import {Abdomen_fma9577} from './Abdomen_fma9577';
import {Abdomen_fma14603} from './Abdomen_fma14603';
import {Back_fma25056} from './Back_fma25056';
import {Big_Toe_Right_fma37854} from './Big_Toe_Right_fma37854';
import {Chest_fma223729_Male} from './Chest_fma223729_Male';
import {Finger_Front_fma38325} from './Finger_Front_fma38325';
import {Foot_Left_Bottom_fma272} from './Foot_Left_Bottom_fma27.2';
import {Foot_Left_Inside_fma273} from './Foot_Left_Inside_fma27.3';
import {Foot_Left_Outside_fma274} from './Foot_Left_Outside_fma27.4';
import {Foot_Left_Top_271} from './Foot_Left_Top_27.1';
import {Foot_Right_Bottom_fma2320} from './Foot_Right_Bottom_fma23.2.0';
import {Foot_Right_Inside_fma2330} from './Foot_Right_Inside_fma23.3.0';
import {Foot_Right_Outside_fma2340} from './Foot_Right_Outside_fma23.4.0';
import {Foot_Right_Top_fma2310} from './Foot_Right_Top_fma23.1.0';
import {Hand_Left_Back_fma9714} from './Hand_Left_Back_fma9714';
import {Hand_Left_Front_fma9714} from './Hand_Left_Front_fma9714';
import {Hand_Right_Back_fma321784} from './Hand_Right_Back_fma321784';
import {Hand_Right_Front_fma321784} from './Hand_Right_Front_fma321784';
import {Knee_Left_Back_fma24883} from './Knee_Left_Back_fma24883';
import {Knee_Left_Front_fma24883} from './Knee_Left_Front_fma24883';
import {Knee_Right_Back_fma24977} from './Knee_Right_Back_fma24977';
import {Knee_Right_Front_fma24977} from './Knee_Right_Front_fma24977';
import {Small_Toe} from './Small_Toe';
import {useEffect} from 'react';
import {isWeb} from 'config';

export const BodyPartsComponent = ({
  mainFma,
  selectedBodyPart,
  onPressClick,
}) => {
  switch (mainFma) {
    case fma15_1_0:
      return (
        <Hand_Right_Back_fma321784
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma15_2_0:
      return (
        <Hand_Right_Front_fma321784
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma38325:
      return (
        <Finger_Front_fma38325
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma19_1_0:
      return (
        <Hand_Left_Back_fma9714
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma19_2_0:
      return (
        <Hand_Left_Front_fma9714
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma27_2:
      return (
        <Foot_Left_Bottom_fma272
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma27_3:
      return (
        <Foot_Left_Inside_fma273
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma27_4:
      return (
        <Foot_Left_Outside_fma274
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma27_1:
      return (
        <Foot_Left_Top_271
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma24883_F:
      return (
        <Knee_Left_Front_fma24883
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma24883_B:
      return (
        <Knee_Left_Back_fma24883
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma24977_B:
      return (
        <Knee_Right_Back_fma24977
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma37854:
      return (
        <Big_Toe_Right_fma37854
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma23_2_0:
      return (
        <Foot_Right_Bottom_fma2320
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma23_3_0:
      return (
        <Foot_Right_Inside_fma2330
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma23_4_0:
      return (
        <Foot_Right_Outside_fma2340
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma23_1_0:
      return (
        <Foot_Right_Top_fma2310
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma24977_F:
      return (
        <Knee_Right_Front_fma24977
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case small_toe:
      return (
        <Small_Toe
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma9577:
      return (
        <Abdomen_fma9577
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma24040:
      return (
        <Abdomen_fma24040
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma20373:
      return (
        <Abdomen_fma20373
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma20377:
      return (
        <Abdomen_fma20377
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma14603:
      return (
        <Abdomen_fma14603
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma25056:
      return (
        <Back_fma25056
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    case fma223729:
      return (
        <Chest_fma223729_Male
          selectedBodyPart={selectedBodyPart}
          onPressClick={onPressClick}
        />
      );
    default:
      return <View />;
  }
};

const TopBar = () => (
  <Image
    source={PurpleTop}
    style={[viewStyles.height110, viewStyles.width100P]}
    resizeMode={'stretch'}
  />
);

const Header = (props) => (
  <View
    style={[
      viewStyles.flexRow,
      viewStyles.positionAbsolute,
      viewStyles.justifyContentSpaceBetween,
      viewStyles.alignItemsCenter,
      viewStyles.right0,
      viewStyles.paddingHorizontal10,
      viewStyles.left0,
      viewStyles.top30,
    ]}>
    <View style={viewStyles.width20} />
    <Text style={[textStyles.fontFamilyMARKPRO_BOLD, textStyles.fontSize16]}>
      Please locate the pain
    </Text>

    <Pressable onPress={() => props.close()}>
      <Icon style={viewStyles.zIndex100} name="close" size={25} />
    </Pressable>
  </View>
);

const ButtonSelectedPart = (props) => {
  useFocusEffect(
    useCallback(() => {
      changeAndroidStatusBarColor(Colors.topPurple);
      return () => {
        changeAndroidStatusBarColor(Colors.primary);
      };
    }, []),
  );

  const button = () => {
    const id = Array.isArray(props.fmaId) ? props.fmaId[0] : props.fmaId;
    const filteredFma = props.quickReplies.filter((qr) => {
      return qr?.data?.item_id === props.fmaId;
    });

    const name = filteredFma[0]?.data?.display_name_layman;

    return (
      <TouchableHighlight
        activeOpacity={0.9}
        underlayColor={Colors.buttonOnHighlight}
        style={[
          viewStyles.borderRadius18,
          viewStyles.borderWidth1,
          viewStyles.borderColorPrimary,
          viewStyles.alignItemsCenter,
          viewStyles.justifyContentCenter,
          viewStyles.flexGrow1,
          viewStyles.marginHorizontal30,
          viewStyles.marginVertical20,
        ]}
        onPressIn={() => {
          props.updateChatThread(filteredFma[0]);
        }}>
        <Text
          style={[
            textStyles.colorTextPrimary,
            textStyles.fontFamilyMARKPRO_BOLD,
            textStyles.fontSize18,
            textStyles.textAlignCenter,
          ]}>
          {name}
        </Text>
      </TouchableHighlight>
    );
  };

  return (
    <View style={viewStyles.height90}>{!!props?.fmaId > 0 && button()}</View>
  );
};

export default function HumanAnatomy(props) {
  const [selectedBodyPart, setSelectedBodyPart] = useState();

  const onPressClick = (bodyPart) => {
    setSelectedBodyPart(bodyPart);
  };

  const mainFma = getMainFma(props.humanAnatomyDataObject?.qr);

  const updateChatThread = (qr) => {
    const imageObject = {
      mainFmaId: mainFma,
      selectedBodyPart,
    };

    const answerMap = {
      answer_index: qr.data.answer_index,
      display_name: qr.data.display_name,
      question_id: qr.data.question_id,
    };

    const action = {
      coordinates: null,
      data: answerMap,
      svg: imageObject,
      tag: props.humanAnatomyDataObject.tag,
      msgText: qr.data.display_name_layman,
      value: {action: ASSESSMENT_QUESTION_ANSWER_STAGE},
      step: HUMAN_ANATOMY,
    };

    props.onPressQuickReply(action);
    props.close();
  };

  return (
    <View
      style={[
        viewStyles.flex1,
        viewColors.backgroundColorWhite,
        isWeb ? {alignItems: 'center'} : {},
      ]}>
      <View
        style={
          isWeb ? {maxWidth: '840px', minWidth: '50vw', height: '100%'} : {}
        }>
        <TopBar />
        <Header close={props.close} />
        <View
          style={[
            viewStyles.flex1,
            viewStyles.justifyContentCenter,
            viewStyles.alignItemsCenter,
            viewStyles.paddingHorizontal40,
            viewStyles.paddingTop25,
          ]}>
          <BodyPartsComponent
            mainFma={mainFma}
            onPressClick={onPressClick}
            selectedBodyPart={selectedBodyPart}
          />
        </View>

        <ButtonSelectedPart
          fmaId={selectedBodyPart}
          quickReplies={props.humanAnatomyDataObject.qr}
          updateChatThread={updateChatThread}
        />
      </View>
    </View>
  );
}
