// @ts-nocheck

import HighlightText from '@sanar/react-native-highlight-text';
import React from 'react';
import {colors, MARKPRO_BOOK, MARKPRO_MEDIUM, Typography} from 'utils';

interface ITextHighlightProps {
  highlightText?: Array<string>;
  text?: string;
  textStyle: 'textStyleOne' | 'textStyleTwo';
  highlightStyle: 'highlightStyleOne' | 'highlightStyleTwo';
}

const textStyles = {
  textStyleOne: {
    fontSize: Typography.subtitle3,
    fontFamily: MARKPRO_MEDIUM,
    color: colors.primary,
  },
  textStyleTwo: {
    fontSize: Typography.body1,
    fontFamily: MARKPRO_BOOK,
    color: colors.black,
    fontWeight: '400',
  },
};

const highlightStyles = {
  highlightStyleOne: {backgroundColor: colors.primaryLight},
  highlightStyleTwo: {color: colors.green},
};

export function TextHighlight({
  highlightText,
  text,
  highlightStyle,
  textStyle,
}: ITextHighlightProps) {
  return (
    <HighlightText
      style={textStyles[textStyle]}
      highlightStyle={highlightStyles[highlightStyle]}
      searchWords={highlightText}
      textToHighlight={text}
    />
  );
}
