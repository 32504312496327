import MessageStore from 'components/chat/message-store/models/AuthMessages';

export const singletonAuthMessage = (function () {
  let instance = null;
  return {
    getInstance: () => {
      if (instance == null) {
        instance = new MessageStore();
        instance.constructor = null;
      }

      return instance;
    },
  };
})();
