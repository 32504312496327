import {Text, StyleSheet, View} from 'react-native';
import {Colors} from 'utils';
import React from 'react';
import {MARKPRO_BOLD} from 'utils';

export default function SeverityTextTitle(props) {
  switch (props.severity) {
    case 0:
    case 1:
      return (
        <View style={Styles.severityTextTitle1}>
          <Text style={Styles.severityText1}>No Pain</Text>
        </View>
      );
    case 2:
    case 3:
      return (
        <View style={Styles.severityTextTitle1}>
          <Text style={Styles.severityText1}>Mild Pain</Text>
        </View>
      );

    case 4:
    case 5:
      return (
        <View style={Styles.severityTextTitle3}>
          <Text style={Styles.severityText2}>Moderate Pain</Text>
        </View>
      );

    case 6:
    case 7:
      return (
        <View style={Styles.severityTextTitle3}>
          <Text style={Styles.severityText2}>Severe Pain</Text>
        </View>
      );

    case 8:
    case 9:
      return (
        <View style={Styles.severityTextTitle2}>
          <Text style={Styles.severityText2}>Very Severe Pain</Text>
        </View>
      );

    case 10:
      return (
        <View style={Styles.severityTextTitle2}>
          <Text style={Styles.severityText2}>Worst Pain Possible</Text>
        </View>
      );
    default:
      return null;
  }
}
const Styles = StyleSheet.create({
  severityText2: {
    fontSize: 15,
    fontFamily: MARKPRO_BOLD,
    color: Colors.primary,
  },
  severityTextTitle3: {
    backgroundColor: Colors.moderateBackGround,
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 8,
    marginTop: 8,
  },
  severityText1: {
    fontSize: 15,
    fontFamily: MARKPRO_BOLD,
    color: Colors.mild,
  },
  severityTextTitle2: {
    backgroundColor: Colors.moderateBackGround,
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 8,
    marginTop: 8,
    position: 'absolute',
    right: 37,
  },
  severityTextTitle1: {
    backgroundColor: Colors.mildBackground,
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 8,
    marginTop: 8,
    position: 'absolute',
    left: 37,
  },
});
