import React from 'react';
import Svg, {Path, Rect, Line} from 'react-native-svg';

export const Calendar = () => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M0.865448 6.15855C0.774922 4.2575 2.29181 2.66667 4.19501 2.66667H8H11.805C13.7082 2.66667 15.2251 4.2575 15.1346 6.15855L14.8488 12.1586C14.7642 13.9358 13.2986 15.3333 11.5193 15.3333H4.48073C2.70142 15.3333 1.2358 13.9358 1.15116 12.1586L0.865448 6.15855Z"
      stroke="#736969"
      strokeWidth="1.33333"
      strokeLinejoin="round"
    />
    <Rect
      x="5.33325"
      width="2.66667"
      height="1.33333"
      rx="0.666667"
      transform="rotate(90 5.33325 0)"
      fill="#736969"
    />
    <Rect
      x="12"
      width="2.66667"
      height="1.33333"
      rx="0.666667"
      transform="rotate(90 12 0)"
      fill="#736969"
    />
    <Rect
      x="3.33325"
      y="8.66699"
      width="1.33333"
      height="1.33333"
      rx="0.666667"
      fill="#736969"
    />
    <Rect
      x="6"
      y="8.66699"
      width="1.33333"
      height="1.33333"
      rx="0.666667"
      fill="#736969"
    />
    <Rect
      x="8.6665"
      y="8.66699"
      width="1.33333"
      height="1.33333"
      rx="0.666667"
      fill="#736969"
    />
    <Rect
      x="11.3333"
      y="8.66699"
      width="1.33333"
      height="1.33333"
      rx="0.666667"
      fill="#736969"
    />
    <Rect
      x="3.33325"
      y="11.333"
      width="1.33333"
      height="1.33333"
      rx="0.666667"
      fill="#736969"
    />
    <Rect
      x="6"
      y="11.333"
      width="1.33333"
      height="1.33333"
      rx="0.666667"
      fill="#736969"
    />
    <Rect
      x="8.6665"
      y="11.333"
      width="1.33333"
      height="1.33333"
      rx="0.666667"
      fill="#736969"
    />
    <Rect
      x="11.3333"
      y="11.333"
      width="1.33333"
      height="1.33333"
      rx="0.666667"
      fill="#736969"
    />
    <Line
      x1="0.666748"
      y1="6.00033"
      x2="15.3334"
      y2="6.00033"
      stroke="#736969"
      strokeWidth="1.33333"
    />
  </Svg>
);
