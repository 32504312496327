import React from 'react';
import {View, Text, FlatList, StyleSheet} from 'react-native';
import {Colors} from 'utils';
import {MARKPRO_BOLD, MARKPRO_BOOK, MARKPRO_MEDIUM} from 'utils';
import IconDot from 'react-native-vector-icons/Octicons';

const TipItem = ({description}) => {
  let isParagraph = false;
  if (description.startsWith('# ') || description.startsWith('#')) {
    isParagraph = true;
    description = description.replace('# ', '');
    if (description.startsWith('#')) {
      description = description.replace('#', '');
    }
  }

  description = description.replace('#', '');
  description = description.replace('  ', '');
  description = description.trim();
  return (
    <View key={description} style={[styles.tipItemContainer]}>
      {!isParagraph && (
        <View style={[styles.iconDotView]}>
          <IconDot
            name="primitive-dot"
            size={14}
            color={Colors.primary}
            style={[styles.iconDot]}
          />
        </View>
      )}

      <Text style={styles.tipText}>{description}</Text>
    </View>
  );
};

function Prevention(props) {
  const title = 'Prevention';

  if (props?.ddoConditionLibrary == null) {
    return (
      <View style={styles.commonContainer}>
        <Text style={styles.titleText}>{title}</Text>
        <Text style={[styles.noDataAvailable]}>
          {'-- No data available --'}
        </Text>
      </View>
    );
  }

  return (
    <FlatList
      style={[styles.preventionFlatList]}
      showsVerticalScrollIndicator={false}
      data={props.ddoConditionLibrary.prevention_list}
      ListHeaderComponent={
        <View style={styles.commonContainer}>
          <Text style={styles.titleText}>{title}</Text>
          <Text style={styles.tipTitleText}>You can: </Text>
        </View>
      }
      renderItem={({item, index}) => <TipItem description={item} />}
      keyExtractor={(item) => `${item}`}
    />
  );
}

const styles = StyleSheet.create({
  preventionFlatList: {flex: 1, backgroundColor: Colors.systemChatBubblesColor},
  noDataAvailable: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 16,
    fontFamily: MARKPRO_BOOK,
    color: Colors.textBlackColor,
  },
  iconDot: {paddingHorizontal: 6},
  iconDotView: {paddingLeft: 14},
  tipItemContainer: {
    backgroundColor: Colors.white,
    paddingHorizontal: 33,
    paddingVertical: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  bottomButton: {paddingVertical: 30},
  titleText: {
    flex: 1,
    fontSize: 22,
    fontFamily: MARKPRO_BOLD,
    color: Colors.textDarkGreyColor,
    marginVertical: 20,
  },
  descText: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 16,
    fontFamily: MARKPRO_BOOK,
    color: Colors.textBlackColor,
  },
  tipTitleText: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 17,
    fontFamily: MARKPRO_MEDIUM,
    color: Colors.textDarkGreyColor,
    marginVertical: 10,
  },
  reminderText: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 13,
    fontFamily: MARKPRO_BOOK,
    color: Colors.textBlackColor,
    marginVertical: 10,
  },
  warningText: {
    flex: 1,
    textAlign: 'justify',
    fontSize: 14,
    fontFamily: MARKPRO_MEDIUM,
    color: Colors.deleteDarkColor,
  },
  deleteText: {
    textAlign: 'justify',
    fontSize: 16,
    fontFamily: MARKPRO_BOLD,
    color: Colors.deleteTextColor,
    paddingHorizontal: 10,
  },
  tipText: {
    flex: 1,
    color: Colors.textBlackColor,
    fontSize: 14,
    fontFamily: MARKPRO_BOOK,
    marginHorizontal: 10,
  },
  commonContainer: {backgroundColor: Colors.white, paddingHorizontal: 30},
});

export default Prevention;
