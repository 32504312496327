import {Colors} from 'utils';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import DeleteIcon from './DeleteIcon';
import {MARKPRO_BOLD} from 'utils';
import React from 'react';

export default function ({
  onPress,
  name = 'Remove current photo',
  customStyles = {},
}) {
  return (
    <Pressable
      style={[styles.container, {...customStyles}]}
      onPress={() => {
        onPress();
      }}>
      <View style={[styles.deleteIconView]}>
        <DeleteIcon />
        <Text style={[styles.deleteIconText]}>{name}</Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  deleteIconText: {
    paddingLeft: 5,
    color: Colors.deleteTextColor,
    fontSize: 16,
    fontFamily: MARKPRO_BOLD,
  },
  deleteIconView: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    borderWidth: 1,
    borderColor: Colors.deleteTextColor,
    height: 48,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
