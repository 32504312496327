import {LinkingOptions, NavigationContainer} from '@react-navigation/native';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import {withHumanBodySvgScreen} from 'config';
import {Icon, Text} from 'libs';
import {
  AssessmentResultItem,
  PossibleConditionsScreen,
  SettingsScreen,
} from 'modules';
import React from 'react';
import {Colors, Spacing, useAnalytics} from 'utils';
import {
  AssessmentDashboardScreen,
  AssessmentResultLoading,
  AssessmentResultPossibleCauses,
  AssessmentResults,
  DataAndMethodologyScreen,
  DiseaseCitationsScreen,
  HumanBodySvgScreen,
  ResultsTopTab,
  StaticTextScreen,
  TermsPrivacyDataUsageScreen,
  WelcomeScreen,
} from '../../app/containers';
import {ScreenNames} from './ScreenNamesEnum';

// TODO fix typings
const Stack: any = createStackNavigator();

const linking: LinkingOptions = {
  prefixes: [],
  config: {
    screens: {
      [ScreenNames.Welcome]: '/welcome',
      [ScreenNames.AssessmentChat]: '/assessment-chat',
      [ScreenNames.ResultsTab]: '/results-tab',
      [ScreenNames.AssessmentResults]: '/assessment-results',
      [ScreenNames.PossibleConditions]: '/possible-conditions',
      [ScreenNames.AssessmentResultItem]: '/assessment-result-item',
      [ScreenNames.AssessmentResultLoading]: '/assessment-result-loading',
      [ScreenNames.AssessmentResultPossibleCauses]:
        '/assessment-result-possible-causes',
      [ScreenNames.StaticTextScreen]: {
        path: '/static-text-screen',
        parse: {
          url: (url: string) => JSON.parse(url),
        },
        stringify: {
          url: (url: object) => JSON.stringify(url),
        },
      },
      [ScreenNames.DataAndMethodology]: '/data-and-methodology',
      [ScreenNames.DiseaseCitations]: '/disease-citations',
      [ScreenNames.Privacy]: '/privacy',
      [ScreenNames.Settings]: '/settings',
    },
  },
};

export function AnonymousNavigator({navigation}: any) {
  const routeNameRef = React.useRef();
  const {logScreenView} = useAnalytics();

  return (
    <NavigationContainer
      ref={navigation}
      onReady={() => {
        routeNameRef.current = navigation.current.getCurrentRoute().name;
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigation.current.getCurrentRoute().name;

        if (previousRouteName !== currentRouteName) {
          logScreenView(currentRouteName, currentRouteName);
        }
        routeNameRef.current = currentRouteName;
      }}
      linking={linking}>
      <Stack.Navigator
        screenOptions={{
          gestureEnabled: false,
          headerTitleAlign: 'center',
        }}>
        <Stack.Screen
          {...options}
          name={ScreenNames.Welcome}
          component={WelcomeScreen}
        />
        <Stack.Screen
          {...options}
          name={ScreenNames.AssessmentChat}
          component={AssessmentDashboardScreen}
        />
        <Stack.Screen
          name={ScreenNames.ResultsTab}
          {...options}
          component={ResultsTopTab}
        />
        <Stack.Screen
          name={ScreenNames.AssessmentResults}
          component={AssessmentResults}
          options={({navigation}: any) => ({
            headerStyle: {
              backgroundColor: Colors.primary,
              shadowColor: Colors.primary,
              height: 130,
            },
            headerTitle: () => (
              <Text.Heading
                variant="bold"
                size="h4"
                color={Colors.white}
                textAlign="center">
                Assessment{'\n'}results
              </Text.Heading>
            ),
            headerLeft: () => null,
            headerRight: () => (
              <Icon.Settings
                onPress={() => navigation.navigate(ScreenNames.Settings)}
                style={{right: Spacing.small, bottom: Spacing.base13}}
              />
            ),
          })}
        />
        <Stack.Screen
          name={ScreenNames.PossibleConditions}
          component={PossibleConditionsScreen}
          options={({navigation}: any) => ({
            headerStyle: {
              backgroundColor: Colors.primary,
              shadowColor: Colors.primary,
              height: 130,
            },
            headerTitle: () => (
              <Text.Heading
                variant="bold"
                size="h4"
                color={Colors.white}
                textAlign="center">
                Possible{'\n'}conditions
              </Text.Heading>
            ),
            headerLeft: () => (
              <Icon.ArrowLeft
                onPress={() => navigation.goBack()}
                style={{left: Spacing.base, bottom: Spacing.base13}}
              />
            ),
            headerRight: () => (
              <Icon.Settings
                onPress={() => navigation.navigate(ScreenNames.Settings)}
                style={{right: Spacing.small, bottom: Spacing.base13}}
              />
            ),
          })}
        />

        <Stack.Screen
          name={ScreenNames.AssessmentResultItem}
          {...options}
          component={AssessmentResultItem}
          options={({navigation, route}: any) => ({
            headerStyle: {
              backgroundColor: Colors.topPurple,
              shadowColor: Colors.topPurple,
              height: 130,
            },
            headerTitle: () => (
              <Text.Heading
                variant="bold"
                color={Colors.black}
                size="h4"
                textAlign="center">
                {/* This will create new line just for the first space */}
                {route?.params?.title?.replace(' ', '\n')}
              </Text.Heading>
            ),
            headerLeft: () => (
              <Icon.ArrowLeft
                onPress={() => navigation.goBack()}
                style={{left: Spacing.base, bottom: Spacing.base13}}
                color={Colors.black}
              />
            ),
            headerRight: () => (
              <Icon.Settings
                onPress={() =>
                  navigation.navigate(ScreenNames.Settings, {
                    keepStatusBarColorOnClose: true,
                  })
                }
                style={{right: Spacing.small, bottom: Spacing.base13}}
                color={Colors.black}
              />
            ),
          })}
        />

        <Stack.Screen
          name={ScreenNames.AssessmentResultLoading}
          {...options}
          component={AssessmentResultLoading}
        />
        <Stack.Screen
          name={ScreenNames.AssessmentResultPossibleCauses}
          {...options}
          component={AssessmentResultPossibleCauses}
        />
        <Stack.Screen
          {...options}
          name={ScreenNames.StaticTextScreen}
          component={StaticTextScreen}
        />
        <Stack.Screen
          {...options}
          name={ScreenNames.DataAndMethodology}
          component={DataAndMethodologyScreen}
        />
        <Stack.Screen
          {...options}
          name={ScreenNames.DiseaseCitations}
          component={DiseaseCitationsScreen}
        />
        <Stack.Screen
          {...options}
          name={ScreenNames.Privacy}
          component={TermsPrivacyDataUsageScreen}
        />
        <Stack.Screen
          name={ScreenNames.Settings}
          component={SettingsScreen}
          options={({navigation}: any) => ({
            headerStyle: {
              backgroundColor: Colors.topPurple,
              shadowColor: Colors.topPurple,
            },
            headerTitle: () => (
              <Text.Subtitle variant="medium" size="subtitle3">
                {ScreenNames.Settings}
              </Text.Subtitle>
            ),
            headerLeft: () => null,
            headerRight: () => (
              <Icon.Close onPress={() => navigation.goBack()} />
            ),
          })}
        />

        {withHumanBodySvgScreen ? (
          <Stack.Screen
            {...options}
            name={ScreenNames.HumanBodySvg}
            component={HumanBodySvgScreen}
            options={() => ({
              headerStyle: {
                backgroundColor: Colors.topPurple,
                shadowColor: Colors.topPurple,
              },
              title: ScreenNames.HumanBodySvg,
              headerBackImage: null,
              headerBackTitleVisible: false,
            })}
          />
        ) : null}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const options = {
  options: () => ({
    headerLeft: null,
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  }),
};
