// @ts-nocheck

import {AssessmentResult} from 'modules';
import React, {useEffect} from 'react';
import {EventType, useAnalytics} from 'utils';
import {ITabResultsProps} from '../TabResults';

interface ITreatmentProps extends ITabResultsProps {
  treatmentList?: Array<string>;
}

export function Treatment({title, treatmentList}: ITreatmentProps) {
  const {logEvent} = useAnalytics();
  useEffect(() => {
    logEvent(EventType.RESULT_TREATMENT_VIEW, {timestamp: Date.now()});
  }, []);

  return (
    <>
      <AssessmentResult.Tab.TextTitle title={title} />

      <AssessmentResult.Tab.TextTitleList title="You can:" />

      <AssessmentResult.Tab.RenderList
        items={treatmentList}
        checkForFirstItem={false}
      />
    </>
  );
}
