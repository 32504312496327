import {isWeb} from 'config';
import {Dimensions, PixelRatio} from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export const widthToDp = (number) => {
  const incomingWidth =
    typeof number === 'number' ? number : parseFloat(number);

  if (isWeb) {
    return incomingWidth * 4;
  }

  return PixelRatio.roundToNearestPixel((width * incomingWidth) / 100);
};

export const heightToDp = (number) => {
  const incomingHeight =
    typeof number === 'number' ? number : parseFloat(number);

  if (isWeb) {
    return incomingHeight * 4;
  }

  return PixelRatio.roundToNearestPixel((height * incomingHeight) / 100);
};
