import {useFocusEffect} from '@react-navigation/native';
import {useCallback} from 'react';
import {changeAndroidStatusBarColor, Colors} from 'utils';

export default function useChangeStatusBarColor() {
  useFocusEffect(() => {
    useCallback(() => {
      changeAndroidStatusBarColor(Colors.topPurple);
    }, []);
  });
}
