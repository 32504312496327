interface IColors {
  primary: string;
  primaryDisabled: string;
  secondary: string;
  grey0: string;
  grey1: string;
  purple: string;
  veryLightOrange: string;
  white: string;
  black: string;
  transparent: string;
  primaryLight: string;
  green: string;
  darkGrey: string;
  errorDark: string;
  greyMedium: string;
  darkWhite: string;
}

export const colors: IColors = {
  primary: '#E9662F',
  primaryDisabled: '#FF8C64',
  primaryLight: '#FEE3E9',
  secondary: '#007AFF',
  grey0: '#F2F2F2',
  grey1: '#ABA6A3',
  purple: '#C882BB',
  veryLightOrange: '#FFEEE8',
  white: '#FFFFFF',
  black: '#000',
  transparent: 'transparent',
  green: '#3C976B',
  darkGrey: '#736969',
  errorDark: '#CC3030',
  greyMedium: '#231F20',
  darkWhite: '#F9F6F4',
};
