import React from 'react';
import {useTheme} from 'utils';
import {ButtonBase} from './ButtonBase';
import {IButtonPropsBase} from './IButtonPropsBase';

interface IButtonLightGreyProps extends IButtonPropsBase {}

export function ButtonLightGray({
  title,
  onPress,
  disabled,
  type,
}: IButtonLightGreyProps) {
  const theme = useTheme();

  return (
    <ButtonBase
      title={title}
      onPress={onPress}
      titleStyle={{color: theme.colors?.grey3}}
      buttonStyle={{
        backgroundColor: theme.colors?.white,
        borderWidth: 1,
        borderColor: theme.colors?.grey3,
      }}
      disabled={disabled}
      type={type}
    />
  );
}
