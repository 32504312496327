import React from 'react';
import {Button} from 'react-native-elements';
import {useTheme} from 'utils';
import {IButtonPropsBase} from './IButtonPropsBase';

interface IButtonProps extends IButtonPropsBase {}

export function ButtonBase({
  title,
  onPress,
  buttonStyle,
  containerStyle,
  titleStyle,
  icon,
  iconRight,
  disabled,
  disabledStyle,
  disabledTitleStyle,
  type,
}: IButtonProps) {
  const theme = useTheme();

  return (
    <Button
      title={title}
      buttonStyle={[theme.Button?.buttonStyle, buttonStyle]}
      containerStyle={[containerStyle, theme.Button?.containerStyle]}
      titleStyle={[titleStyle, theme.Button?.titleStyle]}
      disabled={disabled}
      disabledStyle={disabledStyle}
      disabledTitleStyle={disabledTitleStyle}
      icon={icon}
      iconRight={iconRight}
      onPress={onPress}
      type={type}
    />
  );
}
