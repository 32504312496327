import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  GestureResponderEvent,
  StyleProp,
  ViewStyle,
} from 'react-native';
import {Colors} from 'utils';
import {Icon} from 'libs';

interface IButtonIconProps {
  iconName?: 'arrowright' | 'arrowleft';
  iconSize?: number;
  iconColor?: string;
  buttonStyle?: StyleProp<ViewStyle>;
  onPress: (event: GestureResponderEvent) => void;
}

export function ButtonIcon({
  iconName = 'arrowright',
  iconSize = Icon.Size.Medium,
  iconColor = Colors.white,
  buttonStyle,
  onPress,
}: IButtonIconProps) {
  return (
    <TouchableOpacity
      style={[styles.buttonStyle, buttonStyle]}
      onPress={onPress}>
      <Icon.Base name={iconName} size={iconSize} color={iconColor} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  buttonStyle: {
    width: 40,
    height: 40,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
  },
});
