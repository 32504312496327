import React from 'react';
import {StyleSheet} from 'react-native';
import {Colors} from 'utils';
import {Text} from 'libs';
import {Row} from 'modules';

export function PossibleConditionHeaderItem() {
  return (
    <Row justifyContent="space-between" marginBottom={5}>
      <Text.Body variant="bold" color={Colors.searchIconColor} size="body2">
        Possible Cause
      </Text.Body>

      <Text.Body
        style={styles.chanceTextPosition}
        variant="bold"
        color={Colors.searchIconColor}
        size="body2">
        % Chance
      </Text.Body>
    </Row>
  );
}

const styles = StyleSheet.create({
  chanceTextPosition: {
    right: 45,
  },
});
