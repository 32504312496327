import React from 'react';
import {
  NativeSyntheticEvent,
  TextInput as TextInputRN,
  TextInputFocusEventData,
  ViewStyle,
} from 'react-native';

export interface ITextInputProps {
  value: string;
  onChange: (value: string) => void;
  onBlur?: (ev: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  label?: string;
  style?: ViewStyle;
  placeholderTextColor?: string;
  multiline?: boolean;
  numberOfLines?: number;
}

export function TextInput({
  value,
  label,
  onChange,
  onBlur,
  style,
  placeholderTextColor,
  multiline,
  numberOfLines,
}: ITextInputProps) {
  return (
    <TextInputRN
      value={value}
      placeholder={label}
      onChangeText={(text) => onChange(text)}
      onBlur={onBlur}
      style={style}
      placeholderTextColor={placeholderTextColor}
      multiline={multiline}
      numberOfLines={numberOfLines}
    />
  );
}
