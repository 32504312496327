class Storage {
  async getItem(key: string) {
    return localStorage.getItem(key);
  }

  async setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  async removeItem(key: string) {
    localStorage.removeItem(key);
  }
}

export const storage = new Storage();
