import React from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import Animated from 'react-native-reanimated';
import {
  Colors,
  formattedTodayAddress,
  MARKPRO_BOLD,
  MARKPRO_MEDIUM,
  viewStyles,
} from 'utils';
import {Chevron} from '../../';

const NameWithDateContainer = (props) => {
  const {
    name,
    onChevronPress = () => {},
    dateString = null,
    patientPicture,

    showChevron = false,
    showDate,
    color = Colors.white,
    dateContainerStyles = {},

    scale = 'small',
    dateColor = Colors.white,
    animatedNameStyles,
    animatedDateStyles,
    customerProfile,
  } = props;
  const val = dateString ? dateString : formattedTodayAddress();
  const renderDateTexComponent = () => {
    return (
      <Animated.Text
        style={[styles.dateString, {color: dateColor}, animatedDateStyles]}>
        {val}
      </Animated.Text>
    );
  };

  return (
    <View style={[viewStyles.width100P, {...dateContainerStyles}]}>
      <Pressable onPress={onChevronPress} style={[styles.titleBar]}>
        <Animated.Text
          style={[styles.titleBarText, {color: color}, animatedNameStyles]}>
          {name}
        </Animated.Text>
        {showChevron && (
          <Pressable onPress={onChevronPress}>
            <Chevron color={color} />
          </Pressable>
        )}
      </Pressable>
      {showDate && renderDateTexComponent()}
    </View>
  );
};

const styles = StyleSheet.create({
  titleBar: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    top: 12,
    zIndex: 10,
  },
  titleBarText: {
    color: Colors.white,
    fontSize: 18,
    fontFamily: MARKPRO_MEDIUM,
    lineHeight: 19,
    paddingHorizontal: 8,
  },
  dateString: {
    fontFamily: MARKPRO_BOLD,
    textAlign: 'center',
    marginTop: 20,
    fontSize: 14,
    height: 30,
  },
});

export default NameWithDateContainer;
