import {apiBaseUrl} from 'config';

export const PLATFORM_URL = apiBaseUrl;

// Header Values
export const APPLICATION_JSON = 'application/json';
export const APPLICATION_X_WWW_FORM_URLENCODED =
  'application/x-www-form-urlencoded';

export const LOGIN_PLATFORM = '/api/v1/login';
export const UMA_TOKEN =
  'https://us-central1-trmt-security.cloudfunctions.net/api/firebase/token';
export const GET_ASSESSMENT_TOKEN =
  'https://us-central1-trmt-security.cloudfunctions.net/api/firebase/token_mobile';
export const GET_SOME_THINGS = '/api/v1/registration/get-some-things';
export const SUBSYSTEM_STATUS_CHECK =
  '/api/v1/dx-session/subsystem-status-check';
export const COMPATIBILITY_CHECK = '/api/v1/dx-session/compatibility-check';
export const HEALTH_CHECK = '/api/v1/patient/health-check';

export const GET_SYMPTOM_LIST = '/api/v1/dx-session/get-symptom-list';
export const GET_SYMPTOM_LIST_FILTERED =
  '/api/v1/dx-session/get-platform-sdco-list';
export const MATCH_RFE = '/api/v1/nlp-rfe-agent/match-rfe';
export const SYMPTOM_SYNONYMS = '/api/v1/caring/symptom-synonyms';
export const ADD_SYMPTOMS_TO_QUEUE = '/api/v1/dx-session/add-symptoms-to-queue';

// Patient Management API
export const CREATE_PATIENT_PROFILE = '/api/v1/patient/create-patient-profile';
export const UPDATE_PATIENT_PROFILE = '/api/v1/patient/update-patient-profile';
export const DELETE_PATIENT_PROFILE = '/api/v1/patient/delete-patient-profile';
export const GET_PATIENT_PROFILE_INFO = '/api/v1/patient/get-patient-info';
export const GET_PATIENT_PROFILE = '/api/v1/patient/get-patient-profile';

export const ADD_PATIENT_ALLERGIES = '/api/v1/patient/add-patient-allergies';
export const ADD_PATIENT_MEDICATIONS =
  '/api/v1/patient/add-patient-medications';
export const ADD_PATIENT_PROBLEMS = '/api/v1/patient/add-patient-problems';
export const ADD_PATIENT_RISK_FACTORS =
  '/api/v1/patient/add-patient-risk-factors';

export const REMOVE_PATIENT_ALLERGIES =
  '/api/v1/patient/remove-patient-allergies';
export const REMOVE_PATIENT_MEDICATIONS =
  '/api/v1/patient/remove-patient-medications';
export const REMOVE_PATIENT_PROBLEMS =
  '/api/v1/patient/remove-patient-problems';
export const REMOVE_PATIENT_RISK_FACTORS =
  '/api/v1/patient/remove-patient-risk-factors';

export const GET_PATIENT_ALLERGIES = '/api/v1/patient/get-patient-allergies';
export const GET_PATIENT_MEDICATIONS =
  '/api/v1/patient/get-patient-medications';
export const GET_PATIENT_PROBLEMS = '/api/v1/patient/get-patient-problems';
export const GET_PATIENT_RISK_FACTORS =
  '/api/v1/patient/get-patient-risk-factors';

// For DDO
export const PERSISTENCE_GET_HEADERS = '/api/v1/glm-document/get-headers'; //'/api/v1/persistence/get-headers';
export const PERSISTENCE_GET_SOME = '/api/v1/glm-document/get-some'; //'/api/v1/persistence/get-some';
export const GET_DDO_LIST = '/api/v1/dx-session/get-platform-ddo-headers';
export const GET_DDO_CONDITION_LIBRARY = '/api/v1/caring/condition-library';

// DX Session Agent API
export const SESSION_OPEN = '/api/v1/dx-session/session-open';
export const SESSION_CLOSE = '/api/v1/dx-session/session-close';
export const SESSION_CANCEL = '/api/v1/dx-session/session-cancel';
export const SESSION_REOPEN = '/api/v1/dx-session/session-reopen';
export const SESSION_STEP_BACK = '/api/v1/dx-session/session-step-back';
export const ADD_MIN_INFO_SET_SYMPTOPMS_TO_QUEUE =
  '/api/v1/dx-session/add-min-info-set-symptoms-to-queue';

// Did you knows
export const GET_DID_YOU_KNOWS = '/api/v1/caring/didyouknow/customer-filters';

export const GET_DIAGNOSTIC_QUESTION =
  '/api/v1/dx-session/get-diagnostic-question';
export const SUBMIT_DIAGNOSTIC_ANSWER =
  '/api/v1/dx-session/submit-diagnostic-answer';

export const GET_MIN_SET_OF_RISK_FACTORS =
  '/api/v1/dx-session/get-min-info-set-risk-factors';

export const GET_DIFFERENTIAL_DIAGNOSIS =
  '/api/v1/dx-session/get-differential-diagnosis';
export const GET_CLINICAL_PRESENTATIONS =
  '/api/v1/dx-session/get-clinical-presentations';

export const GET_DX_SESSION_RECORD_HEADERS =
  '/api/v1/patient/get-dx-session-record-headers';
export const GET_DX_SESSION_RECORD = '/api/v1/patient/get-dx-session-record';
export const DELETE_DX_SESSION_RECORD =
  '/api/v1/patient/delete-dx-session-record';

export const GET_DISEASE_CITATIONS =
  '/api/v1/caring/condition-library/get-all-ddos';
