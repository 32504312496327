import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {
  fma223623,
  fma223625,
  fma25558,
  fma25559,
  fma269176,
  fma269440,
  fma269442,
} from 'utils';
import {getColorFma} from 'utils';

export const Chest_fma223729_Male = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181.369 148.022">
      <Path
        id="Path_674"
        dataName="Path 674"
        d="M782.242,413.941c-1.087-7.519-3.337-21.849-5.719-29.844a32.369,32.369,0,0,0-3.238-7.9c-3.278-5.293-11.543-9.109-17.363-11.321s-11.963-3.551-17.653-6.079c-4.28-1.9-8.243-4.448-12.471-6.464-3.124-1.49-6.38-2.683-9.63-3.874l-.811-3.087a27.56,27.56,0,0,1-.112-6.889,2.517,2.517,0,0,0-2.5-2.74H679.2a2.517,2.517,0,0,0-2.5,2.74,27.606,27.606,0,0,1-.112,6.889l-.811,3.087c-3.25,1.191-6.506,2.384-9.631,3.874-4.227,2.016-8.19,4.563-12.47,6.464-5.69,2.528-11.833,3.866-17.653,6.079S621.931,370.9,618.653,376.2a32.372,32.372,0,0,0-3.239,7.9c-2.381,8-4.631,22.325-5.718,29.844-1.714,11.856-3.751,50.7-4.408,65.892a3.532,3.532,0,0,0,3.486,3.686l18.634.247a3.532,3.532,0,0,0,3.564-3.177c1.058-10.457,3.769-37.647,5.318-42.687,1.247-4.055,2.894-8.912,3.451-13.118.084-.639.18-1.322.279-2.023.014.8.039,1.607.083,2.411.566,10.432-1.884,39.469,2.212,55.731a3.552,3.552,0,0,0,3.437,2.7H747.106a3.532,3.532,0,0,0,3.512-3.111c2.558-21.541,1.215-55.322,1.215-55.322.044-.809.069-1.617.082-2.426.1.707.2,1.394.282,2.038.557,4.206,2.2,9.063,3.451,13.118,1.549,5.04,4.26,32.23,5.317,42.687a3.534,3.534,0,0,0,3.565,3.177l18.634-.247a3.533,3.533,0,0,0,3.486-3.686C785.993,464.64,783.956,425.8,782.242,413.941Z"
        transform="translate(-605.285 -335.744)"
        fill="#ead9e7"
      />
      <Path
        id={fma269176}
        dataName="Path 698"
        d="M703.927,367.822v-6.713a1.006,1.006,0,0,0-.187-.9,1.178,1.178,0,0,0-1.275-.152c-3.4,1.268-9.95,1.268-13.225,0a.955.955,0,0,0-.347-.067,1.145,1.145,0,0,0-.64.216,1.6,1.6,0,0,0-.406.472c.012.127-.064.3-.064.429v61.92a2.4,2.4,0,0,0,2.276,2.446h11.592a2.4,2.4,0,0,0,2.276-2.446V367.822Z"
        transform="translate(-605.285 -335.744)"
        fill={getColorFma(fma269176, selectedBodyPart)}
        onPressIn={() => onPressClick(fma269176)}
        onClick={() => onPressClick(fma269176)}
      />
      <Path
        id={fma269440}
        dataName="Path 699"
        d="M686.447,360.451a2.885,2.885,0,0,0-1.54-1.907,27.332,27.332,0,0,1-9.337-7.624,2.853,2.853,0,0,0-2.175-1.006,2.813,2.813,0,0,0-.986.178,77.779,77.779,0,0,0-10.019,4.453c-1.6.852-2.629,3.7-3.937,7.309-.532,1.468-1.134,3.131-1.854,4.847a2.861,2.861,0,0,0,.25,2.7c.2.3.321.759,1.444.759l25.4-.031c.445,0,1.782.114,2.3-.662a2.932,2.932,0,0,0,.532-1.643v-6.713A2.78,2.78,0,0,0,686.447,360.451Z"
        transform="translate(-605.285 -335.744)"
        fill={getColorFma(fma269440, selectedBodyPart)}
        onPressIn={() => onPressClick(fma269440)}
        onClick={() => onPressClick(fma269440)}
      />
      <Path
        id={fma223623}
        dataName="Path 700"
        d="M686,372.572a2.848,2.848,0,0,0-1.039-.884,2.813,2.813,0,0,0-1.266-.311H657.644a2.718,2.718,0,0,0-.383.041,2.024,2.024,0,0,0-1.795,1.4c-.265.735.429,6.841.505,7.584,1.983,19.6,5.059,50.882,6.474,65.3a2.162,2.162,0,0,0,1.6,1.932l.036.008a2.983,2.983,0,0,0,2.76-.945l18.864-20.126a2.6,2.6,0,0,0,.825-1.96.368.368,0,0,1,0-.047V374.214A2.812,2.812,0,0,0,686,372.572Z"
        transform="translate(-605.285 -335.744)"
        fill={getColorFma(fma223623, selectedBodyPart)}
        onPressIn={() => onPressClick(fma223623)}
        onClick={() => onPressClick(fma223623)}
      />
      <Path
        id={fma25558}
        dataName="Path 701"
        d="M661.66,450.516c-.089-.91-.2-2.019-.321-3.275-1.342-13.685-4.56-46.448-6.612-66.714a2.831,2.831,0,0,0-2.142-2.468,2.872,2.872,0,0,0-.7-.087,2.788,2.788,0,0,0-2.33,1.255,103.749,103.749,0,0,1-6.736,9.021,2.809,2.809,0,0,0-.664,1.654c-.082,1.342-2.006,32.9-2.117,33.7-.032.384-.044,6.254.257,41.059a2.8,2.8,0,0,0,1.674,2.563,2.851,2.851,0,0,0,3.031-.455l15.715-13.767a2.838,2.838,0,0,0,.955-2.407Z"
        transform="translate(-605.285 -335.744)"
        fill={getColorFma(fma25558, selectedBodyPart)}
        onPressIn={() => onPressClick(fma25558)}
        onClick={() => onPressClick(fma25558)}
      />
      <Path
        id={fma269442}
        dataName="Path 702"
        d="M735.112,366.7c-.7-1.659-1.272-3.241-1.828-4.771-1.334-3.683-2.366-6.532-3.964-7.385a77.839,77.839,0,0,0-10.019-4.453,2.814,2.814,0,0,0-.986-.178,2.855,2.855,0,0,0-2.175,1.006,27.308,27.308,0,0,1-9.338,7.624,2.883,2.883,0,0,0-1.464,1.651,2.785,2.785,0,0,0-.161.914v6.713a2.812,2.812,0,0,0,.533,1.644c.268.374.759.661,2.3.661,0,0,24.1.031,25.391-.135a1.621,1.621,0,0,0,1.456-.594A2.857,2.857,0,0,0,735.112,366.7Z"
        transform="translate(-605.285 -335.744)"
        fill={getColorFma(fma269442, selectedBodyPart)}
        onPressIn={() => onPressClick(fma269442)}
        onClick={() => onPressClick(fma269442)}
      />
      <Path
        id={fma223625}
        dataName="Path 703"
        d="M735.169,372.31a3.147,3.147,0,0,0-2.1-.933H708.014a2.813,2.813,0,0,0-1.266.311,2.86,2.86,0,0,0-1.038.883,2.807,2.807,0,0,0-.533,1.643v50.355c0,.014,0,.027,0,.04a2.833,2.833,0,0,0,.764,1.9l18.925,20.19a2.984,2.984,0,0,0,2.414,1,2.945,2.945,0,0,0,.383-.061,2.152,2.152,0,0,0,1.6-1.922c1.455-14.522,6.625-71.223,6.625-71.223A2.841,2.841,0,0,0,735.169,372.31Z"
        transform="translate(-605.285 -335.744)"
        fill={getColorFma(fma223625, selectedBodyPart)}
        onPressIn={() => onPressClick(fma223625)}
        onClick={() => onPressClick(fma223625)}
      />
      <Path
        id={fma25559}
        dataName="Path 704"
        d="M751.672,423.593c-.1-.868-1.367-21.453-2.115-33.691a2.821,2.821,0,0,0-.663-1.654,103.055,103.055,0,0,1-6.736-9.021,2.791,2.791,0,0,0-2.33-1.256,2.874,2.874,0,0,0-.7.088,2.831,2.831,0,0,0-2.142,2.468c-2.082,20.562-5.406,54.4-6.613,66.714-.139,1.417-.251,2.565-.329,3.358a2.84,2.84,0,0,0,.955,2.406l15.715,13.768a2.843,2.843,0,0,0,3.03.455,2.8,2.8,0,0,0,1.676-2.562C751.718,429.957,751.706,424.025,751.672,423.593Z"
        transform="translate(-605.285 -335.744)"
        fill={getColorFma(fma25559, selectedBodyPart)}
        onPressIn={() => onPressClick(fma25559)}
        onClick={() => onPressClick(fma25559)}
      />
      <Path
        id="Path_716"
        dataName="Path 716"
        d="M685.168,418.587c-5.856,4.458-12.819,7.663-20.154,8.261s-15.037-1.6-20.36-6.686"
        transform="translate(-605.285 -335.744)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_717"
        dataName="Path 717"
        d="M705.364,418.587c5.857,4.458,12.819,7.663,20.155,8.261s15.037-1.6,20.359-6.686"
        transform="translate(-605.285 -335.744)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
