import PropTypes from 'prop-types';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {viewStyles} from 'utils';
import Avatar from './Avatar';
import Bubble from './Bubble';
import Day from './Day';
import SystemMessage from './SystemMessage';
import {isSameUser} from './utils';

export default class Message extends React.Component {
  shouldComponentUpdate(nextProps) {
    const next = nextProps.currentMessage;
    const current = this.props.currentMessage;
    const currentSecure = this.props.setTextSecure;
    const nextSecure = nextProps.setTextSecure;
    const currentUnmountedMsgs = this.props.unmountedMsgs;
    const nextUnmountedMsgs = nextProps.unmountedMsgs;
    const {previousMessage, nextMessage} = this.props;
    const nextPropsMessage = nextProps.nextMessage;
    const nextPropsPreviousMessage = nextProps.previousMessage;
    const shouldUpdate =
      (this.props.shouldUpdateMessage &&
        this.props.shouldUpdateMessage(this.props, nextProps)) ||
      false;
    return (
      next.sent !== current.sent ||
      next.received !== current.received ||
      next.isValid !== current.isValid ||
      next.pending !== current.pending ||
      next.createdAt !== current.createdAt ||
      next.text !== current.text ||
      next.image !== current.image ||
      next.video !== current.video ||
      next.audio !== current.audio ||
      nextUnmountedMsgs.length !== currentUnmountedMsgs.length ||
      previousMessage !== nextPropsPreviousMessage ||
      nextMessage !== nextPropsMessage ||
      nextSecure !== currentSecure ||
      shouldUpdate
    );
  }
  renderDay() {
    if (this.props.currentMessage && this.props.currentMessage.createdAt) {
      const {containerStyle, ...props} = this.props;
      if (this.props.renderDay) {
        return this.props.renderDay(props);
      }
      return <Day {...props} />;
    }
    return null;
  }
  renderBubble() {
    const {containerStyle, ...props} = this.props;
    if (this.props.renderBubble) {
      return this.props.renderBubble(props);
    }
    // @ts-ignore
    return <Bubble {...props} />;
  }
  renderSystemMessage() {
    const {containerStyle, ...props} = this.props;
    if (this.props.renderSystemMessage) {
      return this.props.renderSystemMessage(props);
    }
    return <SystemMessage {...props} />;
  }
  renderAvatar() {
    const {user, currentMessage, showUserAvatar} = this.props;
    if (
      user &&
      user._id &&
      currentMessage &&
      currentMessage.user &&
      user._id === currentMessage.user._id &&
      !showUserAvatar
    ) {
      return null;
    }
    if (
      currentMessage &&
      currentMessage.user &&
      currentMessage.user.avatar === null
    ) {
      return null;
    }
    const {containerStyle, ...props} = this.props;
    return <Avatar {...props} />;
  }
  render() {
    const {currentMessage, nextMessage, position, containerStyle} = this.props;

    if (currentMessage) {
      const sameUser = isSameUser(currentMessage, nextMessage);
      const marginBottom = {marginBottom: sameUser ? 2 : 10};
      return (
        <View>
          {this.renderDay()}
          {currentMessage.system ? (
            this.renderSystemMessage()
          ) : (
            <View
              style={[
                styles[position].container,
                marginBottom,
                !this.props.inverted && viewStyles.marginBottom2,
                containerStyle && containerStyle[position],
              ]}>
              {this.props.position === 'left' ? this.renderAvatar() : null}
              {this.renderBubble()}
              {this.props.position === 'right' ? this.renderAvatar() : null}
            </View>
          )}
        </View>
      );
    }
    return null;
  }
}
Message.defaultProps = {
  renderAvatar: undefined,
  renderBubble: null,
  renderDay: null,
  renderSystemMessage: null,
  position: 'left',
  currentMessage: {},
  nextMessage: {},
  previousMessage: {},
  user: {},
  containerStyle: {},
  showUserAvatar: false,
  inverted: true,
  shouldUpdateMessage: undefined,
};

Message.propTypes = {
  renderAvatar: PropTypes.func,
  showUserAvatar: PropTypes.bool,
  renderBubble: PropTypes.func,
  renderDay: PropTypes.func,
  renderSystemMessage: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
  currentMessage: PropTypes.object,
  nextMessage: PropTypes.object,
  previousMessage: PropTypes.object,
  user: PropTypes.object,
  inverted: PropTypes.bool,
  containerStyle: PropTypes.any,
  shouldUpdateMessage: PropTypes.func,
};

const styles = {
  left: StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      marginLeft: 8,
      marginRight: 0,
    },
  }),
  right: StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      marginLeft: 0,
      marginRight: 8,
    },
  }),
};
