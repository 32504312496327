import React from 'react';
import Svg, {Path, G} from 'react-native-svg';
import {getColorFma} from 'utils';
import {fma24995, fma27_2_4, fma37850, fma51131} from 'utils';

export const Foot_Left_Bottom_fma272 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1242 2208">
      <Path
        id="Path_596"
        data-name="Path 596"
        d="M865.8,909.6l-98,700.5c-6,42.8-26.4,82.8-59.3,110.3c-23.4,19.6-55.1,35.3-96.9,35.3  c-111.5,0-154.8-116.5-154.8-184.1v-213.7c0-97-12.4-193.6-37.9-287c-18.7-68.8-37-147.8-35.4-188.3c3.3-81.1-0.1-233.1-0.1-315.8  c0-52.6,22.8-110.2,76-110.2c63.2,0,73.3,56.7,72.9,101.3c-0.4,45.6-0.2,133.9-0.2,133.9l12.7,0.4c0,0,3.8-105.8,4.4-141.2  c0.3-19.3,13.5-49.8,46.9-48.2c37.7,1.7,40.2,39.4,38.2,62c-1.7,19.8-13.3,138.1-13.3,138.1l11.2,3l11.1-122.9  c0,0,3.3-40.1,40.1-35.9c38.3,4.4,31.6,52.8,31.6,52.8l-15,128.8l11.2,3.4c0,0,6.3-60.4,8.7-79.3c1.6-12.7,10.9-34.3,40.6-30  c30.3,4.4,25.5,45.3,25.5,45.3l-12.4,95.3l9.2,5.9c0,0,6-41.4,8.9-62.1c1.7-12.9,9.9-31.6,34.8-30c26.6,1.7,31.1,18,30.7,53.4  c-0.4,35.5-5.8,77.1,0,94S869.5,884.5,865.8,909.6z"
        fill="#ead9e7"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id={fma27_2_4}
        data-name="Path 597"
        d="M392.6,511.5c84.2-89.2,439.6,181.4,462.9,248.3c0.2,0.5,2.1,3.9,2.9,8.8c6.2,40.2,8.3,73.6,8.7,98  c0.2,11.7-4.5,19.5-9.4,19.3c-3.1-0.1-5.7-4.5-6.3-5.5c-8.5-14.4-103.8-104.9-218.7-134.7c-59.1-15.3-109.7-11-145-8  c-42.1,3.6-77,11.7-102.6,19.2c0-44.9,0.1-89.8,0.1-134.7C367.2,554.9,379.1,525.8,392.6,511.5z"
        fill={getColorFma(fma27_2_4, selectedBodyPart)}
        onPressIn={() => onPressClick(fma27_2_4)}
        onClick={() => onPressClick(fma27_2_4)}
      />
      <G>
        <Path
          id={fma37850}
          data-name="Path 598"
          d="M863.3,930.3l-47.6,348.9c-2,14.3-14,24.9-28.2,24.9c-61.8,0-223.8,0-305.1,0c-14.9,0-27.4-11.6-28.4-26.7   c-8.6-131-67.8-311.7-68.4-391.1c-0.2-32.9,0.8-52.2,0.8-84.1c0-13.4,9-25,21.8-28.1c49.2-12.2,127.9-25.9,207.1-13.2   c94.8,15.2,204.8,111.8,239.3,144C861.3,911.4,864.6,920.9,863.3,930.3z"
          fill={getColorFma(fma37850, selectedBodyPart)}
          onPressIn={() => onPressClick(fma37850)}
          onClick={() => onPressClick(fma37850)}
        />
        <Path
          id={fma51131}
          data-name="Path 599"
          d="M807.1,1329.2l-22.5,158.6c-1.5,10.8-14.7,14.9-21.9,6.8c-23.1-26-70.4-64-149.1-64   c-66.1,0-110.3,35.5-134.3,62.6c-7.8,8.8-22,3.2-22-8.6l-0.1-157.3c0-7.1,5.7-12.8,12.6-12.8h324.7   C802.3,1314.5,808.2,1321.4,807.1,1329.2z"
          transform="translate(-0.003)"
          fill={getColorFma(fma51131, selectedBodyPart)}
          onPressIn={() => onPressClick(fma51131)}
          onClick={() => onPressClick(fma51131)}
        />
        <Path
          id={fma24995}
          data-name="Path 600"
          d="M614.6,1441.7c85.7,1.7,160.1,74.2,159.2,106.3c-4.6,133.8-65.6,207.8-162.3,207.8   c-118.7,0-156.2-120.8-154.6-195.9C459.5,1520.5,529.7,1440,614.6,1441.7z"
          transform="translate(-0.003)"
          fill={getColorFma(fma24995, selectedBodyPart)}
          onPressIn={() => onPressClick(fma24995)}
          onClick={() => onPressClick(fma24995)}
        />
      </G>
      <Path
        id="Path_648"
        data-name="Path 648"
        d="M620,831.5c0,0,34.1,167.6-102.2,186.2"
        fill="none"
        stroke="#241f21"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.25"
      />
    </Svg>
  );
};
