import React, {useEffect, useState} from 'react';
import {InteractionManager, StyleSheet} from 'react-native';
import {
  ScrollLayout,
  AssessmentResult,
  Column,
  Container,
  NewAssessmentButtonAndModal,
} from 'modules';
import {
  TopBarSvg,
  getDDOFromConditionLibrary,
  colors,
  Spacing,
  changeAndroidStatusBarColor,
  Colors,
} from 'utils';
import {Loading, Text} from 'libs';
import {isWeb} from 'config';

export function AssessmentResultItem({route, navigation}: any) {
  const [selectedTitle, setSelectedTitle] = useState('Result Details');
  const [loading, setLoading] = useState(true);
  const [ddoConditionLibrary, setDdoConditionLibrary] = useState(null);

  const runAfterInteractions = (callBack: any) => {
    InteractionManager.runAfterInteractions(() => {
      callBack();
    });
  };

  useEffect(() => {
    changeAndroidStatusBarColor(Colors.topPurple);
    const {differentialDiagnosis, platformUrl, platformToken} = route.params;

    getDDOFromConditionLibrary(
      differentialDiagnosis.diagnosis.ddo_id,
      platformUrl,
      platformToken,
    )
      .then((res) => {
        runAfterInteractions(() => {
          setDdoConditionLibrary(res.data.data[0]);
        });
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <ScrollLayout flexGrow={1} justifyContent="space-between">
      <Column>
        {!isWeb ? <TopBarSvg fillColor={colors.purple} /> : null}

        {loading ? (
          <Loading.Loader />
        ) : (
          <>
            <AssessmentResult.TabActions
              selectedTitle={selectedTitle}
              setSelectedTitle={setSelectedTitle}
            />
            {ddoConditionLibrary ? (
              <AssessmentResult.TabResults
                title={selectedTitle}
                ddoConditionLibrary={ddoConditionLibrary}
                index={route.params?.index}
                differentialDiagnosis={route.params?.differentialDiagnosis}
                differentialDiagnosisItems={
                  route.params?.differentialDiagnosisItems
                }
              />
            ) : (
              <Text.Body style={styles.text}>
                Oops! Looks like you discovered an area we’re still working on.
                Check with your doctor if you have concerns about this
                particular disease, your symptoms, and possible treatment
                options.
              </Text.Body>
            )}
          </>
        )}
      </Column>

      {!loading && (
        <Container
          marginHorizontal={Spacing.small}
          marginVertical={Spacing.medium35}>
          <NewAssessmentButtonAndModal parentNavigation={navigation} />
        </Container>
      )}
    </ScrollLayout>
  );
}

const styles = StyleSheet.create({
  text: {
    padding: 20,
  },
});
