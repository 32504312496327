import React from 'react';
import {View, Text} from 'react-native';
import {allBodyParts} from 'utils';
import {BodyPartsComponent} from 'containers/dashBoard/humanBodyTest/HumanAnatomy';

import {isEntireSelection} from 'utils';
import {textStyles, viewColors, viewStyles} from 'utils';

export default function HumanAnatomyChatSvg({currentMessage}) {
  let mainPart;
  let subParts = [];
  const isEntireBodyPart = isEntireSelection(
    currentMessage?.svg?.selectedBodyPart[0],
  );
  if (isEntireBodyPart) {
    const filtered = allBodyParts.filter((abp) => {
      let isFoundAll = true;
      currentMessage?.svg?.selectedBodyPart.forEach((selectedPart) => {
        if (
          !abp?.sub_parts.includes(selectedPart) &&
          selectedPart !== currentMessage.svg.selectedBodyPart[0]
        ) {
          isFoundAll = false;
        }
      });

      return isFoundAll;
    });

    if (filtered?.length > 0) {
      mainPart = filtered[0].main_part;
      subParts = currentMessage?.svg?.selectedBodyPart;
    }
  } else {
    mainPart = currentMessage?.svg?.mainFmaId;
    subParts = currentMessage?.svg?.selectedBodyPart;
  }

  return (
    <View
      style={[
        viewStyles.paddingHorizontal15,
        viewStyles.paddingVertical15,
        viewStyles.alignItemsCenter,
        viewColors.backgroundColorChatMessageContainerOrangeColor,
      ]}>
      {!isEntireBodyPart ? (
        <View
          style={
            ([
              viewStyles.flex1,
              viewStyles.justifyContentCenter,
              viewStyles.alignItemsCenter,
            ],
            {
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              width: 150,
              height: 150,
              paddingBottom: 5,
            })
          }>
          <BodyPartsComponent
            mainFma={mainPart}
            onPressClick={() => {}}
            selectedBodyPart={subParts}
          />
        </View>
      ) : null}
      <Text
        style={[
          textStyles.colorTextPrimary,
          textStyles.lineHeight22,
          textStyles.textAlignCenter,
          textStyles.fontFamilyMARKPRO_BOLD,
          textStyles.fontSize16,
        ]}>
        {currentMessage?.msgText}
      </Text>
    </View>
  );
}
