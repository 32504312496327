import {IconSize as Size} from './IconPropsBase';
import {IconArrow as Arrow} from './IconArrow';
import {IconClose as Close} from './IconClose';
import {IconSettings as Settings} from './IconSettings';
import {IconBase as Base} from './IconBase';
import {IconArrowLeft as ArrowLeft} from './IconArrowLeft';
import {IconStar as Star} from './IconStar';
import {IconRoundedStar as RoundedStar} from './IconRoundedStar';
import {IconSquare as Square} from './IconSquare';

export {
  Size,
  Arrow,
  Close,
  Settings,
  Base,
  ArrowLeft,
  Star,
  RoundedStar,
  Square,
};
