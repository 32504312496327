import StaticTextScreen from './authScreens/signup/StaticTextScreen';
import DataAndMethodologyScreen from './authScreens/signup/DataAndMethodologyScreen';
import DiseaseCitationsScreen from './authScreens/signup/diseaseCitations/DiseaseCitationsScreen';
import TermsPrivacyDataUsageScreen from './authScreens/signup/TermsPrivacyDataUsageScreen';
import WelcomeScreen from './authScreens/signup/WelcomeScreen';
import AssessmentResultItem from './dashBoard/assessment/assesmentResults/AssessmentResultItem';
import AssessmentResultLoading from './dashBoard/assessment/assesmentResults/AssessmentResultLoading';
import AssessmentResultPossibleCauses from './dashBoard/assessment/assesmentResults/AssessmentResultPossibleCauses';
import AssessmentResults from './dashBoard/assessment/assesmentResults/AssessmentResults';
import Description from './dashBoard/assessment/assesmentResults/Description';
import Prevention from './dashBoard/assessment/assesmentResults/Prevention';
import ResultsTopTab from './dashBoard/assessment/assesmentResults/ResultsTopTab';
import Risks from './dashBoard/assessment/assesmentResults/Risks';
import Symptoms from './dashBoard/assessment/assesmentResults/Symptoms';
import Treatment from './dashBoard/assessment/assesmentResults/Treatment';
import AssessmentDashboardScreen from './dashBoard/assessment/AssessmentDashboardScreen';

import PlatformReLogin from './PlatformReLogin';
import SplashScreen from './SplashScreen';
export * from './dashBoard';
export {
  SplashScreen,
  StaticTextScreen,
  DataAndMethodologyScreen,
  DiseaseCitationsScreen,
  WelcomeScreen,
  AssessmentDashboardScreen,
  AssessmentResultItem,
  AssessmentResultLoading,
  AssessmentResultPossibleCauses,
  AssessmentResults,
  Description,
  Prevention,
  ResultsTopTab,
  Risks,
  Symptoms,
  Treatment,
  TermsPrivacyDataUsageScreen,
  PlatformReLogin,
};
