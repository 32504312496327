import React from 'react';
import Svg, {Rect} from 'react-native-svg';

export const ArrowForwardOrange = () => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <Rect
      width="2"
      height="10"
      rx="1"
      transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.3496 5)"
      fill="#E9662F"
    />
    <Rect
      x="19"
      y="10.7"
      width="2"
      height="10"
      rx="1"
      transform="rotate(45 19 10.7)"
      fill="#E9662F"
    />
    <Rect
      width="2"
      height="16"
      rx="1"
      transform="matrix(-4.37114e-08 1 1 4.37114e-08 4 11.1)"
      fill="#E9662F"
    />
  </Svg>
);
