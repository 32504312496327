import React, {useEffect, useState} from 'react';
import {Text, View, Image} from 'react-native';
import {
  ENTER_BIRTHDATE,
  ENTER_CITY,
  ENTER_COUNTRY,
  ENTER_EMAIl,
  ENTER_FIRST_NAME,
  ENTER_LAST_NAME,
  ENTER_NICKNAME,
  ENTER_PASSOWRD,
  ENTER_PRONOUN,
  ENTER_PROVINCE,
  ENTER_RELATIONSHIP,
  FINAL_ONBOARDING_SCREEN,
  FINISH,
  SELECT_DISEASE_START_DATE,
  SELECT_RECOVER_DATE,
  SUBMIT_WEIGHT,
  SUBMIT_HEIGHT,
  ADD_PROFILE_SEX,
  FINAL_ONBOARDING_SCREEN_ADD_PROFILE,
  ENTER_HEIGHT,
  ENTER_WEIGHT,
  SMOKING,
  DRINKING,
  IS_TRAVELLED,
  FINAL_ONBOARDING_SCREEN_LIFESTYLE,
  FAMILY_HISTORY_COMPLETED,
  ASSESSMENT_QUESTION_ANSWER_STAGE,
  INITIAL_STAGE,
  ENTER_GENDER,
  ENTER_BIRTH_MONTH,
  SECOND_PASS,
} from 'utils';

import Svg, {
  Path,
  Defs,
  ClipPath,
  G,
  Line,
  Ellipse,
  Rect,
} from 'react-native-svg';
import chatStyles from './CommonStyles';
import {textStyles, viewStyles} from 'utils';

const AuthChatBubble = ({text, showIcon, iconMapperToImage}) => {
  const marginLeft = {marginLeft: showIcon ? 50 : 0};
  const position = {
    left: -10,
    bottom: -15.5,
  };
  return (
    <View>
      <View style={chatStyles.container}>
        <Text style={[chatStyles.text, marginLeft]}>{text}</Text>
        {showIcon && (
          <Image
            style={[
              chatStyles.iconImage,
              chatStyles.iconPosition,
              viewStyles.height71,
              viewStyles.width71,
              viewStyles.positionAbsolute,
              position,
            ]}
            resizeMode={'contain'}
            source={iconMapperToImage()}
          />
        )}
      </View>
    </View>
  );
};

const RegisterCompleteGreetingsMessage = ({
  text,
  showIcon,
  iconMapperToImage,
}) => {
  const ImagesContainer = (_) => (
    <View style={[chatStyles.iconPosition, viewStyles.bottom_16]}>
      <View style={[viewStyles.marginLeft20, viewStyles.marginBottom0_5]}>
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="48.675"
          viewBox="0 0 35 48.675">
          <Defs>
            <ClipPath id="clip-path">
              <Path
                id="Path_3"
                dataName="Path 3"
                d="M325.348,168.294a20.892,20.892,0,0,1,3.388-7.945,22.12,22.12,0,0,1,1.9-2.387,18.072,18.072,0,0,1,9.469-5.672,12.951,12.951,0,0,1,3.067-.255,11.865,11.865,0,0,1,4.581,1.156,15.878,15.878,0,0,1,4.862,3.615,17.567,17.567,0,0,1,1.909,2.477,15.3,15.3,0,0,1,2.514,8.38,12.425,12.425,0,0,1-.249,2.284,28.019,28.019,0,0,1-4.258,9.439c-1.369,2.115-2.728,4.2-4.07,6.323-.332.524-.656,1.055-1,1.568-.171.255-.349.505-.531.752l-.005,5.613a7.065,7.065,0,0,1-7.071,7.059h0a7.066,7.066,0,0,1-7.059-7.072l0-5.38a19.52,19.52,0,0,1-1.584-1.8,44.163,44.163,0,0,1-4.891-8.85A15.5,15.5,0,0,1,325.348,168.294Z"
                fill="none"
              />
            </ClipPath>
          </Defs>
          <G
            id="Group_4"
            dataName="Group 4"
            transform="translate(-325.045 -152.026)">
            <Path
              id="Path_1"
              dataName="Path 1"
              d="M325.348,168.294a20.892,20.892,0,0,1,3.388-7.945,22.12,22.12,0,0,1,1.9-2.387,18.072,18.072,0,0,1,9.469-5.672,12.951,12.951,0,0,1,3.067-.255,11.865,11.865,0,0,1,4.581,1.156,15.878,15.878,0,0,1,4.862,3.615,17.567,17.567,0,0,1,1.909,2.477,15.3,15.3,0,0,1,2.514,8.38,12.425,12.425,0,0,1-.249,2.284,28.019,28.019,0,0,1-4.258,9.439c-1.369,2.115-2.728,4.2-4.07,6.323-.332.524-.656,1.055-1,1.568-.171.255-.349.505-.531.752l-.005,5.613a7.065,7.065,0,0,1-7.071,7.059h0a7.066,7.066,0,0,1-7.059-7.072l0-5.38a19.52,19.52,0,0,1-1.584-1.8,44.163,44.163,0,0,1-4.891-8.85A15.5,15.5,0,0,1,325.348,168.294Z"
              fill="#f7ba3d"
            />
            <G id="Group_2" dataName="Group 2">
              <G id="Group_1" dataName="Group 1" clipPath="url(#clip-path)">
                <Path
                  id="Path_2"
                  dataName="Path 2"
                  d="M346.934,188.029l-17.407-.015-1.748,10.952,2.6,3.914,21.006.6,9.133-6.946-3.908-4.06Z"
                  fill="#e9662f"
                />
              </G>
            </G>
            <G id="Group_3" dataName="Group 3">
              <Line
                id="Line_1"
                dataName="Line 1"
                x2="13.786"
                y2="0.012"
                transform="translate(333.036 190.78)"
                fill="none"
                stroke="#241f21"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.25"
              />
              <Line
                id="Line_2"
                dataName="Line 2"
                x2="13.786"
                y2="0.012"
                transform="translate(333.087 194.173)"
                fill="none"
                stroke="#241f21"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.25"
              />
            </G>
            <Path
              id="Path_4"
              dataName="Path 4"
              d="M336.428,187.849l-4.074-19.3a5.177,5.177,0,0,0,7.9.431s2.673,3.533,7.591.243l-4,18.817"
              fill="none"
              stroke="#241f21"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.25"
            />
          </G>
        </Svg>
      </View>
      <Image
        style={[
          viewStyles.width80,
          viewStyles.height80,
          viewStyles.left_10,
          viewStyles.bottom_3,
        ]}
        resizeMode={'contain'}
        source={iconMapperToImage()}
      />
    </View>
  );

  const marginLeft = {marginLeft: showIcon ? 65 : 0};

  return (
    <View>
      <View style={[chatStyles.container]}>
        <Text style={[chatStyles.text, marginLeft]}>{text}</Text>
        {showIcon && <ImagesContainer />}
      </View>
    </View>
  );
};

const FamilyHistoryCompletedMessage = ({
  showIcon,
  iconMapperToImage,
  currentMessage,
}) => {
  let t = currentMessage.data;
  const ImagesContainer = (_) => (
    <View style={[chatStyles.iconPosition, viewStyles.bottom_16]}>
      <View
        style={[
          viewStyles.marginLeft10,
          viewStyles.marginBottom7,
          viewStyles.width48,
          viewStyles.height48,
          viewStyles.justifyContentCenter,
          viewStyles.alignItemsCenter,
        ]}>
        <View style={[viewStyles.marginLeft10, viewStyles.marginBottom15]}>
          <Svg
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="64.314"
            viewBox="0 0 54 64.314">
            <Defs>
              <ClipPath id="clip-path">
                <Path
                  id="Path_11"
                  dataName="Path 11"
                  d="M578.007,183.1,555.953,171a2.182,2.182,0,0,0-3.206,1.585L546.992,208.1a2.191,2.191,0,0,0,2.228,2.5,2.254,2.254,0,0,0,1.261-.457l27.77-23.384a2.181,2.181,0,0,0-.244-3.668Z"
                  fill="none"
                />
              </ClipPath>
            </Defs>
            <G
              id="Group_7"
              dataName="Group 7"
              transform="translate(-546.968 -146.291)">
              <Path
                id="Path_5"
                dataName="Path 5"
                d="M578.007,183.1,555.953,171a2.182,2.182,0,0,0-3.206,1.585L546.992,208.1a2.191,2.191,0,0,0,2.228,2.5,2.254,2.254,0,0,0,1.261-.457l27.77-23.384a2.181,2.181,0,0,0-.244-3.668Z"
                fill="#f7ba3d"
              />
              <Ellipse
                id="Ellipse_1"
                dataName="Ellipse 1"
                cx="3.554"
                cy="14.158"
                rx="3.554"
                ry="14.158"
                transform="translate(551.993 175.068) rotate(-62.09)"
                fill="#883e79"
                stroke="#883e79"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.913"
              />
              <Path
                id="Path_6"
                dataName="Path 6"
                d="M555.613,171.617l22.053,12.1a1.475,1.475,0,0,1,.166,2.48l-27.806,23.412a1.475,1.475,0,0,1-2.334-1.4l5.753-35.51A1.474,1.474,0,0,1,555.613,171.617Z"
                fill="none"
              />
              <Rect
                id="Rectangle_2"
                dataName="Rectangle 2"
                width="3.174"
                height="3.174"
                transform="matrix(0.999, -0.036, 0.036, 0.999, 557.492, 163.588)"
                fill="#e9662f"
                stroke="#e9662f"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.913"
              />
              <Rect
                id="Rectangle_3"
                dataName="Rectangle 3"
                width="3.174"
                height="3.174"
                transform="matrix(0.732, 0.681, -0.681, 0.732, 578.094, 161.926)"
                fill="#e9662f"
                stroke="#e9662f"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.913"
              />
              <Rect
                id="Rectangle_4"
                dataName="Rectangle 4"
                width="3.174"
                height="3.174"
                transform="matrix(0.732, 0.681, -0.681, 0.732, 585.389, 178.219)"
                fill="#f7ba3d"
                stroke="#f7ba3d"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.913"
              />
              <Rect
                id="Rectangle_5"
                dataName="Rectangle 5"
                width="3.174"
                height="3.174"
                transform="matrix(0.732, 0.681, -0.681, 0.732, 583.313, 146.936)"
                fill="#2cb59b"
                stroke="#2cb59b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.913"
              />
              <Rect
                id="Rectangle_6"
                dataName="Rectangle 6"
                width="3.174"
                height="3.174"
                transform="matrix(0.314, 0.949, -0.949, 0.314, 587.535, 158.295)"
                fill="#f7ba3d"
                stroke="#f7ba3d"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.913"
              />
              <Path
                id="Path_7"
                dataName="Path 7"
                d="M598.256,165.468l-.76,3.418,3.016,1.779-3.485.333-.76,3.418-1.394-3.212-3.485.333,2.624-2.318-1.394-3.212,3.016,1.779Z"
                fill="#2cb59b"
                stroke="#2cb59b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.913"
              />
              <G id="Group_6" dataName="Group 6">
                <G id="Group_5" dataName="Group 5" clip-path="url(#clip-path)">
                  <Path
                    id="Path_8"
                    dataName="Path 8"
                    d="M549.383,178.446s8.985,16.731,26.171,16.927"
                    fill="none"
                    stroke="#241f21"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.25"
                  />
                  <Path
                    id="Path_9"
                    dataName="Path 9"
                    d="M543.669,188.1s12.755,11.342,30.813,11.781"
                    fill="none"
                    stroke="#241f21"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.25"
                  />
                  <Path
                    id="Path_10"
                    dataName="Path 10"
                    d="M542.265,191.884s9.388,18.3,26.295,16.23"
                    fill="none"
                    stroke="#241f21"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.25"
                  />
                </G>
              </G>
              <Path
                id="Path_12"
                dataName="Path 12"
                d="M568.444,147.1l1.292,5.082,5.233.341-4.434,2.8,1.292,5.082-4.033-3.352-4.434,2.8,1.942-4.871-4.033-3.352,5.233.341Z"
                fill="#f7ba3d"
                stroke="#f7ba3d"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.913"
              />
              <Path
                id="Path_13"
                dataName="Path 13"
                d="M570.522,180.852s6.679-10.072,17.752-11.372"
                fill="none"
                stroke="#241f21"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.25"
              />
              <Path
                id="Path_14"
                dataName="Path 14"
                d="M561.767,179.676a54.936,54.936,0,0,0,5.733-16.231"
                fill="none"
                stroke="#241f21"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.25"
              />
            </G>
          </Svg>
        </View>
      </View>
      <Image
        style={[viewStyles.width80, viewStyles.height80, viewStyles.left_10]}
        resizeMode={'contain'}
        source={iconMapperToImage()}
      />
    </View>
  );

  const marginLeft = {marginLeft: showIcon ? 65 : 0};

  return (
    <View>
      <View style={[chatStyles.container]}>
        <Text
          style={[
            textStyles.fontSize16,
            textStyles.fontFamilyMARKPRO_BOLD,
            viewStyles.paddingVertical5,
            marginLeft,
          ]}>
          {t}
        </Text>
        <Text style={[chatStyles.text, marginLeft]}>
          {
            "Thanks for helping me get to know you—it lets me give you more accurate insights, assessments, and recommendations. Plus, I think you're neat."
          }
        </Text>
        {showIcon && <ImagesContainer />}
      </View>
    </View>
  );
};

const LifeStyleCompleteMessage = ({text, showIcon, iconMapperToImage}) => {
  const ImagesContainer = (_) => (
    <View style={[chatStyles.iconPosition, viewStyles.bottom_16]}>
      <View style={[viewStyles.marginLeft6, viewStyles.marginBottom3]}>
        <Svg height={58} width={44} viewBox="0 0 44 58" top={-2} left={7}>
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.4922 36.5235C27.7691 35.6926 28.375 35.021 29.1195 34.5598C33.7916 31.6661 37 26.138 37 20.5C37 12.3163 31.5 7 22.0562 7C13.8725 7 7.5 14.3163 7.5 22.5C7.5 28.1069 10.4912 32.6663 15.0068 34.9817C15.8545 35.4163 16.5597 36.1102 16.8824 37.0066L17.6593 39.1647C18.157 40.5472 19.5104 41.4326 20.9766 41.3349L23.7942 41.1471C25.1284 41.0581 26.2763 40.1712 26.6991 38.9028L27.4922 36.5235Z"
            fill="#E9662F"
          />
          <Path
            d="M22.3668 50.9746C21.8554 50.9746 21.3162 50.9645 20.7771 50.9796C20.3736 50.9896 20.0744 50.6987 20.0118 50.1871C19.9492 49.6956 20.137 49.2943 20.5475 49.1489C20.8153 49.0536 21.0971 48.9834 21.3719 49.0034C22.4155 49.0686 23.4555 49.169 24.4991 49.2643C24.833 49.2943 25 49.5652 25 50.0417C25 50.4831 24.9061 50.7539 24.5582 50.8993C23.8243 51.2053 23.1146 50.6887 22.3668 50.9746Z"
            fill="#2D2D2D"
          />
          <Path
            d="M21.2188 44.8946C20.6654 44.8946 20.1091 44.8803 19.5557 44.8993C19.247 44.9089 18.9411 44.9852 18.6324 44.9996C18.2538 45.0139 18.012 44.6798 18.0004 44.174C17.9887 43.6824 18.2596 43.2052 18.5974 43.1766C19.1654 43.1289 19.7334 43.105 20.3013 43.0811C21.2625 43.0477 22.2236 43 23.1819 43C24.0964 43 25.011 43.0477 25.9255 43.0811C26.1644 43.0907 26.4061 43.0907 26.6449 43.1384C26.942 43.2004 27.0352 43.649 26.9886 43.9831C26.9449 44.2837 26.7323 44.6989 26.5634 44.7419C26.0624 44.8707 25.5498 44.8516 25.0401 44.8707C24.2188 44.9041 23.3945 44.9423 22.5731 44.9375C22.1246 44.9375 21.6761 44.8516 21.2246 44.8039C21.2217 44.8325 21.2188 44.8659 21.2188 44.8946Z"
            fill="#2B2B2B"
          />
          <Path
            d="M21.5035 47.8946C21.0731 47.8946 20.6404 47.8803 20.21 47.8993C19.9699 47.9089 19.732 47.9852 19.4919 47.9996C19.1974 48.0139 19.0093 47.6798 19.0003 47.174C18.9912 46.6824 19.2019 46.2052 19.4647 46.1766C19.9064 46.1289 20.3482 46.105 20.7899 46.0811C21.5375 46.0477 22.285 46 23.0303 46C23.7417 46 24.453 46.0477 25.1643 46.0811C25.3501 46.0907 25.5381 46.0907 25.7238 46.1384C25.9549 46.2004 26.0274 46.649 25.9912 46.9831C25.9572 47.2837 25.7918 47.6989 25.6604 47.7419C25.2708 47.8707 24.8721 47.8516 24.4756 47.8707C23.8368 47.9041 23.1957 47.9423 22.5569 47.9375C22.208 47.9375 21.8592 47.8516 21.508 47.8039C21.5058 47.8325 21.5035 47.8659 21.5035 47.8946Z"
            fill="#2B2B2B"
          />
          <Path
            d="M19 12.0009C17.5 11.5009 13 12.501 11.5 18.5007C15 15.001 15.5 13.5011 19 12.0009Z"
            fill="#F9F6F4"
          />
        </Svg>
      </View>
      <Image
        style={[viewStyles.width80, viewStyles.height80, viewStyles.left_9]}
        resizeMode={'contain'}
        source={iconMapperToImage()}
      />
    </View>
  );

  const marginLeft = {marginLeft: showIcon ? 65 : 0};
  return (
    <View>
      <View style={chatStyles.container}>
        <Text style={[chatStyles.headerText, marginLeft, viewStyles.bottom5]}>
          One last thing:
        </Text>
        <Text style={[chatStyles.text, marginLeft]}>{text}</Text>
        {showIcon && <ImagesContainer />}
      </View>
    </View>
  );
};

const AddProfileCompleteGreetingsMessage = ({
  text,
  showIcon,
  iconMapperToImage,
}) => {
  const ImagesContainer = (_) => (
    <View style={[chatStyles.iconPosition, viewStyles.bottom_15]}>
      <Image
        style={[viewStyles.width80, viewStyles.height80, viewStyles.left_10]}
        resizeMode={'contain'}
        source={iconMapperToImage()}
      />
    </View>
  );

  const marginLeft = {marginLeft: showIcon ? 65 : 0};

  return (
    <View>
      <View style={[chatStyles.container]}>
        <Text style={[chatStyles.text, marginLeft]}>{text}</Text>
        {showIcon && <ImagesContainer />}
      </View>
    </View>
  );
};

export default function DefaultChatBubble(props) {
  let text = props.currentMessage.data;
  let icon = props.currentMessage.icon;
  let isValid = props.isValid;

  const [showIcon, setShowIcon] = useState(true);
  useEffect(() => {
    if (isInAnswered()) {
      setShowIcon(false);
    } else {
      setShowIcon(true);
    }
  }, [props.answeredQuestions, props.tag]);

  const isInAnswered = () => {
    const list = props.answeredQuestions.filter((el) => el === props.tag);
    return list.length > 0;
  };

  const iconMapperToImage = () => {
    return icon;
  };

  switch (props.currentMessage.step) {
    case ENTER_FIRST_NAME:
    case ENTER_LAST_NAME:
    case ADD_PROFILE_SEX:
    case ENTER_EMAIl:
    case ENTER_NICKNAME:
    case ENTER_BIRTHDATE:
    case ENTER_BIRTH_MONTH:
    case ENTER_COUNTRY:
    case ENTER_PROVINCE:
    case ENTER_CITY:
    case ENTER_PRONOUN:
    case ENTER_RELATIONSHIP:
    case SELECT_DISEASE_START_DATE:
    case SELECT_RECOVER_DATE:
    case ENTER_HEIGHT:
    case ENTER_WEIGHT:
    case SMOKING:
    case DRINKING:
    case IS_TRAVELLED:
    case SUBMIT_WEIGHT:
    case SUBMIT_HEIGHT:
    case FINISH:
    case INITIAL_STAGE:
    case ASSESSMENT_QUESTION_ANSWER_STAGE:
    case ENTER_GENDER:
    case SECOND_PASS:
      return (
        <AuthChatBubble
          {...props}
          text={text}
          iconMapperToImage={iconMapperToImage}
          showIcon={showIcon}
        />
      );
    case FINAL_ONBOARDING_SCREEN:
      return (
        <RegisterCompleteGreetingsMessage
          {...props}
          text={text}
          iconMapperToImage={iconMapperToImage}
          showIcon={showIcon}
        />
      );
    case FINAL_ONBOARDING_SCREEN_ADD_PROFILE:
      return (
        <AddProfileCompleteGreetingsMessage
          {...props}
          text={text}
          iconMapperToImage={iconMapperToImage}
          showIcon={showIcon}
        />
      );
    case FINAL_ONBOARDING_SCREEN_LIFESTYLE:
      return (
        <LifeStyleCompleteMessage
          {...props}
          text={text}
          iconMapperToImage={iconMapperToImage}
          showIcon={showIcon}
        />
      );
    case FAMILY_HISTORY_COMPLETED:
      return (
        <FamilyHistoryCompletedMessage
          text={'text'}
          iconMapperToImage={iconMapperToImage}
          showIcon={showIcon}
          {...props}
        />
      );
  }
}
