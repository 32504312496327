import React from 'react';
import {UnorderedPrimaryListItem} from 'modules';

interface IRenderListProps {
  items?: Array<string>;
  checkForFirstItem?: boolean;
}

export function RenderList({
  items,
  checkForFirstItem = true,
}: IRenderListProps) {
  return items?.map((textItem, index) =>
    checkForFirstItem && index === 0 && textItem?.startsWith('#') ? null : (
      <UnorderedPrimaryListItem key={index} text={textItem} />
    ),
  );
}
