import {isWeb} from 'config';
import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import SplashScreen from 'react-native-splash-screen';
import {PLATFORM_URL, textStyles, viewStyles} from 'utils';
import {DefaultButton} from '../components';

export default function PlatformReLogin({checkPlatformLogin}) {
  useEffect(() => {
    if (!isWeb) {
      SplashScreen.hide();
    }
  }, []);
  return (
    <View
      style={[
        viewStyles.flex1,
        viewStyles.justifyContentCenter,
        viewStyles.alignItemsCenter,
      ]}>
      <View style={[viewStyles.width90P, viewStyles.height300]}>
        <Text
          style={[
            textStyles.fontSize22,
            textStyles.colorTextPrimary,
            textStyles.fontFamilyMARKPRO_BOLD,
            textStyles.textAlignCenter,
          ]}>
          Platform Login
        </Text>
        <Text
          style={[
            textStyles.fontSize17,
            textStyles.colorTextBlack,
            textStyles.fontFamilyMARKPRO_MEDIUM,
            textStyles.textAlignCenter,
            viewStyles.paddingTop10,
            viewStyles.paddingBottom30,
          ]}>
          Something went wrong, please try again
        </Text>
        <DefaultButton
          name={'Try Again'}
          click={async () => {
            await checkPlatformLogin(PLATFORM_URL);
          }}
        />
      </View>
    </View>
  );
}
