import React from 'react';
import DatePicker from '@react-native-community/datetimepicker';
import {View} from 'react-native';

function DateTimePicker({
  title,
  onChangeText,
  value,
  mode,
  format,
  error,
  minDate,
  type,
  maxDate,
}) {
  let options = {};
  if (minDate) {
    options.minDate = minDate;
  }
  return (
    <View>
      <DatePicker
        value={value}
        mode={mode}
        dateFormat={'DD-MM-YYYY'}
        display={type}
        textColor={'black'}
        maximumDate={maxDate}
        minimumDate={minDate}
        placeholderText="Select Date"
        onChange={(event, date) => {
          onChangeText(event, date);
        }}
      />
    </View>
  );
}

export default DateTimePicker;
