import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {fma54348, fma60546, fma60866} from 'utils';
import {getColorFma} from 'utils';

export const Small_Toe = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1242 2208">
      <Path
        id="Path_629"
        dataName="Path 629"
        d="M916.4,1465.8c0,52.3-2,104.5-4.8,151.1c-1.5,25.3-22.4,45-47.8,45H379.6c-25.5,0-46.4-19.9-47.8-45.4  c-2.3-43.6-3.9-94.3-3.9-150.7c0-72,28.2-237.3,28.2-308.5c0-70.6-34.7-213.6-34.7-295.8c0-262.8,166.8-315.3,303-315.3  c129.2,0,293.6,51.1,293.6,315.3c0,131-31.8,221-31.8,295.8C886.3,1197.1,916.4,1332.2,916.4,1465.8z"
        fill="#ead9e7"
        style="mix-blend-mode: multiply;isolation: isolate"
      />
      <Path
        id={fma60546}
        dataName="Path 631"
        d="M858.5,1566.7H386.7c-32.8,0-59.3-26.7-59-59.5c1.5-127.9,31.9-304.4,27.9-352.2c-1.7-19.7-5.3-51.8-8.3-78  c-3.1-26.2,11.6-51.3,36.1-61.3c52.9-21.8,148.9-55.1,241.8-55.1c82.4,0,185,44.8,238.3,75.8c20.7,12.1,31.7,35.6,28.4,59.3  c-2.5,17.6-4.8,37.9-5.5,61.4c-1.1,38.1,34.4,203.6,31.1,352.3C916.8,1541.4,890.4,1566.7,858.5,1566.7z"
        fill={getColorFma(fma60546, selectedBodyPart)}
        onPressIn={() => onPressClick(fma60546)}
        onClick={() => onPressClick(fma60546)}
      />
      <Path
        id="Path_632"
        dataName="Path 632"
        d="M794.6,612.2c5.1,36.3,10.5,132.6-12.2,160c-61.7,74.6-259,79.3-319.9,0c-21.7-28.2-16.4-115.4-12-160.3"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id={fma54348}
        dataName="Path 661"
        d="M477,760.6c56,74.5,233.8,69.5,291.3,0c21.1-25.6,16.1-115.3,11.3-149.2c0,0-149.9-60.2-313.8-0.2  C465.8,611.2,456.9,733.8,477,760.6z"
        fill={getColorFma(fma54348, selectedBodyPart)}
        onPressIn={() => onPressClick(fma54348)}
        onClick={() => onPressClick(fma54348)}
      />
      <Path
        id={fma60866}
        dataName="Path 662"
        d="M431.2,619.2l-0.8,108.1c-0.1,19.4,5.8,38.4,17.4,53.9c0.4,0.6,0.8,1,1.1,1.5c72.8,91.9,263.8,87.6,344,6.5  c3.6-3.6,7.7-9,9.8-15.2c0,0,15.7-13.2,14.4-97c-0.3-18.6-0.6-34.2-0.9-47.3c-0.2-10.7,12.5-16.5,20.3-9.2  c50.2,47.1,81.6,119.3,81.6,240.9c-0.6,22.1-1.9,49.3-3.4,74.8c-2.6,29.6-6.5,56.8-10.5,82.2c-7,9.7-20.3,14-32.3,8.5  c-53.7-24.5-140.8-75.6-252.7-75.6c-109.8,0-199.9,42.6-251.4,62.5c-11.4,4.4-23.5,0.3-30.3-8.5c-4.3-27.3-8.5-54.7-11.5-80.3  c-1.9-27.3-3.4-56.5-4.2-78.8c3.1-118.5,39.2-190,92.9-234.6C421.3,606,431.3,610.6,431.2,619.2z"
        fill={getColorFma(fma60866, selectedBodyPart)}
        onPressIn={() => onPressClick(fma60866)}
        onClick={() => onPressClick(fma60866)}
      />
    </Svg>
  );
};
