import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {
  fma24486,
  fma297439,
  fma297445,
  fma305666,
  fma35464,
  fma44622,
  fma73055,
} from 'utils';
import {getColorFma} from 'utils';
import 'react-native-gesture-handler';

export const Knee_Right_Front_fma24977 = ({selectedBodyPart, onPressClick}) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.915 151.985">
      <Path
        id="Path_452"
        dataName="Path 452"
        d="M7.167,0A2.664,2.664,0,0,0,4.505,3.057c.958,6.433,3.119,21.1,3.683,26.178.733,6.585-.244,34.629-.974,38.287s-1.707,21.46-.244,33.409C8.4,112.572,1.488,117.27,0,144.784a2.671,2.671,0,0,0,2.063,2.756c8.482,1.936,40.052,8.269,62.853,1.14a2.678,2.678,0,0,0,1.887-2.74c-.45-6.386-1.586-25.932.641-34.035,1.793-6.52,12.439-30.971,13.412-57.552C81.645,33.01,84,11.353,84.9,3.646A2.661,2.661,0,0,0,82.561.695a2.839,2.839,0,0,0-.29-.018Z"
        transform="translate(0 0)"
        fill="#ead9e7"
      />

      <Path
        id={fma24486}
        dataName="Path 459"
        d="M26.967,72.4l.486,23.411s-.2,6.84-.755,14.76l21.987,1.959a102.469,102.469,0,0,1,4.13-15.5s3.414-10.973,6.34-22.679C59.155,74.35,44.28,62.889,26.967,72.4Z"
        transform="translate(0 0)"
        fill={getColorFma(fma24486, selectedBodyPart)}
        onPressIn={() => onPressClick(fma24486)}
        onClick={() => onPressClick(fma24486)}
      />

      <Path
        id={fma73055}
        dataName="Path 460"
        d="M27.027,70.936c16.887-9.389,32.494,1.89,32.494,1.89l5.548,1.219,10.3-22.984C47.081,30.82,17.514,42.1,17.514,42.1L23.062,71Z"
        transform="translate(0 0)"
        fill={getColorFma(fma73055, selectedBodyPart)}
        onPressIn={() => onPressClick(fma73055)}
        onClick={() => onPressClick(fma73055)}
      />

      <Path
        id={fma305666}
        dataName="Path 461"
        d="M6.564,96.689l19.548-.879L25.624,72.4H21.6l-5.61-30L8.36,45.075c-.2,9.7-.716,20.294-1.146,22.447C6.564,70.767,5.725,85.143,6.564,96.689Z"
        transform="translate(0 0)"
        fill={getColorFma(fma305666, selectedBodyPart)}
        onPressIn={() => onPressClick(fma305666)}
        onClick={() => onPressClick(fma305666)}
      />

      <Path
        id={fma44622}
        dataName="Path 462"
        d="M65.74,75.57,60.5,74.655c-2.927,11.706-6.341,22.679-6.341,22.679l17.091,3.419c3.784-11.135,8.947-28.268,9.612-46.4.008-.217.018-.434.027-.651l-3.928-2.031Z"
        transform="translate(0 0)"
        fill={getColorFma(fma44622, selectedBodyPart)}
        onPressIn={() => onPressClick(fma44622)}
        onClick={() => onPressClick(fma44622)}
      />

      <Path
        id={fma35464}
        dataName="Path 463"
        d="M24.039,130.926c9.268,4.634,22.924,2.2,22.924,2.2s-.514-7.42,1.706-19.387L26.684,111.78C26.16,119.183,25.335,126.33,24.039,130.926Z"
        transform="translate(0 0)"
        fill={getColorFma(fma35464, selectedBodyPart)}
        onPressIn={() => onPressClick(fma35464)}
        onClick={() => onPressClick(fma35464)}
      />

      <Path
        id={fma297445}
        dataName="Path 464"
        d="M6.687,97.909c.106,1.468.118,1.674.283,3.022.914,7.467-1.6,12.087-3.911,21.695L22.7,129.951a107.912,107.912,0,0,0,2.865-19.387c.559-7.92.611-13.291.611-13.291Z"
        transform="translate(0 0)"
        fill={getColorFma(fma297445, selectedBodyPart)}
        onPressIn={() => onPressClick(fma297445)}
      />

      <Path
        id={fma297439}
        dataName="Path 465"
        d="M53.548,98.736A82.284,82.284,0,0,0,49.89,112.7a79.752,79.752,0,0,0-1.463,20c6.289,0,12.453-3.007,17.69-5.851.033-5.792.366-11.435,1.33-14.938.571-2.073,1.467-4.443,3.232-9.633Z"
        transform="translate(0 0)"
        fill={getColorFma(fma297439, selectedBodyPart)}
        onPressIn={() => onPressClick(fma297439)}
        onClick={() => onPressClick(fma297439)}
      />
      <Path
        id="Path_466"
        dataName="Path 466"
        d="M27.941,77.276s-9.056,9.7,0,23.289"
        transform="translate(0 0)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Path
        id="Path_467"
        dataName="Path 467"
        d="M57.937,84.715s1.462,10.243-2.926,16.1"
        transform="translate(0 0)"
        fill="none"
        stroke="#241f21"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};
