import {useFocusEffect} from '@react-navigation/native';
import {isWeb} from 'config';
import {Icon, Text} from 'libs';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import {WebView} from 'react-native-webview';
import {connect} from 'react-redux';
import {
  changeAndroidStatusBarColor,
  Colors,
  TopBarSvg,
  TopIcon,
  viewStyles,
} from 'utils';

function StaticTextScreen(props) {
  useFocusEffect(
    React.useCallback(() => {
      changeAndroidStatusBarColor(Colors.topPurple);
    }, []),
  );

  return (
    <View style={[styles.privacyPolicyScreenContainer]}>
      <SafeAreaView
        style={[styles.safeAreaView]}
        forceInset={{bottom: 'never'}}>
        <View style={styles.headerContainer}>
          <View style={styles.headerTitleContainer}>
            <Text.Subtitle variant="medium" size="subtitle3">
              {props.route.params.title}
            </Text.Subtitle>
          </View>
          <TopIcon style={{...viewStyles.zIndex100}} />
          <View style={styles.iconCloseContainer}>
            <Icon.Close
              onPress={props.navigation.goBack}
              size={Icon.Size.Medium}
            />
          </View>
        </View>
        {!isWeb ? <TopBarSvg fillColor={Colors.topPurple} /> : null}
        <View style={styles.container}>
          <WebView
            useWebKit={true}
            bounces={false}
            injectedJavaScriptForMainFrameOnly={false}
            source={props.route.params.url}
            showsVerticalScrollIndicator={false}
          />
        </View>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {flex: 1},
  privacyPolicyScreenContainer: {flex: 1, backgroundColor: Colors.topPurple},
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  headerContainer: {
    height: isWeb ? 100 : 70,
    backgroundColor: Colors.topPurple,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTitleContainer: {
    position: 'absolute',
    top: 45,
  },
  iconCloseContainer: {
    position: 'absolute',
    right: 5,
    bottom: 18,
  },
});

const mapStateToProps = (state) => ({
  windowWidth: state.appConfig.windowWidth,
  windowHeight: state.appConfig.windowHeight,
});

export default connect(mapStateToProps)(StaticTextScreen);
