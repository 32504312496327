import {Spacer} from 'app/components';
import {isWeb} from 'config';
import {Text} from 'libs';
import {ICitation} from 'modules/user/hooks/useCitations';
import React from 'react';
import {ActivityIndicator, StyleSheet, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {Colors} from 'utils';
import {DiseaseCitationsEmptyContent} from './DiseaseCitationsEmptyContent';

interface iDiseaseCitationsContentProps {
  filteredCitations: ICitation[];
  setOpenedLink: (link: string) => void;
  loading: boolean;
}

function DiseaseCitationsContent({
  filteredCitations,
  setOpenedLink,
  loading,
}: iDiseaseCitationsContentProps) {
  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.contentContainer}>
      {filteredCitations.length === 0 && !loading ? (
        <DiseaseCitationsEmptyContent />
      ) : loading ? (
        <ActivityIndicator color={Colors.primary} size={25} />
      ) : (
        <>
          {filteredCitations.map((t) => {
            return (
              <View key={t.display_name}>
                <Text.Subtitle variant="bold" size="subtitle1">
                  {t.display_name}
                </Text.Subtitle>
                {t.source_links
                  ?.split('\n')
                  .join(' ')
                  .split(' ')
                  .map((link) => {
                    return (
                      <View key={link}>
                        <Text.Subtitle
                          color="blue"
                          onPress={() => {
                            isWeb
                              ? window.open(link, '_blank')
                              : setOpenedLink(link);
                          }}
                          variant="normal"
                          size="subtitle4">
                          {link}
                        </Text.Subtitle>
                        <Spacer height={10} color={Colors.transparent}></Spacer>
                      </View>
                    );
                  })}
                <Spacer height={10} color={Colors.transparent}></Spacer>
              </View>
            );
          })}
        </>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  contentContainer: {
    padding: 15,
    paddingVertical: 20,
  },
});

export default DiseaseCitationsContent;
