import React from 'react';
import {Colors} from 'utils';
import {IconPropsBase, IconSize} from './IconPropsBase';
import Icon from 'react-native-vector-icons/FontAwesome';

interface IconStarProps extends IconPropsBase {}

export function IconStar({
  size = IconSize.Medium,
  color = Colors.black,
  onPress,
}: IconStarProps) {
  return <Icon name="star" size={size} color={color} onPress={onPress} />;
}
