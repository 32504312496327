import {yupResolver} from '@hookform/resolvers/yup';
import {Button} from 'libs';
import React, {PropsWithChildren} from 'react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import * as Yup from 'yup';

interface IFormProps<TFieldsValues> {
  submitHandler: SubmitHandler<TFieldsValues>;
  validationSchema: Yup.ObjectSchema<{}>;
}

export function Form<TFieldsValues extends Record<string, any>>({
  children,
  submitHandler,
  validationSchema,
}: PropsWithChildren<IFormProps<TFieldsValues>>) {
  const formMethods = useForm<TFieldsValues>({
    resolver: yupResolver(validationSchema),
  });

  const {handleSubmit, formState} = formMethods;
  const {isDirty, isSubmitting, isValid} = formState;
  const isDisabled = !isDirty || !isValid || isSubmitting;

  return (
    <FormProvider {...formMethods}>
      {children}
      <Button.Primary
        onPress={handleSubmit(submitHandler)}
        disabled={isDisabled}
        title="Submit"
      />
    </FormProvider>
  );
}
